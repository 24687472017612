/* eslint-disable */
import { GET_USER_DETAILS_REQUEST, GET_USER_DETAILS_SUCCESS, GET_USER_DETAILS_ERROR } from '../actions/actionTypes';
import { GET_UPCOMING_INTERVIEWS_REQUEST, GET_UPCOMING_INTERVIEWS_SUCCESS, GET_UPCOMING_INTERVIEWS_ERROR } from '../actions/actionTypes';
import { GET_RECENT_ACTIVITIES_REQUEST, GET_RECENT_ACTIVITIES_SUCCESS, GET_RECENT_ACTIVITIES_ERROR } from '../actions/actionTypes';
import { GET_JOB_STATS_REQUEST, GET_JOB_STATS_SUCCESS, GET_JOB_STATS_ERROR } from '../actions/actionTypes';
import { GET_AGENCY_CLIENTS_REQUEST,GET_AGENCY_CLIENTS_SUCCESS,GET_AGENCY_CLIENTS_ERROR } from '../actions/actionTypes';
import { GET_CANDIDATE_STATS_REQUEST, GET_CANDIDATE_STATS_SUCCESS, GET_CANDIDATE_STATS_ERROR } from '../actions/actionTypes';

const initialState = {
    isGetUserDetailsSuccess: false,
    isGetUserDetailsError: false,
    userData: {},
    isUpcomingInterviewsSuccess: false,
    isUpcomingInterviewsError: false,
    upcomingInterviewsData: {},
    isRecentActivitiesSuccess: false,
    isRecentActivitiesError: false,
    recentActivitiesData: {},
    isCandidateStatsSuccess: false,
    isCandidateStatsError: false,
    candidateStatsData: {},
    isJobStatsSuccess: false,
    isJobStatsError: false,
    jobStatsData: {},
    agencyClients : {},
    isAgencyClientsSuccess : false,
    isAgencyClientsError : false,
    message: ''
}

export function dashboardReducer(state = initialState, { type, response }) {
    switch (type) {
        case GET_USER_DETAILS_REQUEST:
            return {
                ...state,
                isGetUserDetailsSuccess: false,
                isGetUserDetailsError: false,
                userData: {}
            };
        case GET_USER_DETAILS_SUCCESS:
            return {
                ...state,
                isGetUserDetailsSuccess: true,
                isGetUserDetailsError: false,
                userData: response
            };
        case GET_USER_DETAILS_ERROR:
            return {
                ...state,
                isGetUserDetailsSuccess: false,
                isGetUserDetailsError: true,
                userData: response
            };
        case GET_UPCOMING_INTERVIEWS_REQUEST:
            return {
                ...state,
                isUpcomingInterviewsSuccess: false,
                isUpcomingInterviewsError: false,
                upcomingInterviewsData: {}
            };
        case GET_UPCOMING_INTERVIEWS_SUCCESS:
            return {
                ...state,
                isUpcomingInterviewsSuccess: true,
                isUpcomingInterviewsError: false,
                upcomingInterviewsData: response
            };
        case GET_UPCOMING_INTERVIEWS_ERROR:
            return {
                ...state,
                isUpcomingInterviewsSuccess: false,
                isUpcomingInterviewsError: true,
                upcomingInterviewsData: response
            };
        case GET_RECENT_ACTIVITIES_REQUEST:
            return {
                ...state,
                isRecentActivitiesSuccess: false,
                isRecentActivitiesError: false,
                recentActivitiesData: {}
            };
        case GET_RECENT_ACTIVITIES_SUCCESS:
            return {
                ...state,
                isRecentActivitiesSuccess: true,
                isRecentActivitiesError: false,
                recentActivitiesData: response
            };
        case GET_RECENT_ACTIVITIES_ERROR:
            return {
                ...state,
                isRecentActivitiesSuccess: false,
                isRecentActivitiesError: true,
                recentActivitiesData: response
            };
        case GET_JOB_STATS_REQUEST:
            return {
                ...state,
                isJobStatsSuccess: false,
                isJobStatsError: false,
                jobStatsData: {}
            };
        case GET_JOB_STATS_SUCCESS:
            return {
                ...state,
                isJobStatsSuccess: true,
                isJobStatsError: false,
                jobStatsData: response
            };
        case GET_JOB_STATS_ERROR:
            return {
                ...state,
                isJobStatsSuccess: false,
                isJobStatsError: true,
                jobStatsData: response
            };
        case GET_AGENCY_CLIENTS_REQUEST:
            return {
                ...state,
                isAgencyClientsSuccess:false,
                isagencyClientsError:false,
                agencyClients: {}
            };
        case GET_AGENCY_CLIENTS_SUCCESS:
            return {
                ...state,
                isAgencyClientsSuccess:true,
                isagencyClientsError:false,
                agencyClients: response
            };
        case GET_AGENCY_CLIENTS_ERROR:
            return {
                ...state,
                isAgencyClientsSuccess:false,
                isagencyClientsError:true,
                agencyClients: response
            };
        case GET_CANDIDATE_STATS_REQUEST:
            return {
                ...state,
                isCandidateStatsSuccess: false,
                isCandidateStatsError: false,
                candidateStatsData: {}
            };
        case GET_CANDIDATE_STATS_SUCCESS:
            return {
                ...state,
                isCandidateStatsSuccess: true,
                isCandidateStatsError: false,
                candidateStatsData: response
            };
        case GET_CANDIDATE_STATS_ERROR:
                return {
                    ...state,
                    isCandidateStatsSuccess: false,
                    isCandidateStatsError: true,
                    candidateStatsData: response
                };
        default:
            return state;
    }
}