/* eslint-disable */
import { PORTAL_LANGUAGE } from './../actions/actionTypes';
import Content from '../../languages/en.json';

const initialState = {
    portalContent: Content,
    languageCode: (localStorage.getItem("languageCode") === null || localStorage.getItem("languageCode") === "undefined" ) ? 'en' : localStorage.getItem("languageCode")
};

export function languageReducer(state = initialState, { type, data }) {
    let pContent = {};

    switch (type) {
        case PORTAL_LANGUAGE:
            if (data.languageCode) {
                if (data.languageCode === 'en') {
                    pContent = Content;
                } else {
                    Object.entries(global.translatedContent[data.languageCode]).map(([key, value]) => {
                        pContent[key] = value;//[data.languageCode];
                    })
                }

                return {
                    ...state,
                    languageCode: data.languageCode ? data.languageCode : state.languageCode,
                    portalContent: pContent
                };
            }
        default:
            if (state.languageCode === 'en') {
                pContent = Content;

                return {
                    ...state,
                    languageCode: state.languageCode,
                    portalContent: pContent
                };
            } else {
                if (localStorage.getItem("translatedContent") !== null) {
                    Object.entries(JSON.parse(localStorage.getItem("translatedContent"))[state.languageCode]).map(([key, value]) => {
                        pContent[key] = value;
                    });

                    return {
                        ...state,
                        languageCode: state.languageCode,
                        portalContent: pContent
                    };
                }
                else {
                    pContent = Content;

                    return {
                        ...state,
                        languageCode: state.languageCode,
                        portalContent: pContent
                    };
                }
            }
    }
}
// Selectors
export const getPortalContent = (state) => state.languageReducer.portalContent;
export const getLanguageCode = (state) => state.languageReducer.languageCode;