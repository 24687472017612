import { takeLatest, put, call } from 'redux-saga/effects';
import { GET_ALL_JOBS_REQUEST } from '../actions/actionTypes';
import { getAllJobsSuccess, getAllJobsError } from '../actions/action';
import allJobsAPI from '../../apis/allJobsApi';

function* getAllJobs(params) {
  try {
    const response = yield call(allJobsAPI.getAllJobs, params);
    const data = yield response.data;
    // console.log("data: " + JSON.stringify(data));

    if (data.payload) yield put(getAllJobsSuccess(data.payload));
    else yield put(getAllJobsError(data));
  } catch (error) {
    yield put(getAllJobsError(error.message));
  }
}

function* allJobsSaga() {
  yield takeLatest(GET_ALL_JOBS_REQUEST, getAllJobs);
}
export default allJobsSaga;
