/* eslint-disable */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Overlay from "../../components/overlay"
import styles from "./styles.module.scss";
import classNames from "classnames";
import close from "../../images/closeIconBlack.svg";

function FilterModal(props) {
  const {
    filterData,
    showFilterModal,
    closeFilterModal,
    filterOptionsData,
    content
  } = props;
  const [filterOptions, setFilterOptions] = useState(filterOptionsData);
  const [filterCriteria, setFilterCriteria] = useState({});
  const [showMoreField, setShowMoreField] = useState(null);

  const sortComparator = (a, b) => {
    if (Array.isArray(a.value) && Array.isArray(b.value)) {
      return a.value[0] - b.value[0];
    } else if (typeof a.value === 'number' && typeof b.value === 'number') {
      return a.value - b.value;
    }
    return a.label.localeCompare(b.label);
  };

  useEffect(() => {
    setFilterOptions(() => {
      const sortedOptions = filterOptionsData.map((filter) => {
        if(!filter?.options)return {...filter};
        const sortedFilterOptions = [...filter?.options].sort(sortComparator);
        return { ...filter, options: sortedFilterOptions };
      });
      return sortedOptions;
    });
  },[]);

  const updateFilterCriteria = (field, value) => {
    setFilterCriteria((prevFilterCriteria) => {
      const updatedSelection = Array.isArray(prevFilterCriteria[field])
        ? prevFilterCriteria[field].includes(value)
          ? prevFilterCriteria[field].filter((item) => item !== value)
          : [...prevFilterCriteria[field], value]
        : [value];

      const updatedFilterCriteria = {
        ...prevFilterCriteria,
        [field]: updatedSelection,
      };
  
      filterOptions.forEach((filterOption) => {
        if (filterOption.dependent === field) {
          const dynamicOptions = getDynamicOptions(filterOption, updatedSelection);
          filterOption.options = dynamicOptions.sort(sortComparator);

          const changedFieldOptions = getDynamicOptions(filterOption,[value]);
          const finalCriteria = updatedFilterCriteria[filterOption.value]?.filter((item) => 
                      {
                        return !changedFieldOptions.some((changedOption) => changedOption.value === item);
                      });
          updatedFilterCriteria[filterOption.value] = finalCriteria;
        }
      });
  
      return updatedFilterCriteria;
    });
  };

  const handleMultiSelectChange = (field, value) => {
    if (filterOptions.find((option) => option.value === field)?.selection === "multi") {
      updateFilterCriteria(field, value);
    } else {
      setFilterCriteria((prevFilterCriteria) => {
        return {
          ...prevFilterCriteria,
          [field]: prevFilterCriteria[field]?.[0] === value ? [] : [value]
        }});
    }
  };

  const getDynamicOptions = (filterOption, selectedValues) => {
    let availableOptions = [];
    if (filterOption?.dynamicOptions && filterOption?.dependent) {
      selectedValues.forEach((val)=>{
        availableOptions = availableOptions.concat(filterOption.dynamicOptions(val));
      })     
    }
    return availableOptions;
  };

  const renderOptions = (filterOption) => {
    const maxOptionsToShow = 9; 
    const optionsToDisplay = showMoreField === filterOption.value
      ? filterOption.options
      : filterOption?.options?.slice(0, maxOptionsToShow);
    const remainingOptions = filterOption?.options?.slice(maxOptionsToShow);
  
    return (
      <div className={styles.filterSelectBtnsDiv}>
        {optionsToDisplay?.map((option, optionIndex) => (
          <button
            key={optionIndex}
            className={classNames(
              filterCriteria?.[filterOption?.value]?.includes(option?.value)
                ? styles.filterSelectedButton
                : styles.filterSelectBtns, "body-3"
            )}
            type="button"
            onClick={() =>
              handleMultiSelectChange(filterOption?.value, option.value)
            }
          >
            <span aria-label={option.label}>{option.label}</span>
          </button>
        ))}
        {remainingOptions?.length > 0 && (
          <div className={styles.showMoreLessButtonsWrapper}>
            <button
              className={styles.showMoreLessButton}
              onClick={() => (showMoreField ? handleShowLess() : handleShowMore(filterOption))}
            >
              {showMoreField ? "Show Less" : "Show More"} ...
            </button>
          </div>
        )}
      </div>
    );
  };
  
  const handleShowMore = (filterOption) => {
    setShowMoreField(filterOption.value);
  };
  
  const handleShowLess = () => {
    setShowMoreField(null);
  };

  const handleClearFilter = () => {
    setFilterCriteria({});
    filterOptions.forEach((filterOption) => {
      if (filterOption.dependent?.length>0) {
        delete filterOption.options;
      }
    });
  }

  return (
    <Overlay
      isOpen={showFilterModal}
      showCloseIcon={false}
      onClose={closeFilterModal}
    >
      <div>
        <div className={styles.filterModalHeader}>
          <div className={classNames(styles.filterModalTitle,"heading-3")}>
            {content.FILTER}
          </div>
          <img
              className={styles.closeButton}
              src={close}
              onClick={() => closeFilterModal(false)}
              alt="close"
            />
        </div>
        <div className={styles.modalBodyStyle}>
          {filterOptions?.map((filterOption, index) => (
            <div key={index}>
              <div className={styles.formGroup}>
                {<label className={classNames(styles.modalFieldName,"subtitle-3")}>{filterOption?.heading}</label>}
                {(!filterOption?.options || filterOption?.options?.length === 0) && 
                <div className={classNames(styles.emptyMsg,"body-1")}>{filterOption?.emptyStateMsg}</div>}   
                {renderOptions(filterOption)}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.filterModalFooter}>
          <button
            variant="secondary"
            className={classNames(styles.filterFooterCancelBtn,"body-4")}
            onClick={() => handleClearFilter()}
          >
            {content.CLEAR_FILTERS}
          </button>
          <button
            variant="primary"
            className={classNames("button-primary","button-text")}
            onClick={()=>filterData(filterCriteria)}
          >
            {content.APPLY_FILTERS}
          </button>
        </div>
      </div>
    </Overlay>
  );
}

const mapStateToProps = (state) => {
  return {
    content: state.languageReducer.portalContent,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FilterModal));
