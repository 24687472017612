import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { REQUEST_DEMO } from '../../services/constants';
import { EMAIL, PHONE } from '../../utils/constants';
import { getPortalContent } from '../../redux/reducers/languageReducer';
import styles from './styles.module.scss';

import Login from './login';
import PhoneNumberLogin from './phoneNumberLogin';
import { isIndianPhoneNumber } from '../../utils/countryCodeList';
import Helper from '../../services/helper';

const IS_REGION_INDIA = isIndianPhoneNumber();

function loginWall() {
  const portalContent = useSelector(getPortalContent);
  const [loginType, setLoginType] = useState(IS_REGION_INDIA ? PHONE : EMAIL);
  const [otpRetry, setOtpRetry] = useState(3);
  const [allowCreateAccount, setAllowCreateAccount] = useState(true);

  const handleLoginTypeChange = (type = EMAIL) => {
    if (typeof (type) === 'string') {
      setLoginType(type);
    } else {
      throw TypeError(`allowed type is string and given ${typeof (type)}`);
    }
  };

  useEffect(() => {
    const returnUrl = Helper.getUrlParameter('returnUrl');
    if (returnUrl)setAllowCreateAccount(false);
  }, []);

  return (
    <div className={styles.loginWallContainer}>
      {loginType === EMAIL && <Login changeLoginType={handleLoginTypeChange} />}
      {loginType === PHONE && <PhoneNumberLogin otpRetry={otpRetry} setOtpRetry={setOtpRetry} changeLoginType={handleLoginTypeChange} allowLoginWithEmail={allowCreateAccount} />}
      {allowCreateAccount && (
      <div className={classnames(styles.contact, styles.footer, 'contact-us')}>
        {portalContent.DONT_HAVE_ACC}
        <a href={REQUEST_DEMO} className={styles.contactUsLabel}>
          <b>{portalContent.REQUEST_A_DEMO}</b>
        </a>
      </div>
      )}
    </div>
  );
}

export default loginWall;
