import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import fallBackUserImg from '../../../images/user.png';

function CandidateCard(props) {
  const {
    candidate, content, index, handleStageChange, openProfileModal,
  } = props;

  return (
    <div key={index} className={styles.candidateCard}>
      <div className={styles.cardBody}>
        <img
          onClick={() => {
            openProfileModal(candidate.candidateId, candidate.subStageId);
          }}
          src={candidate.profilePic ? candidate.profilePic : fallBackUserImg}
          alt="candidateProfileImg"
          className={styles.profileImg}
          onError={(e) => {
            e.target.src = fallBackUserImg;
          }}
        />
        <div
          onClick={() => {
            openProfileModal(candidate.candidateId, candidate.subStageId);
          }}
          className={classNames('body-2', styles.cardTitleName)}
        >
          {candidate.name.length > 16
            ? `${candidate.name.substring(0, 15)}...`
            : candidate.name}
        </div>
        <div className={classNames('body-3', styles.cardTitleExp)}>
          {candidate.experience ? `${candidate.experience} Exp` : 'NA'}
        </div>
        <button
          className={classNames(
            'button-text button-secondary',
            styles.cardButton,
          )}
          type="button"
          onClick={() => handleStageChange(
            candidate,
            candidate.isShortlisted ? 'Submitted to client' : 'Shortlisted',
            true,
            index,
          )}
        >
          {candidate.isShortlistReqPending ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <>
              {candidate.isShortlisted ? '-' : '+'}
              {candidate.isShortlisted
                ? ` ${content.REMOVE_FROM_SHORTLIST}`
                : ` ${content.ADD_TO_SHORTLIST}`}
            </>
          )}
        </button>
      </div>
    </div>
  );
}

export default CandidateCard;
