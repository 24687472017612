import React from 'react';
import {
  CardBody,
  Row,
  Col,
} from 'reactstrap';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { getFunnelUrl } from './helpers';

import styles from './styles.module.scss';

const CardBodyJobStats = ({
  content, data, funnelParams,
}) => {
  const getCount = (stageIndex) => data?.applicationStages
    && data?.applicationStages[stageIndex]?.count;

  return (
    <CardBody className={styles.jobBody}>
      <Row className={styles.jobBodyRow}>
        {[100, 200, 300, 400, 500, 600].map((stage, stageIndex) => (
          <Col key={stage} className={styles.jobCategory}>
            <Link
              to={() => getFunnelUrl(stage, funnelParams)}
              className={classNames(styles.frame14, 'linkAppearanceNone')}
            >
              <div className="button-text">
                {content[`STAGE_${stage}`]}
              </div>
              <b className={styles.countTag}>
                {getCount(stageIndex)}
              </b>
            </Link>
          </Col>
        ))}
      </Row>
    </CardBody>
  );
};

export default CardBodyJobStats;
