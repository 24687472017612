import axios from 'axios';

import { AUTH_ENDPOINT, REFRESHTOKEN, TOKEN } from './constants';
import Helper from './helper';
import { HTTP_METHOD } from '../utils/constants';

const axiosInstance = axios.create();

let refreshSubscribers = [];
let refreshAttempts = 0;
const MAX_REFRESH_ATTEMPTS = 2; // Maximum number of attempts to refresh the token

// Flag to prevent multiple token refresh requests
let isRefreshing = false;

const getRefreshToken = async () => {
  let newIdToken = null;
  try {
    const token = Helper.getItem(TOKEN);

    const response = await axiosInstance.post(
      `${AUTH_ENDPOINT}/auth/updateToken`,
      { refreshToken: Helper.getItem(REFRESHTOKEN) },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      },
    );

    newIdToken = response?.data?.payload?.token;
    if (newIdToken) {
      Helper.saveItem(TOKEN, newIdToken);
    }
  } catch (error) {
    newIdToken = null;
    throw error;
  }
  return newIdToken;
};

async function refreshAccessToken() {
  const newAccessToken = await getRefreshToken();

  // Reset the refresh token state
  isRefreshing = false;

  refreshSubscribers.forEach((callback) => callback(newAccessToken));
  refreshSubscribers = [];

  return newAccessToken;
}

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const { skipAuth = false } = originalRequest.method === HTTP_METHOD.GET ? originalRequest.params : JSON.parse(originalRequest.data);
    if (
      error.response
      && error.response.status === 401
      && !skipAuth
    ) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const newAccessToken = await refreshAccessToken();
          originalRequest.headers.Authorization = newAccessToken;
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          Helper.lastVisitedRoute();
          Helper.clearStorage();
          window.location.href = '/';
          throw refreshError;
        } finally {
          // Increment the refresh attempts
          refreshAttempts += 1;
          // Reset the refresh token state after the maximum attempts
          if (refreshAttempts >= MAX_REFRESH_ATTEMPTS) {
            isRefreshing = false;
            refreshAttempts = 0;
          }
        }
      } else if (error.response.data.authType === 'refreshTokenExpired') {
        Helper.lastVisitedRoute();
        Helper.removeStorageItem(REFRESHTOKEN);
        Helper.removeStorageItem(TOKEN);
        window.location.href = '/';
        return undefined;
      }
      return new Promise((resolve, reject) => {
        // Reject the request after the maximum attempts
        if (refreshAttempts >= MAX_REFRESH_ATTEMPTS) {
          reject(error);
        } else {
          refreshSubscribers.push((newAccessToken) => {
            originalRequest.headers.Authorization = newAccessToken;
            resolve(axiosInstance(originalRequest));
          });
        }
      });
    }

    // Return a Promise rejection if the status code is not 401
    return Promise.reject(error);
  },
);

export default axiosInstance;
