/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  FormGroup,
  Input,
  Row,
  Col,
  InputGroupText,
  InputGroup,
} from 'reactstrap';
import { updatePasswordRequest as updatePasswordRequestAction } from '../../redux/actions/action';
import Helper from '../../services/helper';
import 'react-toastify/dist/ReactToastify.css';
import styles from './styles.module.scss';
import Logo from '../../images/ternLogo.svg';
import eye from '../../images/eye.svg';
import hide from '../../images/hide.svg';
import AlertMessage from '../../subViews/alertMessage';
import exclamation from '../../images/exclamation.svg';

function UpdatePassword(props) {
  const { content } = props;
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [updatePasswordShow, setUpdatePasswordShown] = useState(false);
  const [updateNewPasswordShown, setUpdateNewPasswordShown] = useState(false);
  const [updatePassword, setUpdatePassword] = useState('');
  const [updateConfirmPasswordShown, setUpdateConfirmPasswordShown] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState();
  const [isSuccessAlertMsg, setIsSuccessAlertMsg] = useState(null);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const prevProps = useRef(props);
  const validatePasswords = () => {
    const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*()])(?=.*[A-Z])(?=.*[a-z]).{8,}$/;
    const isPasswordValid = passwordRegex.test(newPassword);
    if (password === '' || newPassword === '' || updatePassword === '') {
      setErrorMsg(content.PLEASE_ENTER_PASSWORD);
      setIsLoading(false);
      return false;
    } if (password === newPassword) {
      setErrorMsg(content.PLEASE_UPDATE_YOUR_PASSWORD);
      setIsLoading(false);
      return false;
    } if (newPassword !== updatePassword) {
      setErrorMsg(content.NEW_PWD_AND_CONFIRM_PWD_NOT_MATCH);
      setIsLoading(false);
      return false;
    } if (!isPasswordValid) {
      setErrorMsg(content.INVALID_PASSWORD_INPUT);
      setIsLoading(false);
      setIsPasswordValid(false);
      return false;
    } setErrorMsg(null);
    return true;
  };
  const handleUpdatePassword = async () => {
    const { updatePasswordRequest } = props;
    setIsPasswordValid(true);
    setIsLoading(true);
    if (!validatePasswords()) return;
    const encryptedPassword = Helper.cryptoEncryption(password);
    const encryptednewPassword = Helper.cryptoEncryption(newPassword);
    const encryptedupdatePassword = Helper.cryptoEncryption(updatePassword);

    var updatePasswordObj = {
      oldPassword: encryptedPassword,
      newPassword: encryptednewPassword,
      confirmNewPassword: encryptedupdatePassword,
    };

    await updatePasswordRequest(updatePasswordObj);
  };

  useEffect(() => {
    const clientId = Helper.getItem('clientId');
    if (!clientId) {
      Helper.clearStorage();
      Helper.lastVisitedRoute();
      history.push("/");
      return;
    }
    const {
      isUpdatePasswordSuccess: prevIsUpdatePasswordSuccess,
      isUpdatePasswordError: prevIsUpdatePasswordError,
    } = prevProps.current;
    const {
      isUpdatePasswordSuccess,
      isUpdatePasswordError,
      updatePasswordData,
    } = props;

    if (prevIsUpdatePasswordSuccess === false && isUpdatePasswordSuccess) {
      setIsLoading(false);
      Helper.clearStorage();
      setIsSuccessAlertMsg(true);
      setMessage('Password updated successfully !');
    }

    if (prevIsUpdatePasswordError === false && isUpdatePasswordError) {
      setIsLoading(false);
      setIsSuccessAlertMsg(false);
      setMessage(updatePasswordData?.responseMessage);
    }

    prevProps.current = props;
  }, [props.isUpdatePasswordSuccess, props.isUpdatePasswordError]);

  const onPressEnter = async (e) => {
    if (e.key === 'Enter') handleUpdatePassword();
  };

  return (
    <div className={styles.content}>
    

      <div className={styles.ternImg}>
        <Col md="12" className={styles.centeredContainer}>
          <img alt="logo" src={Logo} className={styles.logo} />
        </Col>
        <Col md="12" className={styles.centeredContainer}>
          <div className={classNames(styles.iconFooterText, 'heading-3')}>
            {content.UPDATE_PASSWORD}
          </div>
        </Col>
      </div>
      <br />
      {message && (
      <>
        <AlertMessage isSuccess={isSuccessAlertMsg} message={message} />
        {isSuccessAlertMsg && (
        <Row className={styles.centeredContainer}>
          <button
            className="button-primary button-text"
            color="warning"
            type="button"
            onClick={() => history.push('/login')}
          >
            {content.LOGIN_NOW}
          </button>
        </Row>
        )}
      </>
      )}

      {!isSuccessAlertMsg && (
      <Col md="3">
        <Row className={styles.centeredContainer}>
          <Col md="12">
            <FormGroup>
              <label className={styles.labelText}>
                {content.ENTER_OLD_PASSWORD}
              </label>

              <InputGroup>
                <Input
                  className={styles.password}
                  type={updatePasswordShow ? 'text' : 'password'}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={onPressEnter}
                />
                <InputGroupText className={styles.passwordIcon}>
                  <img
                    className={styles.visibleIcon}
                    onClick={() => setUpdatePasswordShown(!updatePasswordShow)}
                    src={!updatePasswordShow ? hide : eye}
                    alt="password visibility icon"
                  />
                </InputGroupText>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row className={styles.centeredContainer}>
          <Col md="12">
            <FormGroup>
              <label className={styles.labelText}>
                {content.ENTER_NEW_PASSWORD}
              </label>
              <InputGroup>
                <Input
                  className={styles.password}
                  type={updateNewPasswordShown ? 'text' : 'password'}
                  onChange={(e) => setNewPassword(e.target.value)}
                  onKeyDown={onPressEnter}
                />
                <InputGroupText className={styles.passwordIcon}>
                  <img
                    className={styles.visibleIcon}
                    onClick={() => setUpdateNewPasswordShown(!updateNewPasswordShown)}
                    src={!updateNewPasswordShown ? hide : eye}
                    alt="password visibility icon"
                  />
                </InputGroupText>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row className={styles.centeredContainer}>
          <Col md="12">
            <FormGroup>
              <label className={styles.labelText}>
                {content.CONFIRM_NEW_PASSWORD}
              </label>
              <InputGroup>
                <Input
                  className={styles.password}
                  type={updateConfirmPasswordShown ? 'text' : 'password'}
                  onChange={(e) => setUpdatePassword(e.target.value)}
                  onKeyDown={onPressEnter}
                />
                <InputGroupText className={styles.passwordIcon}>
                  <img
                    className={styles.visibleIcon}
                    onClick={() => setUpdateConfirmPasswordShown(
                      !updateConfirmPasswordShown,
                    )}
                    src={!updateConfirmPasswordShown ? hide : eye}
                    alt="password visibility icon"
                  />
                </InputGroupText>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        {errorMsg && isPasswordValid && (
              <div className={styles.errorText}>{errorMsg}</div>
              )}
              {errorMsg && !isPasswordValid && (
              <div style={{ display: 'flex', alignItems: 'flex-start' }} className={styles.errorText}>
                <img
                  src={exclamation}
                  alt="exclamation"
                  style={{ marginTop: '3px' }}
                />
                <span style={{ marginLeft: '10px' }}>
                  {content.INVALID_PASSWORD_INPUT}
                </span>
              </div>
              )}
        <Row className={styles.centeredContainer}>
          <Col >
            <button
              className={classNames(
                styles.submitBtn,
                'button-text',
                'button-primary',
              )}
              color="warning"
              type="button"
              onClick={handleUpdatePassword}
            >
              {isLoading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                content.UPDATE_PASSWORD
              )}
            </button>
          </Col>
        </Row>
      </Col>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  content: state.languageReducer.portalContent,
  languageCode: state.languageReducer.languageCode,
  isUpdatePasswordSuccess: state.authReducer.isUpdatePasswordSuccess,
  isUpdatePasswordError: state.authReducer.isUpdatePasswordError,
  updatePasswordData: state.authReducer.updatePasswordData,
});

const mapDispatchToProps = (dispatch) => ({
  updatePasswordRequest: (data) => dispatch(updatePasswordRequestAction(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(UpdatePassword));
