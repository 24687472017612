import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AGENCY_CLIENTS } from '../../services/constants';
import Helper from '../../services/helper';
import { getPortalContent } from '../../redux/reducers/languageReducer';
import EmptyJobsStats from './emptyJobsStats';
import ErrorJobsStats from './errorJobsStats';
import RenderShimmer from './renderShimmer';
import JobStatsCard from './jobStatsCard';

const JobStats = ({
  isJobStatsError,
  jobStatsData,
  isJobDetailsPage = false,
  onClickEmptyCard,
}) => {
  const history = useHistory();
  const content = useSelector(getPortalContent);

  const viewFilterJob = async (shortlistedData, index) => {
    const {
      applicationStages, jobCode, jobId, jobRoleCategory, clientId, title,
    } = jobStatsData?.jobs?.[index] ?? {};

    const jobFilter = {
      shortlistedData,
      stageCountsData: applicationStages,
      jobCode,
      currJobId: jobId,
      jobRoleCategory,
      agencySubClientId: clientId,
      jobTitle: title,
    };

    history.push({
      pathname: '/jobs',
      state: {
        storedState: jobFilter,
      },
    });
  };

  if (isJobStatsError) {
    return <ErrorJobsStats content={content} />;
  }

  const isJobStatsEmpty = jobStatsData?.jobs?.length === 0;
  if (isJobStatsEmpty) {
    return (
      <EmptyJobsStats
        content={content}
        onClickEmptyCard={onClickEmptyCard}
      />
    );
  }

  const renderJobStatsCard = () => {
    const jobs = jobStatsData?.jobs ?? [];
    const clientsList = JSON.parse(Helper.getItem(AGENCY_CLIENTS))?.clientsList ?? [];

    return jobs.map((data, jobIndex) => {
      const client = clientsList?.find((clientObj) => clientObj.id === data.clientId);

      const funnelParams = {
        jobCode: data?.jobCode,
        currJobId: data?.jobId,
        jobTitle: data?.title, // optional
      };

      return (
        <JobStatsCard
          data={data}
          content={content}
          client={client}
          jobStatsData={jobStatsData}
          jobIndex={jobIndex}
          isJobDetailsPage={isJobDetailsPage}
          viewFilterJob={viewFilterJob}
          funnelParams={funnelParams}
        />
      );
    });
  };

  const jobsCount = jobStatsData?.jobs?.length;
  if (jobsCount) {
    return renderJobStatsCard();
  }

  return <RenderShimmer />;
};

export default JobStats;
