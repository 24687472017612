/* eslint-disable max-len */
import { takeLatest, put, call } from 'redux-saga/effects';
import { LIST_POTENTIAL_APPLICANTS_REQUEST, DISASSOCIATE_APPLICANT_REQUEST } from '../actions/actionTypes';
import {
  listPotentialApplicantsSuccess, listPotentialApplicantsError, disassociateApplicantSuccess, disassociateApplicantError,
} from '../actions/action';
import addCandidatesApi from '../../apis/addCandidatesApi';

function* listPotentialApplicants(params) {
  try {
    const response = yield call(addCandidatesApi.listPotentialApplicants, params);
    const data = yield response.data;
    if (data.payload) yield put(listPotentialApplicantsSuccess({ ...data.payload }));
    else yield put(listPotentialApplicantsError(data));
  } catch (error) {
    yield put(listPotentialApplicantsError(error.message));
  }
}

function* disassociateApplicant(params) {
  try {
    const response = yield call(addCandidatesApi.disassociateApplicant, params);
    const data = yield response.data;

    if (data.message !== 'fail') yield put(disassociateApplicantSuccess(data.payload));
    else yield put(disassociateApplicantError(data));
  } catch (error) {
    yield put(disassociateApplicantError(error.message));
  }
}

function* addCandidateSaga() {
  yield takeLatest(LIST_POTENTIAL_APPLICANTS_REQUEST, listPotentialApplicants);
  yield takeLatest(DISASSOCIATE_APPLICANT_REQUEST, disassociateApplicant);
}
export default addCandidateSaga;
