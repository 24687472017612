/* eslint-disable max-len */
import {
  CANCEL_INTERVIEW_ERROR,
  CANCEL_INTERVIEW_REQUEST,
  CANCEL_INTERVIEW_SUCCESS,
  DELETE_INTERVIEW_ERROR,
  DELETE_INTERVIEW_REQUEST,
  DELETE_INTERVIEW_SUCCESS,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_ERROR,
  USER_SET_PASSWORD_REQUEST,
  USER_SET_PASSWORD_SUCCESS,
  USER_SET_PASSWORD_ERROR,
  USER_UPDATE_PASSWORD_REQUEST,
  USER_UPDATE_PASSWORD_SUCCESS,
  USER_UPDATE_PASSWORD_ERROR,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_ERROR,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_FORGOT_PASSWORD_ERROR,
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_ERROR,
  GET_UPCOMING_INTERVIEWS_REQUEST,
  GET_UPCOMING_INTERVIEWS_SUCCESS,
  GET_UPCOMING_INTERVIEWS_ERROR,
  GET_RECENT_ACTIVITIES_REQUEST,
  GET_RECENT_ACTIVITIES_SUCCESS,
  GET_RECENT_ACTIVITIES_ERROR,
  GET_CANDIDATE_STATS_REQUEST,
  GET_CANDIDATE_STATS_SUCCESS,
  GET_CANDIDATE_STATS_ERROR,
  GET_JOB_STATS_REQUEST,
  GET_JOB_STATS_SUCCESS,
  GET_JOB_STATS_ERROR,
  GET_CANDIDATE_PROFILE_REQUEST,
  GET_CANDIDATE_PROFILE_SUCCESS,
  GET_CANDIDATE_PROFILE_ERROR,
  GET_JOB_FUNNEL_REQUEST,
  GET_JOB_FUNNEL_REQUEST_SUCCESS,
  GET_JOB_FUNNEL_REQUEST_ERROR,
  CHANGE_APPLICATION_STAGE_REQUEST,
  CHANGE_APPLICATION_STAGE_REQUEST_SUCCESS,
  CHANGE_APPLICATION_STAGE_REQUEST_ERROR,
  GET_JOB_DETAILS_REQUEST,
  GET_JOB_DETAILS_SUCCESS,
  GET_JOB_DETAILS_ERROR,
  GET_ALL_JOBS_REQUEST,
  GET_ALL_JOBS_REQUEST_SUCCESS,
  GET_ALL_JOBS_REQUEST_ERROR,
  CHANGE_JOB_STATUS_REQUEST,
  CHANGE_JOB_STATUS_REQUEST_SUCCESS,
  CHANGE_JOB_STATUS_REQUEST_ERROR,
  LIST_POTENTIAL_APPLICANTS_REQUEST,
  LIST_POTENTIAL_APPLICANTS_REQUEST_SUCCESS,
  LIST_POTENTIAL_APPLICANTS_REQUEST_ERROR,
  SCHEDULE_CANDIDATE_INTERVIEW_REQUEST,
  SCHEDULE_CANDIDATE_INTERVIEW_SUCCESS,
  SCHEDULE_CANDIDATE_INTERVIEW_ERROR,
  DISASSOCIATE_APPLICANT_REQUEST,
  DISASSOCIATE_APPLICANT_SUCCESS,
  DISASSOCIATE_APPLICANT_ERROR,
  GET_AGENCY_CLIENTS_REQUEST,
  GET_AGENCY_CLIENTS_SUCCESS,
  GET_AGENCY_CLIENTS_ERROR,
  LIST_INTERVIEW_REQUEST,
  LIST_INTERVIEW_SUCCESS,
  LIST_INTERVIEW_ERROR,
  LIST_INTERVIEW_EMPTY,
  REQUEST_SERVICE_PACKAGE_REQUEST,
  REQUEST_SERVICE_PACKAGE_SUCCESS,
  REQUEST_SERVICE_PACKAGE_ERROR,
  REQUEST_REPORT_REQUEST,
  REQUEST_REPORT_SUCCESS,
  REQUEST_REPORT_ERROR,
  FETCH_INTERVIEW_DETAILS_ERROR,
  FETCH_INTERVIEW_DETAILS_REQUEST,
  FETCH_INTERVIEW_DETAILS_SUCCESS,
  PORTAL_LANGUAGE,
  REQUEST_REPORT_RESET,
  LIST_CONTACTS_REQUEST,
  LIST_CONTACTS_SUCCESS,
  LIST_CONTACTS_ERROR,
  UPDATE_JOB_REQUEST,
  UPDATE_JOB_SUCCESS,
  UPDATE_JOB_ERROR,
  GET_SPECIALIZATION_REQUEST,
  GET_SPECIALIZATION_SUCCESS,
  GET_SPECIALIZATION_ERROR,
  CREATE_CONTACT_REQUEST,
  CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT_ERROR,
  LIST_ALL_CANDIDATES_REQUEST,
  LIST_ALL_CANDIDATES_SUCCESS,
  LIST_ALL_CANDIDATES_ERROR,
  USER_LOGOUT_COMPLETE, LIST_SKILLS_REQUEST, LIST_SKILLS_SUCCESS, LIST_SKILLS_ERROR,
  USER_LOGIN_WITH_PHONE_REQUEST,
} from './actionTypes';

export const getPortalLanguage = (data) => ({
  type: PORTAL_LANGUAGE,
  data,
});

// users login
export function loginRequest(params) {
  return { type: USER_LOGIN_REQUEST, params, response: params };
}
export function loginWithPhoneRequest(params) {
  return { type: USER_LOGIN_WITH_PHONE_REQUEST, params, response: params };
}
export function loginSuccess(response) {
  return { type: USER_LOGIN_SUCCESS, response };
}
export function loginError(error) {
  return { type: USER_LOGIN_ERROR, response: error };
}

// users forgot password
export function forgotPasswordRequest(params) {
  return { type: USER_FORGOT_PASSWORD_REQUEST, params };
}
export function forgotPasswordSuccess(response) {
  return { type: USER_FORGOT_PASSWORD_SUCCESS, response };
}
export function forgotPasswordError(error) {
  return { type: USER_FORGOT_PASSWORD_ERROR, response: error };
}

// users set password
export function setPasswordRequest(params) {
  return { type: USER_SET_PASSWORD_REQUEST, params };
}
export function setPasswordSuccess(response) {
  return { type: USER_SET_PASSWORD_SUCCESS, response };
}
export function setPasswordError(error) {
  return { type: USER_SET_PASSWORD_ERROR, response: error };
}

// users update password
export function updatePasswordRequest(params) {
  return { type: USER_UPDATE_PASSWORD_REQUEST, params };
}
export function updatePasswordSuccess(response) {
  return { type: USER_UPDATE_PASSWORD_SUCCESS, response };
}
export function updatePasswordError(error) {
  return { type: USER_UPDATE_PASSWORD_ERROR, response: error };
}

// user logout
export function logoutRequest(params) {
  return { type: USER_LOGOUT_REQUEST, params };
}
export function logoutSuccess(response) {
  return { type: USER_LOGOUT_SUCCESS, response };
}
export function logoutError(error) {
  return { type: USER_LOGOUT_ERROR, response: error };
}
export function logoutComplete() {
  return { type: USER_LOGOUT_COMPLETE };
}

// user details
export function getUserDetailsRequest(params) {
  return { type: GET_USER_DETAILS_REQUEST, params };
}
export function getUserDetailsSuccess(response) {
  return { type: GET_USER_DETAILS_SUCCESS, response };
}
export function getUserDetailsError(error) {
  return { type: GET_USER_DETAILS_ERROR, response: error };
}

// dashboard

// upcoming interview
export function getUpcomingInterviewsRequest(params) {
  return { type: GET_UPCOMING_INTERVIEWS_REQUEST, params };
}
export function getUpcomingInterviewsSuccess(response) {
  return { type: GET_UPCOMING_INTERVIEWS_SUCCESS, response };
}
export function getUpcomingInterviewsError(response) {
  return { type: GET_UPCOMING_INTERVIEWS_ERROR, response };
}

// recent activities
export function getRecentActivitiesRequest(params) {
  return { type: GET_RECENT_ACTIVITIES_REQUEST, params };
}
export function getRecentActivitiesSuccess(response) {
  return { type: GET_RECENT_ACTIVITIES_SUCCESS, response };
}
export function getRecentActivitiesError(response) {
  return { type: GET_RECENT_ACTIVITIES_ERROR, response };
}

// candidate stats
export function getCandidateStatsRequest(params) {
  return { type: GET_CANDIDATE_STATS_REQUEST, params };
}
export function getCandidateStatsSuccess(response) {
  return { type: GET_CANDIDATE_STATS_SUCCESS, response };
}
export function getCandidateStatsError(response) {
  return { type: GET_CANDIDATE_STATS_ERROR, response };
}

// job stats
export function getJobStatsRequest(params) {
  return { type: GET_JOB_STATS_REQUEST, params };
}
export function getJobStatsSuccess(response) {
  return { type: GET_JOB_STATS_SUCCESS, response };
}
export function getJobStatsError(response) {
  return { type: GET_JOB_STATS_ERROR, response };
}

// agency clients list
export function getAgencyClientsRequest(params) {
  return { type: GET_AGENCY_CLIENTS_REQUEST, params };
}
export function getAgencyClientsSuccess(response) {
  return { type: GET_AGENCY_CLIENTS_SUCCESS, response };
}
export function getAgencyClientsError(response) {
  return { type: GET_AGENCY_CLIENTS_ERROR, response };
}

// candidate profile
export function getCandidateProfileRequest(params) {
  return { type: GET_CANDIDATE_PROFILE_REQUEST, params };
}
export function getCandidateProfileSuccess(response) {
  return { type: GET_CANDIDATE_PROFILE_SUCCESS, response };
}
export function getCandidateProfileError(response) {
  return { type: GET_CANDIDATE_PROFILE_ERROR, response };
}

export function scheduleCandidateInterviewRequest(params) {
  return { type: SCHEDULE_CANDIDATE_INTERVIEW_REQUEST, params };
}
export function scheduleCandidateInterviewSuccess(response) {
  return { type: SCHEDULE_CANDIDATE_INTERVIEW_SUCCESS, response };
}
export function scheduleCandidateInterviewError(response) {
  return { type: SCHEDULE_CANDIDATE_INTERVIEW_ERROR, response };
}

// jobs
// jobs openings
export function getJobFunnelRequest(params) {
  return { type: GET_JOB_FUNNEL_REQUEST, params };
}
export function getJobFunnelSuccess(response) {
  return { type: GET_JOB_FUNNEL_REQUEST_SUCCESS, response };
}
export function getJobFunnelError(response) {
  return { type: GET_JOB_FUNNEL_REQUEST_ERROR, response };
}

// change stage
export function changeApplicationStageRequest(params) {
  return { type: CHANGE_APPLICATION_STAGE_REQUEST, params };
}
export function changeApplicationStageRequestSuccess(response) {
  return { type: CHANGE_APPLICATION_STAGE_REQUEST_SUCCESS, response };
}
export function changeApplicationStageRequestError(response) {
  return { type: CHANGE_APPLICATION_STAGE_REQUEST_ERROR, response };
}

// job details
export function getJobDetailsRequest(params) {
  return { type: GET_JOB_DETAILS_REQUEST, params };
}
export function getJobDetailsSuccess(response) {
  return { type: GET_JOB_DETAILS_SUCCESS, response };
}
export function getJobDetailsError(response) {
  return { type: GET_JOB_DETAILS_ERROR, response };
}

export function changeJobStatusRequest(params) {
  return { type: CHANGE_JOB_STATUS_REQUEST, params };
}
export function changeJobStatusRequestSuccess(response) {
  return { type: CHANGE_JOB_STATUS_REQUEST_SUCCESS, response };
}
export function changeJobStatusRequestError(response) {
  return { type: CHANGE_JOB_STATUS_REQUEST_ERROR, response };
}

// all jobs
export function getAllJobsRequest(params) {
  return { type: GET_ALL_JOBS_REQUEST, params };
}
export function getAllJobsSuccess(response) {
  return { type: GET_ALL_JOBS_REQUEST_SUCCESS, response };
}
export function getAllJobsError(response) {
  return { type: GET_ALL_JOBS_REQUEST_ERROR, response };
}

// add-candidates
export function listPotentialApplicantsRequest(params) {
  return { type: LIST_POTENTIAL_APPLICANTS_REQUEST, params };
}
export function listPotentialApplicantsSuccess(response) {
  return {
    type: LIST_POTENTIAL_APPLICANTS_REQUEST_SUCCESS,
    response,
  };
}
export function listPotentialApplicantsError(response) {
  return { type: LIST_POTENTIAL_APPLICANTS_REQUEST_ERROR, response };
}
export function disassociateApplicantRequest(params) {
  return { type: DISASSOCIATE_APPLICANT_REQUEST, params };
}
export function disassociateApplicantSuccess(response) {
  return { type: DISASSOCIATE_APPLICANT_SUCCESS, response };
}
export function disassociateApplicantError(response) {
  return { type: DISASSOCIATE_APPLICANT_ERROR, response };
}

export function listInterviewRequest(params) {
  return { type: LIST_INTERVIEW_REQUEST, params };
}
export function listInterviewSuccess(response) {
  return { type: LIST_INTERVIEW_SUCCESS, response };
}
export function listInterviewError(response) {
  return { type: LIST_INTERVIEW_ERROR, response };
}

export function listInterviewEmpty(response) {
  return { type: LIST_INTERVIEW_EMPTY, response };
}

export function fetchInterviewDetailsRequest(params) {
  return { type: FETCH_INTERVIEW_DETAILS_REQUEST, params };
}

export function fetchInterviewDetailsSuccess(response) {
  return { type: FETCH_INTERVIEW_DETAILS_SUCCESS, response };
}
export function fetchInterviewDetailsError(response) {
  return { type: FETCH_INTERVIEW_DETAILS_ERROR, response };
}

export function deleteInterviewRequest(params) {
  return { type: DELETE_INTERVIEW_REQUEST, params };
}

export function deleteInterviewSuccess(response) {
  return { type: DELETE_INTERVIEW_SUCCESS, response };
}

export function deleteInterviewError(response) {
  return { type: DELETE_INTERVIEW_ERROR, response };
}

export function cancelInterviewRequest(params) {
  return { type: CANCEL_INTERVIEW_REQUEST, params };
}

export function cancelInterviewSuccess(response) {
  return { type: CANCEL_INTERVIEW_SUCCESS, response };
}

export function cancelInterviewError(response) {
  return { type: CANCEL_INTERVIEW_ERROR, response };
}

// Eamiler API
export function requestServicePackage(params) {
  return { type: REQUEST_SERVICE_PACKAGE_REQUEST, params };
}
export function requestServicePackageSuccess(response) {
  return { type: REQUEST_SERVICE_PACKAGE_SUCCESS, response };
}
export function requestServicePackageError(response) {
  return { type: REQUEST_SERVICE_PACKAGE_ERROR, response };
}

export function requestReport(response) {
  return { type: REQUEST_REPORT_REQUEST, response };
}
export function requestReportSuccess(response) {
  return { type: REQUEST_REPORT_SUCCESS, response };
}
export function requestReportError(response) {
  return { type: REQUEST_REPORT_ERROR, response };
}
export function requestReportReset(response) {
  return { type: REQUEST_REPORT_RESET, response };
}

export function getContactsRequest(params) {
  return { type: LIST_CONTACTS_REQUEST, params };
}
export function getContactsSuccess(response) {
  return { type: LIST_CONTACTS_SUCCESS, response };
}
export function getContactsError(response) {
  return { type: LIST_CONTACTS_ERROR, response };
}

export function createContactRequest(params) {
  return { type: CREATE_CONTACT_REQUEST, params };
}
export function createContactSuccess(response) {
  return { type: CREATE_CONTACT_SUCCESS, response };
}
export function createContactError(response) {
  return { type: CREATE_CONTACT_ERROR, response };
}

export function updateJobRequest(params) {
  return { type: UPDATE_JOB_REQUEST, params };
}
export function updateJobSuccess(response) {
  return { type: UPDATE_JOB_SUCCESS, response };
}
export function updateJobError(response) {
  return { type: UPDATE_JOB_ERROR, response };
}

export function getSpecializationRequest(params) {
  return { type: GET_SPECIALIZATION_REQUEST, params };
}
export function getSpecializationSuccess(response) {
  return { type: GET_SPECIALIZATION_SUCCESS, response };
}
export function getSpecializationError(response) {
  return { type: GET_SPECIALIZATION_ERROR, response };
}
// all Candidates
export function getAllCandidateRequest(params) {
  return { type: LIST_ALL_CANDIDATES_REQUEST, params };
}
export function getAllCandidateSuccess(response) {
  return { type: LIST_ALL_CANDIDATES_SUCCESS, response };
}
export function getAllCandidateError(response) {
  return { type: LIST_ALL_CANDIDATES_ERROR, response };
}

export function getSkillsRequest(params) {
  return { type: LIST_SKILLS_REQUEST, params };
}
export function getSkillsSuccess(response) {
  return { type: LIST_SKILLS_SUCCESS, response };
}
export function getSkillsError(response) {
  return { type: LIST_SKILLS_ERROR, response };
}
