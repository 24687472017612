import React from 'react';
import PropTypes from 'prop-types';

function Layout({ children }) {
  return (
    <div id="container">
      <div className="main-content">
        <div className="public-content">{children}</div>
      </div>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
