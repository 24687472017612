import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import styles from './styles.module.scss';

import { details, additionalJobInfo } from '../../../utils/constants';
import piplineSummary from '../../../images/jobFunnelShimmer.gif';

const Preview = (props) => {
  const { jobDetails, setIsPreview, content } = props;

  const getAdditionalJobInfoValue = (detail) => {
    switch (detail.key) {
      case 'requiredSkills':
        return jobDetails[detail.key].split(',').join(', ');
      case 'summary':
        return <pre className={styles.summary}>{jobDetails.summary}</pre>;
      case 'jobDescription':
        return (
          <div
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{
              __html: jobDetails.jobDescription,
            }}
          />
        );
      default:
        return jobDetails[detail.key];
    }
  };

  return (
    <div className={styles.content}>
      <div className={styles.titleSection}>
        {jobDetails.title ? (
          <h3>{jobDetails.title}</h3>
        ) : (
          <div>
            <img className={styles.loadingGif} alt="" src={piplineSummary} />
          </div>
        )}

        <div className={styles.editDeleteBtns}>
          <button
            type="button"
            key="cancelButton"
            onClick={() => setIsPreview(false)}
            className="button-primary button-text"
          >
            Cancel Preview
          </button>
        </div>
      </div>
      <div className={styles.jobInfo}>
        {jobDetails.jobDescription && (
          <div className={styles.jobDescription}>
            <div>
              <Col>
                {additionalJobInfo.map((detail, index) => (
                  <Row
                    key={detail.label}
                    md="12"
                    className={index === 0 ? styles.additionalDetailsDiv : ''}
                  >
                    <p>
                      <strong className={styles.additionalDetails}>
                        {detail.label}
                      </strong>
                      <br />
                      <span>
                        {getAdditionalJobInfoValue(detail)}
                      </span>
                    </p>
                  </Row>
                ))}
                {jobDetails.mainDuties && (
                  <Row key={content.MAIN_DUTIES} md="12">
                    <p>
                      <strong className={styles.additionalDetails}>
                        {content.MAIN_DUTIES}
                      </strong>
                      <br />
                      <span>
                        <div
                          /* eslint-disable-next-line react/no-danger */
                          dangerouslySetInnerHTML={{
                            __html: jobDetails.mainDuties,
                          }}
                        />
                      </span>
                    </p>
                  </Row>
                )}
              </Col>
            </div>
          </div>
        )}

        {jobDetails.jobDescription && (
          <div className={styles.otherJobInfo}>
            <Col>
              {details.map((detail) => (
                <Row key={detail.label} md="12">
                  <p>
                    <strong className={styles.otherDetails}>
                      {detail.label}
                    </strong>
                    <br />
                    <span className={styles.otherDetailsValue}>
                      {Array.isArray(jobDetails[detail.key])
                        ? jobDetails[detail.key].join(', ')
                        : jobDetails[detail.key]}
                    </span>
                  </p>
                </Row>
              ))}
            </Col>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  content: state.languageReducer.portalContent,
  languageCode: state.languageReducer.languageCode,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Preview));
