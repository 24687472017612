import { takeLatest, put, call } from 'redux-saga/effects';
import {
  CREATE_CONTACT_REQUEST,
  LIST_CONTACTS_REQUEST,
} from '../actions/actionTypes';
import {
  getContactsSuccess,
  getContactsError,
  createContactSuccess,
  createContactError,
} from '../actions/action';
import ContactsAPI from '../../apis/contactsAPI';

function* getContacts(params) {
  try {
    const response = yield call(ContactsAPI.GetContacts, params);

    const data = yield response.data;

    if (data.payload) yield put(getContactsSuccess(data.payload));
    else yield put(getContactsError(data));
  } catch (error) {
    yield put(getContactsError(error.message));
  }
}

function* createContact(params) {
  try {
    const response = yield call(ContactsAPI.CreateContact, params);

    const data = yield response.data;

    if (data.id) yield put(createContactSuccess({ id: data.id }));
    else yield put(createContactError({ id: data.id }));
  } catch (error) {
    yield put(createContactError(error.message));
  }
}

function* contactsSaga() {
  yield takeLatest(LIST_CONTACTS_REQUEST, getContacts);
  yield takeLatest(CREATE_CONTACT_REQUEST, createContact);
}
export default contactsSaga;
