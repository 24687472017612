import Helper from '../services/helper';

import { AUTH_ENDPOINT } from '../services/constants';
import axiosInstance from '../services/axiosConfig';

class AuthAPI {
  static Login = async (request) => axiosInstance.post(
    `${AUTH_ENDPOINT}/auth/login`,
    { ...request.params, skipAuth: true },
    {
      headers: Helper.authHeaderWithoutAuthorization(),
    },
  );

  static GenerateOTP = async (request) => axiosInstance.post(
    `${AUTH_ENDPOINT}/auth/sendPhoneOtp`,
    { ...request },
    {
      headers: Helper.authHeaderWithoutAuthorization(),
    },
  );

  static ForgotPassword = async (request) => axiosInstance.get(
    `${AUTH_ENDPOINT}/auth/forgotPassword`,
    {
      params: { ...request.params, skipAuth: true },
    },
    {
      headers: Helper.authHeaderWithoutAuthorization(),
    },
  );

  static SetPassword = async (request) => axiosInstance.post(
    `${AUTH_ENDPOINT}/auth/${request.params.session || request.params.routeName === '/set-password' ? 'setPermanentPassword' : 'resetPassword'}`,
    {
      ...request.params, skipAuth: true,
    },
    {
      headers: Helper.authHeaderWithoutAuthorization(),
    },
  );

  static UpdatePassword = async (request) => axiosInstance.post(
    `${AUTH_ENDPOINT}/auth/changePassword`,
    request.params,
    {
      headers: Helper.authHeader(),
    },
  );

  static Logout = async (request) => axiosInstance.post(
    `${AUTH_ENDPOINT}/auth/logout`,
    {
      ...request.params, skipAuth: true,
    },
    {
      headers: Helper.authHeaderWithoutAuthorization(),
    },
  );
}

export default AuthAPI;
