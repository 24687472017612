import React from 'react';
import classNames from 'classnames';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from 'reactstrap';
import shimmer from '../../images/jobFunnelShimmer.gif';
import styles from './styles.module.scss';

const RenderShimmer = () => [...Array(2)].map((item, index) => (
  <Row key={index} className={styles.jobRow}>
    <Col>
      <Card>
        <CardHeader>
          <Row>
            <Col md="10">
              <CardHeader>
                <CardTitle className={classNames(styles.jobTitle)}>
                  <img
                    className={styles.shimmerImg}
                    alt=""
                    src={shimmer}
                  />
                </CardTitle>
              </CardHeader>
            </Col>
            <Col md="2">
              <CardHeader>
                <CardTitle className={classNames(styles.jobCardTitle)}>
                  <img
                    className={styles.shimmerImg}
                    alt=""
                    src={shimmer}
                  />
                </CardTitle>
              </CardHeader>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className={styles.jobBody}>
          <Row className={styles.jobBodyRow}>
            {[...Array(6)].map((_, idx) => (
              <Col key={idx} className={styles.jobCategory}>
                <div className={styles.loaderFrame14}>
                  <img
                    className={styles.shimmerImgStage}
                    alt=""
                    src={shimmer}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>
    </Col>
  </Row>
));

export default RenderShimmer;
