import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';

const ResendOTPTimer = ({ durationInSeconds, onTimerEnd }) => {
  const [timer, setTimer] = useState(durationInSeconds);
  let intervalId;

  useEffect(() => {
    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    if (timer === 0) {
      clearInterval(intervalId);
      if (onTimerEnd) {
        onTimerEnd();
      }
    }

    return () => clearInterval(intervalId);
  }, [timer]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <span className={styles.container}>
      Resend OTP in
      {` ${formatTime(timer)}`}
    </span>
  );
};

export default ResendOTPTimer;
