import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  CardHeader,
  CardTitle,
  Row,
  Col,
} from 'reactstrap';
import styles from './styles.module.scss';

const CardHeaderJobStats = ({
  data,
  client,
  jobStatsData,
  isJobDetailsPage,
  content,
  jobIndex,
}) => {
  const addCandidateRedirectUrl = `/add-candidate?jobCode=${jobStatsData?.jobs[jobIndex]?.jobCode}&currJobId=${jobStatsData?.jobs[jobIndex]?.jobId}&jobRoleCategory=${jobStatsData?.jobs[jobIndex]?.jobRoleCategory}&jobTitle=${jobStatsData?.jobs[jobIndex]?.title}&specialisation=${JSON.stringify(jobStatsData?.jobs[jobIndex]?.jobRoleSpecialisation)}`;

  const agencyRedirectUrl = `/job-details?jobOpeningCode=${jobStatsData?.jobs[jobIndex]?.jobCode}&jobOpeningId=${jobStatsData?.jobs[jobIndex]?.jobId}&jobTitle=${jobStatsData?.jobs[jobIndex]?.title}`;

  const renderAddCandidateButton = () => (
    <Col md="2">
      <CardHeader className={styles.cardHeaderAddCand}>
        <Link className="linkAppearanceNone" to={addCandidateRedirectUrl}>
          <CardTitle
            className={classNames(
              styles.jobCardTitle,
              'button-text',
            )}
          >
            <span className={classNames('customPlusIconGreen')}>
              {content.ADD_CANDIDATES}
            </span>
          </CardTitle>
        </Link>
      </CardHeader>
    </Col>
  );

  const renderJobAndAgencyName = () => (
    <>
      <Link className="linkAppearanceNone" to={agencyRedirectUrl}>
        <CardTitle
          style={{ cursor: 'pointer' }}
          className={classNames('subtitle-1', styles.jobTitle)}
        >
          {data.title}
        </CardTitle>
      </Link>
      {client && (
      <CardTitle className="clientNameTag caption-1">
        <span>{client.name}</span>
      </CardTitle>
      )}
    </>
  );

  const renderCandidateActivitySummary = () => (
    <CardTitle className={styles.cardTitle}>
      {content.CANDIDATES_ACTIVITY_SUMMARY}
    </CardTitle>
  );

  const cardHeaderTitle = isJobDetailsPage
    ? renderCandidateActivitySummary()
    : renderJobAndAgencyName();

  return (
    <CardHeader>
      <Row>
        <Col md="10">
          <CardHeader className={styles.cardHeaderTitle}>
            {cardHeaderTitle}
          </CardHeader>
        </Col>
        {!isJobDetailsPage && renderAddCandidateButton()}
      </Row>
    </CardHeader>
  );
};

export default CardHeaderJobStats;
