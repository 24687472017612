import React from 'react';
import { useSelector } from 'react-redux';
import { getPortalContent } from '../../redux/reducers/languageReducer';
import Picklist from '../../components/picklist';
import Shimmer from '../../components/shimmer';
import styles from './styles.module.scss';

function AgencyJobs({
  currJobTitle, filteredJobs, currJobOpeningId, handleJobListChange,
}) {
  const portalContent = useSelector(getPortalContent);

  return (
    <div className="ant-select-selector">
      <p className="body-2">{portalContent.SELECT_JOB}</p>
      { currJobTitle ? (
        <Picklist
          options={filteredJobs?.length > 0
            ? filteredJobs?.map((job) => ({
              value: job.jobOpeningId,
              label: job.name,
            })) : null}
          selectedValue={currJobOpeningId}
          onSelect={handleJobListChange}
          dropdownStyle={styles.dropdownHeaderStyle}
        />
      ) : <Shimmer />}
    </div>
  );
}

export default AgencyJobs;
