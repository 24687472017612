import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import ItemList from '../../subViews/itemList';
import styles from './styles.module.scss';
import SuccessModal from '../../modals/successModal';
import { servicesData } from '../../data/servicesOfferedListData';

function ServicesOffered({ content }) {
  const [showAddJobSuccessModal, setShowAddJobSuccessModal] = useState(false);

  const closeAddJobSuccessModal = () => {
    setShowAddJobSuccessModal(false);
  };

  const handleReadMoreClick = async () => {
    setShowAddJobSuccessModal(true);
  };

  return (
    <>
      <div className="pageContent">
        <h2 className={classNames(styles.headingText, 'heading-2')}>
          {content.SERVICES_OFFERED}
        </h2>
        <p className={classNames(styles.paraText, 'body-3')}>
          {content.SERVICES_OFFERED_TEXT}
        </p>
        <div className={styles.servicesCards}>
          {servicesData.map((service) => (
            <ItemList
              key={service.category}
              heading={service.category}
              listItems={service.items}
              handleReadMoreClick={handleReadMoreClick}
            />
          ))}
        </div>
      </div>
      <SuccessModal
        heading={content.REQUEST_SENT}
        successMsg={content.SERVICES_OFFERED_SUCCESS_MSG}
        showSuccessModal={showAddJobSuccessModal}
        closeSuccessModal={closeAddJobSuccessModal}
      />
    </>

  );
}

const mapStateToProps = (state) => ({
  content: state.languageReducer.portalContent,
  languageCode: state.languageReducer.languageCode,
});

export default connect(mapStateToProps)(withRouter(ServicesOffered));
