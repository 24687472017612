import { takeLatest, put, call } from 'redux-saga/effects';
import {
  LIST_ALL_CANDIDATES_REQUEST,
} from '../actions/actionTypes';
import {
  getAllCandidateSuccess,
  getAllCandidateError,
} from '../actions/action';

import AllCandidateApi from '../../apis/allCandidateApi';
import { STATUS } from '../../apis/httpStatus';

function* getAllCandidates(params) {
  try {
    const response = yield call(AllCandidateApi.getAllCandidateRequest, params);
    const data = yield response.data;

    if (response.status === STATUS.STATUS_200) yield put(getAllCandidateSuccess(data));
    else yield put(getAllCandidateError(data));
  } catch (error) {
    yield put(getAllCandidateError(error.message));
  }
}

function* allCandidatesSaga() {
  yield takeLatest(LIST_ALL_CANDIDATES_REQUEST, getAllCandidates);
}
export default allCandidatesSaga;
