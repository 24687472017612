import React, { useRef } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

import styles from './styles.module.scss';
import hospitals from '../../../../images/college_icon.png';

import ExperienceSummary from '../../subViews/experienceSummary';
import Helper from '../../../../services/helper';

const REGISTRATION_DIC = {
  national: {
    incNursingRegistrationNumber: 'INC_REGISTRATION_NUMBER',
    stateNursingRegistrationNumber: 'STATE_NURSING_REGISTRATION_NUMBER',
    mciRegistrationNumber: 'MCI_REGISTRATION_NUMBER',
  },
  international: {
    nmcPersonalReferenceNumberPRN: 'NMC_PERSONAL_REFERENCE_NUMBER_PRN',
    nmcRegistrationNumber: 'NMC_PIN',
    nmcRegistrationIssuanceDate: 'NMC_PIN_ISSUANCE_DATE',
    nmcRegistrationExpiryDate: 'NMC_PIN_EXPIRATION_DATE',
    gmcPIN: 'GMC_PIN',
    gmcPINExpirationDate: 'GMC_PIN_EXPIRATION_DATE',
    gmcPINIssuanceDate: '2023-12-12',
    gmcRegistrationExpectedDate: 'GMC_REGISTRATION_EXPECTED_DATE',
    gmcRegistration: 'GMC_REGISTRATION',
    gmcPathwayApplicable: 'GMC_PATHWAY_APPLICABLE',
    otherGMCPathwayStatus: 'OTHER_GMC_PATHWAY_STATUS',
    royalCollegeMembershipStatus: 'ROYAL_COLLEGE_MEMBERSHIP_STATUS',
    royalCollegeExamGiven: 'ROYAL_COLLEGE_EXAM_GIVEN',
    plabExamStatus: 'PLAB_EXAM_STATUS',
    cbtStatus: 'CBT_STATUS',
    cbtType: 'CBT_TYPE',
    cbtDate: 'CBT_DATE',
    osceDate: 'OSCE_DATE',
    osceStatus: 'OSCE_STATUS',
  },
};

function WorkExperience({ candidateProfileData, content }) {
  const tabVisibiliyRef = {
    experience: useRef(null),
    education: useRef(null),
    registration: useRef(null),
    responsibilities: useRef(null),
    achievements: useRef(null),
    backgroundVerification: useRef(null),
    ternScore: useRef(null),
  };

  const handleTabClick = (tabKey) => {
    const tabRef = tabVisibiliyRef[tabKey];

    if (tabRef && tabRef.current) {
      const tabPosition = tabRef.current.getBoundingClientRect().left;
      if (tabPosition) {
        tabRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    }
  };

  const bulletPointList = (text) => {
    // Split the text into individual items
    const items = text.split('\r\n');

    return (
      <ul className={styles.achievements}>
        {items.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    );
  };

  const renderVerificationBadge = (value) => {
    let styleClass;
    let text;

    if (value === 'Verified') {
      styleClass = styles.bgVerified;
      text = content.VERIFIED;
    } else if (value === 'Failed') {
      styleClass = styles.bgFailed;
      text = content.FAILED;
    } else {
      styleClass = styles.bgInProgress;
      text = content.IN_PROGRESS;
    }

    return <span className={classNames(styleClass, 'caption-1')}>{text}</span>;
  };

  const convertTextProcedures = () => {
    let proceduresDone = '-';
    proceduresDone = candidateProfileData.responsibilities?.proceduresDone;

    if (proceduresDone === undefined || !proceduresDone || proceduresDone.length === 0) {
      return null; // Return null to indicate no content
    }

    return (
      <ul className={styles.textWrapper1}>
        {proceduresDone.map((procedure) => (
          <li key={procedure}>{procedure}</li>
        ))}
      </ul>
    );
  };

  const convertTextEquipments = () => {
    let equipmentsHandled = '-';
    equipmentsHandled = candidateProfileData.responsibilities?.equipmentsHandled;
    if (equipmentsHandled === undefined || !equipmentsHandled || equipmentsHandled.length === 0) {
      return null;
    }

    return (
      <ul className={styles.textWrapper1}>
        {equipmentsHandled.map((procedure) => (
          <li key={procedure}>{procedure}</li>
        ))}
      </ul>
    );
  };

  return (
    <Tabs
      className={classNames(
        styles.tabs,
        styles.tabsScrolling,
      )}
      fill
    >
      {candidateProfileData.experience && (
      <Tab
        eventKey={1}
        title={(
          <div
            ref={tabVisibiliyRef.experience}
            onClick={() => handleTabClick('experience')}
            className={styles.workExpTitle}
          >
            {content.EXPERIENCE}
          </div>
        )}
      >
        {candidateProfileData.workExp?.map(
          (item, index) => (
            <React.Fragment key={item.company}>
              <Row
                className={styles.frame16}
              >
                <Col
                  md="2"
                  className={styles.group17}
                >
                  <div
                    className={
                      styles.group21
                    }
                  >
                    <img
                      className={
                        styles.apolloHospitals
                      }
                      alt="Apollo hospitals"
                      src={hospitals}
                    />
                  </div>
                </Col>
                <Col md="10">
                  <ExperienceSummary
                    item={item}
                  />
                </Col>
              </Row>
              {index
                !== candidateProfileData.workExp
                  .length
                  - 1 && (
                  <>
                    <br />
                    <hr
                      className={styles.hrag}
                    />
                    {' '}
                  </>
              )}
            </React.Fragment>
          ),
        )}
      </Tab>
      )}
      {candidateProfileData.education && (
      <Tab
        eventKey={2}
        title={(
          <div
            ref={tabVisibiliyRef.workExpTitle}
            onClick={() => handleTabClick('workExpTitle')}
            className={styles.workExpTitle}
          >
            {content.EDUCATION}
          </div>
        )}
      >
        {candidateProfileData.education?.map(
          (item, index) => (
            <React.Fragment key={item.qualification}>
              <Row
                className={styles.frame16}
              >
                <Col
                  md="2"
                  className={styles.group17}
                >
                  <div
                    className={
                      styles.group21
                    }
                  >
                    <img
                      className={
                        styles.apolloHospitals
                      }
                      alt="Apollo hospitals"
                      src={hospitals}
                    />
                  </div>
                </Col>
                <Col md="10">
                  <div
                    className={
                      styles.frame18
                    }
                  >
                    <div
                      className={
                        styles.textWrapper64
                      }
                    >
                      {item.collegeName}
                    </div>
                    <div
                      className={
                        styles.textWrapper65
                      }
                    >
                      {item.qualification}
                    </div>
                    <div
                      className={
                        styles.frame19
                      }
                    >
                      <div
                        className={
                          styles.textWrapper66
                        }
                      >
                        {item.startDate}
                        {' '}
                        -
                        {' '}
                        {item.endDate}
                      </div>
                      {/* <div className={styles.textWrapper66}>{item.location}</div> */}
                    </div>
                  </div>
                </Col>
              </Row>
              {index
                !== candidateProfileData
                  .education.length
                  - 1 && (
                  <>
                    <br />
                    <hr
                      className={styles.hrag}
                    />
                    {' '}
                  </>
              )}
            </React.Fragment>
          ),
        )}
      </Tab>
      )}
      {candidateProfileData.registration && (
      <Tab
        eventKey={3}
        title={(
          <div
            ref={tabVisibiliyRef.registration}
            onClick={() => handleTabClick('registration')}
            className={styles.workExpTitle}
          >
            {content.REGISTRATION}
          </div>
        )}
      >
        {!Helper.checkEmptyValues(candidateProfileData.registration)
        && (
        <Row className={styles.frame16}>
          <Col
            md="12"
            className={styles.ProceduresCol}
          >
            <div
              className={classNames(
                styles.registrationHeading,
                styles.textWrapper64,
              )}
            >
              {
                content.NATION_REGISTRATION_DETAILS
              }
            </div>
          </Col>
          <Col
            md="12"
            className={styles.tabContent}
          >
            {Object.keys(
              REGISTRATION_DIC.national,
            ).map(
              (keyName) => candidateProfileData
                .registration[keyName]
                && candidateProfileData
                  .registration[keyName]
                  !== '' && (
                  <div
                    key={keyName}
                    className={
                      styles.registrationContainer
                    }
                  >
                    <div className="body-2">
                      {
                        content[
                          REGISTRATION_DIC
                            .national[
                              keyName
                            ]
                        ]
                      }
                    </div>
                    <div className="body-1">
                      {
                        candidateProfileData
                          .registration[
                            keyName
                          ]
                      }
                    </div>
                  </div>
              ),
            )}
          </Col>
        </Row>
        )}
        {!Helper.checkEmptyValues(candidateProfileData.registration)
        && (
        <Row className={styles.frame20}>
          <Col
            md="12"
            className={styles.ProceduresCol}
          >
            <div
              className={classNames(
                styles.registrationHeading,
                styles.textWrapper64,
              )}
            >
              {
                content.INTERNATION_REGISTRATION_DETAILS
              }
            </div>
          </Col>
          <div
            // md="12"
            className={classNames(
              styles.registrationInternational,
              styles.tabContent,
            )}
          >
            {Object.keys(
              REGISTRATION_DIC.international,
            ).map(
              (keyName) => candidateProfileData
                .registration[keyName]
                && candidateProfileData
                  .registration[keyName]
                  !== '' && (
                  <div
                    key={keyName}
                    className={
                      styles.registrationContainer
                    }
                  >
                    <div className="body-2">
                      {
                        content[
                          REGISTRATION_DIC
                            .international[
                              keyName
                            ]
                        ]
                      }
                    </div>
                    <div className="body-1">
                      {
                        candidateProfileData
                          .registration[
                            keyName
                          ]
                      }
                    </div>
                  </div>
              ),
            )}
          </div>
        </Row>
        )}
      </Tab>
      )}
      {candidateProfileData.profession !== 'Doctor' && candidateProfileData.responsibilities && candidateProfileData.responsibilities.proceduresDone.length > 0 && candidateProfileData.responsibilities.equipmentsHandled.length > 0 && (
      <Tab
        eventKey={4}
        title={(
          <div
            ref={tabVisibiliyRef.responsibilities}
            onClick={() => handleTabClick('responsibilities')}
            className={styles.workExpTitle}
          >
            {content.RESPONSIBILITIES}
          </div>
        )}
      >
        {candidateProfileData.responsibilities.proceduresDone.length > 0
        && (
        <Row className={styles.frame16}>
          <Col
            md="12"
            className={styles.ProceduresCol}
          >
            <div
              className={styles.textWrapper64}
            >
              {content.PROCEDURES_DONE}
            </div>
          </Col>
          <Col
            md="12"
            className={styles.tabContent}
          >
            {convertTextProcedures()}
          </Col>
        </Row>
        )}
        {candidateProfileData.responsibilities.equipmentsHandled.length > 0
        && (
        <Row className={styles.frame20}>
          <Col
            md="12"
            className={styles.ProceduresCol}
          >
            <div
              className={styles.textWrapper64}
            >
              {content.EQUIPMENTS_HANDLED}
            </div>
          </Col>
          <Col
            md="12"
            className={styles.tabContent}
          >
            {convertTextEquipments()}
          </Col>
        </Row>
        )}
      </Tab>
      )}
      {candidateProfileData.profession === 'Doctor' && candidateProfileData.achievements && (
      <Tab
        eventKey={5}
        title={(
          <div
            ref={tabVisibiliyRef.achievements}
            onClick={() => handleTabClick('achievements')}
            className={styles.workExpTitle}
          >
            {content.ACHIEVEMENTS}
          </div>
        )}
      >
        <Row className={styles.frame16}>
          <Col
            md="12"
            className={styles.ProceduresCol}
          >
            <div
              className={
                styles.textWrapper64
              }
            >
              {
                content.PUBLICATIONS_AND_RESEARCH
              }
            </div>
          </Col>
          <Col
            md="12"
            className={styles.tabContent}
          >
            {bulletPointList(
              candidateProfileData.achievements,
            )}
          </Col>
        </Row>
      </Tab>
      )}
      {candidateProfileData.backgroundVerification && (
      <Tab
        eventKey={6}
        title={(
          <div
            ref={tabVisibiliyRef.backgroundVerification}
            onClick={() => handleTabClick('backgroundVerification')}
            className={styles.workExpTitle}
          >
            {
              content.BACKGROUND_VERIFICATION
            }
          </div>
        )}
      >
        <Row className={styles.frame16}>
          <Col
            md="12"
            className={styles.tabContent}
          >
            <div
              className={
                styles.bgVerificationContainer
              }
            >
              <div>
                <div
                  className={classNames(
                    styles.bgVerificationHeading,
                    styles.bgVerificationHeadingLeft,
                    'body-4',
                  )}
                >
                  {content.TYPE}
                </div>
                <div
                  className={classNames(
                    styles.bgVerificationLeftCol,
                    'body-1',
                  )}
                >
                  {
                    content.NATIONAL_IDENTITY
                  }
                </div>
                <div
                  className={classNames(
                    styles.bgVerificationLeftCol,
                    'body-1',
                  )}
                >
                  {
                    content.DEGREE_VERIFICATION
                  }
                </div>
                <div
                  className={classNames(
                    styles.bgVerificationLeftCol,
                    'body-1',
                  )}
                >
                  {
                    content.LANGUAGE_PROFICIENCY_TEST
                  }
                </div>
                <div
                  className={classNames(
                    styles.bgVerificationLeftCol,
                    'body-1',
                  )}
                >
                  {
                    content.NURSING_REGISTRATION_NUMBER
                  }
                </div>
                <div
                  className={classNames(
                    styles.bgVerificationLeftCol,
                    'body-1',
                  )}
                >
                  {content.REFERENCES_CHECK}
                </div>
                <div
                  className={classNames(
                    styles.bgVerificationLeftCol,
                    'body-1',
                  )}
                >
                  {
                    content.CRIMINAL_BACKGROUND_CHECK
                  }
                </div>
                <div
                  className={classNames(
                    styles.bgVerificationLeftCol,
                    'body-1',
                  )}
                >
                  {
                    content.PAYSLIPS_VERIFICATION
                  }
                </div>
              </div>
              <div>
                <div
                  className={classNames(
                    styles.bgVerificationHeading,
                    styles.bgVerificationHeadingRight,
                    'body-4',
                  )}
                >
                  {content.STATUS}
                </div>
                <div
                  className={classNames(
                    styles.bgVerificationRightCol,
                    'body-1',
                  )}
                >
                  {renderVerificationBadge(
                    candidateProfileData
                      ?.backgroundVerification
                      ?.nationalIdentity,
                  )}
                </div>
                <div
                  className={classNames(
                    styles.bgVerificationRightCol,
                    'body-1',
                  )}
                >
                  {renderVerificationBadge(
                    candidateProfileData
                      ?.backgroundVerification
                      ?.degreeVerification,
                  )}
                </div>
                <div
                  className={classNames(
                    styles.bgVerificationRightCol,
                    'body-1',
                  )}
                >
                  {renderVerificationBadge(
                    candidateProfileData
                      ?.backgroundVerification
                      ?.languageProficiencyTest,
                  )}
                </div>
                <div
                  className={classNames(
                    styles.bgVerificationRightCol,
                    'body-1',
                  )}
                >
                  {renderVerificationBadge(
                    candidateProfileData
                      ?.backgroundVerification
                      ?.nursingRegNo,
                  )}
                </div>
                <div
                  className={classNames(
                    styles.bgVerificationRightCol,
                    'body-1',
                  )}
                >
                  {renderVerificationBadge(
                    candidateProfileData
                      ?.backgroundVerification
                      ?.referencesCheck,
                  )}
                </div>
                <div
                  className={classNames(
                    styles.bgVerificationRightCol,
                    'body-1',
                  )}
                >
                  {renderVerificationBadge(
                    candidateProfileData
                      ?.backgroundVerification
                      ?.criminalBackgroundCheck,
                  )}
                </div>
                <div
                  className={classNames(
                    styles.bgVerificationRightCol,
                    'body-1',
                  )}
                >
                  {renderVerificationBadge(
                    candidateProfileData
                      ?.backgroundVerification
                      ?.payslipsVerification,
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Tab>
      )}
    </Tabs>
  );
}

const mapStateToProps = (state) => ({
  content: state.languageReducer.portalContent,
  languageCode: state.languageReducer.languageCode,
});

export default connect(
  mapStateToProps,
)(withRouter(WorkExperience));
