import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getPortalContent } from '../../redux/reducers/languageReducer';
import Shimmer from '../../components/shimmer';
import styles from './styles.module.scss';

function JobDetails({
  currJobTitle, jobOpeningCode, currJobOpeningId, jobDetailsData,
}) {
  const portalContent = useSelector(getPortalContent);
  const addCandidateToLink = `/add-candidate?jobCode=${jobDetailsData.jobCode}&currJobId=${jobDetailsData.jobId}&jobRoleCategory=${jobDetailsData.jobRoleCategory}&jobTitle=${jobDetailsData.jobtitle}&specialisation=${JSON.stringify(jobDetailsData.specialisation)}`;
  return (
    <>
      {currJobTitle ? (
        <Link className="linkAppearanceNone" to={`/job-details?jobOpeningCode=${jobOpeningCode}&jobOpeningId=${currJobOpeningId}&jobTitle=${currJobTitle}`}>
          <button
            className="button-secondary button-text"
            color="warning"
            type="button"
          >
            {portalContent.VIEW_JOB_DETAILS}
          </button>
        </Link>
      ) : (
        <div className={styles.mr15}>
          <Shimmer />
        </div>
      )}

      {currJobTitle ? (
        <Link className="linkAppearanceNone" to={addCandidateToLink}>
          <button
            className="button-primary button-text"
            color="warning"
            type="button"
          >
            +
            {' '}
            {portalContent.ADD_CANDIDATES}
          </button>
        </Link>
      ) : <Shimmer />}
    </>
  );
}

export default JobDetails;
