/* eslint-disable max-len */
import { takeLatest, put, call } from 'redux-saga/effects';
import {
  GET_JOB_FUNNEL_REQUEST,
  CHANGE_APPLICATION_STAGE_REQUEST,
  UPDATE_JOB_REQUEST,
  GET_SPECIALIZATION_REQUEST,
  LIST_SKILLS_REQUEST,
} from '../actions/actionTypes';
import {
  getJobFunnelSuccess,
  getJobFunnelError,
  changeApplicationStageRequestSuccess,
  changeApplicationStageRequestError,
  updateJobSuccess, updateJobError, getSpecializationSuccess, getSpecializationError, getSkillsSuccess, getSkillsError,
} from '../actions/action';
import jobsAPI from '../../apis/jobsApi';

function* getJobFunnel(params) {
  try {
    const response = yield call(jobsAPI.getJobFunnel, params);
    const data = yield response.data;

    if (data.payload) yield put(getJobFunnelSuccess({ stageId: params.params.stageId, ...data.payload }));
    else yield put(getJobFunnelError(data));
  } catch (error) {
    yield put(getJobFunnelError(error.message));
  }
}

function* changeApplicationStage(params) {
  try {
    const response = yield call(jobsAPI.changeApplicationStage, params);
    const data = yield response.data;

    if (data.status === 200) yield put(changeApplicationStageRequestSuccess(data.payload));
    else yield put(changeApplicationStageRequestError(data.payload));
  } catch (error) {
    yield put(changeApplicationStageRequestError(error.message));
  }
}

function* updateJob(params) {
  try {
    const response = yield call(jobsAPI.updateJob, params);
    const data = yield response.data;

    if (data.status === 200) yield put(updateJobSuccess(data.payload));
    else yield put(updateJobError(data.payload));
  } catch (error) {
    yield put(updateJobError(error.message));
  }
}

function* getSpecialization(params) {
  try {
    const response = yield call(jobsAPI.getSpecialization, params);
    const data = yield response.data;

    if (data.status === 200) yield put(getSpecializationSuccess(data));
    else yield put(getSpecializationError(data.payload));
  } catch (error) {
    yield put(getSpecializationError(error.message));
  }
}

function* getSkills(params) {
  try {
    const response = yield call(jobsAPI.getSkills, params);
    const data = yield response.data;

    if (data.status === 200) yield put(getSkillsSuccess(data.payload));
    else yield put(getSkillsError(data.payload));
  } catch (error) {
    yield put(getSkillsError(error.message));
  }
}

function* jobsSaga() {
  yield takeLatest(GET_JOB_FUNNEL_REQUEST, getJobFunnel);
  yield takeLatest(CHANGE_APPLICATION_STAGE_REQUEST, changeApplicationStage);
  yield takeLatest(UPDATE_JOB_REQUEST, updateJob);
  yield takeLatest(GET_SPECIALIZATION_REQUEST, getSpecialization);
  yield takeLatest(LIST_SKILLS_REQUEST, getSkills);
}

export default jobsSaga;
