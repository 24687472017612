/* eslint-disable import/no-extraneous-dependencies */
import countryCodes from 'country-codes-list';
import * as Flag from 'country-flag-icons/string/3x2';

export const getCountryCodeList = () => {
  const allCountryCodesObject = countryCodes.all();
  const filteredCountryCodes = allCountryCodesObject.map((country) => ({
    countryCallingCode: `+${country.countryCallingCode}`,
    countryCode: country.countryCode,
    countryFlag: Flag[country.countryCode],
    countryNameEn: country.countryNameEn,
    region: country.region,
    uinqueKey: `${country.countryCallingCode}${country.region}${country.countryNameEn}`,
  }));

  filteredCountryCodes.sort((a, b) => a.countryNameEn.localeCompare(b.countryNameEn));

  return filteredCountryCodes;
};

export const searchArrayByString = (filterStr) => {
  const arr = getCountryCodeList();

  if (filterStr === '') getCountryCodeList();

  return arr.filter((item) => (
    item.countryNameEn.toLowerCase().includes(filterStr.toLowerCase())
      || item.countryCode.toLowerCase().includes(filterStr.toLowerCase())
      || item.countryCallingCode.toLowerCase().includes(filterStr.toLowerCase())
  ));
};

export const isIndianPhoneNumber = () => {
  const validTimeZones = ['Asia/Calcutta', 'Asia/Thimphu', 'Asia/Thimbu', 'Indian/Maldives', 'Asia/Katmandu', 'Asia/Karachi', 'Asia/Colombo'].map((d) => d.toLowerCase());

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (validTimeZones.includes(timezone.toLowerCase())) return true;

  return false;
};
