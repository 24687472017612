import Dashboard from '../views/dashboard';
import Profile from '../views/profile';
import Jobs from '../views/jobs';
import ForgotPassword from '../views/auth/forgotPassword';
import SetPassword from '../views/auth/setPassword';
import UpdatePassword from '../views/auth/updatePassword';
import AddCandidate from '../views/addCandidate';
import JobDetails from '../views/jobDetails';
import EditJob from '../views/editJob';
import allJobs from '../views/allJobs';
import servicesOffered from '../views/servicesOffered';
import Reports from '../views/reports';
import AllCandidate from '../views/allCandidate';
import LoginWall from '../views/auth/loginWall';

const routes = [
  {
    id: 1,
    path: '/dashboard',
    component: Dashboard,
    isPublic: false,
  },
  {
    id: 2,
    path: '/profile/:uid',
    component: Profile,
    isPublic: false,
  },
  {
    id: 3,
    path: '/jobs',
    component: Jobs,
    isPublic: false,
  },
  {
    id: 4,
    path: '/',
    component: LoginWall,
    isPublic: true,
  },
  {
    id: 5,
    path: '/login',
    component: LoginWall,
    isPublic: true,
  },
  {
    id: 6,
    path: '/forgot-password',
    component: ForgotPassword,
    isPublic: true,
  },
  {
    id: 7,
    path: '/reset-password',
    component: SetPassword,
    isPublic: true,
  },
  {
    id: 8,
    path: '/update-password',
    component: UpdatePassword,
    isPublic: false,
  },
  {
    id: 9,
    path: '/add-candidate',
    component: AddCandidate,
    isPublic: false,
  },
  {
    id: 10,
    path: '/job-details',
    component: JobDetails,
    isPublic: false,
  },
  {
    id: 11,
    path: '/edit-job',
    component: EditJob,
    isPublic: false,
  },
  {
    id: 12,
    path: '/all-jobs',
    component: allJobs,
    isPublic: false,
  },
  {
    id: 13,
    path: '/services-offered',
    component: servicesOffered,
    isPublic: false,
  },
  {
    id: 14,
    path: '/set-password',
    component: SetPassword,
    isPublic: false,
  },
  {
    id: 15,
    path: '/reports',
    component: Reports,
    isPublic: false,
  },
  {
    id: 16,
    path: '/all-candidate',
    component: AllCandidate,
    isPublic: false,
  },
];

export default routes;
