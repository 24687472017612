import React, { useState, useEffect } from 'react';
import {
  useLocation,
  useHistory,
  withRouter, NavLink,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from './styles.module.scss';
import frame1 from '../../images/frame-1.png';
import userImg from '../../images/userImgBlue.png';
import heartHand from '../../images/heart-hand.svg';
import { logoutComplete, logoutRequest } from '../../redux/actions/action';
import Helper from '../../services/helper';
import { REFRESHTOKEN, TOKEN, CONTACTUS } from '../../services/constants';
import { getPortalContent } from '../../redux/reducers/languageReducer';
import FeedbackModal from '../../modals/feedback';
import { PATH } from '../../services/urls';
import { menuLinks } from '../../utils/constants';

function Header() {
  const dispatch = useDispatch();
  const content = useSelector(getPortalContent);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(true);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const { isLogoutSuccess } = useSelector(
    (state) => state.authReducer,
  );

  React.useEffect(() => {
    const token = Helper.getItem(TOKEN);
    if (token) {
      setIsUserLoggedIn(true);
    } else setIsUserLoggedIn(false);
  }, [location]);
  const closeLogoutModal = () => {
    setShowLogoutModal(false);
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (showLogoutModal && !event.target.closest('.container-header')) {
        closeLogoutModal();
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [showLogoutModal]);

  const openLogoutModal = () => {
    setShowLogoutModal(true);
  };

  const handleLogout = async () => {
    const logoutUserObj = { token: Helper.getItem(REFRESHTOKEN) };
    dispatch(logoutRequest(logoutUserObj));
  };

  const handleFeedbackModal = () => {
    setFeedbackModal((feedbackModal) => !feedbackModal);
  };
  const handleFeedbackSuccess = () => {
    handleFeedbackModal();
  };

  useEffect(() => {
    if (isLogoutSuccess) {
      history.push('/');
      dispatch(logoutComplete());
    }
  }, [isLogoutSuccess]);
  const renderMenuLinks = () => menuLinks.map((menu) => (
    <NavLink
      kay={menu.path}
      to={menu.path}
      className={classNames(
        styles.navLink,
        location.pathname.match(menu.path) ? styles.activeLink : null,
      )}
    >
      {menu.name}
    </NavLink>
  ));
  return (
    <nav>
      <div className="container-header">
        <div className={styles.header}>
          <div>
            <NavLink
              to={PATH.DASHBOARD}
              className={classNames(styles.ternLogo)}
            >
              <img alt="Frame" src={frame1} />
            </NavLink>
            {isUserLoggedIn && (
              <div className={styles.clickableLinks}>{renderMenuLinks()}</div>
            )}
          </div>
          {isUserLoggedIn && (
            <div>
              <div className={styles.feedback} onClick={handleFeedbackModal}>
                <img src={heartHand} alt="heart-hand" />
                <NavLink to="#" className={styles.navLink}>
                  {content.PROVIDE_FEEDBACK}
                </NavLink>
              </div>
              <div className={styles.frame28}>
                <div
                  onClick={showLogoutModal ? closeLogoutModal : openLogoutModal}
                  className={styles.overlap9}
                >
                  <div className={styles.rectangle17} />
                  <div className={styles.rd}>
                    <img src={userImg} alt="userImg" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showLogoutModal && (
        <div className={styles.logoutModal}>
          <div className={styles.modalContent}>
            <a className={styles.fieldsLogoutModal} href={CONTACTUS}>
              CONTACT US
            </a>
            <br />
            <NavLink
              className={styles.fieldsLogoutModal}
              to="#"
              onClick={handleLogout}
            >
              LOGOUT
            </NavLink>
            <br />
            <NavLink
              type="button"
              className={styles.fieldsLogoutModal}
              to="/update-password"
            >
              CHANGE PASSWORD
            </NavLink>
          </div>
        </div>
      )}

      <FeedbackModal
        feedbackSuccess={handleFeedbackSuccess}
        feedbackModal={feedbackModal}
        closeFeedbackModal={handleFeedbackModal}
      />
    </nav>
  );
}

export default withRouter(Header);
