import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getPortalContent } from '../../redux/reducers/languageReducer';
import { getAllCandidateRequest } from '../../redux/actions/action';

import styles from './styles.module.scss';
import Helper from '../../services/helper';

import PageHeading from '../../subViews/pageHeading';
import ProfileInfo from './subSections/profileInfo';
import InterviewStage from './subSections/InterviewStage';
import Filters from './subSections/filters';
import Loader from '../../subViews/loader';
import PaginatedItems from '../../components/pagination';
import EmptyState from './subSections/emptyState';
import HR from '../../subViews/horizontalLine';
import { CLIENTID } from '../../services/constants';
import { ALL_CANDIDATE_PAGE_DEFAULT_ITEM_COUNT, ALL_CANDIDATE_DEFAULT_PAGE_NO } from '../../utils/constants';
import { getTableRangeLabel } from '../allJobs/utils';

const LOADER_SIZE = '64px';

function AllCandidate() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(ALL_CANDIDATE_DEFAULT_PAGE_NO);
  const [filterObj, setFilterObj] = useState(null);

  const portalContent = useSelector(getPortalContent);
  const candidatesData = useSelector((state) => state.allCandidatesReducer.candidatesData);
  const isLoading = useSelector((state) => state.allCandidatesReducer.isLoading);
  const isGetAllCandidatesError = useSelector((state) => state.allCandidatesReducer.isGetAllCandidatesError);

  const candidates = candidatesData?.payload?.candidates ?? [];
  const totalCandidates = candidatesData?.payload?.totalCandidates ?? 0;
  const clientId = Helper.getItem(CLIENTID);

  const getCandidates = (params) => {
    dispatch(getAllCandidateRequest(params));
    setCurrentPage(ALL_CANDIDATE_DEFAULT_PAGE_NO);
  };

  useEffect(() => {
    if (!clientId) {
      Helper.clearStorage();
      Helper.lastVisitedRoute();
      history.push('/');
    }

    const filterObj = { clientId };

    getCandidates(filterObj);
  }, []);

  useEffect(() => {
    if (isGetAllCandidatesError) {
      toast.error('Something went wrong!', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [isGetAllCandidatesError]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);

    const pageNum = selectedPage + 1;
    const newFilterObj = {
      clientId,
      pageNum,
      ...filterObj,
    };
    dispatch(getAllCandidateRequest(newFilterObj));
  };

  const renderCandidate = () => {
    if (candidates?.length < 1) {
      return <EmptyState portalContent={portalContent} />;
    }

    return candidates.map((data) => (
      <div key={data?.profileInfo?.candidateId}>
        <div className={styles.mainContentContainer}>
          <ProfileInfo
            profileData={data?.profileInfo}
            portalContent={portalContent}
          />
          <div className={styles.verticalLineContainer}>
            <div className={styles.verticalLine} />
          </div>
          <InterviewStage
            interviewStageData={data?.interviewState}
            portalContent={portalContent}
          />
        </div>
        <HR customClass={styles.horizontalLine} />
      </div>
    ));
  };

  const renderPaginatedlabel = () => (
    <div className={styles.paginationNote}>
      {getTableRangeLabel(portalContent, totalCandidates, candidates?.length, currentPage, ALL_CANDIDATE_PAGE_DEFAULT_ITEM_COUNT, portalContent.CANDIDATES)}
    </div>
  );

  const renderContent = () => (isLoading ? <Loader loaderHeight={LOADER_SIZE} loaderWidth={LOADER_SIZE} /> : renderCandidate());

  return (
    <div className="pageContent">
      <PageHeading
        heading={portalContent.ALL_CANDIDATES_HEADING}
        subHeading={portalContent.ALL_CANDIDATES_DESC}
      />
      <div className={styles.container}>
        <Filters
          portalContent={portalContent}
          clientId={clientId}
          setFilterObj={setFilterObj}
          filterObj={filterObj}
          getCandidates={getCandidates}
          currentPage={currentPage}
        />

        {renderContent()}

        {totalCandidates > 0 && (
          <>
            <PaginatedItems
              currentPage={currentPage}
              totalItems={totalCandidates}
              itemsPerPage={ALL_CANDIDATE_PAGE_DEFAULT_ITEM_COUNT}
              onPageChange={handlePageChange}
            />
            {renderPaginatedlabel()}
          </>
        )}
      </div>
    </div>
  );
}

export default AllCandidate;
