export const CURRENT_USER = 'user';
export const TOKEN = 'token';
export const BAD_REQUEST = 400;
export const AWS_ENDPOINT = 'https://api.tern-group.com'; // MODIFIED_FOR_DEPLOYMENT
export const SPECIALIZATION_URL = 'https://dfl7nxgyq763i.cloudfront.net/static/specialisations.json';
export const AWS_V2_ENDPOINT = 'https://wapi.tern-group.com'; // MODIFIED_FOR_DEPLOYMENT
export const AUTH_ENDPOINT = 'https://auth.tern-group.com'; // MODIFIED_FOR_DEPLOYMENT
export const ACCESSTOKEN = 'accessToken';
export const REFRESHTOKEN = 'refreshToken';
export const USERID = 'userId';
export const CLIENTID = 'clientId';
export const USERROLE = 'userRole';
export const EXPIREAT = 'expireAt';
export const CONTACTUS = 'https://tern-group.com/contact-us';
export const REQUEST_DEMO = 'https://www.tern-group.com/recruiter#footer-schedule-demo';
export const AGENCY_CLIENTS = 'agencyClients';
export const LAST_VISITED_URL = 'lastVistitedUrl';
export const INTERVIEW_STATUS_CANCELLED = 'Cancelled';
export const JOB_STAGE_200 = 'APPLSTAGE:TERN:200';
export const CANDIDATE_ROLE = 'candidate';
export const RECRUITER_ROLE = 'recruiter';
export const COOKIE_PATH = '/';
export const CANDIDATE_PLATFORM = 'https://talent.tern-group.com'; // MODIFIED_FOR_DEPLOYMENT
export const RECRUITER_PLATFORM_DOMAIN = 'https://recruit.tern-group.com'; // MODIFIED_FOR_DEPLOYMENT
export const ROLE = 'role';
export const CLIENT = 'client';
export const CANDIDATE_TOKEN = 'CANDIDATE_TOKEN';
export const CANDIDATE_REFRESH_TOKEN = 'CANDIDATE_REFRESH_TOKEN';
export const COOKIE_DOMAIN = '.tern-group.com'; // MODIFIED_FOR_DEPLOYMENT
export const RETURN_URL = 'returnUrl';
export const LOCAL_PLATFORM_CANDIDATE = 'http://localhost:9000';
export const LOCAL_PLATFORM_RECRUITER = 'http://localhost:4000';
export const ENVIRONMENTS = {
  DEV: 'DEV',
  PROD: 'PROD',
  DEMO: 'DEMO',
  STAG: 'STAG',
  LOCAL: 'LOCALHOST',
};
