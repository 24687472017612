import React from 'react';

import ReactPaginate from 'react-paginate';
import styles from './styles.module.scss';

function PaginatedItems({
  currentPage,
  totalItems,
  itemsPerPage,
  onPageChange,
}) {
  const pageCount = Math.ceil(totalItems / itemsPerPage);

  const handlePageClick = (selectedPage) => {
    onPageChange(selectedPage.selected);
  };
  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel=">"
      previousLabel="<"
      pageCount={pageCount}
      containerClassName={styles.paginationBttns}
      activeClassName="paginationActive"
      activeLinkClassName={styles.paginationSelected}
      marginPagesDisplayed={4}
      pageRangeDisplayed={2}
      renderOnZeroPageCount={null}
      onPageChange={handlePageClick}
      forcePage={currentPage}
    />
  );
}

export default PaginatedItems;
