/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route } from 'react-router-dom';
import routes from './routes';
import LandingLayout from '../components/layout/landingLayout';

const PrivateRoutes = () => {
  const privateRoutes = routes.filter((route) => !route.isPublic);

  return privateRoutes.map((route) => {
    const RouteVal = route.component;

    return (
      <Route
        key={route.id}
        path={route.path}
        exact
        render={(props) => (
          <LandingLayout>
            <RouteVal {...props} />
          </LandingLayout>
        )}
      />
    );
  });
};

export default PrivateRoutes;
