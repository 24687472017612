/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';

import {
  FormGroup,
  Input,
  Row,
  Col,
  InputGroupText,
  InputGroup,
  Spinner,
} from 'reactstrap';
import { setPasswordRequest, loginRequest } from '../../redux/actions/action';
import Helper from '../../services/helper';
import 'react-toastify/dist/ReactToastify.css';
import styles from './styles.module.scss';
import Logo from '../../images/ternLogo.svg';
import eye from '../../images/eye.svg';
import hide from '../../images/hide.svg';
import AlertMessage from '../../subViews/alertMessage';
import exclamation from '../../images/exclamation.svg';

function SetPassword(props) {
  const {
    content, loginData, email, isLoginSuccess, isSetPasswordSuccess,
  } = props;
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isExpire, setIsExpire] = useState(true);
  const [message, setMessage] = useState();
  const [isSuccessAlertMsg, setIsSuccessAlertMsg] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const prevProps = useRef(props);
  const history = useHistory();
  const location = useLocation();
  const routeName = location.pathname;

  const getUrlParameters = () => {
    const queryParameters = Helper.parseURLParams(window.location.search);
    const { email } = queryParameters;
    const verificationCode = queryParameters.code;
    const secretCode = queryParameters.q;
    return {
      email,
      verificationCode,
      secretCode: secretCode ? Helper.cryptoEncryption(secretCode) : "",
    };
  };

  const handleLogin = async () => {
    const { loginRequest } = props;
    const urlParams = getUrlParameters();
    const userCredentials = {
      email: urlParams.email,
      password: urlParams.verificationCode
        ? urlParams.verificationCode
        : urlParams.secretCode,
    };
    loginRequest(userCredentials);
  };

  useEffect(() => {
    const fetchData = async () => {
      const urlParams = getUrlParameters();
      if (urlParams.secretCode) {
        setIsExpire(true);
        await handleLogin();
      } else {
        setIsExpire(false);
      }
    };
    fetchData();
  }, []);

  const handleSetPassword = async () => {
    const { setPasswordRequest } = props;
    setIsLoading(true);
    setIsPasswordValid(true);
    if (!validatePasswords()) return;
    const urlParams = getUrlParameters();
    let setPasswordObj = {};
    const encryptedPassword = Helper.cryptoEncryption(password);
    if (urlParams.verificationCode) {
      setPasswordObj = {
        password: encryptedPassword,
        email: urlParams.email,
        verificationCode: urlParams.verificationCode,
      };
    } else {
      setPasswordObj = {
        password: encryptedPassword,
        email,
        session: loginData.session,
        routeName,
      };
    }
    await setPasswordRequest(setPasswordObj);
  };

  const validatePasswords = () => {
    const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*()])(?=.*[A-Z])(?=.*[a-z]).{8,}$/;
    const isPasswordValid = passwordRegex.test(password);
    if (password === '' || repeatPassword === '') {
      setErrorMsg(content.PLEASE_ENTER_PASSWORD);
      setIsLoading(false);
      return false;
    } if (password !== repeatPassword) {
      setErrorMsg(content.BOTH_PWD_MATCH);
      setIsLoading(false);
      return false;
    } if (password.length < 8) {
      setErrorMsg(content.PASSWORD_LENGTH_MISMATCH);
      setIsLoading(false);
      return false;
    } if (!isPasswordValid) {
      setErrorMsg(content.INVALID_PASSWORD_INPUT);
      setIsLoading(false);
      setIsPasswordValid(false);
      return false;
    } setErrorMsg(null);

    return true;
  };
  useEffect(() => {
    Helper.checkAndRedirectToHomePage(history);
    const {
      isSetPasswordSuccess: prevIsSetPasswordSuccess,
      isSetPasswordError: prevIsSetPasswordError,
    } = prevProps.current;
    const { isSetPasswordSuccess, isSetPasswordError, setPasswordData } = props;

    if (prevIsSetPasswordSuccess === false && isSetPasswordSuccess) {
      setIsLoading(false);
      setMessage(setPasswordData?.responseMessage);
      setIsSuccessAlertMsg(true);
      window.location.href = 'https://recruit.tern-group.com';
    }

    if (prevIsSetPasswordError === false && isSetPasswordError) {
      setMessage(setPasswordData?.responseMessage);
      setIsSuccessAlertMsg(false);
      setIsLoading(false);
    }

    prevProps.current = props;
  }, [props.isSetPasswordSuccess, props.isSetPasswordError]);

  useEffect(() => {
    if (loginData.authType === 'expire' || loginData.authType === 'disable') {
      setErrorMsg(loginData.responseMessage);
      setIsExpire(false);
    } else if (
      loginData.authType === 'incorrectCred'
      || loginData.authType === 'invalidEmail'
    ) {
      setErrorMsg('Invalid link!');
      setIsExpire(false);
    } else if (loginData.authType || loginData.status === 404) {
      setIsExpire(false);
    }
  }, [loginData]);

  const onPressEnter = async (e) => {
    if (e.key === 'Enter') handleSetPassword();
  };
  return (
    <div className={styles.content}>
   

      <div className={styles.ternImg}>
        <Col md="12" className={styles.centeredContainer}>
          <img alt="logo" src={Logo} className={styles.logo} />
        </Col>
        {isSetPasswordSuccess ? (
          <Col md="12" className={styles.centeredContainer}>
            <div className={classNames(styles.title, 'heading-3')}>
              {content.PASSWORD_SUCCESSFULLY_CHANGED}
            </div>
          </Col>
        ) : (
          <Col md="12" className={styles.centeredContainer}>
            <div className={classNames(styles.iconFooterText, 'heading-3')}>
              {content.SET_PASSWORD}
            </div>
          </Col>
        )}
      </div>
      <br />
      {message && (
      <>
        <AlertMessage isSuccess={isSuccessAlertMsg} message={message} />
        {isSuccessAlertMsg && (
        <Row className={styles.centeredContainer}>
          <button
            className="button-primary button-text"
            color="warning"
            type="button"
            onClick={() => window.location.href = 'https://recruit.tern-group.com'}
          >
            {content.LOGIN_NOW}
          </button>
        </Row>
        )}
      </>
      )}
      {isExpire && (
      <Row className={styles.ternImg}>
        <Col md="12" className={styles.centeredContainer}>
        <Spinner animation="border" role="status">
            <span className="visually-hidden"></span>
        </Spinner>
        </Col>
      </Row>
      )}
      {(loginData.authType === 'expire'
          || loginData.authType === 'incorrectCred'
          || loginData.authType === 'disable'
          || loginData.authType === 'invalidEmail') && (
          <AlertMessage isSuccess={isLoginSuccess} message={errorMsg} />
      )}
      {!message
        && !isExpire
        && loginData.authType !== 'expire'
        && loginData.authType !== 'incorrectCred'
        && loginData.authType !== 'disable'
        && loginData.authType !== 'invalidEmail' ? (
          <>
            <Row className={styles.centeredContainer}>
              <Col >
                <FormGroup>
                  <label className={styles.labelText}>
                    {content.ENTER_NEW_PASSWORD}
                  </label>

                  <InputGroup>
                    <Input
                      className={styles.password}
                      type={passwordShown ? 'text' : 'password'}
                      name="newPassword"
                      onChange={(e) => setPassword(e.target.value)}
                      onKeyDown={onPressEnter}
                    />
                    <InputGroupText className={styles.passwordIcon}>
                      <img
                        className={styles.visibleIcon}
                        onClick={() => setPasswordShown(!passwordShown)}
                        src={!passwordShown ? hide : eye}
                        alt="password visibility icon"
                      />
                    </InputGroupText>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row className={styles.centeredContainer}>
              <Col >
                <FormGroup>
                  <label className={styles.labelText}>
                    {content.RE_ENTER_PASSWORD}
                  </label>
                  <InputGroup>
                    <Input
                      className={styles.password}
                      type={newPasswordShown ? 'text' : 'password'}
                      name="newPassword"
                      onChange={(e) => setRepeatPassword(e.target.value)}
                      onKeyDown={onPressEnter}
                    />
                    <InputGroupText className={styles.passwordIcon}>
                      <img
                        className={styles.visibleIcon}
                        onClick={() => setNewPasswordShown(!newPasswordShown)}
                        src={!newPasswordShown ? hide : eye}
                        alt="password visibility icon"
                      />
                    </InputGroupText>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            {errorMsg && isPasswordValid && (
                    <div className={styles.errorText}>{errorMsg}</div>
                  )}
                  {errorMsg && !isPasswordValid && (
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                      <img
                        src={exclamation}
                        alt="exclamation"
                        style={{ marginTop: '3px' }}
                      />
                      <span style={{ marginLeft: '10px' }}>
                        {content.INVALID_PASSWORD_INPUT}
                      </span>
                    </div>
                  )}
            <Row className={styles.centeredContainer}>
              <Col>
                <button
                  className={classNames(
                    styles.submitBtn,
                    'button-text',
                    'button-primary',
                  )}
                  color="warning"
                  type="button"
                  onClick={handleSetPassword}
                >
                  {isLoading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    content.SET_PASSWORD
                  )}
                </button>
              </Col>
            </Row>
          </>
        ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  content: state.languageReducer.portalContent,
  languageCode: state.languageReducer.languageCode,
  isSetPasswordSuccess: state.authReducer.isSetPasswordSuccess,
  isSetPasswordError: state.authReducer.isSetPasswordError,
  setPasswordData: state.authReducer.setPasswordData,
  loginData: state.authReducer.loginData,
  email: state.authReducer.email,
});

const mapDispatchToProps = (dispatch) => ({
  setPasswordRequest: (data) => dispatch(setPasswordRequest(data)),
  loginRequest: (data) => dispatch(loginRequest(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SetPassword));
