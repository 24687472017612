import React from 'react';

import styles from './styles.module.scss';

function PageHeading(props) {
  const { heading, subHeading } = props;

  return (
    <div className={styles.headingContainer}>
      <div className="heading-3">{heading}</div>
      <div className="body-2">{subHeading}</div>
    </div>
  );
}

export default PageHeading;
