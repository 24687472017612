import Helper from '../../services/helper';
import { PATH } from '../../services/urls';

export const stageIdFormat = 'APPLSTAGE:TERN:';

export const getFunnelUrl = (stageId, params) => {
  const newParams = { ...params };
  newParams.shortlistedData = `${stageIdFormat}${stageId}`;

  const url = Helper.makeURLWithParams(PATH.JOB, newParams);
  return url;
};

export const jobCandidateCount = (job) => job.applicationStages?.reduce((count, stage) => count + stage.count, 0);
