/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useHistory, withRouter, Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import classNames from "classnames";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  getJobDetailsRequest,
  changeJobStatusRequest,
  getJobStatsRequest,
} from "../../redux/actions/action";

import styles from "./styles.module.scss";
import Helper from "../../services/helper";

import loading from "../../images/loading.gif";
import piplineSummary from "../../images/jobFunnelShimmer.gif";
import editGreen from "../../images/editGreen.svg";

import Picklist from "../../components/picklist";
import JobStats from "../../subViews/jobStats";

import { AGENCY_CLIENTS } from "../../services/constants";
import {
  details,
  additionalJobInfo,
  jobStages,
  jobStatusMapping,
  IN_PROGRESS,
} from "../../utils/constants";
import content from "../../languages/en.json";

const JobDetails = (props) => {
  const { jobDetailsData, jobStatsData, isJobStatsError, content } = props;
  const [selectedStatus, setSelectedStatus] = useState("");
  const prevProps = useRef(props);
  const [currJobId, setCurrJobId] = useState(null);
  const [currClientId, setCurrClientId] = useState(null);
  const [isActivateJobReqPending, setIsActivateJobReqPending] = useState(false);

  const jobId = jobStatsData?.jobs?.[0]?.jobId ?? "";
  const jobCode = jobStatsData?.jobs?.[0]?.jobCode ?? "";
  const jobTitle = jobStatsData?.jobs?.[0]?.title ?? "";
  const jobRoleCategory = jobStatsData?.jobs?.[0]?.jobRoleCategory ?? "";
  const jobRoleSpecialisation =
    jobStatsData?.jobs?.[0]?.jobRoleSpecialisation ?? "";

  const history = useHistory();
  const clientData = JSON.parse(
    Helper.getItem(AGENCY_CLIENTS),
  )?.clientsList?.find((client) => client.id === jobDetailsData?.clientId);

  const url = new URL(window.location.href);

  // Get the query parameters
  const queryParams = new URLSearchParams(url.search);
  const jobOpeningCode = queryParams.get("jobOpeningCode");
  const jobOpeningId = queryParams.get("jobOpeningId");

  useEffect(() => {
    if (!jobOpeningCode || !jobOpeningId) {
      const storedState = props?.location?.state?.storedState;
      const jobOpeningCode = storedState?.jobOpeningCode;
      const jobOpeningId = storedState?.jobOpeningId;

      // Push the values to the query parameters
      queryParams.set("jobOpeningCode", jobOpeningCode);
      queryParams.set("jobOpeningId", jobOpeningId);
      queryParams.set("jobTitle", jobTitle);

      // Update the URL with the new query parameters
      url.search = queryParams.toString();
      window.history.replaceState({}, "", url.toString());
    }
  }, []);

  useEffect(() => {
    const { getJobDetailsRequest, getJobStatsRequest } = props;

    const clientId = Helper.getItem("clientId");
    if (!clientId) {
      Helper.clearStorage();
      Helper.lastVisitedRoute();
      history.push("/");
      return;
    }

    let storedJobOpeningCode;
    let storedJobOpeningId;

    if (!jobOpeningCode || !jobOpeningId) {
      const storedState = props?.location?.state?.storedState;
      storedJobOpeningCode = storedState?.jobOpeningCode;
      storedJobOpeningId = storedState?.jobOpeningId;
    }
    setCurrClientId(clientId);
    setCurrJobId(jobOpeningId);

    const jobDetailsObj = {};
    jobDetailsObj.jobOpeningId =  storedJobOpeningId ?? jobOpeningId;
    jobDetailsObj.jobOpeningCode = storedJobOpeningCode ?? jobOpeningCode;
    jobDetailsObj.clientId = clientId;

    getJobDetailsRequest(jobDetailsObj);

    const jobStatsObj = {};
    jobStatsObj.jobOpeningId = storedJobOpeningId ?? jobOpeningId;
    jobStatsObj.clientId = clientId;
    jobStatsObj.jobOpeningCode = storedJobOpeningCode ?? jobOpeningCode;

    getJobStatsRequest(jobStatsObj);
  }, []);

  useEffect(() => {
    const {
      isChangeJobStatusSuccess: prevIsChangeJobStatusSuccess,
      isChangeJobStatusError: prevIsChangeJobStatusError,
    } = prevProps.current;
    const { isChangeJobStatusSuccess, isChangeJobStatusError } = props;
    if (prevIsChangeJobStatusSuccess === false && isChangeJobStatusSuccess) {
      notification(content.JOB_STATUS_UPDATED_SUCCESSFULLY);
      if (jobDetailsData?.jobStatus === "Waiting for approval") {
        window.location.reload(true);
      }
    }

    if (prevIsChangeJobStatusError === false && isChangeJobStatusError) {
      const messages = [];
      messages.push("Error");
      setIsActivateJobReqPending(false);
    }
    prevProps.current = props;
  }, [props.isChangeJobStatusSuccess, props.isChangeJobStatusError]);

  const notification = (messages) => {
    toast.success(messages, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
    });
  };

  const getAdditionalJobInfoValue = (detail) => {
    switch (detail.key) {
      case 'requiredSkills':
        return jobDetailsData[detail.key].split(',').join(', ');
      case 'summary':
        return <pre className={styles.summary}>{jobDetailsData.summary}</pre>;
      case 'jobDescription':
        return (
          <div
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{
              __html: jobDetailsData.jobDescription,
            }}
          />
        );
      default:
        return jobDetailsData[detail.key];
    }
  };

  const handleJobStatusChange = async (selectedOption) => {
    const { changeJobStatusRequest } = props;
    setSelectedStatus(selectedOption.value);
    setIsActivateJobReqPending(true);
    const changeJobStatusObject = {
      clientId: currClientId,
      jobOpeningId: currJobId,
      jobStageId: selectedOption.value,
    };
    await changeJobStatusRequest(changeJobStatusObject);
  };

  const getUrl = (path) => {
    const paramObj = {
      jobCode,
      currJobId: jobId,
      jobRoleCategory,
      specialisation: JSON.stringify(jobRoleSpecialisation),
    };

    return Helper.makeURLWithParams(path, paramObj);
  };

  const getEditJobUrl = (path) => {
    const paramObj = {
      jobOpeningCode,
      jobOpeningId: jobId,
      jobTitle,
    };

    return Helper.makeURLWithParams(path, paramObj);
  };

  return (
    <>
      <div className={styles.content}>
        <div className={styles.titleSection}>
          {jobDetailsData.title ? (
            <>
              <h3>{jobDetailsData.title}</h3>
              {clientData && (
                <div className="clientNameTag caption-1">
                  <span>{clientData.name}</span>
                </div>
              )}
            </>
          ) : (
            <div>
              <img className={styles.loadingGif} alt="" src={piplineSummary} />
            </div>
          )}
          <div className={styles.editDeleteBtns}>
            {jobDetailsData?.jobStatus ? (
              jobDetailsData.jobStatus === "Waiting for approval" ? (
                <button
                  onClick={() => handleJobStatusChange(jobStages[0])}
                  className="button-primary button-text"
                >
                  {!isActivateJobReqPending ? (
                    content.ACTIVATE_JOB
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              ) : (
                <div className={styles.selectContainer}>
                  <label
                    htmlFor="jobStatusSelect"
                    style={{ fontWeight: "bold" }}
                  >
                    {content.CURRENT_STATUS}
                  </label>
                  <Picklist
                    options={jobStages}
                    selectedValue={
                      selectedStatus ||
                      jobStatusMapping[jobDetailsData?.jobStatus]
                    }
                    onSelect={handleJobStatusChange}
                    dropdownStyle={styles.dropdownHeaderStyle}
                    id="jobStatusSelect"
                  />
                </div>
              )
            ) : (
              <div>
                <img
                  className={styles.loadingGif}
                  alt=""
                  src={piplineSummary}
                />
              </div>
            )}

            {jobDetailsData?.jobStatus ? (<Link
              className="linkAppearanceNone"
              to={() => getEditJobUrl("/edit-job")}
            >
              <button
                className={classNames(
                  "button-secondary",
                  "button-text",
                  styles.buttonHover
                )}
              >
                <span className={styles.cardICons}>
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.6719 3.76562H20.625C20.2031 3.34375 19.5 3.34375 19.0781 3.76562L17.6719 5.125L19.875 7.32812L21.2344 5.92188C21.6562 5.5 21.6562 4.79688 21.2344 4.32812L20.6719 3.76562ZM9.79688 13.0469C9.65625 13.1406 9.5625 13.3281 9.51562 13.5156L8.71875 16.2344L11.4844 15.4844C11.6719 15.4375 11.8125 15.3438 11.9531 15.2031L18.2812 8.875L16.125 6.71875L9.79688 13.0469ZM17.4844 2.17188C18.7969 0.859375 20.9062 0.859375 22.2188 2.17188L22.8281 2.78125C24.1406 4.09375 24.1406 6.20312 22.8281 7.51562L13.5469 16.7969C13.1719 17.1719 12.6562 17.4531 12.0938 17.6406L7.40625 18.9531C7.03125 19.0938 6.60938 19 6.32812 18.6719C6 18.3906 5.90625 17.9688 6 17.5938L7.35938 12.9062C7.5 12.3438 7.82812 11.8281 8.20312 11.4531L17.4844 2.17188ZM4.125 4H9.375C9.98438 4 10.5 4.51562 10.5 5.125C10.5 5.78125 9.98438 6.25 9.375 6.25H4.125C3.04688 6.25 2.25 7.09375 2.25 8.125V20.875C2.25 21.9531 3.04688 22.75 4.125 22.75H16.875C17.9062 22.75 18.75 21.9531 18.75 20.875V15.625C18.75 15.0156 19.2188 14.5 19.875 14.5C20.4844 14.5 21 15.0156 21 15.625V20.875C21 23.1719 19.125 25 16.875 25H4.125C1.82812 25 0 23.1719 0 20.875V8.125C0 5.875 1.82812 4 4.125 4Z"
                      fill="#006D5B"
                    />
                  </svg>
                </span>
                {content.EDIT_JOB}
              </button>
            </Link>) : <div>
              <img
                className={styles.loadingGif}
                alt=""
                src={piplineSummary}
              />
            </div>}

            {Object.keys(jobDetailsData).length < 1 ? (
              <div>
                <img
                  className={styles.loadingGif}
                  alt=""
                  src={piplineSummary}
                />
              </div>
            ) : jobDetailsData?.jobStatus === IN_PROGRESS &&
              (selectedStatus === "JOBSTAGE:TERN:1210" ||
                selectedStatus === "") ? (
              <Link
                className="linkAppearanceNone"
                to={() => getUrl("/add-candidate")}
              >
                <button className="button-secondary-with-icon button-text">
                  {content.ADD_CANDIDATES}
                </button>
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
        {jobDetailsData?.jobStatus === IN_PROGRESS && (
          <JobStats
            isJobStatsError={isJobStatsError}
            jobStatsData={jobStatsData}
            isJobDetailsPage={true}
          />
        )}

        {jobDetailsData?.jobDescription ? (
          <div className={styles.jobInfo}>
            {jobDetailsData.jobDescription && (
              <div className={styles.jobDescription}>
                <div>
                  <Col>
                    {additionalJobInfo.map((detail, index) => (
                      <Row
                        key={detail.label}
                        md="12"
                        className={
                          index === 0 ? styles.additionalDetailsDiv : ""
                        }
                      >
                        <p>
                          <strong className={styles.additionalDetails}>
                            {detail.label}
                          </strong>
                          <br />
                          <span> {getAdditionalJobInfoValue(detail)} </span>
                        </p>
                      </Row>
                    ))}
                    {jobDetailsData.mainDuties && <Row key={content.MAIN_DUTIES} md="12">
                      <p>
                        <strong className={styles.additionalDetails}>
                          {content.MAIN_DUTIES}
                        </strong>
                        <br />
                        <span>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: jobDetailsData.mainDuties
                            }}
                          />
                        </span>
                      </p>
                    </Row>}
                  </Col>
                </div>
              </div>
            )}

            {jobDetailsData.jobDescription && (
              <div className={styles.otherJobInfo}>
                <Col>
                  {details.map((detail) => (
                    <Row key={detail.label} md="12">
                      <p>
                        <strong className={styles.otherDetails}>
                          {detail.label}
                        </strong>
                        <br />
                        <span className={styles.otherDetailsValue}>
                          {jobDetailsData[detail.key]
                            ? jobDetailsData[detail.key]
                            : " "}
                        </span>
                      </p>
                    </Row>
                  ))}
                </Col>
              </div>
            )}
          </div>
        ) : (
          <div className={styles.loaderRow}>
            <div className={styles.loaderDiv}>
              <img
                className={styles.loaderImg}
                alt="loadingJobDescription"
                src={loading}
              />
              <p>{content.JOB_DETAILS_LOADING_MSG}</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    content: state.languageReducer.portalContent,
    languageCode: state.languageReducer.languageCode,
    isGetJobDetailsSuccess: state.jobDetailsReducer.isGetJobDetailsSuccess,
    isGetJobDetailsError: state.jobDetailsReducer.isGetJobDetailsError,
    jobDetailsData: state.jobDetailsReducer.jobDetailsData,
    isChangeJobStatusSuccess: state.jobDetailsReducer.isChangeJobStatusSuccess,
    isChangeJobStatusError: state.jobDetailsReducer.isChangeJobStatusError,
    isChangeJobStatusData: state.jobDetailsReducer.isChangeJobStatusData,
    isJobStatsSuccess: state.dashboardReducer.isJobStatsSuccess,
    isJobStatsError: state.dashboardReducer.isJobStatsError,
    jobStatsData: state.dashboardReducer.jobStatsData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getJobDetailsRequest: (data) => dispatch(getJobDetailsRequest(data)),
  changeJobStatusRequest: (data) => dispatch(changeJobStatusRequest(data)),
  getJobStatsRequest: (data) => dispatch(getJobStatsRequest(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(JobDetails));
