import Helper from '../services/helper';

export const setJobCodeAndId = () => {
  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.search);
  return queryParams.get('jobOpeningId');
};

export const noClientIdFallback = (history) => {
  const clientId = Helper.getItem('clientId');
  if (!clientId) {
    Helper.clearStorage();
    Helper.lastVisitedRoute();
    history.push('/');
  }
};
