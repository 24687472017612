import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import StickyBox from 'react-sticky-box';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { Col, Row } from 'reactstrap';
import styles from './styles.module.scss';
import 'react-toastify/dist/ReactToastify.css';

import { noClientIdFallback, setJobCodeAndId } from '../../utils/editJobHelper';
import {
  getContactsRequest,
  getJobDetailsRequest, getSkillsRequest,
  getSpecializationRequest,
  updateJobRequest,
} from '../../redux/actions/action';
import Helper from '../../services/helper';
import InputWithTitle from '../../components/input/InputWithTitle';
import SelectWithTitle from '../../components/input/SelectWithTitle';
import RichTextWithTitle from '../../components/input/RichTextWithTitle';
import {
  employmentTypes,
  jobCountries,
  jobEnglishLevelRequirement,
  jobRoles,
  jobSpecializationsData,
  jobSponsorshipProvided,
  salaryCurrencies,
  workExperienceOptions,
  workingPatterns,
} from '../../data/jobOptions';
import DatePickerWithTitle from '../../components/input/DatePickerWithTitle';
import MultiSelectWithTitle from '../../components/input/MultiSelectWithTitle';
import piplineSummary from '../../images/jobFunnelShimmer.gif';
import { AGENCY_CLIENTS, CLIENTID } from '../../services/constants';
import Preview from './preview';

const neutralText = {
  color: '#666E82',
  cursor: 'pointer',
  height: '40px',
  padding: '8px, 16px, 8px, 16px',
  borderRadius: '8px',
  gap: '8px',
};

const layoutStyleHalf = {
  width: '50%',
};

const layoutStyle = {
  width: '100%',
};

const bottomMarginStyle = {
  marginBottom: '15px',
};

function EditJob(props) {
  const {
    jobDetailsData, contacts, history: propHistory, isUpdateJobSuccess, isGetJobDetailsSuccess,
    isSkillsSuccess, content,
  } = props;
  const history = useHistory();
  const prevProps = useRef(props);

  const basicInfoRef = useRef(null);
  const locationDetailsRef = useRef(null);
  const qualificationCriteriaRef = useRef(null);
  const employmentDetailsRef = useRef(null);
  const skillsRef = useRef(null);
  const salaryDetailsRef = useRef(null);
  const descriptionRef = useRef(null);

  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.search);

  const [loading, setLoading] = useState(true);
  const [isPreview, setIsPreview] = useState(false);
  const [isJobUpdating, setIsJobUpdating] = useState(false);
  const [jobOpeningId, setJobOpeningId] = useState(null);
  const [jobName, setJobName] = useState('');
  const [jobSummary, setJobSummary] = useState('');
  const [role, setRole] = useState(jobRoles[0].value);
  const [isEmployerSelectionDisabled, setIsEmployerSelectionDisabled] = useState(false);
  const [employerName, setEmployerName] = useState(null);
  const [employerNames, setEmployerNames] = useState([]);
  const [recruiterAssigned, setRecruiterAssigned] = useState(null);
  const [targetDate, setTargetDate] = useState('');
  const [noOfOpenings, setNoOfOpenings] = useState('');
  const [city, setCity] = useState('');
  const [province, setProvince] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [workExperience, setWorkExperience] = useState(
    workExperienceOptions[0].value,
  );
  const [bandRequirement, setBandRequirement] = useState('');
  const [gradeRequirement, setGradeRequirement] = useState('');
  const [essentialQualifications, setEssentialQualifications] = useState('');
  const [desirableQualifications, setDesirableQualifications] = useState('');
  const [essentialExperience, setEssentialExperience] = useState('');
  const [additionalExperience, setAdditionalExperience] = useState('');
  const [fromSalaryDetails, setFromSalaryDetails] = useState(0);
  const [toSalaryDetails, setToSalaryDetails] = useState(0);
  const [desirableExperience, setDesirableExperience] = useState('');
  const [employmentType, setEmploymentType] = useState(null);
  const [workingPattern, setWorkingPattern] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [country, setCountry] = useState(null);
  const [englishLevelRequirement, setEnglishLevelRequirement] = useState(
    jobEnglishLevelRequirement[0].value,
  );
  const [sponsorshipProvided, setSponsorshipProvided] = useState(
    jobSponsorshipProvided[0].value,
  );
  const [jobSpecializations, setJobSpecializations] = useState({});
  const [specialization, setSpecialization] = useState([]);
  const [requiredSkills, setRequiredSkills] = useState(
    [],
  );
  const [requiredSkillsOptions, setRequiredSkillsOptions] = useState(
    [],
  );
  const [jobDescription, setJobDescription] = useState('');
  const [mainDuties, setMainDuties] = useState('');
  const [recruiterList, setRecruiterList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isSearchSkillLoading, setIsSearchSkillLoading] = useState('');
  const [isTitleError, setIsTitleError] = useState('');
  const [isRoleError, setIsRoleError] = useState('');
  const [isSpecializationsError, setIsSpecializationsError] = useState('');
  const [isSalaryToError, setIsSalaryToError] = useState('');
  const [isSalaryFromError, setIsSalaryFromError] = useState('');

  const scrollBehaviour = { behavior: 'smooth', block: 'start' };

  const searchSkills = () => {
    const { getSkillsRequest } = props;

    if (!searchText) {
      setRequiredSkillsOptions([]);
      return;
    }

    setIsSearchSkillLoading(true);

    getSkillsRequest({ searchText });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchSkills();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  const fetchContacts = () => {
    const { getContactsRequest } = props;

    const contactsRequest = {
      clientId: Helper.getItem(CLIENTID),
    };

    getContactsRequest(contactsRequest);
  };

  useEffect(() => {
    const { getJobDetailsRequest } = props;

    const jobOpeningId = setJobCodeAndId(props?.location?.state?.storedState);
    setJobOpeningId(jobOpeningId);

    noClientIdFallback(history);

    const jobDetailsObj = {
      jobOpeningId,
      clientId: Helper.getItem(CLIENTID),
    };

    getJobDetailsRequest(jobDetailsObj);

    fetchContacts();
  }, []);

  useEffect(() => {
    const clientsListFormatted = JSON.parse(
      Helper.getItem(AGENCY_CLIENTS),
    )?.clientsList?.map((client) => ({ value: client.id, label: client.name }));

    if (clientsListFormatted && jobDetailsData.clientId !== Helper.getItem('clientId')) {
      setEmployerNames(clientsListFormatted);
      setIsEmployerSelectionDisabled(false);
    }
  }, [isGetJobDetailsSuccess]);

  const getLabel = (contact) => (
    <Row>
      <Col className={styles.emailSelectOptionLayout}>{contact.fullName}</Col>
    </Row>
  );

  const scrollToRef = (refName) => {
    let ref = null;
    switch (refName) {
      case 'basicInfoRef':
        ref = basicInfoRef;
        break;
      case 'locationDetailsRef':
        ref = locationDetailsRef;
        break;
      case 'qualificationCriteriaRef':
        ref = qualificationCriteriaRef;
        break;
      case 'employmentDetailsRef':
        ref = employmentDetailsRef;
        break;
      case 'skillsRef':
        ref = skillsRef;
        break;
      case 'salaryDetailsRef':
        ref = salaryDetailsRef;
        break;
      case 'descriptionRef':
        ref = descriptionRef;
        break;
      default:
        ref = basicInfoRef;
    }

    ref.current.scrollIntoView(scrollBehaviour);
  };

  const goToViewJobDetails = () => {
    const jobFilter = {
      jobOpeningCode: queryParams.get('jobOpeningCode'),
      jobOpeningId,
      jobTitle: jobName,
    };

    propHistory.push({
      pathname: '/job-details',
      state: {
        storedState: jobFilter,
      },
    });
  };

  const notification = (messages) => {
    toast.success(messages, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: 'light',
    });
  };

  useEffect(() => {
    if (contacts?.length) {
      setRecruiterList(
        contacts.map((contact) => ({
          value: contact.id,
          label: getLabel(contact),
          id: contact.id,
        })),
      );
    }
  }, [contacts]);

  useEffect(() => {
    const { isUpdateJobSuccess: prevIsUpdateJobSuccess } = prevProps.current;

    const { isUpdateJobSuccess } = props;

    if (!prevIsUpdateJobSuccess && isUpdateJobSuccess) {
      setIsJobUpdating(false);

      notification('Edit job successful.');

      goToViewJobDetails();
    }

    prevProps.current = props;
  }, [isUpdateJobSuccess]);

  useEffect(() => {
    const { isSkillsSuccess: prevIsSkillsSuccess } = prevProps.current;

    const { isSkillsSuccess, skills } = props;

    if (!prevIsSkillsSuccess && isSkillsSuccess) {
      setRequiredSkillsOptions(skills?.relatedSkills?.map((skill) => ({ label: skill, value: skill })));
      setIsSearchSkillLoading(false);
    }

    prevProps.current = props;
  }, [isSkillsSuccess]);

  const getSpecializationOptions = () => {
    if (role?.toLowerCase() === 'doctor') {
      return jobSpecializations?.doctor;
    }
    return jobSpecializations?.nurse ?? [];
  };

  useEffect(() => {
    const { isGetJobDetailsSuccess } = props;

    if (isGetJobDetailsSuccess) {
      if (jobDetailsData) {
        setLoading(false);
        const {
          title,
          jobDescription,
          zipcode,
          city,
          clientId,
          country,
          province,
          numberOfOpenings,
          targetDate,
          yearsOfExperience,
          englishLevel,
          band,
          employmentType,
          role,
          workingPattern,
          sponsorshipProvided,
          specialisation,
          requiredSkills,
          essentialQualification,
          desirableQualification,
          essentialExperience,
          desirableExperience,
          mainDuties,
          salaryCurrency,
          additionalExperience,
          grade,
          salaryFrom,
          salaryTo,
          summary,
          recruiterAssigned,
          clientName,
        } = jobDetailsData;

        setJobName(title);
        setJobSummary(summary);
        setRole(role);
        setCity(city);
        if (country) {
          setCountry(country);
        }
        setProvince(province);
        setPostalCode(zipcode);
        setEnglishLevelRequirement(englishLevel);
        setNoOfOpenings(numberOfOpenings);
        setBandRequirement(band);
        setWorkingPattern(workingPattern);
        setSponsorshipProvided(sponsorshipProvided);

        if (specialisation !== '' && jobSpecializations && Object.keys(jobSpecializations).length > 0) {
          const specialisationList = specialisation.split(', ');
          const specialisationByType = getSpecializationOptions();
          const filteredSpecializations = specialisationByType.filter(
            (jS) => specialisationList.indexOf(jS.value) >= 0,
          );

          setSpecialization(filteredSpecializations);
        }
        if (recruiterAssigned && recruiterAssigned.id) {
          setRecruiterAssigned(recruiterAssigned.id);
        }
        setWorkExperience(yearsOfExperience);
        setEssentialQualifications(essentialQualification);
        setDesirableQualifications(desirableQualification);
        setEssentialExperience(essentialExperience);
        setDesirableQualifications(desirableQualification);
        if (clientId === Helper.getItem('clientId')) {
          setEmployerNames([{ value: clientId, label: clientName }]);
          setEmployerName(clientId);
          setIsEmployerSelectionDisabled(true);
        }
        setEmployerName(clientId);
        setJobDescription(jobDescription);
        if (targetDate) {
          const [dd, mm, yyyy] = targetDate.split('-');
          setTargetDate(`${yyyy}-${mm}-${dd}`);
        }
        setEmploymentType(employmentType);
        setDesirableExperience(desirableExperience);
        setMainDuties(mainDuties);
        setCurrency(salaryCurrency);
        setAdditionalExperience(additionalExperience);
        setGradeRequirement(grade);
        setToSalaryDetails(salaryTo);
        setFromSalaryDetails(salaryFrom);
        if (requiredSkills) {
          const skillsList = requiredSkills.trim().split(',');
          if (skillsList && skillsList.length > 0) {
            setRequiredSkills(skillsList.map((skill) => ({ label: skill, value: skill })));
          }
        }
      }
    }
  }, [isGetJobDetailsSuccess]);

  useEffect(() => {
    const { getSpecializationRequest } = props;

    getSpecializationRequest();
  }, []);

  useEffect(() => {
    const jobSpecializationsCopy = { ...jobSpecializations };

    Object.keys(jobSpecializationsData).forEach((specializationType) => {
      jobSpecializationsCopy[specializationType] = Object.values(
        jobSpecializationsData[specializationType],
      ).map((key) => ({
        id: key.displayName,
        value: key.displayName,
        label: key.displayName,
      }));
    });

    setJobSpecializations(
      jobSpecializationsCopy,
    );
  }, []);

  const getLocation = () => {
    const location = [];
    if (city && city !== '-') {
      location.push(city);
    }
    if (province && province !== '-') {
      location.push(province);
    }
    if (country && country !== '-') {
      location.push(country);
    }

    if (postalCode && postalCode !== '-') {
      location.push(postalCode);
    }

    return location.join(', ');
  };

  // eslint-disable-next-line consistent-return
  const getSalaryRange = () => {
    if (fromSalaryDetails && toSalaryDetails) {
      return `${fromSalaryDetails} - ${toSalaryDetails} ${currency}`;
    }
    return '-';
  };

  const getLocalJobDetails = () => {
    const [yyyy, mm, dd] = targetDate.split('-');

    return {
      jobId: jobOpeningId,
      clientId: Helper.getItem('clientId'),
      title: jobName,
      jobDescription,
      jobSummary,
      summary: jobSummary,
      postalCode,
      employerName,
      clientName: employerNames.length > 0 ? employerNames?.find((employer) => employer.value === employerName).label : undefined,
      city,
      country,
      province,
      location: getLocation(),
      numberOfOpenings: noOfOpenings,
      salaryFrom: Number.isInteger(parseInt(fromSalaryDetails, 10)) ? parseInt(fromSalaryDetails, 10) : fromSalaryDetails,
      salaryTo: Number.isInteger(parseInt(toSalaryDetails, 10)) ? parseInt(toSalaryDetails, 10) : toSalaryDetails,
      salaryCurrency: currency,
      salary: getSalaryRange(),
      targetDate: `${yyyy}-${mm}-${dd}`,
      yearsOfExperience: workExperience,
      englishLevel: englishLevelRequirement,
      band: bandRequirement,
      employmentType,
      role,
      workingPattern,
      sponsorshipProvided,
      specialisation: specialization.map((s) => s.value),
      requiredSkills: requiredSkills
        ? requiredSkills?.map((s) => s.value).join(',')
        : null,
      essentialQualification: essentialQualifications,
      desirableQualification: desirableQualifications,
      essentialExperience,
      desirableExperience,
      recruiterAssigned,
      mainDuties,
      numberOfPosition: noOfOpenings,
      additionalExperience,
      gradeRequirement,
      grade: gradeRequirement,
      addedOn: jobDetailsData.addedOn,
      jobReferenceNumber: jobDetailsData.jobReferenceNumber,
    };
  };

  const updateJob = () => {
    const { updateJobRequest } = props;

    const jobDetailsToUpdate = getLocalJobDetails();
    setIsTitleError('');
    setIsSpecializationsError('');
    setIsRoleError('');
    setIsSalaryToError('');
    setIsSalaryFromError('');

    if (!jobDetailsToUpdate.title) {
      setIsTitleError('Job Name Required');
      return;
    }

    if (jobDetailsToUpdate.specialisation.length < 1) {
      setIsSpecializationsError('Specialization Required');
      return;
    }

    if (!jobDetailsToUpdate.role) {
      setIsRoleError('Role Required');
      return;
    }

    if (Number.isNaN(jobDetailsToUpdate.salaryTo) || jobDetailsToUpdate.salaryTo < 0) {
      scrollToRef('salaryDetailsRef');
      setIsSalaryToError('Invalid Salary To');
      return;
    }

    if (Number.isNaN(jobDetailsToUpdate.salaryFrom) || jobDetailsToUpdate.salaryFrom < 0) {
      scrollToRef('salaryDetailsRef');
      setIsSalaryFromError('Invalid Salary From');
      return;
    }

    if (jobDetailsToUpdate.salaryTo < jobDetailsToUpdate.salaryFrom) {
      scrollToRef('salaryDetailsRef');
      setIsSalaryFromError('Invalid Range');
      return;
    }

    setIsJobUpdating(true);

    updateJobRequest(jobDetailsToUpdate);
  };

  const renderSidebar = () => (
    <StickyBox offsetTop={20} offsetBottom={20}>
      <div className={styles.editJobSideBarSection}>
        <span
          onClick={() => scrollToRef('basicInfoRef')}
          className="body-2"
          style={neutralText}
        >
          Basic Info
        </span>
        <span
          onClick={() => scrollToRef('locationDetailsRef')}
          className="body-2"
          style={neutralText}
        >
          Location Details
        </span>
        <span
          onClick={() => scrollToRef('qualificationCriteriaRef')}
          className="body-2"
          style={neutralText}
        >
          Qualification Criteria
        </span>
        <span
          onClick={() => scrollToRef('employmentDetailsRef')}
          className="body-2"
          style={neutralText}
        >
          Employment Details
        </span>
        <span
          onClick={() => scrollToRef('skillsRef')}
          className="body-2"
          style={neutralText}
        >
          Skills
        </span>
        <span
          onClick={() => scrollToRef('salaryDetailsRef')}
          className="body-2"
          style={neutralText}
        >
          Salary Details
        </span>
        <span
          onClick={() => scrollToRef('descriptionRef')}
          className="body-2"
          style={neutralText}
        >
          Description
        </span>
      </div>
    </StickyBox>
  );

  const filterOption = (input, option) => {
    const foundContact = contacts.find((data) => data.id === option.id);
    if (!foundContact) {
      return false;
    }

    return (
      foundContact.fullName.toLowerCase().includes(input.toLowerCase())
    );
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!isPreview ? (
        <div className={styles.content}>
          <div className={styles.editJobSection}>
            {loading ? (
              <div>
                <img
                  className={styles.loadingGif}
                  alt=""
                  src={piplineSummary}
                />
              </div>
            ) : (
              <div className="heading-3">{jobName}</div>
            )}
            <div className={styles.buttonDiv}>
              {!loading ? (
                <button
                  className={classNames(
                    'button-secondary',
                    'button-text',
                    styles.editJobButtonSec,
                  )}
                  color="warning"
                  type="button"
                  onClick={goToViewJobDetails}
                >
                  Cancel
                </button>
              ) : (
                <div>
                  <img
                    className={styles.loadingGif}
                    alt=""
                    src={piplineSummary}
                  />
                </div>
              )}
              {!loading ? (
                <button
                  className={classNames(
                    'button-secondary',
                    'button-text',
                    styles.editJobButtonSec,
                  )}
                  color="warning"
                  type="button"
                  onClick={() => setIsPreview(true)}
                >
                  Preview
                </button>
              ) : (
                <div>
                  <img
                    className={styles.loadingGif}
                    alt=""
                    src={piplineSummary}
                  />
                </div>
              )}
              {!loading ? (
                <button
                  className={classNames(
                    'button-primary',
                    'button-text',
                    styles.editJobButtonPrim,
                  )}
                  color="warning"
                  type="button"
                  onClick={updateJob}
                >
                  {isJobUpdating ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    content.SAVE_CHANGES
                  )}
                </button>
              ) : (
                <div>
                  <img
                    className={styles.loadingGif}
                    alt=""
                    src={piplineSummary}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={styles.mainSection}>
            {renderSidebar()}
            <div className={styles.editArea}>
              <div className={styles.editSection} ref={basicInfoRef}>
                <div style={bottomMarginStyle}>
                  <span className="heading-4">Basic Info</span>
                </div>
                <div>
                  <InputWithTitle
                    inputValue={jobName}
                    onChangeInput={setJobName}
                    title="Job Name"
                    error={isTitleError}
                  />
                </div>
                <div>
                  <InputWithTitle
                    inputValue={jobSummary}
                    onChangeInput={setJobSummary}
                    title="Job Summary"
                    inputType="textarea"
                  />
                </div>
                <div className={styles.editDivLayout}>
                  <div className={styles.rlInputs}>
                    <SelectWithTitle
                      showSearch={false}
                      title="Role"
                      layoutStyle={layoutStyleHalf}
                      selectOptions={jobRoles}
                      selectValue={role}
                      onChangeInput={setRole}
                      error={isRoleError}
                    />
                    <MultiSelectWithTitle
                      title="Specialization"
                      layoutStyle={layoutStyleHalf}
                      selectOptions={getSpecializationOptions()}
                      selectValue={specialization}
                      onChangeInput={setSpecialization}
                      error={isSpecializationsError}
                    />
                  </div>
                </div>
                <div className={styles.editDivLayout}>
                  <div className={styles.rlInputs}>
                    <SelectWithTitle
                      disabled={isEmployerSelectionDisabled}
                      title="Employer Name"
                      layoutStyle={layoutStyleHalf}
                      selectOptions={employerNames}
                      selectValue={employerName}
                      onChangeInput={setEmployerName}
                    />
                    <SelectWithTitle
                      showSearch
                      title="Recruiter Assigned"
                      layoutStyle={layoutStyleHalf}
                      selectOptions={recruiterList}
                      selectValue={recruiterAssigned}
                      onChangeInput={setRecruiterAssigned}
                      filterOption={filterOption}
                      selectPlaceholder="Select Recruiter"
                    />
                  </div>
                </div>
                <div className={styles.editDivLayout}>
                  <div className={styles.rlInputs}>
                    <DatePickerWithTitle
                      title="Target Date"
                      inputValue={targetDate}
                      layoutStyle={layoutStyleHalf}
                      minDate={new Date()}
                      onChangeInput={setTargetDate}
                    />
                    <InputWithTitle
                      inputValue={noOfOpenings}
                      onChangeInput={setNoOfOpenings}
                      title="No. of Openings"
                      layoutStyle={layoutStyleHalf}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.editSection} ref={locationDetailsRef}>
                <div style={bottomMarginStyle}>
                  <span className="heading-4">Location Details</span>
                </div>
                <div className={styles.rlInputs}>
                  <InputWithTitle
                    inputValue={city}
                    onChangeInput={setCity}
                    title="City"
                    inputPlaceholder="London"
                    layoutStyle={layoutStyleHalf}
                  />
                  <SelectWithTitle
                    title="Country"
                    inputPlaceholder="United Kingdom"
                    layoutStyle={layoutStyleHalf}
                    selectOptions={jobCountries}
                    selectValue={country}
                    onChangeInput={setCountry}
                  />
                </div>

                <div className={styles.rlInputs}>
                  <InputWithTitle
                    inputValue={province}
                    onChangeInput={setProvince}
                    title="Province"
                    inputPlaceholder="Hammersmith and Fulham"
                    layoutStyle={layoutStyleHalf}
                  />
                  <InputWithTitle
                    inputValue={postalCode}
                    onChangeInput={setPostalCode}
                    title="Postal Code"
                    inputPlaceholder="E1 8RU"
                    layoutStyle={layoutStyleHalf}
                  />
                </div>
              </div>
              <div
                className={styles.editSection}
                ref={qualificationCriteriaRef}
              >
                <div style={bottomMarginStyle}>
                  <span className="heading-4">Qualification Criteria</span>
                </div>
                <div style={bottomMarginStyle}>
                  <SelectWithTitle
                    title="Work Experience"
                    layoutStyle={layoutStyle}
                    selectOptions={workExperienceOptions}
                    selectValue={workExperience}
                    onChangeInput={setWorkExperience}
                  />
                </div>
                <div className={styles.rlInputs}>
                  <SelectWithTitle
                    title="English Level Requirement"
                    layoutStyle={layoutStyle}
                    selectOptions={jobEnglishLevelRequirement}
                    selectValue={englishLevelRequirement}
                    onChangeInput={setEnglishLevelRequirement}
                  />
                  <InputWithTitle
                    inputValue={bandRequirement}
                    onChangeInput={setBandRequirement}
                    title="Band Requirement"
                    inputPlaceholder="Band 6"
                    layoutStyle={layoutStyleHalf}
                  />
                  <InputWithTitle
                    inputValue={gradeRequirement}
                    onChangeInput={setGradeRequirement}
                    title="Grade Requirement"
                    inputPlaceholder=""
                    layoutStyle={layoutStyleHalf}
                  />
                </div>
                <div>
                  <InputWithTitle
                    inputValue={essentialQualifications}
                    onChangeInput={setEssentialQualifications}
                    title="Essential Qualifications"
                    inputType="textarea"
                    inputPlaceholder="Post Graduate Diploma"
                    layoutStyle={layoutStyle}
                  />
                </div>
                <div>
                  <InputWithTitle
                    inputValue={desirableQualifications}
                    onChangeInput={setDesirableQualifications}
                    title="Desirable Qualifications"
                    inputType="textarea"
                    inputPlaceholder="Post Graduate MSc"
                    layoutStyle={layoutStyle}
                  />
                </div>
                <div>
                  <InputWithTitle
                    inputValue={essentialExperience}
                    onChangeInput={setEssentialExperience}
                    title="Essential Experience"
                    inputType="textarea"
                    inputPlaceholder="Demonstrate competency in history"
                    layoutStyle={layoutStyle}
                  />
                </div>
                <div>
                  <InputWithTitle
                    inputValue={desirableExperience}
                    onChangeInput={setDesirableExperience}
                    title="Desirable Experience"
                    inputPlaceholder="Enter Desirable Experience"
                    layoutStyle={layoutStyle}
                  />
                </div>
                <div>
                  <InputWithTitle
                    inputValue={additionalExperience}
                    onChangeInput={setAdditionalExperience}
                    title="Additional Experience"
                    inputType="textarea"
                    inputPlaceholder="Effective and inclusive communication skills"
                    layoutStyle={layoutStyle}
                  />
                </div>
              </div>

              <div className={styles.editSection} ref={employmentDetailsRef}>
                <div style={bottomMarginStyle}>
                  <span className="heading-4">Employment Details</span>
                </div>
                <div>
                  <SelectWithTitle
                    title="Sponsership Provided"
                    layoutStyle={layoutStyle}
                    selectOptions={jobSponsorshipProvided}
                    selectValue={sponsorshipProvided}
                    onChangeInput={setSponsorshipProvided}
                  />
                </div>
                <div className={styles.rlInputs}>
                  <SelectWithTitle
                    title="Working Pattern"
                    layoutStyle={layoutStyleHalf}
                    selectOptions={workingPatterns}
                    selectValue={workingPattern}
                    onChangeInput={setWorkingPattern}
                  />
                  <SelectWithTitle
                    title="Employment Type"
                    layoutStyle={layoutStyleHalf}
                    selectOptions={employmentTypes}
                    selectValue={employmentType}
                    onChangeInput={setEmploymentType}
                  />
                </div>
              </div>
              <div className={styles.editSection} ref={skillsRef}>
                <div style={bottomMarginStyle}>
                  <span className="heading-4">Skills</span>
                </div>
                <div>
                  <MultiSelectWithTitle
                    title="Required Skills"
                    layoutStyle={layoutStyle}
                    selectValue={requiredSkills}
                    onChangeInput={setRequiredSkills}
                    onInputChange={setSearchText}
                    selectOptions={requiredSkillsOptions}
                    isLoading={isSearchSkillLoading}
                    noOptionMessage={() => 'Start typing to search for skills required'}
                  />
                </div>
              </div>
              <div className={styles.editSection} ref={salaryDetailsRef}>
                <div style={bottomMarginStyle}>
                  <span className="heading-4">Salary details</span>
                </div>
                <div className={styles.rlInputs}>
                  <InputWithTitle
                    inputValue={fromSalaryDetails}
                    onChangeInput={setFromSalaryDetails}
                    title="From"
                    inputType="number"
                    inputPlaceholder="14,000"
                    layoutStyle={layoutStyleHalf}
                    error={isSalaryFromError}
                  />
                  <InputWithTitle
                    inputValue={toSalaryDetails}
                    onChangeInput={setToSalaryDetails}
                    title="To"
                    inputType="number"
                    inputPlaceholder="23,000"
                    layoutStyle={layoutStyleHalf}
                    error={isSalaryToError}
                  />
                  <SelectWithTitle
                    title="Currency"
                    layoutStyle={layoutStyleHalf}
                    selectOptions={salaryCurrencies}
                    selectValue={currency}
                    onChangeInput={setCurrency}
                  />
                </div>
              </div>
              <div className={styles.editSection} ref={descriptionRef}>
                <div style={bottomMarginStyle}>
                  <span className="heading-4">Description</span>
                </div>
                <div className={styles.divInput}>
                  <RichTextWithTitle
                    title="Job Description"
                    layoutStyle={layoutStyle}
                    inputValue={jobDescription}
                    onChangeInput={setJobDescription}
                  />
                </div>

                <div className={styles.divInput}>
                  <RichTextWithTitle
                    title="Main Duties"
                    inputValue={mainDuties}
                    onChangeInput={setMainDuties}
                    layoutStyle={layoutStyle}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Preview
          setIsPreview={setIsPreview}
          jobDetails={getLocalJobDetails()}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  content: state.languageReducer.portalContent,
  languageCode: state.languageReducer.languageCode,

  isGetJobDetailsSuccess: state.jobDetailsReducer.isGetJobDetailsSuccess,
  isGetJobDetailsError: state.jobDetailsReducer.isGetJobDetailsError,
  jobDetailsData: state.jobDetailsReducer.jobDetailsData,

  isUpdateJobSuccess: state.jobsReducer.isUpdateJobSuccess,
  isUpdateJobError: state.jobsReducer.isUpdateJobError,
  isUpdateJobData: state.jobsReducer.isUpdateJobData,

  isSpecializationSuccess: state.jobDetailsReducer.isSpecializationSuccess,
  isSpecializationError: state.jobDetailsReducer.isSpecializationError,
  specializationData: state.jobDetailsReducer.specializationData,

  isContactsSuccess: state.contactsReducer.isContactsSuccess,
  isContactsError: state.contactsReducer.isContactsError,
  contacts: state.contactsReducer.contacts,

  isSkillsSuccess: state.jobsReducer.isSkillsSuccess,
  isSkillsError: state.jobsReducer.isSkillsError,
  skills: state.jobsReducer.skills,
});

const mapDispatchToProps = (dispatch) => ({
  getJobDetailsRequest: (data) => dispatch(getJobDetailsRequest(data)),
  updateJobRequest: (data) => dispatch(updateJobRequest(data)),
  getSpecializationRequest: (data) => dispatch(getSpecializationRequest(data)),
  getContactsRequest: (data) => dispatch(getContactsRequest(data)),
  getSkillsRequest: (data) => dispatch(getSkillsRequest(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(EditJob));
