/* eslint-disable */
import React from 'react';
import {
  useTable, useFilters, useSortBy, usePagination,
} from 'react-table';
import cx from 'classnames';
import styles from './styles.module.scss';

function Table({
  columns, data, emptyDataMsg, isBorderRadiusReq = false,
}) {
  const dataArray = Array.isArray(data) ? data : [data];
  const {
    getTableProps, headerGroups, page, prepareRow,
  } = useTable(
    {
      columns,
      data: dataArray, // Use the converted array,
      initialState: { pageSize: 100000, pageIndex: 0 },
    },
    useFilters,
    useSortBy,
    usePagination,
  );

  return (
    <div className="ReactTable -striped -highlight primary-pagination">
      <div className="pagination-top" />
      <table
        {...getTableProps()}
        className="rt-table"
        style={{
          fontSize: '12px',
          borderRadius: isBorderRadiusReq ? '10px' : '0px',
        }}
      >
        <thead
          className="rt-thead -header"
          style={{
            backgroundColor: '#006d5b',
            color: 'white',
            paddingLeft: isBorderRadiusReq ? '0px' : '20px',
          }}
        >
          {headerGroups.map((headerGroup, index) => (
            <tr key={index} className={styles.tableSize}>
              {headerGroup.headers.map((column, key) => (
                <th
                  key={key}
                  width={column.marginLeft}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  {...column.getHeaderProps(
                    column.isSortingEnable !== false
                      ? column.getSortByToggleProps()
                      : {},
                  )}
                  className={cx('rt-th rt-resizable-header', {
                    '-cursor-pointer':
                      headerGroup.headers.length - 1 !== key
                      && column.isSortingEnable !== false,
                    '-sort-asc': column.isSorted && !column.isSortedDesc,
                    '-sort-desc': column.isSorted && column.isSortedDesc,
                  }, column.class)}
                >
                  <div className="rt-resizable-header-content">
                    {column.render('Header')}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {page.length > 0 ? (
            page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  key={i}
                  className={
                  i % 2 === 0
                    ? styles.tableBodySizeOdd
                    : styles.tableBodySizeEven
                }
                >
                  {row.cells.map((cell, index) => (
                    <td
                      key={index}
                      {...cell.getCellProps()}
                      className={cx('rt-td', cell.column.class)}
                      style={{ border: 'transparent' }}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })
          ) : page.length === 0 && (
          <p
            className="text-center mt-5 mb-5 text-bold"
            style={{ fontFamily: 'Poppins', fontSize: '14px' }}
          >
            {emptyDataMsg || 'There are no candidates in this stage'}
          </p>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
