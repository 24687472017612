import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from 'reactstrap';
import Helper from '../../services/helper';
import styles from './styles.module.scss';
import RecentActivities from '../../subViews/recentActivities';
import UpcomingInterviews from '../../subViews/upcomingInterviews';
import JobStats from '../../subViews/jobStats';
import CandidateStats from '../../subViews/candidateStats';
import UserDetails from '../../subViews/userDetails';
import PostJobModal from '../../modals/postJobModal';
import SuccessModal from '../../modals/successModal';
import {
  getUpcomingInterviewsRequest,
  getRecentActivitiesRequest,
  getJobStatsRequest,
  getAgencyClientsRequest,
  getCandidateStatsRequest,
} from '../../redux/actions/action';
import { getPortalContent } from '../../redux/reducers/languageReducer';
import { getLoginData, getPasswordData } from '../../redux/reducers/authReducer';

function Dashboard() {
  const dispatch = useDispatch();
  const content = useSelector(getPortalContent);
  const loginData = useSelector(getLoginData);
  const setPasswordData = useSelector(getPasswordData);
  const {
    upcomingInterviewsData,
    recentActivitiesData,
    isRecentActivitiesError,
    jobStatsData,
    candidateStatsData,
    isUpcomingInterviewsError,
    isCandidateStatsError,
    isJobStatsError,
    isAgencyClientsSuccess,

  } = useSelector((state) => state.dashboardReducer);

  const history = useHistory();
  const [showAddJobModal, setShowAddJobModal] = useState(false);
  const [showAddJobSuccessModal, setShowAddJobSuccessModal] = useState(false);
  const [epochTime, setEpochTime] = useState(null);
  const [currClientId, setCurrClientId] = useState(null);

  useEffect(() => {
    if (loginData.authType === 'reset' && setPasswordData.authType === 'reset') {
      Helper.clearStorage();
      history.push('/reset-password');
      return;
    }

    const clientId = Helper.getItem('clientId');
    if (!clientId) {
      Helper.clearStorage();
      Helper.lastVisitedRoute();
      history.push('/');
      return;
    }
    setCurrClientId(clientId);

    dispatch(getAgencyClientsRequest({ parentClientId: clientId }));

    dispatch(getUpcomingInterviewsRequest({ totalCount: 2, clientId }));

    dispatch(getRecentActivitiesRequest({ limit: 3, clientId }));

    dispatch(getJobStatsRequest({
      pageNum: 1,
      pageLimit: 2,
      clientId,
      jobStatusId: 'JOBSTAGE:TERN:1210',
    }));

    dispatch(getCandidateStatsRequest({ clientId }));
  }, []);

  const viewAllJobs = async () => {
    history.push('/all-jobs');
  };

  const openAddJobModal = () => {
    setEpochTime(Date.now());
    setShowAddJobModal(true);
  };

  const closeAddJobModal = () => {
    setShowAddJobModal(false);
  };

  const closeAddJobSuccessModal = () => {
    setShowAddJobSuccessModal(false);
  };

  const addJob = async () => {
    setShowAddJobModal(false);
    setShowAddJobSuccessModal(true);
  };
  return (
    <>
      <div className="pageContent">
        <div>
          <UserDetails />
          <CardTitle className={styles.dashboardMessage}>
            {content.DASHBORD_MESSAGE}
          </CardTitle>
        </div>
        <Row className={styles.mainRow}>
          <Col md="3" className={styles.colPadding1}>
            <Card className="card">
              <CardHeader className="cardTitlePaddingTopMedium">
                <CardTitle
                  className={classNames(
                    'subtitle-1',
                    'cardTitleMarginTopSmall',
                    'ellipseStyle',
                  )}
                >
                  {content.UPCOMING_INTERVIEWS}
                </CardTitle>
              </CardHeader>
              <CardBody className="cardBody">
                <UpcomingInterviews
                  isUpcomingInterviewsError={isUpcomingInterviewsError}
                  upcomingInterviewsData={upcomingInterviewsData}
                />
              </CardBody>
              <CardFooter>
                <div className={styles.activityFooter} />
              </CardFooter>
            </Card>
          </Col>
          <Col md="3" className={styles.colPadding2}>
            <Card className="card">
              <CardHeader className="cardTitlePaddingTopMedium">
                <CardTitle
                  className={classNames(
                    'subtitle-1',
                    'cardTitleMarginTopSmall',
                    'ellipseStyle',
                  )}
                >
                  {content.RECENT_ACTIVITY}
                </CardTitle>
              </CardHeader>
              <CardBody className="cardBody">
                <RecentActivities
                  isRecentActivitiesError={isRecentActivitiesError}
                  recentActivitiesData={recentActivitiesData}
                />
              </CardBody>
              <CardFooter>
                <div className={styles.activityFooter} />
              </CardFooter>
            </Card>
          </Col>
          <Col md="6" className={styles.colPadding3}>
            <Card>
              <CandidateStats
                isCandidateStatsError={isCandidateStatsError}
                candidateStatsData={candidateStatsData}
              />
            </Card>
          </Col>
        </Row>
        <div className={styles.yourJobs}>
          <div className={classNames(styles.yourJobsText, 'heading-2')}>
            <span className={styles.jobTextColor}>{content.YOUR_JOBS}</span>
          </div>
          <div className={styles.yourJobsHr}>
            <hr />
          </div>
          <div className={styles.yourJobsButton}>
            <button
              className="button-secondary button-text text-nowrap"
              color="warning"
              type="button"
              onClick={viewAllJobs}
            >
              {content.VIEW_YOUR_JOBS}
            </button>
            <button
              className="button-primary-with-icon button-text text-nowrap"
              color="warning"
              type="button"
              onClick={openAddJobModal}
            >
              {content.POST_A_JOB}
            </button>
          </div>
        </div>
        {isAgencyClientsSuccess && (
          <JobStats
            isJobStatsError={isJobStatsError}
            jobStatsData={jobStatsData}
            onClickEmptyCard={openAddJobModal}
          />
        )}
      </div>
      <PostJobModal
        addJob={addJob}
        showAddJobModal={showAddJobModal}
        closeAddJobModal={closeAddJobModal}
        clientId={currClientId}
        epochTime={epochTime}
      />
      <SuccessModal
        heading={content.JOB_ADDED_SUCCESSFULLY}
        successMsg={content.SUCCESS_MESSAGE}
        showSuccessModal={showAddJobSuccessModal}
        closeSuccessModal={closeAddJobSuccessModal}
      />
    </>
  );
}
export default Dashboard;
