/* eslint-disable default-param-last */
import {
  LIST_ALL_CANDIDATES_REQUEST,
  LIST_ALL_CANDIDATES_SUCCESS,
  LIST_ALL_CANDIDATES_ERROR,
} from '../actions/actionTypes';

const InitialState = {
  isGetAllCandidatesSuccess: false,
  isGetAllCandidatesError: false,
  candidatesData: null,
  isLoading: false,
};

export function allCandidatesReducer(
  state = InitialState,
  { type, response },
) {
  switch (type) {
    case LIST_ALL_CANDIDATES_REQUEST:
      return {
        ...state,
        isGetAllCandidatesSuccess: false,
        isGetAllCandidatesError: false,
        candidatesData: null,
        isLoading: true,
      };
    case LIST_ALL_CANDIDATES_SUCCESS:
      return {
        ...state,
        isGetAllCandidatesSuccess: true,
        isGetAllCandidatesError: false,
        candidatesData: response,
        isLoading: false,
      };
    case LIST_ALL_CANDIDATES_ERROR:
      return {
        ...state,
        isGetAllCandidatesSuccess: false,
        isGetAllCandidatesError: true,
        candidatesData: response,
        isLoading: false,
      };
    default:
      return state;
  }
}

// selector
export const getAllCandidates = (state) => state.allCandidatesReducer.candidatesData;
