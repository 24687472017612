import { combineReducers } from 'redux';
import { languageReducer } from './languageReducer';
import { authReducer } from './authReducer';
import { dashboardReducer } from './dashboardReducer';
import { candidateReducer } from './candidateReducer';
import { jobsReducer } from './jobsReducer';
import { jobDetailsReducer } from './jobDetailsReducer';
import { allJobsReducer } from './allJobsReducer';
import { addCandidatesReducer } from './addCandidatesReducer';
import { contactsReducer } from './contactsReducer';
import { servicePackageReducer, reportReducer } from './emailerReducer';
import { allCandidatesReducer } from './allCandidatesReducer';

const rootReducer = combineReducers({
  languageReducer,
  authReducer,
  dashboardReducer,
  candidateReducer,
  jobsReducer,
  jobDetailsReducer,
  allJobsReducer,
  addCandidatesReducer,
  servicePackageReducer,
  reportReducer,
  contactsReducer,
  allCandidatesReducer,
});

export default rootReducer;
