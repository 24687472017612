import {
  takeLatest, takeEvery, put, call,
} from 'redux-saga/effects';

import {
  GET_CANDIDATE_PROFILE_REQUEST,
  SCHEDULE_CANDIDATE_INTERVIEW_REQUEST,
  LIST_INTERVIEW_REQUEST,
  FETCH_INTERVIEW_DETAILS_REQUEST,
  DELETE_INTERVIEW_REQUEST,
  CANCEL_INTERVIEW_REQUEST,
} from '../actions/actionTypes';
import {
  getCandidateProfileSuccess,
  getCandidateProfileError,
  scheduleCandidateInterviewSuccess,
  scheduleCandidateInterviewError,
  listInterviewSuccess,
  listInterviewError,
  fetchInterviewDetailsError,
  fetchInterviewDetailsSuccess,
  cancelInterviewSuccess,
  cancelInterviewError,
  deleteInterviewError,
  deleteInterviewSuccess,
  listInterviewEmpty,
} from '../actions/action';
import candidateAPI from '../../apis/candidateApi';

function* getCandidateProfile(params) {
  try {
    const response = yield call(candidateAPI.GetCandidateProfile, params);

    const data = yield response.data;

    if (data.payload) yield put(getCandidateProfileSuccess(data.payload));
    else yield put(getCandidateProfileError(data));
  } catch (error) {
    yield put(getCandidateProfileError(error.message));
  }
}

function* scheduleCandidateInterview(params) {
  try {
    const response = yield call(
      candidateAPI.ScheduleCandidateInterview,
      params,
    );

    const { data } = response;

    if (data.id) yield put(scheduleCandidateInterviewSuccess(data.payload));
    else yield put(scheduleCandidateInterviewError(data));
  } catch (error) {
    yield put(scheduleCandidateInterviewError(error.message));
  }
}

function* listCandidateInterviews(params) {
  try {
    const response = yield call(candidateAPI.ListInterviews, params);

    const { data } = response;

    if (data) yield put(listInterviewSuccess(data.payload));
    else yield put(listInterviewEmpty(data));
  } catch (error) {
    yield put(listInterviewError(error.message));
  }
}

function* fetchInterviewDetails(params) {
  try {
    const response = yield call(candidateAPI.FetchInterviewDetails, params);

    const { data } = response;

    if (data) yield put(fetchInterviewDetailsSuccess(data.payload));
    else yield put(fetchInterviewDetailsError(data));
  } catch (error) {
    yield put(fetchInterviewDetailsError(error.message));
  }
}

function* deleteCandidateInterview(params) {
  try {
    const response = yield call(candidateAPI.DeleteInterview, params);

    const { data } = response;

    if (data) yield put(deleteInterviewSuccess(data.id));
    else yield put(deleteInterviewError(data));
  } catch (error) {
    yield put(deleteInterviewError(error.message));
  }
}

function* cancelCandidateInterview(params) {
  try {
    const response = yield call(candidateAPI.CancelInterview, params);

    const { data } = response;

    if (data) yield put(cancelInterviewSuccess(data.id));
    else yield put(cancelInterviewError(data));
  } catch (error) {
    yield put(cancelInterviewError(error.message));
  }
}

function* candidateSaga() {
  yield takeLatest(GET_CANDIDATE_PROFILE_REQUEST, getCandidateProfile);
  yield takeLatest(
    SCHEDULE_CANDIDATE_INTERVIEW_REQUEST,
    scheduleCandidateInterview,
  );
  yield takeLatest(LIST_INTERVIEW_REQUEST, listCandidateInterviews);
  yield takeEvery(FETCH_INTERVIEW_DETAILS_REQUEST, fetchInterviewDetails);
  yield takeLatest(DELETE_INTERVIEW_REQUEST, deleteCandidateInterview);
  yield takeLatest(CANCEL_INTERVIEW_REQUEST, cancelCandidateInterview);
}

export default candidateSaga;
