/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { getJobFunnelRequest } from '../../redux/actions/action';
import styles from './styles.module.scss';
import homeIon from '../../images/home-line.svg';
import rightArrow from '../../images/chevron-right.svg';
import Helper from '../../services/helper';
import { PATH } from '../../services/urls';

function Breadcrumbs(props) {
  let crumbs;
  const location = useLocation();
  const url = new URL(window.location.href);

  const queryParams = new URLSearchParams(url.search);
  const jobOpeningCode = queryParams.get('jobOpeningCode');
  const jobOpeningId = queryParams.get('jobOpeningId');
  const jobTitle = queryParams.get('jobTitle');

  const [currJobDetails, setCurrJobDetails] = useState(
    {
      jobOpeningCode,
      jobOpeningId,
      jobTitle,
    },
  );

  useEffect(() => {
    if (!jobOpeningCode || !jobOpeningId || !jobTitle) {
      const storedState = props?.location?.state?.storedState;

      setCurrJobDetails({
        jobOpeningCode: storedState?.jobOpeningCode,
        jobOpeningId: storedState?.jobOpeningId,
        jobTitle: storedState?.jobTitle,
      });
    }
  }, []);

  const prevProps = useRef(props);

  useEffect(() => {
    const {
      isJobFunnelSuccess: prevIsJobFunnelSuccess,
    } = prevProps.current;
    const { isJobFunnelSuccess, isJobFunnelData } = props;

    if (prevIsJobFunnelSuccess === false && isJobFunnelSuccess) {
      if (currJobDetails?.jobOpeningId !== isJobFunnelData?.jobOpeningId) {
        setCurrJobDetails({
          jobOpeningCode: isJobFunnelData?.jobOpeningCode,
          jobOpeningId: isJobFunnelData?.jobOpeningId,
          jobTitle: isJobFunnelData?.title,
        });
      }
    }

    prevProps.current = props;
  }, [props.isJobFunnelSuccess]);

  switch (location.pathname) {
    case '/edit-job':
      crumbs = [
        {
          name: 'All Jobs',
          path: '/all-jobs',
        },
        {
          name: currJobDetails?.jobTitle,
          path: '/job-details',
          state: {
            storedState: currJobDetails,
          },
        },
        {
          name: 'Edit Job',

        },
      ];
      break;
    case '/job-details':
      crumbs = [
        {
          name: 'All Jobs',
          path: '/all-jobs',
        },
        {
          name: currJobDetails?.jobTitle,
        },
      ];
      break;
    case '/all-jobs':
      crumbs = [
        {
          name: 'All Jobs',
        },
      ];
      break;
    case '/jobs':
      crumbs = [
        {
          name: 'All Jobs',
          path: '/all-jobs',
        },
        {
          name: currJobDetails?.jobTitle,
          path: '/job-details',
          state: {
            storedState: currJobDetails,
          },
        },
        {
          name: 'Talent Pipeline',

        },
      ];
      break;
    case '/services-offered':
      crumbs = [
        {
          name: 'Services Offered',
        },
      ];
      break;
    case '/add-candidate':
      crumbs = [
        {
          name: 'All Jobs',
          path: '/all-jobs',
        },
        {
          name: currJobDetails?.jobTitle,
          path: '/job-details',
          state: {
            storedState: currJobDetails,
          },
        },
        {
          name: 'Add Candidate',
        },
      ];
      break;
    case '/reports':
      crumbs = [
        {
          name: 'Reports',
        },
      ];
      break;
    case PATH.ALL_CANDIDATES:
      crumbs = [
        {
          name: 'All Candidates',
        },
      ];
      break;
    default:
      crumbs = [];
  }
  const createQueryParams = (state) => {
    const flattenObj = Helper.flattenObject(state);
    if (Object.keys(flattenObj) < 1) {
      return null;
    }

    const paramStr = [];
    for (const key in flattenObj) {
      const str = `${key}=${flattenObj[key]}`;
      paramStr.push(str);
    }
    return `?${paramStr.join('&')}`;
  };

  return location.pathname === '/dashboard' || location.pathname === '/update-password'
    ? null
    : (
      <div className={styles.breadcrumbContainer}>
        <Link key="/dashboard" to="/dashboard">
          <img src={homeIon} alt="Menu" className={styles.cardICons} />
        </Link>
        {crumbs.map(({ name, path, state }, key) => (key + 1 === crumbs.length ? (
          <div key={key} className={styles.textAlignment}>
            <img src={rightArrow} alt="Menu" className={styles.cardICons} />
            <span className={styles.breadCrumbText}>
              {' '}
              {name}
            </span>
          </div>
        ) : (
          <Link key={key} to={{ pathname: path, search: createQueryParams(state), state }}>
            <img src={rightArrow} alt="Menu" className={styles.cardICons} />
            <span className={styles.breadCrumbText}>
              {' '}
              {name}
            </span>
          </Link>
        )))}
      </div>
    );
}

const mapStateToProps = (state) => ({
  isJobFunnelSuccess: state.jobsReducer.isJobFunnelSuccess,
  isJobFunnelData: state.jobsReducer.isJobFunnelData,
});

const mapDispatchToProps = (dispatch) => ({
  getJobFunnelRequest: (data) => dispatch(getJobFunnelRequest(data)),

});

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs);
