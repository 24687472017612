/* eslint-disable */
import {
  GET_CANDIDATE_PROFILE_REQUEST,
  GET_CANDIDATE_PROFILE_SUCCESS,
  GET_CANDIDATE_PROFILE_ERROR,
  LIST_INTERVIEW_EMPTY,
  FETCH_INTERVIEW_DETAILS_REQUEST,
  FETCH_INTERVIEW_DETAILS_SUCCESS,
  FETCH_INTERVIEW_DETAILS_ERROR,
  DELETE_INTERVIEW_REQUEST,
  DELETE_INTERVIEW_SUCCESS,
  DELETE_INTERVIEW_ERROR,
  SCHEDULE_CANDIDATE_INTERVIEW_REQUEST,
  SCHEDULE_CANDIDATE_INTERVIEW_SUCCESS,
  SCHEDULE_CANDIDATE_INTERVIEW_ERROR,
  LIST_INTERVIEW_REQUEST,
  LIST_INTERVIEW_SUCCESS,
  LIST_INTERVIEW_ERROR,
  CANCEL_INTERVIEW_REQUEST,
  CANCEL_INTERVIEW_ERROR,
  CANCEL_INTERVIEW_SUCCESS,
} from "../actions/actionTypes";

const initialState = {
  isGetCandidateProfileSuccess: false,
  isGetCandidateProfileError: false,
  candidateProfileData: {},
  isScheduleCandidateInterviewSuccess: false,
  isScheduleCandidateInterviewError: false,
  scheduleCandidateInterviewData: {},
  listInterviewsData: {},
  isListInterviewsSuccess: false,
  isListInterviewsError: false,
  interviewDetailsData: {},
  isInterviewDetailsSuccess: false,
  isInterviewDetailsError: false,
  isCancelInterviewSuccess: false,
  isCancelInterviewError: false,
  cancelInterviewData: {},
  isDeleteInterviewSuccess: false,
  isDeleteInterviewError: false,
  deleteInterviewData: {},
};

export function candidateReducer(state = initialState, { type, response }) {
  switch (type) {
    case GET_CANDIDATE_PROFILE_REQUEST:
      return {
        ...state,
        isGetCandidateProfileSuccess: false,
        isGetCandidateProfileError: false,
        candidateProfileData: {},
      };
    case GET_CANDIDATE_PROFILE_SUCCESS:
      return {
        ...state,
        isGetCandidateProfileSuccess: true,
        isGetCandidateProfileError: false,
        candidateProfileData: response,
      };
    case GET_CANDIDATE_PROFILE_ERROR:
      return {
        ...state,
        isGetCandidateProfileSuccess: false,
        isGetCandidateProfileError: true,
        candidateProfileData: response,
      };
    case SCHEDULE_CANDIDATE_INTERVIEW_REQUEST:
      return {
        ...state,
        isScheduleCandidateInterviewSuccess: false,
        isScheduleCandidateInterviewError: false,
        scheduleCandidateInterviewData: {},
      };
    case SCHEDULE_CANDIDATE_INTERVIEW_SUCCESS:
      return {
        ...state,
        isScheduleCandidateInterviewSuccess: true,
        isScheduleCandidateInterviewError: false,
        scheduleCandidateInterviewData: response,
      };
    case SCHEDULE_CANDIDATE_INTERVIEW_ERROR:
      return {
        ...state,
        isScheduleCandidateInterviewSuccess: false,
        isScheduleCandidateInterviewError: true,
        scheduleCandidateInterviewData: response,
      };
    case LIST_INTERVIEW_REQUEST:
      return {
        ...state,
        isListInterviewsSuccess: false,
        isListInterviewsError: false,
        listInterviewsData: {},
      };
    case LIST_INTERVIEW_SUCCESS:
      return {
        ...state,
        isListInterviewsSuccess: true,
        isListInterviewsError: false,
        listInterviewsData: response,
      };
    case LIST_INTERVIEW_ERROR:
      return {
        ...state,
        isListInterviewsSuccess: false,
        isListInterviewsError: true,
        listInterviewsData: response,
      };
    case LIST_INTERVIEW_EMPTY:
      return {
        ...state,
        isListInterviewsSuccess: true,
        isListInterviewsError: false,
        listInterviewsData: response,
      };
    case FETCH_INTERVIEW_DETAILS_REQUEST:
      return {
        ...state,
        isInterviewDetailsSuccess: false,
        isInterviewDetailsError: false,
      };
    case FETCH_INTERVIEW_DETAILS_SUCCESS:
      const interviewDetails = {};
      interviewDetails[response.interviewDetails[0].id] =
        response.interviewDetails[0];

      return {
        ...state,
        isInterviewDetailsSuccess: false,
        isInterviewDetailsError: false,
        interviewDetailsData: {
          ...state.interviewDetailsData,
          ...interviewDetails,
        },
      };
    case FETCH_INTERVIEW_DETAILS_ERROR:
      return {
        ...state,
        isInterviewDetailsSuccess: false,
        isInterviewDetailsError: false,
        interviewDetailsData: { ...state.interviewDetailsData },
      };
    case DELETE_INTERVIEW_REQUEST:
      return {
        ...state,
        isDeleteInterviewSuccess: false,
        isDeleteInterviewError: false,
        deleteInterviewData: {},
      };
    case DELETE_INTERVIEW_SUCCESS:
      return {
        ...state,
        isDeleteInterviewSuccess: true,
        isDeleteInterviewError: false,
        deleteInterviewData: response,
      };
    case DELETE_INTERVIEW_ERROR:
      return {
        ...state,
        isDeleteInterviewSuccess: false,
        isDeleteInterviewError: true,
        deleteInterviewData: response,
      };
    case CANCEL_INTERVIEW_REQUEST:
      return {
        ...state,
        isCancelInterviewSuccess: false,
        isCancelInterviewError: false,
        cancelInterviewData: {},
      };
    case CANCEL_INTERVIEW_SUCCESS:
      return {
        ...state,
        isCancelInterviewSuccess: true,
        isCancelInterviewError: false,
        cancelInterviewData: response,
      };
    case CANCEL_INTERVIEW_ERROR:
      return {
        ...state,
        isCancelInterviewSuccess: false,
        isCancelInterviewError: true,
        cancelInterviewData: response,
      };
    default:
      return state;
  }
}
