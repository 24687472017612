import { toast } from 'react-toastify';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import {
  TOKEN,
  CLIENTID,
  USERROLE,
  LAST_VISITED_URL,
  ROLE,
  CLIENT,
} from './constants';

const buffer = require('buffer/').Buffer;

class Helper {
  static tokenRefreshInterval;

  static saveObject(name, obj) {
    const serializedObj = JSON.stringify(obj);
    localStorage.setItem(name, serializedObj);
  }

  static saveItem(name, value) {
    localStorage.setItem(name, value);
  }

  static sessionSaveItem(name, value) {
    sessionStorage.setItem(name, value);
  }

  static getItem(name) {
    return localStorage.getItem(name);
  }

  static clearStorage() {
    localStorage.clear();
  }

  static sessionGetItem(name) {
    return sessionStorage.getItem(name);
  }

  static removeStorageItem(name) {
    localStorage.removeItem(name);
  }

  static lastVisitedRoute() {
    if (window.location.search) {
      const path = window.location.pathname + window.location.search;
      this.sessionSaveItem(LAST_VISITED_URL, path);
    } else {
      this.sessionSaveItem(LAST_VISITED_URL, window.location.pathname);
    }
  }

  static authHeader() {
    const token = this.getItem(TOKEN);
    if (token) {
      return {
        'Content-Type': 'application/json',
        Authorization: token,
      };
    }
    return {
      'Content-Type': 'application/json',
    };
  }

  static authHeaderWithoutAuthorization() {
    return {
      'Content-Type': 'application/json',
    };
  }

  static convertFileToBase64(fileObj) {
    return new Promise((resolve, reject) => {
      const fileName = fileObj.name;
      let file = null;

      const fileReader = new FileReader();

      fileReader.onload = (fileLoadedEvent) => {
        file = fileLoadedEvent.target.result;
        resolve({ fileData: file, fileName });
      };

      fileReader.onerror = (error) => {
        reject(error);
      };

      fileReader.readAsDataURL(fileObj);
    });
  }

  static parseJsonWebToken(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const buff = buffer.from(base64, 'base64');
    const payloadinit = buff.toString('ascii');
    return JSON.parse(payloadinit || '{}');
  }

  static addAttributesWithParams(data) {
    return {
      ...data,
      params: {
        ...data.params,
        clientId: Helper.getItem(CLIENTID),
      },
    };
  }

  static getCategoryData(category) {
    return category.split(':').slice(-1);
  }

  static saveUserAttributes(token) {
    const userData = Helper.parseJsonWebToken(token);
    const result = userData['cognito:groups'];

    result.forEach((category) => {
      if (category.includes(ROLE)) {
        Helper.saveItem(USERROLE, Helper.getCategoryData(category));
      }
      if (category.includes(CLIENT)) {
        Helper.saveItem(CLIENTID, Helper.getCategoryData(category));
      }
    });
  }

  static getUserRoles(token) {
    const roles = [];
    const userData = Helper.parseJsonWebToken(token);
    const groups = userData['cognito:groups'];

    groups.forEach((category) => {
      if (category.includes(ROLE)) {
        roles.push(Helper.getCategoryData(category));
      }
    });

    return roles;
  }

  static getRecruiterClientId(token) {
    const userData = Helper.parseJsonWebToken(token);

    const profileData = JSON.parse(userData.profile);

    const recruiterClientId = profileData.zClId;

    return recruiterClientId;
  }

  static indexOfArray = (val, array) => {
    const hash = {};
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < array.length; i++) {
      hash[array[i]] = i;
    }
    return (Object.prototype.hasOwnProperty.call(hash, val)) ? hash[val] : -1;
  };

  static notification = (message, error = false) => {
    const options = {
      position: 'top-center',
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: 'light',
    };

    if (error) {
      toast.error(message, options);
    } else {
      toast.success(message, options);
    }
  };

  static validateEmail = (email) => {
    if (email === '') {
      return 'Please enter email.';
    } if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      return '* Please enter valid email';
    } return null;
  };

  static validatePassword = (password) => {
    if (password === '') {
      return '* Please enter password';
    } return null;
  };

  static checkAndRedirectToHomePage = (history) => {
    const clientId = this.getItem(CLIENTID);
    if (clientId) history.push('/dashboard');
  };

  static intervals = (startString, endString) => {
    const start = moment(startString, 'hh:mm a');
    const end = moment(endString, 'hh:mm a');

    start.minutes(Math.ceil(start.minutes() / 15) * 15);

    const result = [];

    const current = moment(start);

    while (current <= end) {
      result.push(current.format('hh:mm a'));
      current.add(15, 'minutes');
    }

    return result;
  };

  static convertTo24hr = (time12hr) => moment(time12hr, 'hh:mm a').format('HH:mm');

  /**
   * Decrypt Token and fetch UserInfo
   */
  static getUserInfo = () => {
    let userInfo = {};
    const token = this.getItem(TOKEN);
    if (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      userInfo = JSON.parse(atob(base64)) ?? {};
    }
    return userInfo;
  };

  static parseURLParams(url) {
    const queryString = url.substring(1);
    const paramsArray = queryString.split('&');
    const resultObject = {};
    paramsArray.forEach((param) => {
      const [key, value] = param.split('=');
      resultObject[key] = value;
    });
    return resultObject;
  }

  static makeURLWithParams(path, paramsObj) {
    let url = `${path}?`;

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in paramsObj) {
      url += `${key}=${paramsObj[key]}`;

      // Check if it's not the last key, then append an ampersand
      if (Object.keys(paramsObj).indexOf(key) !== Object.keys(paramsObj).length - 1) {
        url += '&';
      }
    }

    return url;
  }

  static flattenObject(obj, parentKey = '') {
    const result = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        const newKey = parentKey ? `${key}` : key;

        if (typeof obj[key] === 'object' && obj[key] !== null) {
          Object.assign(result, this.flattenObject(obj[key], newKey));
        } else {
          result[newKey] = obj[key];
        }
      }
    }

    return result;
  }

  static checkEmptyValues(obj = {}, skipKeys = []) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key) && !skipKeys.includes(key)) {
        const value = obj[key];

        // Check if the value is not empty (null, undefined, empty string, empty array, or empty object)
        if (
          !(
            value === null
            || value === undefined
            || value === ''
            || (Array.isArray(value) && value.length === 0)
            || (typeof value === 'object' && Object.keys(value).length === 0)
          )
        ) {
          return false; // Return false if any non-empty value is found
        }
      }
    }

    return true; // Return true if all values are empty
  }

  static cryptoEncryption(input) {
    const encryptionKey = CryptoJS.enc.Hex.parse(process.env.REACT_APP_CRYPTO_ENCRPTION_KEY);
    const plaintextMessage = CryptoJS.enc.Utf8.parse(input);
    const initializationVector = CryptoJS.lib.WordArray.random(128 / 8);
    const encrypted = CryptoJS.AES.encrypt(plaintextMessage, encryptionKey, {
      iv: initializationVector,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    });
    return initializationVector.concat(encrypted.ciphertext).toString(CryptoJS.enc.Hex);
  }

  static getUrlParameter(paramName) {
    const name = paramName.replace(/[[\]]/g, '');
    const regex = new RegExp(`[?&]${name}=([^&#]*)`);
    const results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }
}
export default Helper;
