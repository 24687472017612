import Helper from '../services/helper';

import { AWS_V2_ENDPOINT } from '../services/constants';
import axiosInstance from '../services/axiosConfig';

class JobsAPI {
  static listPotentialApplicants = async (request) => axiosInstance.get(`${AWS_V2_ENDPOINT}/listPotentialCandidates`, {
    params: request.params,
    headers: Helper.authHeader(),
  });

  static disassociateApplicant = async (request) => axiosInstance.post(
    `${AWS_V2_ENDPOINT}/disassociateApplicant`,
    request.params,
    {
      headers: Helper.authHeader(),
    },
  );
}

export default JobsAPI;
