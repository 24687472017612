/* eslint-disable */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Select } from 'antd';
import classNames from 'classnames';
import {  toast } from 'react-toastify';

import styles from './styles.module.scss';
import { getJobFunnelRequest } from '../../redux/actions/action';
import { stageChangeOptions } from '../../data/jobStages';
import CandidateAPI from '../../apis/candidateApi';
import Helper from '../../services/helper';

import trash from '../../images/trash.png';
import loader from '../../images/loadingWithoutBG.gif';
import uploadArrow from '../../images/uploadArrow.svg';
import close from '../../images/closeIconBlack.svg';
import downArrow from '../../images/down_arrow.png';

const customSelectStyle = {
  width: 350,
  border: '#006d5b solid 1px',
  borderRadius: '15px',
  backgroundColor: 'transparent',
  color: '#006d5b',
  height: '44px',
};

const acceptableResumeFormat = ['pdf', 'doc', 'docx'];

function AddCandidateModal(props) {
  const {
    showCvUploadModal,
    closeCvUploadModal,
    content,
    addCandidate,
    isJobFunnelData,
    jobOpeningId,
    clientId,
    jobOpeningName,
    epochTime,
  } = props;
  const [listOfCvs, setListOfCvs] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [applicationStatus, setApplicationStatus] = useState({ name: '', code: '' });
  const [isLoading, setIsLoading] = useState({ status: false, timeStamp: '' });
  const [error, setError] = useState({
    status: true, resumeMessage: '', applicationMessage: '', code: '',
  });

  const GROUP_ID = ['AddCandidate', 'client', clientId, epochTime].join(':'); // for reffering multiple resume with single id

  const handleFileChangeAndUpload = async (file, idx) => {
    if (!file) return;

    const fileNameParts = file.name.split('.');
    const fileType = fileNameParts[fileNameParts.length - 1].toLowerCase();

    if (!acceptableResumeFormat.includes(fileType)) {
      setError({ status: true, resumeMessage: 'Unsupported file format. Please upload a file in PDF, DOC, or DOCX format.', code: 'resume' });
      return;
    } if (file.size / (1024 * 1024) > 4) {
      setError({ status: true, resumeMessage: 'File size exceeds the allowed limit. Please upload a file smaller than 4mb', code: 'resume' });
      return;
    }

    setError({ status: false });
    const cvObj = file && {
      fileName: file.name,
      progress: 0,
      timeStamp: file.lastModified,
    };
    const previousCvList = listOfCvs;
    setListOfCvs((prevList) => [...prevList, cvObj]);

    const fileBase64format = await Helper.convertFileToBase64(file);

    const body = {
      clientId,
      fileName: fileBase64format.fileName,
      jobOpeningId,
      jobOpeningName,
      fileContent: fileBase64format.fileData,
      isBase64: true,
      uploadGroupId: GROUP_ID,
    };

    // uploading file
    const uploadConfig = {
      onUploadProgress(progressEvent) {
        setUploadProgress({ ...uploadProgress, [cvObj.timeStamp]: Math.round((progressEvent.loaded * 100) / progressEvent.total) });
      },
    };

    try {
      setIsLoading({ ...isLoading, status: true, [file.lastModified]: true });
      const resp = await CandidateAPI.UploadCV(body, uploadConfig);
      cvObj.uploadJobId = resp.data.uploadJobId;
      setListOfCvs((prevList) => {
        const updatedList = [...prevList];
        updatedList[idx] = cvObj;
        return updatedList;
      });
    } catch (e) {
      setListOfCvs(previousCvList);
      toast.error('Something went wrong');
      setIsLoading({ ...isLoading, status: false, [file.lastModified]: false });
    }
    setIsLoading({ ...isLoading, status: false, [file.lastModified]: false });
  };

  // drag & drop
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const { files } = e.dataTransfer;

    for (let i = 0; i < files.length; i++) {
      handleFileChangeAndUpload(files[i], i);
    }
  };
  // drag & drop ends

  const handleCvRemoval = async (listOfCvs, uploadJobId = null, timeStamp = null) => {
    if (listOfCvs.length < 1) {
      return;
    }

    const body = {
      clientId,
      jobOpeningId,
      uploadGroupId: GROUP_ID,
      jobOpeningName,
    };

    if (uploadJobId) {
      body.uploadJobIds = [uploadJobId];
    } else {
      body.uploadJobIds = listOfCvs.map((obj) => obj.uploadJobId);
    }

    try {
      await CandidateAPI.deleteUploadedCV(body);

      const newList = listOfCvs.filter((item) => item.uploadJobId !== uploadJobId);
      setListOfCvs(newList);
    } catch (e) {
      toast.error('Something went wrong');
    }
  };

  const handleUploadSubmit = async (applicationStatus, listOfCvs) => {
    if (listOfCvs.length < 1) {
      setError({ ...error, resumeMessage: 'Resume is required', code: 'resume' });
      return;
    }
    if (!applicationStatus.name) {
      setError({ status: true, applicationMessage: 'Application Status is required', code: 'application' });
      return;
    }
    setError({ status: false, resumeMessage: '', applicationMessage: '' });

    const uploadedCvs = listOfCvs.map((obj) => obj.uploadJobId);

    const body = {
      uploadJobIds: uploadedCvs,
      applicationStatusCode: applicationStatus.code,
      uploadGroupId: GROUP_ID,
      clientId,
      jobOpeningId,
      applicationStatusName: applicationStatus.name,
      jobOpeningName,
    };
    try {
      setIsLoading({ ...isLoading, status: true });
      await CandidateAPI.submitUploadedCV(body);

      // reset previous states
      setListOfCvs([]);
      setUploadProgress({});
      setApplicationStatus({ name: '', code: '' });
      setIsLoading({ ...isLoading, status: false });

      addCandidate(); // initiate success popup
    } catch (e) {
      toast.error('Something went wrong');
      setIsLoading({ ...isLoading, status: true });
    }
  };

  const handleClose = async (listOfCvs, GROUP_ID) => {
    await handleCvRemoval(listOfCvs, GROUP_ID);
    setListOfCvs([]);
    setUploadProgress({});
    setError({
      status: true, resumeMessage: '', applicationMessage: '', code: '',
    });
    closeCvUploadModal();
  };

  const handleSelectChange = (value, obj) => {
    setApplicationStatus({ name: value, code: obj.id });
  };

  return (
    <Modal
      show={showCvUploadModal}
      onHide={closeCvUploadModal}
      keyboard={false}
    >

      <Modal.Body
        style={{ padding: '0px', margin: '0px', border: 'none' }}
        className={styles.modelBody}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontFamily: 'Poppins',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: '700',
            marginBottom: '30px',
          }}
        >
          <div>
            {`${content.ADD_CANDIDATE_TO_JOB} `}
            {isJobFunnelData.title ? isJobFunnelData.title : '...'}
          </div>
          <img
            style={{
              cursor: 'pointer',
            }}
            height="22px"
            width="22px"
            src={close}
            onClick={() => handleClose(listOfCvs)}
            alt="close"
          />
        </div>

        <div
          style={{
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24px',
            letterSpacing: '0em',
            textAlign: 'left',
            marginBottom: '10px',
          }}
        >
          {content.SELECT_APPLIACTION_STAGE}
          <span className={styles.requiredField}>*</span>
        </div>
        {/* select option for application stage */}

        <div style={{ marginBottom: '30px' }}>
          <Select
            suffixIcon={<img src={downArrow} height="24px" width="24px" />}
            bordered={false}
            colortext="#006d5b"
            style={customSelectStyle}
            defaultValue={applicationStatus.name}
            onChange={handleSelectChange}
            options={stageChangeOptions || []}
          />
          {error.code === 'application' && <div style={{ marginTop: '5px', textAlign: 'center' }} className="error error-alert-msg">{error.applicationMessage}</div>}
        </div>

        <div style={{ marginBottom: '30px' }}>
          {listOfCvs.map((data, idx) => (
            <div key={idx} className={classNames(styles.progressbar, 'body-1')}>
              <div style={{
                height: '100%',
                width: `${uploadProgress[`${data.timeStamp}`]}%`,
                backgroundColor: '#F3F8FF',
                transition: 'width 0.5s',
                borderRadius: '15px',
              }}
              />
              <div className={styles.progressPercent}>
                <span>{data.fileName}</span>
                <span>{uploadProgress[`${data.timeStamp}`] < 100 ? `${uploadProgress[`${data.timeStamp}`]}%` : ''}</span>
                <img
                  onClick={() => handleCvRemoval(listOfCvs, data.uploadJobId, data.timeStamp)}
                  src={isLoading.status & isLoading[data.timeStamp] ? loader : trash}
                  style={{
                    width: '18px', height: '18px', cursor: 'pointer', backgroundColor: 'transparent',
                  }}
                />
              </div>
            </div>
          ))}
        </div>

        {error.code === 'resume' && <div style={{ marginBottom: '5px', textAlign: 'center' }} className="error error-alert-msg">{error.resumeMessage}</div>}

        <div
          style={{
            alignItems: 'center',
            textAlign: 'center',
            border: '1px dashed #006D5B',
            // border: `1px dashed ${isDragOver ? '#ff4500' : '#006D5B'}`,
            padding: '20px',
            borderRadius: '20px',
            marginBottom: '10px',
            cursor: 'pointer',
            width: '598px',
          }}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={() => document.querySelector('input[type="file"]').click()}
        >
          <label htmlFor="file-input">
            <img
              src={uploadArrow}
              alt="successTickSvg"
              style={{ width: '35px', height: '35px' }}
            />
          </label>
          <p>{content.DRAG_AND_DROP}</p>
          <input
            type="file"
            id="file-input"
            onChange={(e) => handleFileChangeAndUpload(e.target.files?.[0] || null)}
            style={{ display: 'none' }}
            accept=".pdf,.doc,.docx"
          />
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {content.DROP_FILES_HERE}
          </div>
          <p>- OR -</p>
          <button
            style={{
              minWidth: '30%',
              width: 'auto',
              color: '#fff',
              backgroundColor: '#006D5B',
              borderRadius: '50px',
              padding: '10px 75px 10px 75px',
              border: '1px solid #006D5B',
            }}
          >
            {content.BROWSE}
          </button>
        </div>

        <div
          className={styles.CvUploadNote}
        >
          {content.CV_UPLOAD_NOTE}
        </div>

        <div
          style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}
        >
          <button
            className="button-secondary button-text"
            type="button"
            onClick={() => handleClose(listOfCvs)}
          >
            Close
          </button>
          <button
            className="button-primary button-text"
            onClick={() => handleUploadSubmit(applicationStatus, listOfCvs)}
            type="submit"
            disabled={isLoading.status}
          >
            {isLoading.status ? content.UPLOADING_CV : content.UPLOAD_CV}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  content: state.languageReducer.portalContent,
  isJobFunnelSuccess: state.jobsReducer.isJobFunnelSuccess,
  isJobFunnelError: state.jobsReducer.isJobFunnelError,
  isJobFunnelData: state.jobsReducer.isJobFunnelData,
});

const mapDispatchToProps = (dispatch) => ({
  getJobFunnelRequest: (data) => dispatch(getJobFunnelRequest(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AddCandidateModal));
