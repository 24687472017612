import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

function Index({ customClass = '' }) {
  return (
    <hr className={classNames(styles.horizontalLine, customClass)} />
  );
}

export default Index;
