import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { CardBody, Row } from 'reactstrap';
import styles from './styles.module.scss';

const EmptyCardBodyJobStats = ({ jobIndex, jobStatsData }) => {
  const discoverCandidateRedirectUrl = `/add-candidate?jobCode=${jobStatsData?.jobs[jobIndex]?.jobCode}&currJobId=${jobStatsData?.jobs[jobIndex]?.jobId}&jobRoleCategory=${jobStatsData?.jobs[jobIndex]?.jobRoleCategory}&jobTitle=${jobStatsData?.jobs[jobIndex]?.title}&specialisation=${JSON.stringify(jobStatsData?.jobs[jobIndex]?.jobRoleSpecialisation)}`;

  return (
    <CardBody className={classNames(styles.jobBody, 'centerAlignContent')}>
      <Row className={styles.jobBodyRow}>
        <Link className="linkAppearanceNone" to={discoverCandidateRedirectUrl}>
          There are no applicants for this job. Click here to discover candidates.
        </Link>
      </Row>
    </CardBody>
  );
};

export default EmptyCardBodyJobStats;
