import React from 'react';

import emptyStateImg from '../../../../images/emptyStateImg.webp';
import styles from './styles.module.scss';

function index(props) {
  const { portalContent } = props;

  return (
    <div className={styles.emptyStateContainer}>
      <img
        className={styles.emptyStateImg}
        width="133px"
        height="120px"
        src={emptyStateImg}
        alt="emptyStateImg"
      />
      <div className="subtitle-1">
        {portalContent?.ALL_CANDIDATES_NO_RECORD_MESSAGE}
      </div>
    </div>
  );
}

export default index;
