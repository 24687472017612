export const PORTAL_LANGUAGE = 'PORTAL_LANGUAGE';
// users - auth
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const USER_LOGIN_WITH_PHONE_REQUEST = 'USER_LOGIN_WITH_PHONE_REQUEST';

export const USER_FORGOT_PASSWORD_REQUEST = 'USER_FORGOT_PASSWORD_REQUEST';
export const USER_FORGOT_PASSWORD_SUCCESS = 'USER_FORGOT_PASSWORD_SUCCESS';
export const USER_FORGOT_PASSWORD_ERROR = 'USER_FORGOT_PASSWORD_ERROR';

export const USER_SET_PASSWORD_REQUEST = 'USER_SET_PASSWORD_REQUEST';
export const USER_SET_PASSWORD_SUCCESS = 'USER_SET_PASSWORD_SUCCESS';
export const USER_SET_PASSWORD_ERROR = 'USER_SET_PASSWORD_ERROR';

export const USER_UPDATE_PASSWORD_REQUEST = 'USER_UPDATE_PASSWORD_REQUEST';
export const USER_UPDATE_PASSWORD_SUCCESS = 'USER_UPDATE_PASSWORD_SUCCESS';
export const USER_UPDATE_PASSWORD_ERROR = 'USER_UPDATE_PASSWORD_ERROR';

export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_ERROR = 'USER_LOGOUT_ERROR';
export const USER_LOGOUT_COMPLETE = 'USER_LOGOUT_COMPLETE';

// dashboard
export const GET_USER_DETAILS_REQUEST = 'GET_USER_DETAILS_REQUEST';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_ERROR = 'GET_USER_DETAILS_ERROR';

export const GET_UPCOMING_INTERVIEWS_REQUEST = 'GET_UPCOMING_INTERVIEWS_REQUEST';
export const GET_UPCOMING_INTERVIEWS_SUCCESS = 'GET_UPCOMING_INTERVIEWS_SUCCESS';
export const GET_UPCOMING_INTERVIEWS_ERROR = 'GET_UPCOMING_INTERVIEWS_ERROR';

export const GET_RECENT_ACTIVITIES_REQUEST = 'GET_RECENT_ACTIVITIES_REQUEST';
export const GET_RECENT_ACTIVITIES_SUCCESS = 'GET_RECENT_ACTIVITIES_SUCCESS';
export const GET_RECENT_ACTIVITIES_ERROR = 'GET_RECENT_ACTIVITIES_ERROR';

export const GET_JOB_STATS_REQUEST = 'GET_JOB_STATS_REQUEST';
export const GET_JOB_STATS_SUCCESS = 'GET_JOB_STATS_SUCCESS';
export const GET_JOB_STATS_ERROR = 'GET_JOB_STATS_ERROR';

// dashboard
export const GET_AGENCY_CLIENTS_REQUEST = 'GET_AGENCY_CLIENTS_REQUEST';
export const GET_AGENCY_CLIENTS_SUCCESS = 'GET_AGENCY_CLIENTS_SUCCESS';
export const GET_AGENCY_CLIENTS_ERROR = 'GET_AGENCY_CLIENTS_ERROR';

export const GET_CANDIDATE_STATS_REQUEST = 'GET_CANDIDATE_STATS_REQUEST';
export const GET_CANDIDATE_STATS_SUCCESS = 'GET_CANDIDATE_STATS_SUCCESS';
export const GET_CANDIDATE_STATS_ERROR = 'GET_CANDIDATE_STATS_ERROR';

// candidates
// Profile
export const GET_CANDIDATE_PROFILE_REQUEST = 'GET_CANDIDATE_PROFILE_REQUEST';
export const GET_CANDIDATE_PROFILE_SUCCESS = 'GET_CANDIDATE_PROFILE_SUCCESS';
export const GET_CANDIDATE_PROFILE_ERROR = 'GET_CANDIDATE_PROFILE_ERROR';

// schedule Interview

export const SCHEDULE_CANDIDATE_INTERVIEW_REQUEST = 'SCHEDULE_CANDIDATE_INTERVIEW_REQUEST';
export const SCHEDULE_CANDIDATE_INTERVIEW_SUCCESS = 'SCHEDULE_CANDIDATE_INTERVIEW_SUCCESS';
export const SCHEDULE_CANDIDATE_INTERVIEW_ERROR = 'SCHEDULE_CANDIDATE_INTERVIEW_ERROR';

// jobs
// jobs by openings
export const GET_JOB_FUNNEL_REQUEST = 'GET_JOB_FUNNEL_REQUEST';
export const GET_JOB_FUNNEL_REQUEST_SUCCESS = 'GET_JOB_FUNNEL_REQUEST_SUCCESS';
export const GET_JOB_FUNNEL_REQUEST_ERROR = 'GET_JOB_FUNNEL_REQUEST_ERROR';

export const CHANGE_APPLICATION_STAGE_REQUEST = 'CHANGE_APPLICATION_STAGE_REQUEST';
export const CHANGE_APPLICATION_STAGE_REQUEST_SUCCESS = 'CHANGE_APPLICATION_STAGE_REQUEST_SUCCESS';
export const CHANGE_APPLICATION_STAGE_REQUEST_ERROR = 'CHANGE_APPLICATION_STAGE_REQUEST_ERROR';

// JobDetails
export const GET_JOB_DETAILS_REQUEST = 'GET_JOB_DETAILS_REQUEST';
export const GET_JOB_DETAILS_SUCCESS = 'GET_JOB_DETAILS_SUCCESS';
export const GET_JOB_DETAILS_ERROR = 'GET_JOB_DETAILS_ERROR';

export const CHANGE_JOB_STATUS_REQUEST = 'CHANGE_JOB_STATUS_REQUEST';
export const CHANGE_JOB_STATUS_REQUEST_SUCCESS = 'CHANGE_JOB_STATUS_REQUEST_SUCCESS';
export const CHANGE_JOB_STATUS_REQUEST_ERROR = 'CHANGE_JOB_STATUS_REQUEST_ERROR';

// allJobs
export const GET_ALL_JOBS_REQUEST = 'GET_ALL_JOBS_REQUEST';
export const GET_ALL_JOBS_REQUEST_SUCCESS = 'GET_ALL_JOBS_REQUEST_SUCCESS';
export const GET_ALL_JOBS_REQUEST_ERROR = 'GET_ALL_JOBS_REQUEST_ERROR';

// add-candidates
export const LIST_POTENTIAL_APPLICANTS_REQUEST = 'LIST_POTENTIAL_APPLICANTS_REQUEST';
export const LIST_POTENTIAL_APPLICANTS_REQUEST_SUCCESS = 'LIST_POTENTIAL_APPLICANTS_REQUEST_SUCCESS';
export const LIST_POTENTIAL_APPLICANTS_REQUEST_ERROR = 'LIST_POTENTIAL_APPLICANTS_REQUEST_ERROR';

export const DISASSOCIATE_APPLICANT_REQUEST = 'DISASSOCIATE_APPLICANT_REQUEST';
export const DISASSOCIATE_APPLICANT_SUCCESS = 'DISASSOCIATE_APPLICANT_SUCCESS';
export const DISASSOCIATE_APPLICANT_ERROR = 'DISASSOCIATE_APPLICANT_ERROR';

export const LIST_INTERVIEW_REQUEST = 'LIST_INTERVIEW_REQUEST';
export const LIST_INTERVIEW_SUCCESS = 'LIST_INTERVIEW_SUCCESS';
export const LIST_INTERVIEW_ERROR = 'LIST_INTERVIEW_ERROR';
export const LIST_INTERVIEW_EMPTY = 'LIST_INTERVIEW_EMPTY';

export const FETCH_INTERVIEW_DETAILS_REQUEST = 'FETCH_INTERVIEW_DETAILS_REQUEST';
export const FETCH_INTERVIEW_DETAILS_SUCCESS = 'FETCH_INTERVIEW_DETAILS_SUCCESS';
export const FETCH_INTERVIEW_DETAILS_ERROR = 'FETCH_INTERVIEW_DETAILS_ERROR';
export const DELETE_INTERVIEW_REQUEST = 'DELETE_INTERVIEW_REQUEST';
export const DELETE_INTERVIEW_SUCCESS = 'DELETE_INTERVIEW_SUCCESS';
export const DELETE_INTERVIEW_ERROR = 'DELETE_INTERVIEW_ERROR';

export const CANCEL_INTERVIEW_REQUEST = 'CANCEL_INTERVIEW_REQUEST';
export const CANCEL_INTERVIEW_SUCCESS = 'CANCEL_INTERVIEW_SUCCESS';
export const CANCEL_INTERVIEW_ERROR = 'CANCEL_INTERVIEW_ERROR';
export const REQUEST_SERVICE_PACKAGE_REQUEST = 'REQUEST_SERVICE_PACKAGE_REQUEST';
export const REQUEST_SERVICE_PACKAGE_SUCCESS = 'REQUEST_SERVICE_PACKAGE_SUCCESS';
export const REQUEST_SERVICE_PACKAGE_ERROR = 'REQUEST_SERVICE_PACKAGE_ERROR';

export const REQUEST_REPORT_REQUEST = 'REQUEST_REPORT_REQUEST';
export const REQUEST_REPORT_SUCCESS = 'REQUEST_REPORT_SUCCESS';
export const REQUEST_REPORT_ERROR = 'REQUEST_REPORT_ERROR';
export const REQUEST_REPORT_RESET = 'REQUEST_REPORT_RESET';

export const LIST_CONTACTS_REQUEST = 'LIST_CONTACTS_REQUEST';
export const LIST_CONTACTS_SUCCESS = 'LIST_CONTACTS_SUCCESS';
export const LIST_CONTACTS_ERROR = 'LIST_CONTACTS_ERROR';

export const CREATE_CONTACT_REQUEST = 'CREATE_CONTACT_REQUEST';
export const CREATE_CONTACT_SUCCESS = 'CREATE_CONTACT_SUCCESS';
export const CREATE_CONTACT_ERROR = 'CREATE_CONTACT_ERROR';

export const UPDATE_JOB_REQUEST = 'UPDATE_JOB_REQUEST';
export const UPDATE_JOB_SUCCESS = 'UPDATE_JOB_SUCCESS';
export const UPDATE_JOB_ERROR = 'UPDATE_JOB_ERROR';

export const GET_SPECIALIZATION_REQUEST = 'GET_SPECIALIZATION_REQUEST';
export const GET_SPECIALIZATION_SUCCESS = 'GET_SPECIALIZATION_SUCCESS';
export const GET_SPECIALIZATION_ERROR = 'GET_SPECIALIZATION_ERROR';
// all-candidates
export const LIST_ALL_CANDIDATES_REQUEST = 'LIST_ALL_CANDIDATES_REQUEST';
export const LIST_ALL_CANDIDATES_SUCCESS = 'LIST_ALL_CANDIDATES_SUCCESS';
export const LIST_ALL_CANDIDATES_ERROR = 'LIST_ALL_CANDIDATES_ERROR';

export const LIST_SKILLS_REQUEST = 'LIST_SKILLS_REQUEST';
export const LIST_SKILLS_SUCCESS = 'LIST_SKILLS_SUCCESS';
export const LIST_SKILLS_ERROR = 'LIST_SKILLS_ERROR';
