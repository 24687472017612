import React from 'react';
import { Select } from 'antd';

import './selectStyle.css';
import styles from './styles.module.scss';
import inputStyles from '../styles.module.scss';

const colorText = '#006d5b';
function SelectWithTitle({
  title,
  layoutStyle,
  selectValue,
  onChangeInput,
  selectPlaceholder,
  selectOptions,
  filterOption,
  showSearch,
  selectStyle,
  disabled,
  error,
}) {
  return (
    <div className={styles.selectLayout} style={layoutStyle}>
      <div className="body-2-no-color">{title}</div>
      <Select
        disabled={disabled}
        className={styles.selectBoxLayout}
        showSearch={showSearch}
        bordered={false}
        colortext={colorText}
        style={selectStyle}
        placeholder={selectPlaceholder}
        value={selectValue}
        onSelect={(event) => onChangeInput(event)}
        options={selectOptions}
        filterOption={filterOption}
      />
      {error && <span className={inputStyles.danger}>{error}</span>}
    </div>
  );
}

export default SelectWithTitle;
