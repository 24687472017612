/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment/moment';
import { Col, Row } from 'reactstrap';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Collapse, Select, Space } from 'antd';
import { withRouter } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { Form } from 'react-bootstrap';

import './interviewAccordionStyles.css';
import styles from './styles.module.scss';
import threeDot from '../../../../images/three-dots-vertical.svg';
import sheduleDate from '../../../../images/sheduleDate.svg';
import feedback from '../../../../images/feedback.svg';
import attends from '../../../../images/attends.svg';
import editOn from '../../../../images/editOn.svg';
import editGreen from '../../../../images/editGreen.svg';
import trashGreen from '../../../../images/trashGreen.svg';
import loading from '../../../../images/loading.gif';
import maskGroup from '../../../../images/profilepic.jpg';
import fallBackUserImg from '../../../../images/user.png';
import noInterviews from '../../../../images/noInterviews.png';
import videoCallIcon from '../../../../images/videoCallIcon.svg';
import Helper from '../../../../services/helper';
import Overlay from '../../../../components/overlay';
import { feedbackOptions } from '../../../../data/interviewFeedback';
import {
  cancelInterviewRequest,
  deleteInterviewRequest,
  fetchInterviewDetailsRequest,
  listInterviewRequest,
} from '../../../../redux/actions/action';
import {INTERVIEW_STATUS_CANCELLED, JOB_STAGE_200} from "../../../../services/constants";

function Interviews(props) {
  const {
    listInterviewsData,
    interviewDetailsData,
    candidateProfileId,
    isListInterviewsError,
    isListInterviewsSuccess,
    profileType,
  } = props;

  const [activeKey, setActiveKey] = useState(['UPCOMING_0']);
  const [pastInterviewActiveKey, setPastInterviewActiveKey] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [interviewId, setInterviewId] = useState('');

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [cancelFeedback, setCancelFeedback] = useState('');

  const [feedbackOption, setFeedbackOption] = useState(
    feedbackOptions[0].value,
  );

  const [disableCancelButton, setDisableCancelButton] = useState(false);
  const [disableDeleteButton, setDisableDeleteButton] = useState(false);

  const prevProps = useRef(props);

  const handleCollapseChange = (key) => {
    setActiveKey(key);
  };

  const customSelectStyle = {
    width: 644,
    border: '#006d5b solid 1px',
    borderRadius: '30px',
    backgroundColor: 'transparent',
    color: '#006d5b',
    height: '44px',
    display: 'flex',
    padding: '10px 30px',
    justifyContent: 'space-between',
    alignItems: 'center',
  };

  const handleCollapseChangeForPastInterviews = (key) => {
    setPastInterviewActiveKey(key);
  };

  const CustomMenu = React.forwardRef(
    ({
      children, style, className, 'aria-labelledby': labeledBy,
    }, ref) => {
      const [value] = useState('');

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className={styles.listUnstyled}>
            {React.Children.toArray(children).filter(
              (child) => !value || child.props.children.toLowerCase().startsWith(value),
            )}
          </ul>
        </div>
      );
    },
  );

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <img src={threeDot} alt="Menu" className={styles.threeDotIcon} />
    </a>
  ));

  const interviewDetailsLoader = () => (
    <div>
      <img
        src={loading}
        className={styles.interviewDetailsLoader}
        alt="loader"
      />
    </div>
  );

  const cancelInterview = async (event) => {
    const { cancelInterviewRequest } = props;

    setDisableCancelButton(true);

    const interviewCancelRequestData = {
      interviewId,
      reason: feedbackOption,
      feedback: cancelFeedback,
      clientId: Helper.getItem('clientId'),
    };

    cancelInterviewRequest(interviewCancelRequestData);
  };

  const fetchListInterviews = () => {
    const { listInterviewRequest } = props;

    const clientId = Helper.getItem('clientId');

    const candidateProfileObj = {
      candidateId: candidateProfileId,
      clientId,
    };

    listInterviewRequest(candidateProfileObj);
  };

  useEffect(() => {
    const { isDeleteInterviewSuccess: prevIsDeleteInterviewSuccess } = prevProps.current;
    const { isDeleteInterviewSuccess } = props;

    if (!prevIsDeleteInterviewSuccess && isDeleteInterviewSuccess) {
      fetchListInterviews();

      setShowDeleteModal(false);
      setDisableDeleteButton(false);
    }

    prevProps.current = props;
  }, [props.isDeleteInterviewSuccess]);

  useEffect(() => {
    const { isCancelInterviewSuccess: prevIsCancelInterviewSuccess } = prevProps.current;
    const { isCancelInterviewSuccess } = props;

    if (!prevIsCancelInterviewSuccess && isCancelInterviewSuccess) {
      fetchListInterviews();

      setShowCancelModal(false);
      setDisableCancelButton(false);
    }

    prevProps.current = props;
  }, [props.isCancelInterviewSuccess]);

  const deleteInterview = async () => {
    const { deleteInterviewRequest } = props;

    setDisableDeleteButton(true);

    const interviewDeleteRequestData = {
      interviewId,
      // deleteComments, //TODO will add once backend support this
      clientId: Helper.getItem('clientId'),
    };

    deleteInterviewRequest(interviewDeleteRequestData);
  };

  const renderSubmittedOn = (interview) => (
    <Col>
      <div className={styles.detailsBox}>
        <span className={styles.detailSubBox}>
          <img src={sheduleDate} alt="Menu" className={styles.cardICons} />
          <span className={styles.smallHeading}>Submitted On</span>
        </span>
        <span className={styles.smallText}>
          {moment(interview.createdTime).calendar()}
        </span>
      </div>
    </Col>
  );

  const renderPastInterviewDetails = (pastInterviews) => pastInterviews.map((interview, index) => (
    <div key={interview.id}>
      <Space direction="vertical" style={{ minWidth: '100%' }}>
        <Collapse
          collapsible="header"
          activeKey={pastInterviewActiveKey}
          onChange={handleCollapseChangeForPastInterviews}
          onClick={() => {
            ref.current?.scrollIntoView({ behavior: 'smooth' });
          }}
          className={getInterviewTitleClass(interview)}
          items={[
            {
              key: `PAST_${index}`,
              label: interview.interviewName,
              children: !interviewDetailsData[interview.id] ? (
                interviewDetailsLoader()
              ) : (
                <div className={styles.bodyOuterBox}>
                  <div className={styles.bodyInnerBox}>
                    <div className={styles.cardSubTitle}>
                      <span className={styles.boxTitile}>
                        Overall Rating
                      </span>
                      <span className={styles.jobTextColor}>
                        <Dropdown drop="left">
                          <Dropdown.Toggle
                            as={CustomToggle}
                            id="dropdown-custom-components"
                          />
                          <Dropdown.Menu as={CustomMenu}>
                            {interview.interviewStatus !== INTERVIEW_STATUS_CANCELLED ? (
                              <Dropdown.Item
                                eventKey="1"
                                onClick={() => openCancelModal(interview.id)}
                              >
                                <img
                                  src={editGreen}
                                  alt="Menu"
                                  className={styles.cardICons}
                                  style={{ marginRight: '1vw' }}
                                />
                                <span className={styles.smallText}>
                                  Cancel Interview
                                </span>
                              </Dropdown.Item>
                            ) : null}
                            <Dropdown.Item
                              eventKey="2"
                              onClick={() => openDeleteModal(interview.id)}
                            >
                              <img
                                src={trashGreen}
                                alt="Menu"
                                className={styles.cardICons}
                                style={{ marginRight: '1vw' }}
                              />
                              <span className={styles.smallText}>
                                Delete Interview
                              </span>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </span>
                    </div>
                  </div>
                  <div className={styles.cardContentBox}>
                    <div>
                      <Row className={styles.cardSubContentBox}>
                        {interview.interviewStatus ? (
                          <Col>
                            <button
                              color="warning"
                              type="button"
                              className={
                                              interview.interviewStatus !== INTERVIEW_STATUS_CANCELLED
                                                ? styles.shorListedButton
                                                : styles.cancelButton
                                            }
                            >
                              {getInterviewStatus(
                                interviewDetailsData[interview.id],
                              )}
                            </button>
                          </Col>
                        ) : null}
                        {interview.interviewStatus === INTERVIEW_STATUS_CANCELLED ? (
                          <Col className={styles.detailsBox}>
                            <span className={styles.detailSubBox}>
                              <img
                                src={feedback}
                                alt="Menu"
                                className={styles.cardICons}
                              />
                              <span className={styles.smallHeading}>
                                Reason for cancellation
                              </span>
                            </span>
                            <span className={styles.reasonForCancellation}>
                              <span className="body-1">
                                {interview.cancellationReason}
                              </span>
                            </span>
                          </Col>
                        ) : null}
                        {interview.interviewStatus !== INTERVIEW_STATUS_CANCELLED ? renderSubmittedOn(interview) : null}
                        {interview.feedback ? (
                          <Col>
                            <div className={styles.detailsBox}>
                              <span className={styles.detailSubBox}>
                                <img
                                  src={feedback}
                                  alt="Menu"
                                  className={styles.cardICons}
                                />
                                <span className={styles.smallHeading}>
                                  Interview Feedback
                                </span>
                              </span>
                              <span className={styles.feedBackBoxText}>
                                {interview.feedback}
                              </span>
                            </div>
                          </Col>
                        ) : null}
                      </Row>
                    </div>
                    <div>
                      <span className={styles.detailSubBox}>
                        <img
                          src={attends}
                          alt="Menu"
                          className={styles.cardICons}
                        />
                        <span className={styles.smallHeading}>
                          Attendees
                        </span>
                      </span>
                      <div>
                        <Row className={styles.attendeesCardSubContentBox}>
                          <Col>
                            <div className={styles.detailsAttendeesBox}>
                              <span>
                                <img
                                  className={styles.cardIConsLarg}
                                  src={maskGroup}
                                  onError={(e) => {
                                    e.target.src = fallBackUserImg;
                                  }}
                                  alt="Mask Group"
                                />
                              </span>
                              <div
                                className={styles.detailsAttendeesTitleBox}
                              >
                                <span className={styles.smallHeading}>
                                  {interview.candidateName}
                                </span>
                                <span className={styles.smallText}>
                                  (Candidate)
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col className={styles.detailsAttendeesBox}>
                            <span>
                              <img
                                className={styles.cardIConsLarg}
                                src={maskGroup}
                                onError={(e) => {
                                  e.target.src = fallBackUserImg;
                                }}
                                alt="Mask Group"
                              />
                            </span>
                            <div className={styles.detailsAttendeesTitleBox}>
                              <span className={styles.smallHeading}>
                                {getInterviewerName(interview.interviewer)}
                              </span>
                              <span className={styles.smallText}>
                                (Interviewer)
                              </span>
                            </div>
                          </Col>
                          {interview.interviewStatus === INTERVIEW_STATUS_CANCELLED ? renderSubmittedOn(interview) : null}
                          <Col className={styles.detailsBox}>
                            <span className={styles.detailSubBox}>
                              <img
                                src={editOn}
                                alt="Menu"
                                className={styles.cardICons}
                              />
                              <span className={styles.smallHeading}>
                                Edited On
                              </span>
                            </span>
                            <span className={styles.smallText}>
                              {moment(interview.modifiedTime).calendar()}
                            </span>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div>
                      <div>
                        <Row className={styles.cardSubContentBox}>
                          <Col className={styles.detailsBox}>
                            <span className={styles.detailSubBox}>
                              <img
                                src={sheduleDate}
                                alt="Menu"
                                className={styles.cardICons}
                              />
                              <span className={styles.smallHeading}>
                                Interview Date
                              </span>
                            </span>
                            <span className={styles.smallText}>
                              {moment(interview.startDateTime).calendar()}
                            </span>
                          </Col>
                          <Col className={styles.detailsBox}>
                            <span className={styles.detailSubBox}>
                              <img
                                src={feedback}
                                alt="Menu"
                                className={styles.cardICons}
                              />
                              <span className={styles.smallHeading}>
                                Reason for Edit
                              </span>
                            </span>
                            <span className={styles.reasonForEditBoxText}>
                              Lorem ipsum dolor sit amet consectetur. Hendrerit dolor volutpat leo leo lorem. Morbi felis nunc id at et. .. Lorem ipsum dolor sit amet
                            </span>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              ),
            },
          ]}
        />
      </Space>
      <br />
      {' '}
      <br />
    </div>
  ));

  const getInterviewStatus = (interview) => {
    const { interviewSlots, interviewStatus } = interview;

    if (interviewStatus === INTERVIEW_STATUS_CANCELLED) {
      return INTERVIEW_STATUS_CANCELLED;
    }

    const slots = interviewSlots?.filter((slot) => slot.status === 'Confirmed');

    return slots?.length > 0
      ? 'Timing Confirmed'
      : 'Timing yet to be confirmed by candidate';
  };

  const getInterviewerName = (interviewer) => (typeof interviewer === 'string' || interviewer instanceof String
    ? interviewer
    : interviewer.name);

  const displaySlotSection = (interview) => {
    const interviewStatus = getInterviewStatus(interview);

    return interviewStatus === 'Timing Confirmed'
      ? 'Interview Date'
      : 'Interview Timing Slots';
  };

  const getInterviewTimings = (interview) => {
    const sectionType = displaySlotSection(interview);
    const { interviewSlots } = interview;

    if (sectionType === 'Interview Date') {
      return (
        <span className={styles.smallText}>
          {moment(interview.startDateTime).format('ddd, MMM Do, YYYY')}
            &nbsp;
          {moment(interview.startDateTime).format('LT')}
          {' '}
          to
          {' '}
          {moment(interview.endDateTime).format('LT')}
        </span>
      );
    }
    return interviewSlots?.map((slot) => (
      <span className={styles.smallText}>
        {moment(slot.startTime1).format('ddd, MMM Do, YYYY')}
            &nbsp;
        {moment(slot.startTime1).format('LT')}
        {' '}
        to&nbsp;
        {moment(slot.endTime).format('LT')}
      </span>
    ));
  };

  const getInterviewTitleClass = ({ interviewStatus }) => (interviewStatus !== INTERVIEW_STATUS_CANCELLED
    ? 'greenHeaderAccordian'
    : 'redHeaderAccordian');

  const statusButtonClass = (interview) => {
    if (interview.interviewStatus === INTERVIEW_STATUS_CANCELLED) {
      return styles.cancelButton;
    }

    return getInterviewStatus(interview)
    === 'Timing yet to be confirmed by candidate'
      ? styles.timingYetToConfirmButton
      : styles.shorListedButton;
  };
  const getVideoInterviewDetails = ({ inviteLink, platform, otherDetails }) => (inviteLink ? (
    <Col className={styles.scheduledBox}>
      <span className={styles.detailSubBox}>
        <img src={videoCallIcon} alt="Menu" className={styles.cardICons} />
        <span className={styles.smallHeading}>Interview  Link</span>
      </span>
      <button
        color="warning"
        type="button"
        className={styles.zoomVideoButton}
      >
        <a target="_blank" href={inviteLink} rel="noreferrer">
          {platform}
        </a>
      </button>
      <div className={styles.zoomVideoLinkBox}>
        <span>
          <a target="_blank" href={inviteLink} rel="noreferrer">
            {inviteLink}
          </a>
        </span>
        <span className={styles.zoomVideoLinkMettingIdBox}>
          <span>
            {' '}
            {otherDetails}
            {' '}
          </span>
        </span>
      </div>
    </Col>
  ) : (
    <Col className={styles.scheduledBox}>
      Interview link will be shared soon
    </Col>
  ));

  const renderInterviewer = (interview) => (interview.interviewer.length > 0 ? (
    interview.interviewer.map((interviewer, i) => {
      if (i < 3) {
        return (
          <Col className={styles.detailsAttendeesBox}>
            <span>
              <img
                className={styles.cardIConsLarg}
                src={maskGroup}
                onError={(e) => {
                  e.target.src = fallBackUserImg;
                }}
                alt="Mask Group"
              />
            </span>
            <div className={styles.detailsAttendeesTitleBox}>
              <span className={styles.smallHeading}>
                {getInterviewerName(interviewer)}
              </span>
              <span className={styles.smallText}>(Interviewer)</span>
            </div>
          </Col>
        );
      }
    })
  ) : (
    <Col className={styles.detailsAttendeesBox}>
      <span>
        <img
          className={styles.cardIConsLarg}
          src={maskGroup}
          onError={(e) => {
            e.target.src = fallBackUserImg;
          }}
          alt="Mask Group"
        />
      </span>
      <div className={styles.detailsAttendeesTitleBox}>
        <span className={styles.smallHeading} />
        <span className={styles.smallText}>(Interviewer)</span>
      </div>
    </Col>
  ));

  const renderScheduledInterviewDetails = () => {
    const { interviews } = listInterviewsData;

    if (isListInterviewsError) {
      return <div>{'Something went wrong. Please try again! '}</div>;
    }

    if (!isListInterviewsSuccess) {
      return;
    }

    const upcomingInterviews = interviews?.filter((interview) => moment(interview.endDateTime).isAfter(new Date()));

    if (upcomingInterviews && upcomingInterviews.length > 0) {
      return upcomingInterviews.map((interview, index) => (
        <div key={interview.id + index}>
          <Space direction="vertical" style={{ minWidth: '100%' }}>
            <Collapse
              collapsible="header"
              activeKey={activeKey}
              onChange={handleCollapseChange}
              onClick={() => {
                console.log('ONCLICK CALLED');
                ref.current?.scrollIntoView({ behavior: 'smooth' });
              }}
              className={getInterviewTitleClass(interview)}
              items={[
                {
                  key: `UPCOMING_${index}`,
                  label: interview.interviewName,
                  children: !interviewDetailsData[interview.id] ? (
                    interviewDetailsLoader()
                  ) : (
                    <div className={styles.bodyOuterBox}>
                      <div className={styles.bodyInnerBox}>
                        <div className={styles.cardSubTitle}>
                          <button
                            color="warning"
                            type="button"
                            className={statusButtonClass(
                              interviewDetailsData[interview.id],
                            )}
                          >
                            {getInterviewStatus(
                              interviewDetailsData[interview.id],
                            )}
                          </button>
                          <span className={styles.jobTextColor}>
                            <Dropdown drop="left">
                              <Dropdown.Toggle
                                as={CustomToggle}
                                id="dropdown-custom-components"
                              />
                              <Dropdown.Menu as={CustomMenu}>
                                {interview.interviewStatus !== INTERVIEW_STATUS_CANCELLED ? (
                                  <Dropdown.Item
                                    eventKey="1"
                                    onClick={() => openCancelModal(interview.id)}
                                  >
                                    <img
                                      src={editGreen}
                                      alt="Menu"
                                      className={styles.cardICons}
                                      style={{ marginRight: '1vw' }}
                                    />
                                    <span className={styles.smallText}>
                                      Cancel Interview
                                    </span>
                                  </Dropdown.Item>
                                ) : null}
                                <Dropdown.Item
                                  eventKey="2"
                                  onClick={() => openDeleteModal(interview.id)}
                                >
                                  <img
                                    src={trashGreen}
                                    alt="Menu"
                                    className={styles.cardICons}
                                    style={{ marginRight: '1vw' }}
                                  />
                                  <span className={styles.smallText}>
                                    Delete Interview
                                  </span>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </span>
                        </div>
                      </div>
                      <div className={styles.cardContentBoxSD}>
                        <Row className={styles.cardSubContentBox}>
                          {getVideoInterviewDetails(
                            interviewDetailsData[interview.id],
                          )}
                        </Row>
                        <div>
                          <span className={styles.detailSubBox}>
                            <img
                              src={attends}
                              alt="Menu"
                              className={styles.cardICons}
                            />
                            <span className={styles.smallHeading}>
                              Attendees
                            </span>
                          </span>
                          <div>
                            <Row className={styles.attendeesCardSubContentBox}>
                              <Col>
                                <div className={styles.detailsAttendeesBox}>
                                  <span>
                                    <img
                                      className={styles.cardIConsLarg}
                                      src={maskGroup}
                                      onError={(e) => {
                                        e.target.src = fallBackUserImg;
                                      }}
                                      alt="Mask Group"
                                    />
                                  </span>
                                  <div
                                    className={styles.detailsAttendeesTitleBox}
                                  >
                                    <span className={styles.smallHeading}>
                                      {interview.candidateName}
                                    </span>
                                    <span className={styles.smallText}>
                                      (Candidate)
                                    </span>
                                  </div>
                                </div>
                              </Col>
                              {renderInterviewer(interview)}
                            </Row>
                          </div>
                        </div>
                        <div>
                          <Row className={styles.cardSubContentBox}>
                            <Col className={styles.detailsBox}>
                              <span className={styles.detailSubBox}>
                                <img
                                  src={sheduleDate}
                                  alt="Menu"
                                  className={styles.cardICons}
                                />
                                <span className={styles.smallHeading}>
                                  {displaySlotSection(
                                    interviewDetailsData[interview.id],
                                  )}
                                </span>
                              </span>
                              {getInterviewTimings(
                                interviewDetailsData[interview.id],
                              )}
                            </Col>
                            <Col className={styles.detailsBox}>
                              <span className={styles.detailSubBox}>
                                <img
                                  src={sheduleDate}
                                  alt="Menu"
                                  className={styles.cardICons}
                                />
                                <span className={styles.smallHeading}>
                                  Created On
                                </span>
                              </span>
                              <span className={styles.smallText}>
                                {moment(interview.createdTime).format(
                                  'ddd, MMM Do, YYYY',
                                )}
                                      &nbsp;
                                {moment(interview.createdTime).format('LT')}
                              </span>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      {interview?.scheduleComments ? (
                        <Row>
                          <Col>
                            <div className={styles.detailsBox}>
                              <span className={styles.detailSubBox}>
                                <img
                                  src={feedback}
                                  alt="Menu"
                                  className={styles.cardICons}
                                />
                                <span className={styles.smallHeading}>
                                  Additional Comments
                                </span>
                              </span>
                              <span className={styles.additionalComments}>
                                {interview.scheduleComments}
                              </span>
                            </div>
                          </Col>
                        </Row>
                      ) : null}
                    </div>
                  ),
                },
              ]}
            />
          </Space>
          <br />
          {' '}
          <br />
        </div>
      ));
    }
    return <div>You don’t have any scheduled interviews</div>;
  };

  const renderPastInterviews = () => {
    const { interviews } = listInterviewsData;

    const pastInterviews = interviews?.filter((interview) => moment(interview.endDateTime).isBefore(new Date()));

    if (pastInterviews && pastInterviews.length > 0) {
      return (
        <div>
          <Row>
            <Col className={classNames(styles.yourJobsText, 'heading-3')}>
              <span className={styles.jobTextColor}>Past Interview</span>
            </Col>
          </Row>
          {renderPastInterviewDetails(pastInterviews)}
        </div>
      );
    }
  };

  const openDeleteModal = (interviewIdInput) => {
    setInterviewId(interviewIdInput);

    setShowDeleteModal(true);
  };

  const openCancelModal = (interviewIdInput) => {
    setInterviewId(interviewIdInput);

    setCancelFeedback('');

    setShowCancelModal(true);
  };

  const renderDeleteModal = () => (
    <Overlay
      isOpen={showDeleteModal}
      showCloseButton
      onClose={() => setShowDeleteModal(false)}
      classes={{ modal: styles.customModalInterview }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'center',
        }}
      >
        <h3 className={styles.formDataHeading}>
          Delete Interview Confirmation
        </h3>
      </div>
      <div className={styles.modelBody} style={{ borderRadius: '30px' }}>
        <div className="body-2">
          Are you sure you want to delete this scheduled interview?
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px',
            marginTop: '30px',
          }}
        >
          <button
            className="button-secondary button-text"
            type="submit"
            onClick={() => setShowDeleteModal(false)}
          >
            Close
          </button>
          <button
            className="button-primary button-text button-warning"
            disabled={disableDeleteButton}
            onClick={() => deleteInterview()}
          >
            {disableDeleteButton ? 'Deleting..' : 'Delete'}
          </button>
        </div>
      </div>
    </Overlay>
  );

  const renderCancelModal = () => (
    <Overlay
      isOpen={showCancelModal}
      showCloseButton
      onClose={() => setShowCancelModal(false)}
      classes={{ modal: styles.customModalInterview }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'center',
        }}
      >
        <h3 className={styles.formDataHeading}>Cancel Interview</h3>
      </div>
      <div className={styles.modelBody} style={{ borderRadius: '30px' }}>
        <div className={styles.fontHeavy}>Cancellation Reason</div>
        <div
          style={{
            paddingBottom: '20px',
          }}
        >
          <Select
            bordered={false}
            colortext="#006d5b"
            style={customSelectStyle}
            value={feedbackOption}
            onChange={(selectedOption) => setFeedbackOption(selectedOption)}
            options={feedbackOptions || []}
          />
        </div>
        <Form.Group controlId="comments">
          <div className={styles.alertText}>
            <svg
              width="25"
              height="22"
              viewBox="0 0 25 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.76562 17.375C7.375 16.9062 8.17188 16.8125 8.875 17.0469C10.0938 17.5156 11.5 17.75 13 17.75C18.8125 17.75 22.75 14 22.75 10.25C22.75 6.54688 18.8125 2.75 13 2.75C7.14062 2.75 3.25 6.54688 3.25 10.25C3.25 11.75 3.8125 13.2031 4.89062 14.4688C5.3125 14.8906 5.5 15.5 5.45312 16.1094C5.40625 16.9531 5.17188 17.75 4.9375 18.4531C5.73438 18.0781 6.39062 17.6562 6.76562 17.375ZM1.98438 19.25C2.07812 19.1562 2.125 19.0156 2.21875 18.875C2.6875 18.125 3.10938 17.0938 3.20312 15.9219C1.79688 14.3281 1 12.4062 1 10.25C1 4.90625 6.34375 0.5 13 0.5C19.6094 0.5 25 4.90625 25 10.25C25 15.6406 19.6094 20 13 20C11.2188 20 9.57812 19.7188 8.07812 19.2031C7.5625 19.5781 6.625 20.1406 5.54688 20.6094C4.84375 20.9375 4.04688 21.2188 3.20312 21.3594C3.15625 21.3594 3.10938 21.4062 3.10938 21.4062C2.875 21.4531 2.6875 21.4531 2.45312 21.5C2.21875 21.5 1.98438 21.5469 1.70312 21.5469C1.42188 21.5469 1.14062 21.3594 1.04688 21.0781C0.90625 20.7969 1 20.4688 1.1875 20.2344C1.375 20.0469 1.5625 19.8594 1.70312 19.625C1.79688 19.4844 1.89062 19.3906 1.9375 19.2969C1.9375 19.2969 1.9375 19.2969 1.9375 19.25H1.98438Z"
                fill="#006D5B"
              />
            </svg>
            <span
              style={{
                paddingLeft: '10px',
              }}
            >
              Feedback (Additional)
            </span>
          </div>
          <Form.Control
            as="textarea"
            maxlength="210"
            style={{
              paddingRight: '20px  !important',
              paddingLeft: '20px !important',
              color: '#fff !important',
            }}
            className={styles.commentBox}
            value={cancelFeedback}
            onChange={(event) => {
              setCancelFeedback(event.target.value);
            }}
            name="comments"
            rows={3}
          />
        </Form.Group>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px',
            marginTop: '30px',
          }}
        >
          <button
            className="button-secondary button-text"
            type="submit"
            onClick={() => setShowCancelModal(false)}
          >
            Close
          </button>
          <button
            className="button-primary button-text button-warning"
            disabled={disableCancelButton}
            onClick={cancelInterview}
          >
            {disableCancelButton ? 'Cancelling..' : 'Cancel'}
          </button>
        </div>
      </div>
    </Overlay>
  );

  return (
    <>
      <div className={styles.profileSidetag}>
        {!isListInterviewsSuccess ? (
          <div>
            <img src={loading} className={styles.loader} alt="loader" />
          </div>
        ) : (
          <>
            {profileType !== JOB_STAGE_200 ? (
              <div style={{ textAlign: 'center', paddingTop: '10vh' }}>
                <img src={noInterviews} alt="No Interviews" />
              </div>
            ) : (
              <div>
                <Row className={styles.parentRow}>
                  <Col className={classNames(styles.yourJobsText, 'heading-3')}>
                    <span className={styles.jobTextColor}>
                      Scheduled Interview
                    </span>
                  </Col>
                </Row>
                {renderScheduledInterviewDetails()}
                {renderPastInterviews()}
              </div>
            )}
          </>
        )}
      </div>
      {renderDeleteModal()}
      {renderCancelModal()}
    </>
  );
}

const mapStateToProps = (state) => ({
  content: state.languageReducer.portalContent,
  languageCode: state.languageReducer.languageCode,

  isListInterviewsSuccess: state.candidateReducer.isListInterviewsSuccess,
  isListInterviewsError: state.candidateReducer.isListInterviewsError,
  listInterviewsData: state.candidateReducer.listInterviewsData,

  isInterviewDetailsSuccess: state.candidateReducer.isInterviewDetailsSuccess,
  isInterviewDetailsError: state.candidateReducer.isInterviewDetailsError,
  interviewDetailsData: state.candidateReducer.interviewDetailsData,

  isDeleteInterviewSuccess: state.candidateReducer.isDeleteInterviewSuccess,
  isDeleteInterviewError: state.candidateReducer.isDeleteInterviewError,
  deleteInterviewData: state.candidateReducer.deleteInterviewData,

  isCancelInterviewSuccess: state.candidateReducer.isCancelInterviewSuccess,
  isCancelInterviewError: state.candidateReducer.isCancelInterviewError,
  cancelInterviewData: state.candidateReducer.cancelInterviewData,
});

const mapDispatchToProps = (dispatch) => ({
  listInterviewRequest: (data) => dispatch(listInterviewRequest(data)),
  fetchInterviewDetailsRequest: (data) => dispatch(fetchInterviewDetailsRequest(data)),

  deleteInterviewRequest: (data) => dispatch(deleteInterviewRequest(data)),
  cancelInterviewRequest: (data) => dispatch(cancelInterviewRequest(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Interviews));
