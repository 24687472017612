/* eslint-disable default-param-last */
/* eslint-disable max-len */
import {
  GET_JOB_FUNNEL_REQUEST,
  GET_JOB_FUNNEL_REQUEST_SUCCESS,
  GET_JOB_FUNNEL_REQUEST_ERROR,
  CHANGE_APPLICATION_STAGE_REQUEST,
  CHANGE_APPLICATION_STAGE_REQUEST_SUCCESS,
  CHANGE_APPLICATION_STAGE_REQUEST_ERROR,
  UPDATE_JOB_REQUEST,
  UPDATE_JOB_SUCCESS,
  UPDATE_JOB_ERROR,
  GET_SPECIALIZATION_REQUEST,
  GET_SPECIALIZATION_SUCCESS,
  GET_SPECIALIZATION_ERROR, LIST_SKILLS_REQUEST, LIST_SKILLS_SUCCESS, LIST_SKILLS_ERROR,
} from '../actions/actionTypes';

const initialState = {
  isJobFunnelLoading: false,
  isJobFunnelSuccess: false,
  isJobFunnelError: false,
  isJobFunnelData: {},
  isChangeApplicationStageSuccess: false,
  isChangeApplicationStageError: false,
  isChangeApplicationStageData: {},
  isUpdateJobSuccess: false,
  isUpdateJobError: false,
  isUpdateJobData: {},
  isSpecializationSuccess: false,
  isSpecializationError: false,
  specializationData: {},
  isSkillsSuccess: false,
  isSkillsError: false,
  skills: {},
  message: '',
};

export function jobsReducer(state = initialState, { type, response }) {
  switch (type) {
    case GET_JOB_FUNNEL_REQUEST:
      return {
        ...state,
        isJobFunnelSuccess: false,
        isJobFunnelError: false,
        isJobFunnelData: {},
      };
    case GET_JOB_FUNNEL_REQUEST_SUCCESS:
      return {
        ...state,
        isJobFunnelSuccess: true,
        isJobFunnelError: false,
        isJobFunnelData: response,
      };
    case GET_JOB_FUNNEL_REQUEST_ERROR:
      return {
        ...state,
        isJobFunnelSuccess: false,
        isJobFunnelError: true,
        isJobFunnelData: response,
      };
    case CHANGE_APPLICATION_STAGE_REQUEST:
      return {
        ...state,
        isJobFunnelLoading: true,
        isChangeApplicationStageSuccess: false,
        isChangeApplicationStageError: false,
        isChangeApplicationStageData: {},
      };
    case CHANGE_APPLICATION_STAGE_REQUEST_SUCCESS:
      return {
        ...state,
        isJobFunnelLoading: false,
        isChangeApplicationStageSuccess: true,
        isChangeApplicationStageError: false,
        isChangeApplicationStageData: response,
      };
    case CHANGE_APPLICATION_STAGE_REQUEST_ERROR:
      return {
        ...state,
        isJobFunnelLoading: false,
        isChangeApplicationStageSuccess: false,
        isChangeApplicationStageError: true,
        isChangeApplicationStageData: response,
      };
    case UPDATE_JOB_REQUEST:
      return {
        ...state,
        isUpdateJobSuccess: false,
        isUpdateJobError: false,
        isUpdateJobData: {},
      };
    case UPDATE_JOB_SUCCESS:
      return {
        ...state,
        isUpdateJobSuccess: true,
        isUpdateJobError: false,
        isUpdateJobData: response,
      };
    case UPDATE_JOB_ERROR:
      return {
        ...state,
        isUpdateJobSuccess: false,
        isUpdateJobError: true,
        isUpdateJobData: response,
      };
    case GET_SPECIALIZATION_REQUEST:
      return {
        ...state,
        isSpecializationSuccess: false,
        isSpecializationError: false,
        specializationData: {},
      };
    case GET_SPECIALIZATION_SUCCESS:
      return {
        ...state,
        isSpecializationSuccess: true,
        isSpecializationError: false,
        specializationData: response,
      };
    case GET_SPECIALIZATION_ERROR:
      return {
        ...state,
        isSpecializationSuccess: false,
        isSpecializationError: true,
        specializationData: response,
      };
    case LIST_SKILLS_REQUEST:
      return {
        ...state,
        isSkillsSuccess: false,
        isSkillsError: false,
        skills: {},
      };
    case LIST_SKILLS_SUCCESS:
      return {
        ...state,
        isSkillsSuccess: true,
        isSkillsError: false,
        skills: response,
      };
    case LIST_SKILLS_ERROR:
      return {
        ...state,
        isSkillsSuccess: false,
        isSkillsError: true,
        skills: response,
      };
    default:
      return state;
  }
}
