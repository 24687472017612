import React, { useState, useEffect } from 'react';
import useDebounce from '../../../../hooks/useDebounce';

import { convertArrayToCommaSeparatedString, getAgencyClientList } from '../../../../utils/helpers';

import HR from '../../../../subViews/horizontalLine';

import styles from './styles.module.scss';
import FilterIcon from '../../../../images/filter.png';
import DownArrow from '../../../../images/greenDownArrow.png';
import FilterModal from '../../../../modals/filterModal';
import { allCandidatesFilter } from '../../../../data/filterModalFields';

function Filters({
  clientId, setFilterObj, filterObj, portalContent, getCandidates,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const debouncedQuery = useDebounce(searchQuery);

  useEffect(() => {
    setIsFirstLoad(false);
  }, []);

  const FILTER_ICON_SIZE = '16px';

  // setting client list for agency
  const clientsListFormatted = getAgencyClientList();

  const employerField = allCandidatesFilter.find(
    (field) => field.heading === portalContent.EMPLOYER,
  );
  if (employerField) {
    employerField.options = clientsListFormatted;
  }
  // setting client list for agency - END

  const filterCandidates = (filter) => {
    let newFilter;
    if (Object.keys(filter).length === 0) {
      newFilter = { clientId };
    } else {
      newFilter = { ...filter, ...filterObj };
    }

    if (filter.employer) {
      newFilter.clientId = convertArrayToCommaSeparatedString(
        filter.employer,
      );
      delete newFilter.employer;
    }
    if (filter.specialisation) {
      newFilter.specialisations = convertArrayToCommaSeparatedString(
        filter.specialisation,
      );
      delete newFilter.specialisation;
    }
    if (filter.profession) {
      newFilter.profession = convertArrayToCommaSeparatedString(
        filter.profession,
      );
    }

    newFilter.clientId = clientId;

    setFilterObj(newFilter);

    getCandidates(newFilter);
    setIsModalVisible(false);
  };

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
    setFilterObj((prev) => ({
      ...prev,
      candidateName: e.target.value,
    }));
  };

  useEffect(() => {
    const filterObj = {
      candidateName: debouncedQuery,
      clientId,
    };

    setFilterObj((prev) => ({
      ...prev,
      ...filterObj,
    }));

    // prevent dispatch when search query is empty
    if (!isFirstLoad) {
      getCandidates(filterObj);
    }
  }, [debouncedQuery]);

  return (
    <>
      <FilterModal
        filterData={filterCandidates}
        showFilterModal={isModalVisible}
        closeFilterModal={() => setIsModalVisible(false)}
        filterOptionsData={allCandidatesFilter}
      />
      <div className={styles.filterContainer}>
        <input
          className={styles.searchBox}
          type="search"
          placeholder="Search Candidate"
          value={searchQuery}
          onChange={handleSearchQueryChange}
        />
        <button
          onClick={() => setIsModalVisible(true)}
          className={styles.filterBtn}
          type="button"
        >
          <img width={FILTER_ICON_SIZE} height={FILTER_ICON_SIZE} src={FilterIcon} alt="FilterIcon" />
          <span>{portalContent.FILTER}</span>
          <img width={FILTER_ICON_SIZE} height="auto" src={DownArrow} alt="DownArrow" />
        </button>
      </div>
      <HR customClass={styles.horizontalLine} />
    </>
  );
}

export default Filters;
