import React from 'react';

import { Input } from 'reactstrap';
import styles from './styles.module.scss';

function DatePickerWithTitle({
  title,
  layoutStyle,
  inputValue,
  onChangeInput,
  inputPlaceholder,
  inputType = 'date',
  inputStyle,
}) {
  return (
    <div className={styles.inputLayout} style={layoutStyle}>
      <div className="body-2-no-color">{title}</div>
      <Input
        className={styles.inputBoxLayout}
        type={inputType}
        style={inputStyle}
        value={inputValue}
        onChange={(date) => onChangeInput(date.target.value)}
        name="inputBox"
        placeholder={inputPlaceholder}
      />
    </div>
  );
}

export default DatePickerWithTitle;
