import React from 'react';
import funnelShimmmer from '../../images/jobFunnelShimmer.gif';
import styles from './styles.module.css';

function Shimmer() {
  return (
    <img
      className={styles.loadingGif}
      alt="Loading"
      src={funnelShimmmer}
    />
  );
}

export default Shimmer;
