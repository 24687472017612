import React from 'react';
import { useSelector } from 'react-redux';
import { getPortalContent } from '../../redux/reducers/languageReducer';
import Picklist from '../../components/picklist';
import Shimmer from '../../components/shimmer';
import styles from './styles.module.scss';

function Employers({
  clients, agencySubClientId, handleClientListChange,
}) {
  const portalContent = useSelector(getPortalContent);
  return (
    <div className={styles.employerDropdown}>
      <p className="body-2">{portalContent.SELECT_EMPLOYER}</p>
      { agencySubClientId
        ? (
          <Picklist
            options={clients}
            selectedValue={agencySubClientId}
            onSelect={handleClientListChange}
            dropdownStyle={styles.dropdownHeaderStyle}
          />
        )
        : <Shimmer />}
    </div>
  );
}

export default Employers;
