export const servicesData = [
  {
    category: 'Compliance',
    items: [
      'Certificate of Sponsorship Assignment',
      'Certificate of Sponsorship Renewal',
      'Reporting',
      'Sponsor Management System',
      'Specialized Support',
    ],
  },
  {
    category: 'Recruitment Services',
    items: [
      'Sourcing',
      'Confirmation of Qualifications',
      'References Check',
      'OET / IELTS Score Check',
      'NMC Application Status',
      'CBT Pass Confirmation',
      'Manage Job Offers',
    ],
  },
  {
    category: 'Immigration',
    items: [
      'Visa & BRP Letter',
      'Covid 19 Vaccine Certificate',
      'Overseas Police Check',
      'Certificate of Sponsorship form',
    ],
  },
  {
    category: 'Relocation',
    items: [
      'Travel Logistics',
      'Interim Accommodation',
      'Airport Welcome',
    ],
  },
  {
    category: 'Pastoral Support',
    items: [
      'Housing Search & Handholding',
      'Local Guardian',
      'Bank Opening Facilities',
      'OSCE Training',
      'Townhall Registration',
    ],
  },
];
