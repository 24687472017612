import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import selectIcon from '../../images/selectTick.png';
import chevronRight from '../../images/chevron-right.svg';

function ItemList({
  heading, listItems, limit = 4, handleReadMoreClick,
}) {
  const displayItems = listItems.slice(0, limit);
  return (

    <div className={styles.listCard}>

      <h2 className={classNames(styles.headingText, 'heading-3')}>{heading}</h2>

      <ul className="list-group">
        {displayItems.map((item) => (
          <li key={item} className={classNames(styles.listItemText, 'body-1')}>
            <img className={styles.selectTickImg} src={selectIcon} alt="listItem" />
            {item}
          </li>
        ))}
      </ul>

      <button type="button" className={classNames('body-3', styles.knowMoreBtn)} onClick={() => handleReadMoreClick(true)}>
        Know More
        {' '}
        <img src={chevronRight} alt="chevron" />
      </button>

    </div>
  );
}

export default ItemList;
