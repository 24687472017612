import { findByKey } from '../../utils/helpers';

export const findOptionByValue = (options, selectedValue, isNestedOptions) => {
  if (!isNestedOptions) {
    return findByKey(options, 'value', selectedValue);
  }

  let matchingOption;
  // eslint-disable-next-line no-restricted-syntax
  for (const nestedOption of options) {
    matchingOption = findByKey(nestedOption.options, 'id', selectedValue);
    if (matchingOption) break;
  }
  return matchingOption;
};
