/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { useHistory, withRouter, Link } from 'react-router-dom';
import classNames from 'classnames';
import styles from './styles.module.scss';
import 'react-toastify/dist/ReactToastify.css';
import ReactTable from '../../components/reactTable';
import PaginatedItems from '../../components/pagination';
import Picklist from '../../components/picklist';
import loading from '../../images/loading.gif';
import downArrow from '../../images/down_arrow.png';
import filterIcon from '../../images/filter.png';
import Helper from '../../services/helper';
import JobStats from '../../subViews/jobStats';
import { AGENCY_CLIENTS, CLIENTID } from '../../services/constants';
import FilterModal from '../../modals/filterModal';
import { InactiveJobFilterFields } from '../../data/filterModalFields';
import {
  getAllJobsRequest,
  getJobStatsRequest,
} from '../../redux/actions/action';
import PostJobModal from '../../modals/postJobModal';
import SuccessModal from '../../modals/successModal';
import { getTableRangeLabel } from './utils';
import {
  IN_ACTIVE_JOB_IDS, IN_ACTIVE_JOB_PAGE_LIMIT, ACTIVE_JOB_PAGE_LIMIT, ACTIVE_JOB_IDS,
} from '../../utils/constants';

function Jobs(props) {
  const {
    jobStatsData, isAllJobsData, content, isAllJobsError,
  } = props;
  const history = useHistory();
  const prevProps = useRef(props);
  const [currentPageActiveJobs, setCurrentPageActiveJobs] = useState(0);
  const [currentPageInActiveJobs, setCurrentPageInActiveJobs] = useState(0);
  const [isInActiveJobsLoading, setIsInActiveJobsLoading] = useState(true);
  const [activeJobsData, setActiveJobsData] = useState([]);
  const [inActiveJobsData, setInActiveJobsData] = useState([]);
  const [agencySubClientId, setAgencySubClientId] = useState(null);
  const [showInactiveJobsFilter, setShowInactiveJobsFilter] = useState(false);
  const [showActiveJobsFilter, setShowActiveJobsFilter] = useState(false);
  const [filterCriteriaActiveJob, setFilterCriteriaActiveJob] = useState({});

  const [filterCriteriaInactiveJob, setFilterCriteriaInactiveJob] = useState({});
  const [epochTime, setEpochTime] = useState(null);
  const [showAddJobModal, setShowAddJobModal] = useState(false);
  const [showAddJobSuccessModal, setShowAddJobSuccessModal] = useState(false);
  const [currClientId, setCurrClientId] = useState(null);

  const clientsList = JSON.parse(Helper.getItem(AGENCY_CLIENTS))?.clientsList?.map((client) => ({ value: client.id, label: client.name }));
  const clientsListFormatted = clientsList?.length > 0 ? [{ value: Helper.getItem(CLIENTID), label: 'All Employers' }, ...clientsList] : [];

  const employerField = InactiveJobFilterFields.find((field) => field.heading === 'Employer');
  if (employerField) {
    employerField.options = clientsListFormatted.slice(1);
  }

  const activeJobFilterFields = [...InactiveJobFilterFields];
  activeJobFilterFields.shift();
  const getActiveJobs = (pageNum) => {
    const { getJobStatsRequest } = props;
    const clientId = Helper.getItem('clientId');

    const jobStatsObj = {
      pageNum,
      pageLimit: ACTIVE_JOB_PAGE_LIMIT,
      clientId,
      jobStatusId: ACTIVE_JOB_IDS,
    };
    getJobStatsRequest(jobStatsObj);
  };

  const getInActiveJobs = (pageNum) => {
    const { getAllJobsRequest } = props;
    const clientId = Helper.getItem('clientId');

    const allJobsObject = {
      pageNum,
      pageLimit: IN_ACTIVE_JOB_PAGE_LIMIT,
      clientId,
      jobStatusId: IN_ACTIVE_JOB_IDS,
    };

    getAllJobsRequest(allJobsObject);
  };

  const fetchJobsData = async () => {
    const clientId = Helper.getItem('clientId');
    if (!clientId) {
      Helper.clearStorage();
      Helper.lastVisitedRoute();
      history.push('/');
      return;
    }
    setCurrClientId(clientId);
    setAgencySubClientId(clientId);

    getActiveJobs(1);
    getInActiveJobs(1);
  };

  useEffect(() => {
    fetchJobsData();
  }, []);

  const handlePageChangeActiveJobs = (selectedPage) => {
    setCurrentPageActiveJobs(selectedPage);

    // fetching ActiveJobs
    if (Object.keys(filterCriteriaActiveJob).length) {
      filterActiveJobs(filterCriteriaActiveJob, selectedPage + 1);
    } else {
      getActiveJobs(selectedPage + 1);
    }
  };

  const handlePageChangeInActiveJobs = (selectedPage) => {
    setCurrentPageInActiveJobs(selectedPage);

    // fetching InactiveJobs
    if (Object.keys(filterCriteriaInactiveJob).length) {
      filterInActiveJobs(filterCriteriaInactiveJob, selectedPage + 1);
    } else {
      getInActiveJobs(selectedPage + 1);
    }
  };

  useEffect(() => {
    const {
      isAllJobsSuccess: prevIsAllJobsSuccess,
      isAllJobsError: prevIsAllJobsError,
    } = prevProps.current;
    const { isAllJobsSuccess, isAllJobsError, isAllJobsData } = props;

    if (prevIsAllJobsSuccess === false && isAllJobsSuccess) {
      const jobs = isAllJobsData?.jobs;
      setInActiveJobsData(jobs);
      setIsInActiveJobsLoading(false);
    }

    if (prevIsAllJobsError === false && isAllJobsError) {
      const messages = [];
      messages.push('Error');
    }

    prevProps.current = props;
  }, [props.isAllJobsSuccess, props.isAllJobsError]);

  useEffect(() => {
    const {
      isJobStatsSuccess: prevIsJobStatsSuccess,
      isJobStatsError: prevIsJobStatsError,
    } = prevProps.current;
    const { isJobStatsSuccess, isJobStatsError, jobStatsData } = props;

    if (prevIsJobStatsSuccess === false && isJobStatsSuccess) {
      const jobs = jobStatsData?.jobs;
      setActiveJobsData(jobs);
    }

    if (prevIsJobStatsError === false && isJobStatsError) {
      const messages = [];
      messages.push('Error');
    }

    prevProps.current = props;
  }, [props.isJobStatsSuccess, props.isJobStatsError]);

  const openAddJobModal = () => {
    setEpochTime(Date.now());
    setShowAddJobModal(true);
  };

  const closeAddJobModal = () => {
    setShowAddJobModal(false);
  };

  const closeAddJobSuccessModal = () => {
    setShowAddJobSuccessModal(false);
  };

  const addJob = () => {
    setShowAddJobModal(false);
    setShowAddJobSuccessModal(true);
  };

  const handleClientsListChange = async (selectedOption) => {
    const jobStatsObj = {
      pageNum: 1,
      pageLimit: ACTIVE_JOB_PAGE_LIMIT,
      clientId: selectedOption.value,
      jobStatusId: 'JOBSTAGE:TERN:1210',
    };
    props.getJobStatsRequest(jobStatsObj);
    setAgencySubClientId(selectedOption.value);
  };

  const filterActiveJobs = (filterCriteria, pageNum) => {
    const { getJobStatsRequest } = props;
    const clientId = Helper.getItem(CLIENTID);

    setShowActiveJobsFilter(false);
    setFilterCriteriaActiveJob(filterCriteria);

    const activeJobsObject = {
      pageNum,
      pageLimit: ACTIVE_JOB_PAGE_LIMIT,
      clientId:
        filterCriteria?.employer?.length > 0
          ? filterCriteria?.employer.join(',')
          : clientId,
      jobStatusId: ACTIVE_JOB_IDS,
      jobType: filterCriteria.profession
        ? filterCriteria?.profession.join(',')
        : undefined,
      specialisation: filterCriteria.specialisation
        ? filterCriteria?.specialisation.join(',')
        : undefined,
    };

    getJobStatsRequest(activeJobsObject);
  };
  const filterInActiveJobs = (filterCriteria, pageNum) => {
    const { getAllJobsRequest } = props;
    const clientId = Helper.getItem(CLIENTID);

    setShowInactiveJobsFilter(false);
    setFilterCriteriaInactiveJob(filterCriteria);
    setIsInActiveJobsLoading(true);

    const allJobsObject = {
      pageNum,
      pageLimit: IN_ACTIVE_JOB_PAGE_LIMIT,
      clientId: filterCriteria.employer?.length > 0 ? filterCriteria.employer.join(',') : clientId,
      jobStatusId: IN_ACTIVE_JOB_IDS,
      jobType: filterCriteria.profession ? filterCriteria.profession.join(',') : undefined,
      specialisation: filterCriteria.specialisation ? filterCriteria.specialisation.join(',') : undefined,
    };

    getAllJobsRequest(allJobsObject);
  };

  const handleFilterInactiveJobs = (filterCriteria) => {
    const pageNum = 1; // after applying filter, fetching 1st page data
    filterInActiveJobs(filterCriteria, pageNum);
    setCurrentPageInActiveJobs(pageNum - 1);
  };

  const closeInactiveJobsFilter = () => {
    setShowInactiveJobsFilter(false);
  };

  const handleFilterActiveJobs = (filterCriteria) => {
    const pageNum = 1; // after applying filter, fetching 1st page data
    filterActiveJobs(filterCriteria, pageNum);
    setCurrentPageActiveJobs(pageNum - 1);
  };

  const closeActiveJobsFilter = () => {
    setShowActiveJobsFilter(false);
  };

  const renderInactiveJobData = () => {
    const jobsInActiveData = isAllJobsData?.jobs;

    if (isAllJobsError) {
      return (
        <div className={styles.loaderRow}>
          <p style={{ padding: '20px' }}>{content.SOMETHING_WENT_WRONG}</p>
        </div>
      );
    }

    const result = !isInActiveJobsLoading && jobsInActiveData ? (
      <div className={styles.otherJobsList}>
        <ReactTable
          data={jobsInActiveData}
          columns={[
            {
              disableSortBy: true,
              isSortingEnable: false,
              Header: () => (
                <div
                  className={(styles.headerText, 'subtitle-1')}
                  style={{
                    padding: '10px 0px 10px 30px',
                    color: '#fff',
                    textAlign: 'start',
                  }}
                >
                  {content.JOB_TITLE}
                </div>
              ),
              accessor: 'name',
              Cell: ({ row }) => {
                const name_title = row.original?.name ?? '';
                return (
                  <div>
                    <Link className="linkAppearanceNone" to={`/job-details?jobOpeningCode=${row.original?.jobOpeningCode ?? ''}&jobOpeningId=${row.original?.jobOpeningId ?? ''}&jobTitle=${name_title}`}>
                      <div
                        className={classNames(
                          styles.rowText,
                          styles.jobTitle,
                          'body-4',
                        )}
                      >
                        <span title={name_title}>{name_title}</span>
                      </div>
                    </Link>
                  </div>
                );
              },
            },
            {
              disableSortBy: true,
              isSortingEnable: false,
              Header: () => (
                <div className={classNames('subtitle-1', styles.headerText)}>
                  {content.CATEGORY}
                </div>
              ),
              accessor: 'category',
              Cell: ({ row }) => {
                const category_title = row.original?.category ?? '';
                return (
                  <div className={classNames(styles.rowText, 'body-1')}>
                    <span title={category_title}>
                      {category_title}
                    </span>
                  </div>
                );
              },
            },
            {
              disableSortBy: true,
              isSortingEnable: false,
              Header: () => (
                <div className={classNames('subtitle-1', styles.headerText)}>
                  {content.SPECIALISATION}
                </div>
              ),
              accessor: 'specialisation',
              Cell: ({ row }) => {
                const specialisation_title = row?.original?.specialisations?.join(' , ') ?? '';

                return (
                  <div className={classNames(styles.rowText, 'body-1')}>
                    <span title={specialisation_title}>
                      {specialisation_title}
                    </span>
                  </div>
                );
              },
            },
            {
              disableSortBy: true,
              isSortingEnable: false,
              Header: () => (
                <div className={classNames('subtitle-1', styles.headerText)}>
                  {content.EMPLOYMENT_TYPE}
                </div>
              ),
              accessor: 'employmentType',
              Cell: ({ row }) => {
                const emp_title = row.original?.employmentType ?? '';
                return (
                  <div className={classNames(styles.rowText, 'body-1')}>
                    <span title={emp_title}>
                      {emp_title}
                    </span>
                  </div>
                );
              },
            },
            {
              disableSortBy: true,
              isSortingEnable: false,
              Header: () => (
                <div className={classNames('subtitle-1', styles.headerText)}>
                  {content.CURRENT_STATUS}
                </div>
              ),
              accessor: 'status',
              Cell: ({ row }) => {
                const status_title = row.original?.status ?? '';
                return (
                  <div className={classNames(styles.rowText, 'body-1')}>
                    <span title={status_title}>
                      {status_title}
                    </span>
                  </div>
                );
              },
            },
          ]}
          isBorderRadiusReq="true"
          emptyDataMsg={content.EMPTY_DATA_MESSAGE_FOR_ALL_JOBS}
        />
      </div>
    ) : (
      <div style={{ padding: '15px' }}>
        <Row className={styles.loaderRow}>
          <Col
            className={styles.loaderDiv}
          >
            <img src={loading} alt="loader" />
          </Col>
        </Row>
      </div>
    );

    return result;
  };

  const totalInActiveJobs = isAllJobsData?.pageStats?.totalCount;
  const currentInActiveJobsCount = isAllJobsData?.jobs?.length ?? null;

  const totalActiveJobs = jobStatsData?.pageStats?.totalCount;
  const currentActiveJobsCount = jobStatsData?.jobs?.length ?? null;

  const activeJobsRange = getTableRangeLabel(content, totalActiveJobs, currentActiveJobsCount, currentPageActiveJobs, ACTIVE_JOB_PAGE_LIMIT);
  const inActiveJobsRange = getTableRangeLabel(content, totalInActiveJobs, currentInActiveJobsCount, currentPageInActiveJobs, IN_ACTIVE_JOB_PAGE_LIMIT);

  return (
    <>
      <div className={styles.content}>

        <div className={styles.postJobSection}>
          <div className="heading-2">{content.ALL_JOBS}</div>
          <button
            className="button-primary-with-icon button-text text-nowrap"
            color="warning"
            type="button"
            onClick={openAddJobModal}
          >
            {content.POST_A_JOB}
          </button>
        </div>

        <div className={classNames(styles.headerSection, styles.activeJobHeaderSection)}>
          <div className={classNames('heading-3')}>
            {content.ACTIVE_JOBS}
          </div>
          {clientsListFormatted?.length > 0 && (
            <div className={styles.rightHeaderSection}>
              <p className={classNames('subtitle-3')}>Select Client:</p>
&nbsp;
              <Picklist
                options={clientsListFormatted}
                selectedValue={agencySubClientId}
                onSelect={handleClientsListChange}
                dropdownStyle={styles.dropdownHeaderStyle}
              />
              <button
                onClick={() => setShowActiveJobsFilter(true)}
                className={styles.filterButton}
              >
                <img
                  src={filterIcon}
                  width="16px"
                  height="16px"
                  alt="filter icon"
                />
                <span>
                  {content.FILTER}
                  {Object.keys(filterCriteriaActiveJob)?.length > 0 && (
                    <span className={styles.redDot} />
                  )}
                </span>
                <img
                  src={downArrow}
                  width="16px"
                  height="16px"
                  alt="filter icon"
                />
              </button>
            </div>
          )}
        </div>
        <div>
          <JobStats
            isJobStatsError={isAllJobsError}
            jobStatsData={jobStatsData}
          />
        </div>
        {!navigator.userAgent.match(/Android/i)
          ? totalActiveJobs > 0 && (
          <PaginatedItems
            currentPage={currentPageActiveJobs}
            totalItems={totalActiveJobs}
            itemsPerPage={ACTIVE_JOB_PAGE_LIMIT}
            onPageChange={handlePageChangeActiveJobs}
          />
          )
          : null}
        {totalActiveJobs > 0 && (
          <div className={styles.message}>{activeJobsRange}</div>
        )}

        <div className={styles.headerSection}>
          <div className={classNames('heading-3')}>
            {content.INACTIVE_JOBS}
          </div>
          {clientsListFormatted?.length > 0 && (
          <button
            type="button"
            onClick={() => setShowInactiveJobsFilter(true)}
            className={styles.filterButton}
          >
            <img src={filterIcon} width="16px" height="16px" alt="filter icon" />
            <span>
              {content.FILTER}
              {Object.keys(filterCriteriaInactiveJob)?.length > 0 && <span className={styles.redDot} />}
            </span>
            <img src={downArrow} width="16px" height="16px" alt="filter icon" />
          </button>
          )}
        </div>

        <div>{renderInactiveJobData()}</div>
        {!navigator.userAgent.match(/Android/i) ? (totalInActiveJobs > 0
          && (
          <PaginatedItems
            currentPage={currentPageInActiveJobs}
            totalItems={totalInActiveJobs}
            itemsPerPage={IN_ACTIVE_JOB_PAGE_LIMIT}
            onPageChange={handlePageChangeInActiveJobs}
          />
          )
        ) : null}
        {totalInActiveJobs > 0 ? (
          <div className={styles.message}>
            {inActiveJobsRange}
          </div>
        ) : null}
      </div>
      {/* Active Filter Modal */}
      <FilterModal
        filterData={handleFilterActiveJobs}
        showFilterModal={showActiveJobsFilter}
        closeFilterModal={closeActiveJobsFilter}
        filterOptionsData={activeJobFilterFields}
      />
      {/* InActive Filter Modal */}
      <FilterModal
        filterData={handleFilterInactiveJobs}
        showFilterModal={showInactiveJobsFilter}
        closeFilterModal={closeInactiveJobsFilter}
        filterOptionsData={InactiveJobFilterFields}
      />
      <PostJobModal
        addJob={addJob}
        showAddJobModal={showAddJobModal}
        closeAddJobModal={closeAddJobModal}
        content={content}
        clientId={currClientId}
        epochTime={epochTime}
      />
      <SuccessModal
        heading={content.JOB_ADDED_SUCCESSFULLY}
        successMsg={content.SUCCESS_MESSAGE}
        showSuccessModal={showAddJobSuccessModal}
        closeSuccessModal={closeAddJobSuccessModal}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  content: state.languageReducer.portalContent,
  languageCode: state.languageReducer.languageCode,
  isAllJobsSuccess: state.allJobsReducer.isAllJobsSuccess,
  isAllJobsError: state.allJobsReducer.isAllJobsError,
  isAllJobsData: state.allJobsReducer.isAllJobsData,
  isJobStatsSuccess: state.dashboardReducer.isJobStatsSuccess,
  isJobStatsError: state.dashboardReducer.isJobStatsError,
  jobStatsData: state.dashboardReducer.jobStatsData,
});

const mapDispatchToProps = (dispatch) => ({
  getAllJobsRequest: (data) => dispatch(getAllJobsRequest(data)),
  getJobStatsRequest: (data) => dispatch(getJobStatsRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Jobs));
