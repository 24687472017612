/* eslint-disable */
import { GET_JOB_DETAILS_REQUEST , GET_JOB_DETAILS_SUCCESS, GET_JOB_DETAILS_ERROR } from '../actions/actionTypes';
import { CHANGE_JOB_STATUS_REQUEST,CHANGE_JOB_STATUS_REQUEST_SUCCESS,CHANGE_JOB_STATUS_REQUEST_ERROR } from '../actions/actionTypes';
const initialState = {
    isGetJobDetailsSuccess: false,
    isGetJobDetailsError: false,
    jobDetailsData: {},
    message: ''
}

export function jobDetailsReducer(state = initialState, { type, response }) {
    switch (type) {
        case GET_JOB_DETAILS_REQUEST:
            return {
                ...state,
                isGetJobDetailsSuccess: false,
                isGetJobDetailsError: false,
                jobDetailsData: {}
            };
        case GET_JOB_DETAILS_SUCCESS:
            return {
                ...state,
                isGetJobDetailsSuccess: true,
                isGetJobDetailsError: false,
                jobDetailsData: response
            };
        case GET_JOB_DETAILS_ERROR:
            return {
                ...state,
                isGetJobDetailsSuccess: false,
                isGetJobDetailsError: true,
                jobDetailsData: response
            };
        case CHANGE_JOB_STATUS_REQUEST:
            return {
                ...state,
                isChangeJobStatusSuccess: false,
                isChangeJobStatusError: false,
                isChangeJobStatusData: {}
            };
        case CHANGE_JOB_STATUS_REQUEST_SUCCESS:
            return {
                ...state,
                isChangeJobStatusSuccess: true,
                isChangeJobStatusError: false,
                isChangeJobStatusData: response
            };
        case CHANGE_JOB_STATUS_REQUEST_ERROR:
            return {
                ...state,
                isChangeJobStatusSuccess: false,
                isChangeJobStatusError: true,
                isChangeJobStatusData: response
            };
        default:
            return state;
    }
}