import Helper from '../services/helper';

import { AWS_ENDPOINT, AWS_V2_ENDPOINT, SPECIALIZATION_URL } from '../services/constants';
import axiosInstance from '../services/axiosConfig';
import { GET_JOB_FUNNEL } from '../services/urls';

class JobsAPI {
  static getJobFunnel = async (request) => axiosInstance.get(
    `${GET_JOB_FUNNEL}`,
    {
      params: request.params,
      headers: Helper.authHeader(),
    },
  );

  static getSkills = async (request) => axiosInstance.get(
    `${AWS_V2_ENDPOINT}/skills`,
    {
      params: request.params,
      headers: Helper.authHeader(),
    },
  );

  static getSpecialization = async () => axiosInstance.get(
    SPECIALIZATION_URL,
  );

  static changeApplicationStage = async (request) => axiosInstance.put(
    `${AWS_V2_ENDPOINT}/changeApplicationStage`,
    request.params,
    {
      headers: Helper.authHeader(),
    },
  );

  static updateJob = async (request) => axiosInstance.put(
    `${AWS_V2_ENDPOINT}/jobs/update`,
    request.params,
    {
      headers: Helper.authHeader(),
    },
  );

  static UploadJD = async (formData, uploadConfig) => axiosInstance.post(
    `${AWS_ENDPOINT}/attachments/others`,
    formData,
    {
      params: { attachmentType: 'jobs/jd' },
      ...uploadConfig,
      headers: Helper.authHeader(),
    },
  );

  static PostNewJob = async (payload) => axiosInstance.post(
    `${AWS_ENDPOINT}/createJobOpening?attachmentType=jobs/jd`,
    payload,
    {
      headers: Helper.authHeader(),
    },
  );

  static DeleteUploadedJD = async (payload) => axiosInstance.delete(`${AWS_ENDPOINT}/attachments/others`, {
    data: payload,
    headers: Helper.authHeader(),
  });
}

export default JobsAPI;
