export const getRangeLabelString = (content, from, end, total, suffix = '') => {
  if (suffix !== '') {
    return `${content.DISPLAYING} ${from} - ${end} ${content.OF} ${total} ${suffix}`;
  }
  return `${content.DISPLAYING} ${from} - ${end} ${content.OF} ${total} ${content.JOBS}`;
};

export const getTableRangeLabel = (content, totalJobs, currentJobsCount, currentPageJobs, perPageLimit, suffix = '') => {
  const activeJobsFrom = (currentPageJobs * perPageLimit) + 1;
  const activeJobsEnd = (currentPageJobs * perPageLimit) + currentJobsCount;

  return getRangeLabelString(content, activeJobsFrom, activeJobsEnd, totalJobs, suffix);
};
