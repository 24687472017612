import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { getPortalContent } from '../../redux/reducers/languageReducer';
import styles from './styles.module.scss';
import emptyStateImg from '../../images/emptyStateImg.svg';

function EmptyFunnel({ openAddJobModal }) {
  const portalContent = useSelector(getPortalContent);
  return (
    <div className={classNames('centerAlignContent', styles.emptyJobsState)}>
      <img src={emptyStateImg} alt="No Jobs" />
      <p className="subtitle-2">{portalContent.JOB_FUNNEL_EMPTY_JOBS_MSG}</p>
      <button
        className="button-primary-with-icon button-text text-nowrap"
        color="warning"
        type="button"
        onClick={openAddJobModal}
      >
        {portalContent.POST_A_JOB}
      </button>
    </div>
  );
}

export default EmptyFunnel;
