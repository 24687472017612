import React, { useState } from 'react';
import styles from './styles.module.scss';

function ExperienceSummary({ item }) {
  const [isFullSummary, setIsFullSummary] = useState(false);

  const summaryDefaultLength = 150;
  const toggleSummary = () => {
    setIsFullSummary(!isFullSummary);
  };

  const summaryToShow = isFullSummary
    ? item.summary
    : item.summary.slice(0, summaryDefaultLength);

  return (
    <div className={styles.frame18}>
      <div className={styles.textWrapper65}>{item.position}</div>
      <div className={styles.frame19}>
        <div className={styles.textWrapper66}>
          {item.startDate}
          {' '}
          -
          {item.endDate}
        </div>
      </div>

      {item.company && (
      <div className={styles.textWrapper66}>
        {(item.company ? ` , ${item.company}` : '') + item.location}
      </div>
      )}

      <div className={styles.textWrapper66}>{summaryToShow}</div>
      {item?.summary?.length > summaryDefaultLength && (
        <span style={{ cursor: 'pointer' }} onClick={toggleSummary}>
          {isFullSummary ? 'Read Less' : 'Read More'}
        </span>
      )}
    </div>
  );
}

export default ExperienceSummary;
