import React from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import ReactQuill from 'react-quill';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-quill/dist/quill.snow.css';
import './richTextStyle.css';
import styles from './styles.module.scss';

function RichTextWithTitle({
  title,
  layoutStyle,
  inputValue,
  onChangeInput,
  inputStyle,
}) {
  return (
    <div className={styles.inputLayout} style={layoutStyle} data-color-mode="light">
      <div className="body-2-no-color">{title}</div>
      <ReactQuill
        className={styles.inputBoxLayout}
        style={inputStyle}
        value={inputValue}
        onChange={(value) => onChangeInput(value)}
        name="inputBox"
      />
    </div>
  );
}

export default RichTextWithTitle;
