import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Helper from '../../../services/helper';
import {
  requestReport,
  requestReportReset,
} from '../../../redux/actions/action';

import styles from './styles.module.scss';
import Loader from '../../../images/loadingWithoutBG.gif';

function ReportCard(props) {
  const {
    title,
    desc,
    imgSrc,
    buttonTitle,
    indexKey,
    setShowSuccessModal,
    requestReportAction,
    isRequestReportSuccess,
    requestReportData,
    isRequestReportError,
    requestReportResetAction,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const clientId = Helper.getItem('clientId');

  /* for loader for work properly */

  useEffect(() => {
    requestReportResetAction();

    return () => {
      requestReportResetAction();
    };
  }, []);

  useEffect(() => {
    if (Object.keys(requestReportData).length > 0) {
      setIsLoading(false);
      setShowSuccessModal(true);
    }
  }, [isRequestReportSuccess]);

  useEffect(() => {
    if (isRequestReportError) {
      setIsLoading(false);
      setIsError(true);
    }
    if (isError) {
      toast.error('Something went wrong');
    }
  }, [isRequestReportError, isError]);

  /* for loader for work properly */

  const reqReport = async () => {
    const body = {
      clientId,
      reportName: title,
    };

    setIsLoading(true);
    requestReportAction(body);
  };

  return (
    <div key={indexKey} className={styles.cardContainer}>
      <img height="64px" width="64px" src={imgSrc} alt={title} />
      <div className={styles.titleDescContainer}>
        <div className="subtitle-3">{title}</div>
        <div className="body-3">{desc}</div>
      </div>
      <button onClick={reqReport} type="button" className="button-text    button-primary">
        {isLoading ? (
          <img width="40px" height="40px" src={Loader} alt="loading" />
        ) : (
          buttonTitle
        )}
      </button>
    </div>
  );
}

const mapStateToProps = (state) => ({
  content: state.languageReducer.portalContent,
  languageCode: state.languageReducer.languageCode,
  isRequestReportSuccess: state.reportReducer.isRequestReportSuccess,
  isRequestReportError: state.reportReducer.isRequestReportError,
  requestReportData: state.reportReducer.requestReportData,
});

const mapDispatchToProps = (dispatch) => ({
  requestReportAction: (data) => dispatch(requestReport(data)),
  requestReportResetAction: () => dispatch(requestReportReset()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ReportCard));
