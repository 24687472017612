import Helper from '../services/helper';
import { AWS_ENDPOINT } from '../services/constants';
import axiosInstance from '../services/axiosConfig';
import { SUBMIT_PLATFORM_FEEDBACK } from '../services/urls';

class EmailerApi {
  static requestServicePackage = async (request) => axiosInstance.post(
    `${AWS_ENDPOINT}/serviceReq`,
    request.params,
    {
      headers: Helper.authHeader(),
    },
  );

  static requestReport = async (request) => axiosInstance.post(
    `${AWS_ENDPOINT}/serviceReq`,
    request.response,
    {
      headers: Helper.authHeader(),
    },
  );

  static UploadFeedbackAttachment = async (formData, uploadConfig) => axiosInstance.post(
    `${AWS_ENDPOINT}/attachments/others`,
    formData,
    {
      params: { attachmentType: 'feedbacks/platform' },
      ...uploadConfig,
      headers: Helper.authHeader(),
    },
  );

  static SubmitFeedback = async (payload) => axiosInstance.post(
    `${SUBMIT_PLATFORM_FEEDBACK}`,
    payload,
    {
      headers: Helper.authHeader(),
    },
  );

  static DeleteFeedbackAttachment = async (payload) => axiosInstance.delete(`${AWS_ENDPOINT}/attachments/others`, {
    data: payload,
    headers: Helper.authHeader(),
  });
}

export default EmailerApi;
