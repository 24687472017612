/* eslint-disable max-len */
import { takeLatest, put, call } from 'redux-saga/effects';
import { GET_JOB_DETAILS_REQUEST, CHANGE_JOB_STATUS_REQUEST } from '../actions/actionTypes';
import {
  getJobDetailsSuccess, getJobDetailsError, changeJobStatusRequestSuccess, changeJobStatusRequestError,
} from '../actions/action';
import JobDetailsApi from '../../apis/jobDetailsApi';

function* getJobDetails(params) {
  try {
    const response = yield call(JobDetailsApi.GetJobDetails, params);

    const data = yield response.data;

    // console.log("data: " + JSON.stringify(data));

    if (data.payload) yield put(getJobDetailsSuccess(data.payload));
    else yield put(getJobDetailsError(data));
  } catch (error) {
    yield put(getJobDetailsError(error.message));
  }
}

function* changeJobStatus(params) {
  try {
    const response = yield call(JobDetailsApi.changeJobStatus, params);
    const data = yield response.data;

    if (data.status === 200) yield put(changeJobStatusRequestSuccess(data.payload));
    else yield put(changeJobStatusRequestError(data.payload));
  } catch (error) {
    yield put(changeJobStatusRequestError(error.message));
  }
}

function* jobDetailsSaga() {
  yield takeLatest(GET_JOB_DETAILS_REQUEST, getJobDetails);
  yield takeLatest(CHANGE_JOB_STATUS_REQUEST, changeJobStatus);
}
export default jobDetailsSaga;
