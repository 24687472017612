import content from '../languages/en.json';
import { PATH } from '../services/urls';

export const IN_ACTIVE_JOB_IDS = 'JOBSTAGE:TERN:1220,JOBSTAGE:TERN:1230,JOBSTAGE:TERN:1240,JOBSTAGE:TERN:1250,JOBSTAGE:TERN:1260,JOBSTAGE:TERN:1270';
export const ACTIVE_JOB_IDS = 'JOBSTAGE:TERN:1210';
export const SUBMIT_TO_CLIENT = 'APPL_SUBSTAGE:TERN:910';
export const IN_ACTIVE_JOB_PAGE_LIMIT = 10;
export const ACTIVE_JOB_PAGE_LIMIT = 5;
export const IN_PROGRESS = 'In-progress';
export const ALL_CANDIDATE_PAGE_DEFAULT_ITEM_COUNT = 10;
export const DOCTOR = 'Doctor';
export const ALL_CANDIDATE_DEFAULT_PAGE_NO = 0; // indexing 0 based
export const PROFILE_TYPE = {
  ADD_CANDIDATE: 'addCandidate',
};
export const CURRENT_JOB_ID = 'currJobId';

export const PHONE = 'phone';
export const EMAIL = 'email';
export const RETRY = 'retry';
export const OTP_SENT = 'OTP sent!';
export const DEFAULT_COUNTRY_CODE = '+91';

/* job-details */
export const details = [
  { key: 'location', label: content.LOCATION },
  { key: 'numberOfOpenings', label: content.NUMBER_OF_OPENINGS },
  { key: 'salary', label: content.SALARY_RANGE },
  { key: 'jobReferenceNumber', label: content.JOB_REFERENCE_NUMBER },
  { key: 'targetDate', label: content.TARGET_DATE },
  { key: 'yearsOfExperience', label: content.YEARS_OF_EXPERIENCE },
  { key: 'englishLevel', label: content.ENGLISH_LEVEL },
  { key: 'band', label: content.BAND },
  { key: 'grade', label: content.GRADE },
  { key: 'employmentType', label: content.EMPLOYMENT_TYPE },
  { key: 'role', label: content.ROLE_CATEGORY },
  { key: 'workingPattern', label: content.WORKING_PATTERN },
  { key: 'sponsorshipProvided', label: content.SPONSORSHIP_PROVIDED },
  { key: 'addedOn', label: content.ADDED_ON },
  { key: 'specialisation', label: content.SPECIALISATION },
];
export const additionalJobInfo = [
  { key: 'summary', label: content.JOB_SUMMARY },
  { key: 'jobDescription', label: content.JOB_DESCRIPTION },
  { key: 'requiredSkills', label: content.EXPERTISE_REQUIRED },
  { key: 'essentialQualification', label: content.ESSENTIAL_QUALIFICATION },
  { key: 'desirableQualification', label: content.DESIRABLE_QUALIFICATION },
  { key: 'essentialExperience', label: content.ESSENTIAL_EXPERIENCE },
  { key: 'desirableExperience', label: content.DESIRABLE_EXPERIENCE },
];
export const jobStages = [
  { value: 'JOBSTAGE:TERN:1210', label: 'In progress' },
  { value: 'JOBSTAGE:TERN:1220', label: 'Waiting for Approval' },
  { value: 'JOBSTAGE:TERN:1230', label: 'On Hold' },
  { value: 'JOBSTAGE:TERN:1240', label: 'Filled' },
  { value: 'JOBSTAGE:TERN:1250', label: 'Cancelled' },
  { value: 'JOBSTAGE:TERN:1260', label: 'Declined' },
];
export const jobStatusMapping = {
  'In-progress': 'JOBSTAGE:TERN:1210',
  'Waiting for Approval': 'JOBSTAGE:TERN:1220',
  'On-Hold': 'JOBSTAGE:TERN:1230',
  Filled: 'JOBSTAGE:TERN:1240',
  Cancelled: 'JOBSTAGE:TERN:1250',
  Declined: 'JOBSTAGE:TERN:1260',
};
export const stageIdsMapping = {
  0: 'APPLSTAGE:TERN:100',
  1: 'APPLSTAGE:TERN:200',
  2: 'APPLSTAGE:TERN:300',
  3: 'APPLSTAGE:TERN:400',
  4: 'APPLSTAGE:TERN:500',
  5: 'APPLSTAGE:TERN:600',
};

export const HTTP_METHOD = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
};
export const acceptableFileFormat = ['pdf', 'doc', 'docx'];

export const menuLinks = [
  {
    name: content.JOBS,
    path: PATH.ALL_JOBS,
  },
  {
    name: content.ALL_CANDIDATES_HEADING,
    path: PATH.ALL_CANDIDATES,
  },
  {
    name: content.REPORTS,
    path: PATH.REPORTS,
  },
  {
    name: content.SERVICES,
    path: PATH.SERVICES_OFFERED,
  },
];
