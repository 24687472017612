import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import downArrow from '../../images/dropdownArrow.png';
import { findOptionByValue } from './helper';

function Picklist({
  options, selectedValue, onSelect, dropdownStyle, isNestedOptions = false,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const foundOption = findOptionByValue(options, selectedValue, isNestedOptions);
    if (foundOption) {
      setSelectedOption(foundOption);
    }
  }, [selectedValue, options]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
  };

  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  const getArrowImg = (alt) => (
    <img src={downArrow} className={styles.arrow} alt={alt} />
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const renderOptions = () => {
    if (!isNestedOptions) {
      return (
        <div className={options?.length ? styles.dropdownList : ''}>
          {options?.map((option) => (
            <div
              key={option.value}
              className={classNames(
                styles.dropdownItem,
                {
                  [styles.selectedItem]: selectedOption
         && selectedOption.value === option.value,
                },
              )}
              onClick={() => handleOptionSelect(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      );
    }

    return (
      <div className={options ? styles.dropdownList : ''}>
        {options?.map((nestedOption) => (
          <React.Fragment key={nestedOption.value}>
            <span>{nestedOption.label}</span>
            {nestedOption.options.map((option) => (
              <div
                key={option.value}
                className={classNames(styles.dropdownItem, {
                  [styles.selectedItem]:
                    selectedOption && selectedOption.value === option.value,
                })}
                onClick={() => handleOptionSelect(option)}
              >
                {option.label}
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.dropdown} ref={dropdownRef}>
      <div
        className={classNames(
          dropdownStyle,
          styles.dropdownHeader,
          'ellipseStyle',
          'body-2-no-important',
        )}
        onClick={toggleDropdown}
      >
        <div className={styles.textContainer}>
          {selectedOption ? selectedOption.label : 'Select an option'}
        </div>
        {isOpen && options?.length ? (
          <div className={`${styles.arrowContainerUp} ${styles.rotateUp}`}>
            {getArrowImg('Up Arrow')}
          </div>
        ) : (
          <div className={styles.arrowContainer}>
            {getArrowImg('Down Arrow')}
          </div>
        )}
      </div>
      {isOpen && renderOptions()}
    </div>
  );
}

export default Picklist;
