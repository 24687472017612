import { all } from 'redux-saga/effects';
import languageSaga from './languageSaga';
import authSaga from './authSaga';
import dashboardSaga from './dashboardSaga';
import candidateSaga from './candidateSaga';
import jobsSaga from './jobsSaga';
import jobDetailsSaga from './jobdetailsSaga';
import allJobsSaga from './allJobsSaga';
import addCandidatesSaga from './addCandidatesSaga';
import emailerSaga from './emailerSaga';
import contactsSaga from './contactsSaga';
import allCandidatesSaga from './allCandidatesSaga';

export default function* rootSaga() {
  yield all([
    languageSaga(),
    authSaga(),
    dashboardSaga(),
    candidateSaga(),
    jobsSaga(),
    jobDetailsSaga(),
    allJobsSaga(),
    addCandidatesSaga(),
    emailerSaga(),
    contactsSaga(),
    allCandidatesSaga(),
  ]);
}
