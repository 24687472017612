/* eslint-disable */
import Helper from "../services/helper";

import { AWS_ENDPOINT } from "../services/constants";
import axiosInstance from "../services/axiosConfig";

class ContactApi {
    static GetContacts = async (request) => {
        return await axiosInstance.get(`${AWS_ENDPOINT}/listContacts`, {
            headers: Helper.authHeader(),
            params: request.params,
        });
    };

    static CreateContact = async (request) => {
        return await axiosInstance.post(
            `${AWS_ENDPOINT}/createContact`,
            request.params,
            {
                headers: Helper.authHeader(),
            },
        );
    };
}

export default ContactApi;
