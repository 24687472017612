/* eslint-disable */
import { LIST_POTENTIAL_APPLICANTS_REQUEST, LIST_POTENTIAL_APPLICANTS_REQUEST_SUCCESS, LIST_POTENTIAL_APPLICANTS_REQUEST_ERROR, DISASSOCIATE_APPLICANT_REQUEST,DISASSOCIATE_APPLICANT_SUCCESS,DISASSOCIATE_APPLICANT_ERROR } from '../actions/actionTypes';

const initialState = {
    isListPotentialApplicantsSuccess: false,
    isListPotentialApplicantsError: false,
    isListPotentialApplicantsData: {},
    isDisassociateApplicantSuccess : false,
    isDisassociateApplicantError : false,
    isDisassociateApplicantData : {},
    message: ''
}

export function addCandidatesReducer(state = initialState, { type, response }) {
    switch (type) {
        case LIST_POTENTIAL_APPLICANTS_REQUEST:
            return {
                ...state,
                isListPotentialApplicantsSuccess: false,
                isListPotentialApplicantsError: false,
                isListPotentialApplicantsData: {}
            };
        case LIST_POTENTIAL_APPLICANTS_REQUEST_SUCCESS:
            return {
                ...state,
                isListPotentialApplicantsSuccess: true,
                isListPotentialApplicantsError: false,
                isListPotentialApplicantsData: response
            };
        case LIST_POTENTIAL_APPLICANTS_REQUEST_ERROR:
            return {
                ...state,
                isListPotentialApplicantsSuccess: false,
                isListPotentialApplicantsError: true,
                isListPotentialApplicantsData: response
            };
        case DISASSOCIATE_APPLICANT_REQUEST:
            return {
                ...state,
                isDisassociateApplicantSuccess: false,
                isDisassociateApplicantError: false,
                isDisassociateApplicantData: {}
            };
        case DISASSOCIATE_APPLICANT_SUCCESS:
            return {
                ...state,
                isDisassociateApplicantSuccess: true,
                isDisassociateApplicantError: false,
                isDisassociateApplicantData: response
            };
        case DISASSOCIATE_APPLICANT_ERROR:
            return {
                ...state,
                isDisassociateApplicantSuccess: false,
                isDisassociateApplicantError: true,
                isDisassociateApplicantData: response
            };
        default:
            return state;
    }
}