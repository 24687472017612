/* eslint-disable */
import "react-toastify/dist/ReactToastify.css";
import React, { useState, useEffect, useRef } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Card, Row, Col } from "reactstrap";
import {  toast } from "react-toastify";
import { Tabs, Tab } from "react-bootstrap";
import { Select } from "antd";
import Profile from "../profile";
import styles from "./styles.module.scss";
import ReactTable from "../../components/reactTable";
import PaginatedItems from "../../components/pagination";
import Helper from "../../services/helper";
import Picklist from "../../components/picklist";
import Overlay from "../../components/overlay";
import PostJobModal from "../../modals/postJobModal";
import SuccessModal from "../../modals/successModal";
import { AGENCY_CLIENTS } from "../../services/constants";
import loading from "../../images/loading.gif";
import userImg from "../../images/user.png";
import {
  stageChangeOptions,
  mappedApplicationStages,
  subStageTabOptions,
} from "../../data/jobStages";
import {
  getJobFunnelRequest,
  changeApplicationStageRequest,
  getCandidateProfileRequest,
  scheduleCandidateInterviewRequest,
  getAllJobsRequest,
  getJobStatsRequest,
  listInterviewRequest,
  fetchInterviewDetailsRequest,
  getContactsRequest,
  createContactRequest,
  deleteInterviewRequest,
  cancelInterviewRequest,
} from "../../redux/actions/action";
import { DOCTOR } from "../../utils/constants";
import EmptyFunnel from "./emptyFunnel";
import Employers from "./employers";
import AgencyJobs from "./agencyJobs";
import JobDetails from "./jobDetails";
import { getQueryParams, addQueryParams } from "../../utils/helpers";
import { CLIENTID } from '../../services/constants'

const customSelectStyle = {
  width: 150,
  border: "#006d5b solid 1px",
  borderRadius: "30px",
  backgroundColor: "transparent",
  color: "#006d5b",
};
const customDropdownStyle = {
  minWidth: "300px",
  maxWidth: "500px",
};

const PLACE_HOLDER_IMG = userImg

function Funnel(props) {
  const { 
    isJobFunnelData,
    jobStatsData, 
    isJobFunnelError, 
    content, 
    isChangeApplicationStageSuccess,
    isChangeApplicationStageError,
    changeApplicationStageRequest
  } = props;
  const history = useHistory();
  const prevProps = useRef(props);

  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedTab, setSelectedTab] = useState("APPLSTAGE:TERN:100");
  const [selectedSubStageTab, setSelectedSubStageTab] = useState("");
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [candidateIds, setCandidateIds] = useState(null);
  const [candidateProfileId, setCandidateProfileId] = useState(null);
  const [candidateSubStageId, setCandidateSubStageId] = useState(null);
  const [stageCounts, setStageCounts] = useState({});
  const [subStageCounts, setSubStageCounts] = useState({});
  const [isTabChanged, setIsTabChanged] = useState(false);
  const [jobOpeningCode, setJobOpeningCode] = useState(null);
  const [currJobOpeningId, setCurrJobOpeningId] = useState(null);
  const [isStageChanged, setIsStageChanged] = useState(false);
  const [currJobTitle, setCurrJobTitle] = useState(null);
  const [jobFilterList, setJobFilterList] = useState([]);
  const [currClientId, setCurrClientId] = useState(null);
  const [candidatesStage, setCandidateStage] = useState({});
  const [agencySubClientId, setAgencySubClientId] = useState();
  const [isNewSubClientSelected, setIsNewSubClientSelected] = useState(false);
  const [jobDetailsData, setJobDetailsData] = useState({
    jobCode: "",
    jobRoleCategory: "",
    jobId: "",
    jobtitle: "",
    specialisation: [],
  });
  const [epochTime, setEpochTime] = useState(null);
  const [showAddJobModal, setShowAddJobModal] = useState(false);
  const [showAddJobSuccessModal, setShowAddJobSuccessModal] = useState(false);
  const [selectStageLoader, setSelectStageLoader] = useState(false);
  const [isImgLoaded, setIsImgLoaded] = useState(false);

  const stageCountsData = props?.jobStatsData?.applicationStages ?? [];
  
  const {
    shortlistedData,
    jobCode,
    currJobId,
    jobRoleCategory,
    jobTitle,
  } = props?.location?.state?.storedState ?? {};
  
  let queryParams
  // backward compatible with stored states
  if (shortlistedData || jobCode || currJobId || jobRoleCategory || jobTitle) {
    queryParams = {
      shortlistedData,
      jobCode,
      currJobId,
      jobRoleCategory,
      jobTitle,
    }
    addQueryParams(queryParams) 
  } else {
    queryParams = getQueryParams() 
  }
  
  const defaultSubStage = subStageTabOptions[queryParams.shortlistedData]?.id;
  
  const handleImageLoad = () => {
    setIsImgLoaded(true);
  };

  // to run only once and also avoiding infinite re-render
  useEffect(() => {
    setCurrJobOpeningId(queryParams?.currJobId)
    setCurrJobTitle(isJobFunnelData?.title)

    if (jobStatsData?.jobs) {
      setAgencySubClientId(jobStatsData?.jobs[0].clientId)
    }
    // use default if not present
    if (queryParams.subStage) {
      setSelectedSubStageTab(queryParams.subStage)
    }
    else {
      setSelectedSubStageTab(defaultSubStage)
    }
  }, [isJobFunnelData, jobStatsData])

  const clientsListFormatted = JSON.parse(
    Helper.getItem(AGENCY_CLIENTS),
  )?.clientsList?.map((client) => {
    return { value: client.id, label: client.name };
  });
  // Function to open the Profile modal
  const openProfileModal = (candidateId, currSubStageId) => {
    let candidateIdList = [];
    let index = slicedData.findIndex(
      (item) => item.candidateId === candidateId,
    );
    if (index !== -1) {
      let reducedArray = slicedData.slice(index);
      reducedArray.map((data) => {
        candidateIdList.push({ id: data.candidateId });
      });
    } else {
      candidateIdList.push({ id: candidateId });
    }
    setCandidateIds(candidateIdList);
    setCandidateProfileId(candidateId);
    setCandidateSubStageId(currSubStageId);
    setShowProfileModal(true);
  };

  const openAddJobModal = () => {
    setEpochTime(Date.now());
    setShowAddJobModal(true);
  };

  const closeAddJobModal = () => {
    setShowAddJobModal(false);
  };

  const closeAddJobSuccessModal = () => {
    setShowAddJobSuccessModal(false);
  };

  const addJob = (event) => {
    setShowAddJobModal(false);
    setShowAddJobSuccessModal(true);
  };
 


        async function fetchData() {
            let clientId = Helper.getItem("clientId");
            if (!clientId) {
                Helper.clearStorage();
                Helper.lastVisitedRoute();
                history.push("/");
                return;
            }
            setCurrClientId(clientId);

            const {shortlistedData, jobCode, currJobId} =
            queryParams;

            setStageCounts(stageCountsData);
            setJobOpeningCode(jobCode);
            setSelectedTab(shortlistedData);
            setAgencySubClientId(agencySubClientId);
            const {
                getJobFunnelRequest,
                getAllJobsRequest,
                getJobStatsRequest,
            } = props;
            const getJobFunnelObject = {
                jobOpeningId : currJobId,
                pageNo : 1,
                pageLimit : 50,
                clientId : clientsListFormatted?.length>0 && agencySubClientId ? agencySubClientId : clientId,
                stageId : shortlistedData,
                jobOpeningCode : jobCode
            };
            await getJobFunnelRequest(getJobFunnelObject);

            const getAllJobsObject = {
                pageLimit : 10,
                clientId : clientsListFormatted?.length>0 && agencySubClientId ? agencySubClientId : clientId,
                jobStatusId : "JOBSTAGE:TERN:1210"
            };
            await getAllJobsRequest(getAllJobsObject);

            const jobStatsObj = {
                jobOpeningId : currJobId,
                clientId : clientsListFormatted?.length>0 && agencySubClientId ? agencySubClientId : clientId,
                jobOpeningCode : jobCode
            };
            await getJobStatsRequest(jobStatsObj);
        }

  // Function to close the Profile modal
  const closeProfileModal = () => {
    setCandidateIds(null);
    setCandidateProfileId(null);
    setCandidateSubStageId(null);
    setShowProfileModal(false);
  };

  const notification = (messages) => {
    toast.success(messages, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
    });
  };

  useEffect(() => {
    if (props?.jobStatsData?.jobs) {
      setJobDetailsData({
        ...jobDetailsData,
        jobCode: props?.jobStatsData?.jobs[0]?.jobCode,
        jobRoleCategory: props?.jobStatsData?.jobs[0]?.jobRoleCategory,
        jobId: props?.jobStatsData?.jobs[0]?.jobId,
        jobtitle: props?.jobStatsData?.jobs[0]?.title,
        specialisation: props?.jobStatsData?.jobs[0]?.jobRoleSpecialisation,
      });
    }
  }, [props?.jobStatsData?.jobs]);

  const itemsPerPage = 50;
  useEffect(() => {
    async function fetchData() {
      let clientId = Helper.getItem("clientId");
      if (!clientId) {
        Helper.clearStorage();
        Helper.previousPathLocation();
        history.push("/");
        return;
      }
      setCurrClientId(clientId);

      const {
        shortlistedData,
        jobCode,
        currJobId,
        jobRoleCategory,
      } = queryParams;

      setStageCounts(stageCountsData);
      setJobOpeningCode(jobCode);
      setSelectedTab(shortlistedData);
      setAgencySubClientId(agencySubClientId);
      const { getJobFunnelRequest, getAllJobsRequest, getJobStatsRequest } =
        props;
      const getJobFunnelObject = {
        jobOpeningId: currJobId,
        pageNo: 1,
        pageLimit: 50,
        clientId:
          clientsListFormatted?.length > 0 && agencySubClientId
            ? agencySubClientId
            : clientId,
        stageId: shortlistedData,
        jobOpeningCode: jobCode,
      };
      await getJobFunnelRequest(getJobFunnelObject);

      const getAllJobsObject = {
        pageLimit: 10,
        clientId:
          clientsListFormatted?.length > 0 && agencySubClientId
            ? agencySubClientId
            : clientId,
        jobStatusId: "JOBSTAGE:TERN:1210",
      };
      await getAllJobsRequest(getAllJobsObject);

      const jobStatsObj = {
        jobOpeningId: currJobId,
        clientId:
          clientsListFormatted?.length > 0 && agencySubClientId
            ? agencySubClientId
            : clientId,
        jobOpeningCode: jobCode,
      };
      await getJobStatsRequest(jobStatsObj);
    }

    fetchData();
  }, []);

  useEffect(() => {
    const { isJobFunnelSuccess: prevIsJobFunnelSuccess } = prevProps.current;
    const { isJobFunnelSuccess, isJobFunnelData } = props;
    const storedState = props?.location?.state?.storedState;
    if (prevIsJobFunnelSuccess === false && isJobFunnelSuccess) {
      if (storedState && !isTabChanged && !isStageChanged) {
        const { shortlistedData } = storedState;
        filterCandidates(isJobFunnelData?.candidates);

        const defaultSubTab = subStageTabOptions[shortlistedData][0].id;
        setSelectedSubStageTab(defaultSubTab);
        const filteredCandidates = isJobFunnelData?.candidates.filter(
          (candidate) =>
            candidate.subStageId === defaultSubTab &&
            candidate.stageId === shortlistedData,
        );
        setSelectedTab(shortlistedData);
        setFilteredData(filteredCandidates);
        setIsLoading(false);
        setCurrJobTitle(isJobFunnelData?.title);
      } else {
        setIsLoading(false);
        setIsStageChanged(false);
        setIsTabChanged(false);

        if (selectedTab !== isJobFunnelData?.stageId) {
          return;
        }

        filterCandidates(isJobFunnelData?.candidates);
      }
    }

    prevProps.current = props;
  }, [props.isJobFunnelSuccess, props.isJobFunnelError]);

  useEffect(() => {
    const {
      isChangeApplicationStageSuccess: prevIsChangeApplicationStageSuccess,
    } = prevProps.current;
    const { isChangeApplicationStageSuccess } = props;
    if (
      prevIsChangeApplicationStageSuccess === false &&
      isChangeApplicationStageSuccess
    ) {
      notification(content.STAGE_UPDATE_MESSAGE);
      setIsStageChanged(true);

      setSelectStageLoader(false);
    }

    prevProps.current = props;
  }, [
    props.isChangeApplicationStageSuccess,
    props.isChangeApplicationStageError,
  ]);

  useEffect(() => {
    const { isAllJobsSuccess: prevIsAllJobsSuccess } = prevProps.current;
    const { isAllJobsSuccess, isAllJobsData } = props;

    if (prevIsAllJobsSuccess === false && isAllJobsSuccess) {
      const jobs = isAllJobsData?.jobs;
      setJobFilterList(jobs);
      if (isNewSubClientSelected && jobs?.length > 0) {
        setCurrJobTitle(jobs[0]?.name);
        setCurrJobOpeningId(jobs[0]?.jobOpeningId);
        setJobOpeningCode(jobs[0]?.jobOpeningCode);
        const jobFunnelObj = {
          jobOpeningId: jobs[0]?.jobOpeningId,
          pageNo: 1,
          pageLimit: 50,
          clientId: agencySubClientId,
          stageId: selectedTab,
          jobOpeningCode: jobs[0]?.jobOpeningCode,
        };
        props.getJobFunnelRequest(jobFunnelObj);
        const jobStatsObj = {
          jobOpeningId: jobs[0]?.jobOpeningId,
          clientId: agencySubClientId,
          jobOpeningCode: jobs[0]?.jobOpeningCode,
        };
        props.getJobStatsRequest(jobStatsObj);
      }
    }

    prevProps.current = props;
  }, [props.isAllJobsSuccess, props.isAllJobsError]);

  useEffect(() => {
    const { isJobStatsSuccess: prevIsJobStatsSuccess } = prevProps.current;
    const { isJobStatsSuccess, jobStatsData } = props;

    if (prevIsJobStatsSuccess === false && isJobStatsSuccess) {
      const stageCountData = jobStatsData?.jobs[0]?.applicationStages;
      setStageCounts(stageCountData);
    }

    prevProps.current = props;
  }, [props.isJobStatsSuccess, props.isJobStatsError]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  let slicedData = filteredData?.slice(startIndex, endIndex);


  const filterCandidates = () => {
    const candidates = isJobFunnelData?.candidates;
    const currSubStageCounts = {};

    const candidateStages = {};
    candidates?.forEach(({ subStage, candidateId }) => {
      candidateStages[candidateId] = subStage;
    });

    setCandidateStage(candidateStages);

    candidates?.forEach((candidate) => {
      const subStage = candidate.subStageId;
      if (currSubStageCounts[subStage]) {
        currSubStageCounts[subStage]++;
      } else {
        currSubStageCounts[subStage] = 1;
      }
    });

    const filteredCandidates = isJobFunnelData?.candidates.filter(
      (candidate) =>
        candidate.subStageId === getSelectedSubStage() &&
        candidate.stageId === selectedTab,
    );

    setFilteredData(filteredCandidates);
    setSubStageCounts(currSubStageCounts);
  };

  const getSelectedSubStage = () => {
    return selectedSubStageTab || subStageTabOptions[selectedTab][0].id;
  };
  const handleTabChange = async (selectedTab) => {
    setIsLoading(true);
    setIsTabChanged(true);

    setSelectedTab(selectedTab);
    updateTabState(selectedTab);

    setSelectedSubStageTab(subStageTabOptions[selectedTab][0].id);

    try {
      const jobStatsObj = {};
      jobStatsObj.jobOpeningId = currJobOpeningId;
      jobStatsObj.clientId =
        clientsListFormatted?.length > 0 ? agencySubClientId : currClientId;
      jobStatsObj.jobOpeningCode = jobOpeningCode;

      await props.getJobStatsRequest(jobStatsObj);

      const { jobStatsData } = props;
      setStageCounts(jobStatsData?.jobs[0]?.applicationStages);

      const getJobFunnelObject = {
        jobOpeningId: currJobOpeningId,
        pageNo: 1,
        pageLimit: 50,
        clientId: currClientId,
        stageId: selectedTab,
        jobOpeningCode: jobOpeningCode,
      };

      await props.getJobFunnelRequest(getJobFunnelObject);

      setCurrentPage(0);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleSubTabChange = (selectedSubTab) => {
    setIsLoading(true);

    const parseTab = selectedSubTab;
    setSelectedSubStageTab(parseTab);

    const filteredCandidates = isJobFunnelData?.candidates?.filter(
      (candidate) => candidate.subStageId === parseTab,
    );

    setFilteredData(filteredCandidates);
    setIsLoading(false);
  };

  const getSubStageId = (subStageName) => {
    const foundEntry = Object.entries(mappedApplicationStages).find(
      ([_, value]) => value === subStageName,
    );
    return foundEntry[0];
  };

  const handleStageChange = async (selectedOption, data) => {
    setSelectStageLoader(true);

    const { changeApplicationStageRequest } = props;

    candidatesStage[data.candidateId] = selectedOption;

    setCandidateStage(candidatesStage);

    const changeApplicationStageObject = {
      candidateId: data.candidateId,
      jobOpeningId: isJobFunnelData?.jobOpeningId,
      jobOpeningCode: jobOpeningCode,
      stageId: "",
      applicationStageId: getSubStageId(selectedOption),
      applicationStageName: selectedOption,
      clientId: currClientId,
      isAssociated: true,
    };

    await changeApplicationStageRequest(changeApplicationStageObject);
  };

  const updateTabState = (currSelectedTab) => {
    const { jobCode, currJobId, jobTitle } =
    queryParams;
    const updatedState = {
      shortlistedData: currSelectedTab,
      stageCountsData,
      jobCode: jobCode,
      currJobId: currJobId,
      jobTitle: jobTitle,
      agencySubClientId,
    };
    props.history.push({
      state: {
        storedState: updatedState,
      },
    });
  };

  const getHrStyle = (selectedSubStageTab) => {
    switch (selectedSubStageTab) {
      case "Submissions":
        return styles.hrSubmissions;
      case "Interview":
        return styles.hrInterview;
      case "Offered":
        return styles.hrOffered;
      case "Hired":
        return styles.hrHired;
      case "VisaApplied":
        return styles.hrVisaApplied;
      case "Relocated":
        return styles.hrRelocated;
      default:
        return styles.hrTag;
    }
  };

  const handleJobListChange = async (selectedJob) => {
    const getJobFunnelObject = {};
    let selectedJobCode;
    jobFilterList?.forEach((job) => {
      if (job.jobOpeningId === selectedJob.value) {
        selectedJobCode = job.jobOpeningCode;
        setCurrJobTitle(job.name);
      }
    });
    const jobFilter = {
      shortlistedData: selectedTab,
      jobCode: selectedJobCode,
      currJobId: selectedJob.value,
    };
    localStorage.setItem("filterStateKey", JSON.stringify(jobFilter));
    setCurrJobOpeningId(selectedJob.value);
    setJobOpeningCode(selectedJobCode);
    getJobFunnelObject.jobOpeningId = selectedJob.value;
    getJobFunnelObject.pageNo = 1;
    getJobFunnelObject.pageLimit = 50;
    getJobFunnelObject.clientId =
      clientsListFormatted?.length > 0 ? agencySubClientId : currClientId;
    getJobFunnelObject.stageId = selectedTab;
    getJobFunnelObject.jobOpeningCode = selectedJobCode;
    await props.getJobFunnelRequest(getJobFunnelObject);

    const jobStatsObj = {};
    jobStatsObj.jobOpeningId = selectedJob.value;
    jobStatsObj.clientId =
      clientsListFormatted?.length > 0 ? agencySubClientId : currClientId;
    jobStatsObj.jobOpeningCode = selectedJobCode;

    await props.getJobStatsRequest(jobStatsObj);
    setIsLoading(true);
  };

  const handleClientListChange = async (selectedOption) => {
    const allJobsObj = {
      clientId: selectedOption.value,
      jobStatusId: "JOBSTAGE:TERN:1210",
      pageLimit: 10,
    };
    setIsLoading(true);
    setAgencySubClientId(selectedOption.value);
    setIsNewSubClientSelected(true);
    try {
      setCurrJobTitle(null);
      await props.getAllJobsRequest(allJobsObj);
    } catch (err) {
      Helper.notification("Something went wrong", true);
    }
  };

  const renderJobFunnelData = () => {
    if (isJobFunnelError) {
      return (
        <Row className={styles.loaderRow}>{content.SOMETHING_WENT_WRONG}</Row>
      );
    }

    const render_IELTS_RCE_Header = () => {
      if (jobDetailsData?.jobRoleCategory === DOCTOR) {
        return content.RCE_GIVEN;
      }

      return content.IELTS_SCORE;
    };

    const render_IELTS_RCE_Info = ({ row }) => {
      if (row?.original?.jobRoleCategory === DOCTOR) {
        return <div>{row?.original?.registration?.royalCollegeExamGiven}</div>;
      }

      return <div>{row?.original?.ieltsScore}</div>;
    };

    const renderSelectStageDropdown = ({ row }) => {
      return selectStageLoader ? (
        <div className={styles.loaderPlaceholder}>
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      ) : (
        // we've added some custom button actions
        <div className="ant-select-selector">
          <Select
            bordered={false}
            colortext="#006d5b"
            style={customSelectStyle}
            dropdownStyle={customDropdownStyle}
            value={candidatesStage[row.original.candidateId]}
            onChange={(selectedOption) =>
              handleStageChange(selectedOption, row.original)
            }
            options={stageChangeOptions || []}
          />
        </div>
      );
    };

    const result = !isLoading ? (
      <ReactTable
        data={slicedData && slicedData.length > 0 ? slicedData : []}
        columns={[
          {
            disableSortBy: true,
            isSortingEnable: false,
            Header: () => (
              <div className={styles.candidateName}>
                {content.CANDIDATE_NAME}
              </div>
            ),
            accessor: "name",
            Cell: ({ row }) => {
              return (
                <div
                  className={styles.profileModelName}
                  onClick={() =>
                    openProfileModal(
                      row.original.candidateId,
                      row.original.subStageId,
                    )
                  }
                >
                  <div className={styles.icon}>
                    {!isImgLoaded && (
                      <img
                        className={styles.img}
                        src={PLACE_HOLDER_IMG}
                        alt="Placeholder"
                      />
                    )}
                    <img
                      className={styles.img}
                      alt="Element tif"
                      src={
                        row.original.profilePic
                          ? row.original.profilePic
                          : userImg
                      }
                      onLoad={handleImageLoad}
                      onError={(e) => e.target.src = userImg}
                    />
                  </div>
                  <div className={styles.tableName}>{row.original.name}</div>
                </div>
              );
            },
          },
          {
            Header: content.TERN_SCORE,
            accessor: "ternScore",
          },
          {
            Header: content.AVAILABLE_IN_MONTHS,
            accessor: "availableIn",
          },
          {
            Header: render_IELTS_RCE_Header,
            accessor: "ieltsScore",
            Cell: render_IELTS_RCE_Info,
          },
          {
            Header: content.EXP_YEARS,
            accessor: "experience",
          },
          {
            Header: content.SELECT_STAGE,
            accessor: "actions",
            sortable: false,
            filterable: false,
            Cell: renderSelectStageDropdown,
          },
        ]}
        emptyDataMsg={content.EMPTY_DATA_MESSAGE_FOR_JOB_FUNNEL}
      />
    ) : (
      <Row className={styles.loaderRow}>
        <img src={loading} className={styles.loader} alt="loader" />
      </Row>
    );

    return result;
  };

  const renderJobData = () => {
    return props.isAllJobsSuccess && props.isAllJobsData?.jobs?.length > 0 ? (
      <>
        <Row className={styles.filterTabRow}>
          <Col>
            <Tabs
              className={styles.allTabs}
              activeKey={selectedTab}
              onSelect={handleTabChange}
            >
              <Tab
                eventKey={"APPLSTAGE:TERN:100"}
                title={`${content.SUBMISSIONS} (${
                  stageCounts && stageCounts[0] ? stageCounts[0].count : 0
                })`}
              ></Tab>
              <Tab
                eventKey={"APPLSTAGE:TERN:200"}
                title={`${content.INTERVIEW} (${
                  stageCounts && stageCounts[1] ? stageCounts[1].count : 0
                })`}
              ></Tab>
              <Tab
                eventKey={"APPLSTAGE:TERN:300"}
                title={`${content.OFFERED} (${
                  stageCounts && stageCounts[2] ? stageCounts[2].count : 0
                })`}
              ></Tab>
              <Tab
                eventKey={"APPLSTAGE:TERN:400"}
                title={`${content.HIRED} (${
                  stageCounts && stageCounts[3] ? stageCounts[3].count : 0
                })`}
              ></Tab>
              <Tab
                eventKey={"APPLSTAGE:TERN:500"}
                title={`${content.VISA_APPLIED} (${
                  stageCounts && stageCounts[4] ? stageCounts[4].count : 0
                })`}
              ></Tab>
              <Tab
                eventKey={"APPLSTAGE:TERN:600"}
                title={`${content.RELOCATED} (${
                  stageCounts && stageCounts[5] ? stageCounts[5].count : 0
                })`}
              ></Tab>
            </Tabs>
          </Col>
        </Row>
        <Card>
          <Tabs
            className={styles.qualifyTabs}
            activeKey={selectedSubStageTab}
            onSelect={(eventKey) => handleSubTabChange(eventKey)}
          >
            {subStageTabOptions[selectedTab]?.map((subStage, index) => {
              return (
                <Tab
                  key={index}
                  eventKey={subStage.id}
                  title={`${subStage.value} (${
                    subStageCounts[subStage.id]
                      ? subStageCounts[subStage.id]
                      : 0
                  })`}
                ></Tab>
              );
            })}
          </Tabs>
          <hr
            className={`${styles.hrTag} ${getHrStyle(
              mappedApplicationStages[selectedTab],
            )}`}
          />
          {renderJobFunnelData()}
        </Card>
        {!navigator.userAgent.match(/Android/i)
          ? filteredData?.length > 0 && (
              <PaginatedItems
                currentPage={currentPage}
                totalItems={filteredData?.length}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
              />
            )
          : null}
        <div className={styles.paginationText}>
          {content.DISPLAYING} {slicedData?.length} {content.OF}{" "}
          {filteredData?.length} {content.CANDIDATES}
        </div>
      </>
    ) : (
      <Row className={styles.loaderRow}>
        <img src={loading} className={styles.loader} alt="loader" />
      </Row>
    );
  };

  return (
    <>
      <div className={styles.content}>
        {!!clientsListFormatted?.length && (
          <Employers
            clients={clientsListFormatted}
            agencySubClientId={agencySubClientId}
            handleClientListChange={handleClientListChange}
          />
        )}
        {props.isAllJobsSuccess && !!props.isAllJobsData?.jobs?.length ? (
          <>
            <Row className={styles.jobs}>
              <Col lg="4" className={styles.mainSelectColumn}>
                <AgencyJobs
                  currJobTitle={currJobTitle}
                  filteredJobs={jobFilterList}
                  currJobOpeningId={currJobOpeningId}
                  handleJobListChange={handleJobListChange}
                />
              </Col>
              <Col lg="8" className={styles.viewJobColumn}>
                <JobDetails
                  currJobTitle={currJobTitle}
                  currJobOpeningId={currJobOpeningId}
                  jobOpeningCode={jobOpeningCode}
                  jobDetailsData={jobDetailsData}
                />
              </Col>
            </Row>
          </>
        ) : (
          <EmptyFunnel openAddJobModal={openAddJobModal} />
        )}
        {renderJobData()}
      </div>
      <Overlay
        isOpen={showProfileModal}
        showCloseIcon={true}
        onClose={closeProfileModal}
        classes={{ modal: styles.customModal }}
      >
        <div className={styles.modalBody}>
          <Profile
            candidateIds={candidateIds}
            candidateProfileId={candidateProfileId}
            candidateSubStageId={candidateSubStageId}
            isChangeApplicationStageSuccess={isChangeApplicationStageSuccess}
            isChangeApplicationStageError={isChangeApplicationStageError}
            changeApplicationStageRequest={changeApplicationStageRequest}
            jobOpeningId={currJobOpeningId}
            jobOpeningCode={jobOpeningCode}
            profileType={selectedTab}
            jobOpeningName={currJobTitle}
            agencySubClientId={agencySubClientId}
          />
        </div>
      </Overlay>
      <PostJobModal
        addJob={addJob}
        showAddJobModal={showAddJobModal}
        closeAddJobModal={closeAddJobModal}
        clientId={currClientId}
        epochTime={epochTime}
      />
      <SuccessModal
        heading={content.JOB_ADDED_SUCCESSFULLY}
        successMsg={content.SUCCESS_MESSAGE}
        showSuccessModal={showAddJobSuccessModal}
        closeSuccessModal={closeAddJobSuccessModal}
      />
    </>
);
}

const mapStateToProps = (state) => {
  return {
    content: state.languageReducer.portalContent,
    languageCode: state.languageReducer.languageCode,
    isJobFunnelSuccess: state.jobsReducer.isJobFunnelSuccess,
    isJobFunnelError: state.jobsReducer.isJobFunnelError,
    isJobFunnelData: state.jobsReducer.isJobFunnelData,
    isChangeApplicationStageSuccess:
      state.jobsReducer.isChangeApplicationStageSuccess,
    isChangeApplicationStageError:
      state.jobsReducer.isChangeApplicationStageError,
    isChangeApplicationStageData:
      state.jobsReducer.isChangeApplicationStageData,
    isGetCandidateProfileSuccess:
      state.candidateReducer.isGetCandidateProfileSuccess,
    candidateProfileData: state.candidateReducer.candidateProfileData,
    isListInterviewsSuccess: state.candidateReducer.isListInterviewsSuccess,
    isListInterviewsError: state.candidateReducer.isListInterviewsError,
    listInterviewsData: state.candidateReducer.listInterviewsData,
    isScheduleCandidateInterviewSuccess:
      state.candidateReducer.isScheduleCandidateInterviewSuccess,
    isScheduleCandidateInterviewError:
      state.candidateReducer.isScheduleCandidateInterviewError,
    isAllJobsSuccess: state.allJobsReducer.isAllJobsSuccess,
    isAllJobsError: state.allJobsReducer.isAllJobsError,
    isAllJobsData: state.allJobsReducer.isAllJobsData,
    isJobStatsSuccess: state.dashboardReducer.isJobStatsSuccess,
    isJobStatsError: state.dashboardReducer.isJobStatsError,
    jobStatsData: state.dashboardReducer.jobStatsData,

    isInterviewDetailsSuccess: state.candidateReducer.isInterviewDetailsSuccess,
    isInterviewDetailsError: state.candidateReducer.isInterviewDetailsError,
    interviewDetailsData: state.candidateReducer.interviewDetailsData,

    isDeleteInterviewSuccess: state.candidateReducer.isDeleteInterviewSuccess,
    isDeleteInterviewError: state.candidateReducer.isDeleteInterviewError,
    deleteInterviewData: state.candidateReducer.deleteInterviewData,

    isCancelInterviewSuccess: state.candidateReducer.isCancelInterviewSuccess,
    isCancelInterviewError: state.candidateReducer.isCancelInterviewError,
    cancelInterviewData: state.candidateReducer.cancelInterviewData,

    isContactsSuccess: state.contactsReducer.isContactsSuccess,
    isContactsError: state.contactsReducer.isContactsError,
    contacts: state.contactsReducer.contacts,

    isCreateContactSuccess: state.contactsReducer.isCreateContactSuccess,
    isCreateContactError: state.contactsReducer.isCreateContactError,
    createContact: state.contactsReducer.createContact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getJobFunnelRequest: (data) => dispatch(getJobFunnelRequest(data)),
    changeApplicationStageRequest: (data) =>
      dispatch(changeApplicationStageRequest(data)),
    getCandidateProfileRequest: (data) =>
      dispatch(getCandidateProfileRequest(data)),
    scheduleCandidateInterviewRequest: (data) =>
      dispatch(scheduleCandidateInterviewRequest(data)),
    listInterviewRequest: (data) => dispatch(listInterviewRequest(data)),
    getAllJobsRequest: (data) => dispatch(getAllJobsRequest(data)),
    getJobStatsRequest: (data) => dispatch(getJobStatsRequest(data)),
    fetchInterviewDetailsRequest: (data) =>
      dispatch(fetchInterviewDetailsRequest(data)),
    deleteInterviewRequest: (data) => dispatch(deleteInterviewRequest(data)),
    cancelInterviewRequest: (data) => dispatch(cancelInterviewRequest(data)),
    getContactsRequest: (data) => dispatch(getContactsRequest(data)),
    createContactRequest: (data) => dispatch(createContactRequest(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Funnel));