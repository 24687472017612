import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { getJobStageMappedValue } from '../../../../utils/helpers';
import { stageChangeOptions } from '../../../../data/jobStages';
import Helper from '../../../../services/helper';
import { PATH } from '../../../../services/urls';

import styles from './styles.module.scss';

const approvedHiringPipeline = stageChangeOptions.map((data) => data.label);

function InterviewStage({ interviewStageData, portalContent }) {
  const [renderedJobsCount, setRenderedJobsCount] = useState(1);

  const handleElipseToggle = () => {
    if (renderedJobsCount < 2) {
      setRenderedJobsCount(interviewStageData.length);
    } else {
      setRenderedJobsCount(1);
    }
  };

  const getJobsInElipse = () => `+${interviewStageData.length - renderedJobsCount} ${portalContent.MORE_JOB_ROLE}`;

  const renderElipse = () => interviewStageData.length > 1 && (
  <div
    onClick={handleElipseToggle}
    className={classNames('body-3', styles.elipse)}
  >
    {renderedJobsCount < 2 ? (
      getJobsInElipse()
    ) : (
      portalContent.SHOW_LESS
    )}
  </div>
  );

  const getFunnelUrl = (interviewStageData) => {
    const jobStages = getJobStageMappedValue(interviewStageData.hiringPipeline);
    const subStageId = getJobStageMappedValue(interviewStageData.applicationStatus)?.id;

    const params = {
      shortlistedData: jobStages?.id,
      jobCode: interviewStageData?.jobOpeningId,
      currJobId: interviewStageData?.jobId,
      jobTitle: interviewStageData?.jobTitle, // optional
      subStage: subStageId,
    };

    const url = Helper.makeURLWithParams(PATH.JOB, params);
    return url;
  };

  const renderInterviewStatus = () => {
    const filteredData = interviewStageData.slice(0, renderedJobsCount);
    return filteredData.map((data, idx) => (
      <div key={idx} className={styles.interivewStageContainer}>
        <div className="body-3">
          <span className={styles.jobTitle}>{data.jobTitle}</span>
          {data.location && `, ${data.location}`}
        </div>
        {data.hiringPipeline && (
          <div className={styles.interviewStageSection}>
            <div className="body-3">{portalContent.AT}</div>
            <strong>
              {approvedHiringPipeline.includes(data.hiringPipeline)
                ? (
                  <Link
                    className={classNames(
                      'linkAppearanceNone',
                      styles.interviewFunnelLink,
                    )}
                    to={() => getFunnelUrl(data)}
                  >
                    {data.hiringPipeline}
                  </Link>
                )
                : (
                  <div>
                    {data.hiringPipeline}
                  </div>
                )}
            </strong>
            <div className="body-3">
              {portalContent.DOT + data.applicationStatus}
            </div>
          </div>
        )}
      </div>
    ));
  };

  return (
    <div className={styles.interivewStageParentContainer}>
      {renderInterviewStatus()}
      {interviewStageData.length > 1 && (
        <div
          onClick={handleElipseToggle}
          className={classNames('body-3', styles.elipse)}
        >
          {renderElipse()}
        </div>
      )}
    </div>
  );
}

export default InterviewStage;
