/* eslint-disable default-param-last */
/* eslint-disable max-len */
import {
  USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS, USER_LOGIN_ERROR, USER_LOGOUT_COMPLETE,
  USER_FORGOT_PASSWORD_REQUEST, USER_FORGOT_PASSWORD_SUCCESS, USER_FORGOT_PASSWORD_ERROR, USER_SET_PASSWORD_REQUEST, USER_SET_PASSWORD_SUCCESS, USER_SET_PASSWORD_ERROR, USER_UPDATE_PASSWORD_REQUEST, USER_UPDATE_PASSWORD_SUCCESS, USER_UPDATE_PASSWORD_ERROR, USER_LOGOUT_REQUEST, USER_LOGOUT_SUCCESS, USER_LOGOUT_ERROR, USER_LOGIN_WITH_PHONE_REQUEST,
} from '../actions/actionTypes';

const initialState = {
  isLoginSuccess: false,
  isLoginError: false,
  loginData: {},
  isForgotPasswordSuccess: false,
  isForgotPasswordError: false,
  forgotPasswordData: {},
  isSetPasswordSuccess: false,
  isSetPasswordError: false,
  setPasswordData: {},
  isUpdatePasswordSuccess: false,
  isUpdatePasswordError: false,
  updatePasswordData: {},
  isLogoutSuccess: false,
  isLogoutError: false,
  logoutData: {},
  isLoading: false,
  message: '',
  email: '',
};

export function authReducer(state = initialState, { type, response }) {
  switch (type) {
    case USER_LOGIN_REQUEST:
      return {
        ...state,
        isLoginSuccess: false,
        isLoginError: false,
        loginData: {},
        email: response.email,
      };
    case USER_LOGIN_WITH_PHONE_REQUEST:
      return {
        ...state,
        isLoginSuccess: false,
        isLoginError: false,
        loginData: {},
        isLoading: true,
        email: response.email,
      };
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        isLoginSuccess: true,
        isLoginError: false,
        isLoading: false,
        loginData: response,
      };
    case USER_LOGIN_ERROR:
      return {
        ...state,
        isLoginSuccess: false,
        isLoginError: true,
        isLoading: false,
        loginData: response,
      };
    case USER_FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        isForgotPasswordSuccess: false,
        isForgotPasswordError: false,
        forgotPasswordData: {},
      };
    case USER_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isForgotPasswordSuccess: true,
        isForgotPasswordError: false,
        forgotPasswordData: response,
      };
    case USER_FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        isForgotPasswordSuccess: false,
        isForgotPasswordError: true,
        forgotPasswordData: response,
      };
    case USER_SET_PASSWORD_REQUEST:
      return {
        ...state,
        isSetPasswordSuccess: false,
        isSetPasswordError: false,
        setPasswordData: {},
      };
    case USER_SET_PASSWORD_SUCCESS:
      return {
        ...state,
        isSetPasswordSuccess: true,
        isSetPasswordError: false,
        setPasswordData: response,
      };
    case USER_SET_PASSWORD_ERROR:
      return {
        ...state,
        isSetPasswordSuccess: false,
        isSetPasswordError: true,
        setPasswordData: response,
      };
    case USER_UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        isUpdatePasswordSuccess: false,
        isUpdatePasswordError: false,
        updatePasswordData: {},
      };
    case USER_UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        isUpdatePasswordSuccess: true,
        isUpdatePasswordError: false,
        updatePasswordData: response,
      };
    case USER_UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        isUpdatePasswordSuccess: false,
        isUpdatePasswordError: true,
        updatePasswordData: response,
      };
    case USER_LOGOUT_COMPLETE:
    case USER_LOGOUT_REQUEST:
      return {
        isLogoutSuccess: false,
        isLogoutError: false,
        logoutData: {},
      };
    case USER_LOGOUT_SUCCESS:
      return {
        ...state,
        isLogoutSuccess: true,
        isLogoutError: false,
        logoutData: response,
      };
    case USER_LOGOUT_ERROR:
      return {
        ...state,
        isLogoutSuccess: false,
        isLogoutError: true,
        logoutData: response,
      };
    default:
      return state;
  }
}
export const getLoginData = (state) => state.authReducer.loginData;
export const getPasswordData = (state) => state.authReducer.setPasswordData;
