/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { useHistory, withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { loginRequest, getPortalLanguage } from "../../redux/actions/action";
import Helper from "../../services/helper";
import "react-toastify/dist/ReactToastify.css";
import eye from "../../images/eye.svg";
import hide from "../../images/hide.svg";
import Logo from "../../images/ternLogo.svg";
import {
  LAST_VISITED_URL,
  COOKIE_PATH,
  CANDIDATE_ROLE,
  CANDIDATE_PLATFORM,
  CANDIDATE_TOKEN,
  CANDIDATE_REFRESH_TOKEN,
  COOKIE_DOMAIN,
  RETURN_URL,
  LOCAL_PLATFORM_CANDIDATE,
  LOCAL_PLATFORM_RECRUITER,
  ENVIRONMENTS,
  RECRUITER_ROLE,
  RECRUITER_PLATFORM_DOMAIN,
} from '../../services/constants';
import AlertMessage from "../../subViews/alertMessage";
import {
  Button,
  FormGroup,
  Input,
  Row,
  Col,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { useCookies } from "react-cookie";
import { getQueryParams } from "../../utils/helpers";
import { CLIENTID } from '../../services/constants';

let languages = [];
const ENV = process.env.REACT_APP_ENV; // for development only

function Login(props) {
  const { content, changeLoginType } = props;
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [portalLanguageValue, setPortalLanguageValue] = useState();
  const [passwordShown, setPasswordShown] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const [cookies, setCookie] = useCookies([
    CANDIDATE_TOKEN,
    CANDIDATE_REFRESH_TOKEN,
  ]);

  const [portalLanguage, setPortalLanguage] = useState(() => {
    if (props.languageCode === null || props.languageCode === "en") {
      return [{ label: props.content.ENGLISH, value: "en" }];
    } else if (props.languageCode === "hi") {
      return [{ label: props.content.HINDI, value: "hi" }];
    }
  });

  const prevProps = useRef(props);

  languages = [
    { label: props.content.ENGLISH, value: "en" },
    { label: props.content.HINDI, value: "hi" },
  ];

  const handlelogin = async () => {
    const { loginRequest } = props;
    setIsLoading(true);
    setMessage(null);
    if (!validateCredentials()) return;
    const encryptedPassword = Helper.cryptoEncryption(password);
    const userCredentials = { email, password: encryptedPassword };

    await loginRequest(userCredentials);
  };

  const validateCredentials = () => {
    if (email === "" && password === "") {
      setEmailError(null);
      setPasswordError("* Please enter credentials");
      setIsLoading(false);
      return false;
    }

    const isValidEmailMsg = Helper.validateEmail(email);
    setEmailError(isValidEmailMsg || null);

    const isValidPasswordMsg = Helper.validatePassword(password);
    setPasswordError(isValidPasswordMsg || null);

    if (isValidEmailMsg || isValidPasswordMsg) {
      setIsLoading(false);
      return false;
    }

    return true;
  };

  useEffect(() => {
    const { getPortalLanguage } = props;
    localStorage.setItem("languageCode", portalLanguageValue);
    var objLanguage = new Object();

    objLanguage.languageCode = portalLanguageValue;

    getPortalLanguage(objLanguage);
  }, [portalLanguageValue]);

  useEffect(() => {
    const pLanguage =
      props.languageCode === null || props.languageCode === "en"
        ? [{ label: props.content.ENGLISH, value: "en" }]
        : [{ label: props.content.HINDI, value: "hi" }];

    setPortalLanguage(pLanguage);

    languages = [
      { label: props.content.ENGLISH, value: "en" },
      { label: props.content.HINDI, value: "hi" },
    ];
  }, [props.languageCode]);

  const setCookies = (loginData) => {
    const cookieOptions = {
      path: COOKIE_PATH,
      secure: true,
      sameSite: "strict",
      domain: COOKIE_DOMAIN,
    };

    const userRoles = Helper.getUserRoles(loginData?.payload?.token);
    const recruiterClientId = Helper.getRecruiterClientId(loginData?.payload?.token);
    const isRecruiter = Helper.indexOfArray(RECRUITER_ROLE, userRoles) !== -1;
    const isCandidate = Helper.indexOfArray(CANDIDATE_ROLE, userRoles) !== -1;
    const isLocalHost = ENV === ENVIRONMENTS.LOCAL;

    let redirectUrl = isCandidate ? LOCAL_PLATFORM_CANDIDATE : LOCAL_PLATFORM_RECRUITER;

    if(!isLocalHost){
      redirectUrl = isCandidate
        ? CANDIDATE_PLATFORM 
        : RECRUITER_PLATFORM_DOMAIN;
    }

    const queryParams = getQueryParams();
    if (queryParams.hasOwnProperty(RETURN_URL)) {
      redirectUrl += queryParams.returnUrl;
    }

    if (isLocalHost) {
      setCookie(CANDIDATE_TOKEN, loginData?.payload?.token);
      setCookie(CLIENTID, recruiterClientId);
      setCookie(
        CANDIDATE_REFRESH_TOKEN,
        loginData?.payload?.refreshToken,
      );
    } else {
      setCookie(CANDIDATE_TOKEN, loginData?.payload?.token, cookieOptions);
      setCookie(CLIENTID, recruiterClientId, cookieOptions);
      setCookie(
        CANDIDATE_REFRESH_TOKEN,
        loginData?.payload?.refreshToken,
        cookieOptions,
      );
    }

    if (isCandidate || isRecruiter) {
      return window.location.assign(redirectUrl);
    } 

    /*
     * If user role is not isCandidate[zId] or isRecruiter[zClId] then,
     * user will remains login on old cl-dash platform.
    */ 
    Helper.saveItem(REFRESHTOKEN, loginData?.payload?.refreshToken);
    Helper.saveItem(TOKEN, loginData?.payload?.token);
    Helper.saveUserAttributes(loginData?.payload?.token);
    const storedData = Helper.sessionGetItem(LAST_VISITED_URL);
    if (storedData) {
      history.push(storedData);
      sessionStorage.clear();
    } else {
      history.push("/dashboard");
    }
  };

  useEffect(() => {
    Helper.checkAndRedirectToHomePage(history);

    const {
      isLoginSuccess: prevIsLoginSuccess,
      isLoginError: prevIsLoginError,
    } = prevProps.current;
    const { isLoginSuccess, isLoginError, loginData } = props;
    if (
      prevIsLoginSuccess === false &&
      isLoginSuccess &&
      loginData.authType === "login"
    ) {
      setCookies(loginData);
    }

    if (
      prevIsLoginSuccess === false &&
      isLoginSuccess &&
      (loginData.authType === "reset" || loginData.authType === "expire")
    ) {
      history.push("/reset-password");
    }

    if (prevIsLoginError === false && isLoginError) {
      setIsLoading(false);
      setMessage(loginData.responseMessage);
    }

    prevProps.current = props;
  }, [props.isLoginSuccess, props.isLoginError]);

  const onPressEnter = async (e) => {
    if (e.key === "Enter") handlelogin();
  };

  return (
    <>
      <div className={styles.content}>
        <img alt="logo" src={Logo} className={styles.logo} />
        {message && <AlertMessage isSuccess={false} message={message} />}
        <div className={styles.inpContainer}>
          <div >
            <FormGroup>
              <label className={styles.labelText}>{content.ENTER_EMAIL}</label>
              <Input
                className={styles.inputBox}
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={onPressEnter}
              />
              {emailError && <div className={styles.errorText}>{emailError}</div>}
            </FormGroup>
          </div>
          <div >
            <FormGroup>
              <label className={styles.labelText}>{content.ENTER_PASSWORD}</label>
              <InputGroup>
                <Input
                  className={styles.password}
                  type={passwordShown ? "text" : "password"}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={onPressEnter}
                />
                <InputGroupText className={styles.passwordIcon}>
                  <img
                    className={styles.visibleIcon}
                    onClick={() => setPasswordShown(!passwordShown)}
                    src={!passwordShown ? hide : eye}
                    alt="password visibility icon"
                  />
                </InputGroupText>
              </InputGroup>
              {passwordError && (
                <div className={styles.errorText}>{passwordError}</div>
              )}
            </FormGroup>
          </div>
          <div className={styles.forgotPswd}>
            <Link to="/forgot-password" className={styles.labelText}>
              {content.FORGOT_PASSWORD}
            </Link>
          </div>
        </div>
        <div className={styles.btnContainer}>
          <button
            className={classNames(
              styles.btnStyle,
              "button-primary",
              "button-text",
            )}
            color="warning"
            type="button"
            onClick={handlelogin}
          >
            {isLoading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              content.LOGIN
            )}
          </button>
          <button
            className={classNames(
              styles.btnStyle,
              "button-secondary",
              "button-text",
            )}
            type="button"
            onClick={() => changeLoginType(content.PHONE)}
          >
            {content.LOGIN_WITH_PHONE_NUMBER}
          </button>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    content: state.languageReducer.portalContent,
    languageCode: state.languageReducer.languageCode,
    isLoginSuccess: state.authReducer.isLoginSuccess,
    isLoginError: state.authReducer.isLoginError,
    loginData: state.authReducer.loginData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPortalLanguage: (data) => dispatch(getPortalLanguage(data)),
    loginRequest: (data) => dispatch(loginRequest(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
