import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CookiesProvider } from 'react-cookie';
import configureStore from './redux/store/configureStore';
import Routing from './Router/Routing';
import { getTranslations } from './services/languageTranslation';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/general.scss';
import './assets/scss/tern-theme.scss';
import './assets/demo/demo.css';

const store = configureStore();

function App() {
  getTranslations();

  return (
    <div className="App">
      <ToastContainer />
      <Provider store={store}>
        <CookiesProvider defaultSetOptions={{ path: '/' }}>
          <Router>
            <Switch>
              <Routing />
            </Switch>
          </Router>
        </CookiesProvider>
      </Provider>
    </div>
  );
}

export default App;
