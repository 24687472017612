import React from 'react';
import classNames from 'classnames';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  CardFooter,
} from 'reactstrap';
import styles from './styles.module.scss';

const EmptyJobsStats = ({ content, onClickEmptyCard }) => (
  <Row style={{ marginTop: '15px' }}>
    <Col>
      <Card onClick={onClickEmptyCard}>
        <CardHeader tag="div">
          <Row>
            <Col md="10">
              <CardHeader>
                <CardTitle
                  className={classNames(styles.jobTitle)}
                />
              </CardHeader>
            </Col>
            <Col md="2">
              <CardHeader>
                <CardTitle
                  className={classNames(styles.jobCardTitle)}
                />
              </CardHeader>
            </Col>
          </Row>
        </CardHeader>
        <CardBody
          className={classNames(styles.jobBody, 'centerAlignContent')}
        >
          <Row className={styles.jobBodyRow}>
            {content.EMPTY_JOB_STATS_MSG}
          </Row>
        </CardBody>
        <CardFooter>
          <div className={styles.activityFooter} />
        </CardFooter>
      </Card>
    </Col>
  </Row>
);

export default EmptyJobsStats;
