import React from 'react';

import styles from './styles.module.scss';
import FallBackImg from '../../../../images/user.png';

function ProfileInfo({ profileData, portalContent }) {
  if (!profileData) return null;

  const handleProfileImageFallback = (e) => {
    e.target.src = FallBackImg;
    e.target.alt = 'FallBackImg';
  };

  const makeSpecialisationText = (specialisation) => {
    if (!specialisation || specialisation.length === 0) return '';
    if (specialisation.length > 1) {
      return specialisation.join(', ');
    }
    return specialisation[0];
  };

  const renderSpecialization = () => {
    const specializationDetails = profileData.specialisations.filter(Boolean);
    if (specializationDetails.length < 1) {
      return null;
    }
    return (
      <>
        {portalContent.DOT}
        <span>
          {makeSpecialisationText(specializationDetails)}
        </span>
      </>
    );
  };

  const renderEducation = () => {
    const education = profileData?.education;

    if (!education || education.length === 0) return null;

    return education.map((data) => (
      <div key={data}>{data}</div>
    ));
  };

  return (
    <div className={styles.profileContainer}>
      <img
        className={styles.profileImg}
        src={profileData?.profilePic}
        alt="profile"
        onError={handleProfileImageFallback}
      />
      <div className={styles.profileDetails}>
        <div className="subtitle-2">{profileData.name}</div>
        <div className="body-3">
          {profileData.profession}
          {renderSpecialization()}
        </div>
        <div className="body-3">
          {renderEducation()}
        </div>
      </div>
    </div>
  );
}

export default ProfileInfo;
