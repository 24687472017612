import { takeLatest, put, call } from 'redux-saga/effects';
import {
  REQUEST_SERVICE_PACKAGE_REQUEST,
  REQUEST_REPORT_REQUEST,
} from '../actions/actionTypes';
import {
  requestServicePackageError,
  requestServicePackageSuccess,
  requestReportSuccess,
  requestReportError,
} from '../actions/action';

import EmailerApi from '../../apis/emailerApi';

function* getRequestServicePackage(params) {
  try {
    const response = yield call(EmailerApi.requestServicePackage, params);
    const data = yield response.data;

    if (response.status === 200) yield put(requestServicePackageSuccess(data));
    else yield put(requestServicePackageError(data));
  } catch (error) {
    yield put(requestServicePackageError(error.message));
  }
}

function* getRequestReport(params) {
  try {
    const response = yield call(EmailerApi.requestReport, params);
    const data = yield response.data;

    if (response.status === 200) yield put(requestReportSuccess(data));
    else yield put(requestReportError(data));
  } catch (error) {
    yield put(requestReportError(error.message));
  }
}

function* emailerSaga() {
  yield takeLatest(REQUEST_SERVICE_PACKAGE_REQUEST, getRequestServicePackage);
  yield takeLatest(REQUEST_REPORT_REQUEST, getRequestReport);
}
export default emailerSaga;
