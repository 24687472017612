import Helper from '../services/helper';
import { GET_ALL_CANDIDATES_API_ENDPOINT } from '../services/urls';
import axiosInstance from '../services/axiosConfig';

class AllCandidateApi {
  static getAllCandidateRequest = async (request) => axiosInstance.get(
    `${GET_ALL_CANDIDATES_API_ENDPOINT}`,
    {
      params: request.params,
      headers: Helper.authHeader(),
    },
  );
}

export default AllCandidateApi;
