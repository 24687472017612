import Helper from '../services/helper';
import { AWS_ENDPOINT } from '../services/constants';
import axiosInstance from '../services/axiosConfig';
import {
  GET_UPCOMING_INTERVIEW,
  GET_ACTIVITY_STREAM,
  GET_JOB_STATS,
  GET_CLIENTS,
} from '../services/urls';

class DashboardAPI {
  static GetUserDetails = async (request) => axiosInstance.get(`${AWS_ENDPOINT}/getUserDetails`, {
    params: request.params,
    headers: Helper.authHeader(),
  });

  static GetUpcomingInterviews = async (request) => axiosInstance.get(
    `${GET_UPCOMING_INTERVIEW}`,
    {
      params: request.params,
      headers: Helper.authHeader(),
    },
  );

  static GetRecentActivities = async (request) => axiosInstance.get(
    `${GET_ACTIVITY_STREAM}`,
    {
      params: request.params,
      headers: Helper.authHeader(),
    },
  );

  static GetJobStats = async (request) => axiosInstance.get(
    `${GET_JOB_STATS}`,
    {
      params: request.params,
      headers: Helper.authHeader(),
    },
  );

  static GetAgencyClients = async (request) => axiosInstance.get(
    `${GET_CLIENTS}`,
    {
      params: request.params,
      headers: Helper.authHeader(),
    },
  );

  static GetCandidateStats = async (request) => axiosInstance.get(
    `${GET_JOB_STATS}`,
    {
      params: request.params,
      headers: Helper.authHeader(),
    },
  );
}

export default DashboardAPI;
