/* eslint-disable max-len */
import React from 'react';

function EditSVG(props) {
  const {
    onclick, cssClass, defaultColorHexCode, width = '20px', height = '20px',
  } = props;

  return (
    <svg onClick={onclick} className={cssClass} width={width} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.6719 3.76562H20.625C20.2031 3.34375 19.5 3.34375 19.0781 3.76562L17.6719 5.125L19.875 7.32812L21.2344 5.92188C21.6562 5.5 21.6562 4.79688 21.2344 4.32812L20.6719 3.76562ZM9.79688 13.0469C9.65625 13.1406 9.5625 13.3281 9.51562 13.5156L8.71875 16.2344L11.4844 15.4844C11.6719 15.4375 11.8125 15.3438 11.9531 15.2031L18.2812 8.875L16.125 6.71875L9.79688 13.0469ZM17.4844 2.17188C18.7969 0.859375 20.9062 0.859375 22.2188 2.17188L22.8281 2.78125C24.1406 4.09375 24.1406 6.20312 22.8281 7.51562L13.5469 16.7969C13.1719 17.1719 12.6562 17.4531 12.0938 17.6406L7.40625 18.9531C7.03125 19.0938 6.60938 19 6.32812 18.6719C6 18.3906 5.90625 17.9688 6 17.5938L7.35938 12.9062C7.5 12.3438 7.82812 11.8281 8.20312 11.4531L17.4844 2.17188ZM4.125 4H9.375C9.98438 4 10.5 4.51562 10.5 5.125C10.5 5.78125 9.98438 6.25 9.375 6.25H4.125C3.04688 6.25 2.25 7.09375 2.25 8.125V20.875C2.25 21.9531 3.04688 22.75 4.125 22.75H16.875C17.9062 22.75 18.75 21.9531 18.75 20.875V15.625C18.75 15.0156 19.2188 14.5 19.875 14.5C20.4844 14.5 21 15.0156 21 15.625V20.875C21 23.1719 19.125 25 16.875 25H4.125C1.82812 25 0 23.1719 0 20.875V8.125C0 5.875 1.82812 4 4.125 4Z" fill={defaultColorHexCode} />
    </svg>
  );
}

export default EditSVG;
