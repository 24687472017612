/* eslint-disable default-param-last */
import {
  REQUEST_SERVICE_PACKAGE_REQUEST,
  REQUEST_SERVICE_PACKAGE_SUCCESS,
  REQUEST_SERVICE_PACKAGE_ERROR,
  REQUEST_REPORT_REQUEST,
  REQUEST_REPORT_SUCCESS,
  REQUEST_REPORT_ERROR,
  REQUEST_REPORT_RESET,
} from '../actions/actionTypes';

const requestServicePackageInitialState = {
  isRequestServicePackageSuccess: false,
  isRequestServicePackageError: false,
  requestServicePackageData: {},
  requestServicePackageMessage: '',
};

const requestReportInitialState = {
  isRequestReportSuccess: false,
  isRequestReportError: false,
  requestReportData: {},
  requestReportMessage: '',
};

export function servicePackageReducer(
  state = requestServicePackageInitialState,
  { type, response },
) {
  switch (type) {
    case REQUEST_SERVICE_PACKAGE_REQUEST:
      return {
        ...state,
        isRequestServicePackageSuccess: false,
        isRequestServicePackageError: false,
        requestServicePackageData: {},
      };
    case REQUEST_SERVICE_PACKAGE_SUCCESS:
      return {
        ...state,
        isRequestServicePackageSuccess: true,
        isRequestServicePackageError: false,
        requestServicePackageData: response,
      };
    case REQUEST_SERVICE_PACKAGE_ERROR:
      return {
        ...state,
        isRequestServicePackageSuccess: false,
        isRequestServicePackageError: true,
        requestServicePackageData: response,
      };
    default:
      return state;
  }
}

export function reportReducer(
  state = requestReportInitialState,
  { type, response },
) {
  switch (type) {
    case REQUEST_REPORT_REQUEST:
      return {
        ...state,
        isRequestReportSuccess: false,
        isRequestReportError: false,
        requestReportData: {},
      };
    case REQUEST_REPORT_SUCCESS:
      return {
        ...state,
        isRequestReportSuccess: true,
        isRequestReportError: false,
        requestReportData: response,
      };
    case REQUEST_REPORT_ERROR:
      return {
        ...state,
        isRequestReportSuccess: false,
        isRequestReportError: true,
        requestReportData: response,
      };
    case REQUEST_REPORT_RESET:
      return requestReportInitialState;
    default:
      return state;
  }
}
