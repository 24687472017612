export const workingPatterns = [
  {
    label: '-None-',
    value: 'None',
    id: 'None',
  },
  {
    label: 'Full time',
    value: 'Full Time',
    id: 'Full_time',
  },
  {
    label: 'Part Time',
    value: 'Part Time',
    id: 'Part_Time',
  },
];

export const salaryCurrencies = [
  {
    label: '-None-',
    value: 'None',
    id: 'None',
  },
  {
    label: 'GBP',
    value: 'GBP',
    id: 'GBP',
  },
  {
    label: 'INR',
    value: 'INR',
    id: 'INR',
  },
];

export const jobCountries = [{
  value: null,
  label: '-None-',
},
{
  value: 'Afghanistan',
  label: 'Afghanistan',
},
{
  value: 'Aland Islands',
  label: 'Aland Islands',
},
{
  value: 'Albania',
  label: 'Albania',
},
{
  value: 'Algeria',
  label: 'Algeria',
},
{
  value: 'American Samoa',
  label: 'American Samoa',
},
{
  value: 'Andorra',
  label: 'Andorra',
},
{
  value: 'Angola',
  label: 'Angola',
},
{
  value: 'Anguilla',
  label: 'Anguilla',
},
{
  value: 'Antarctica',
  label: 'Antarctica',
},
{
  value: 'Antigua and Barbuda',
  label: 'Antigua and Barbuda',
},
{
  value: 'Argentina',
  label: 'Argentina',
},
{
  value: 'Armenia',
  label: 'Armenia',
},
{
  value: 'Aruba',
  label: 'Aruba',
},
{
  value: 'Australia',
  label: 'Australia',
},
{
  value: 'Austria',
  label: 'Austria',
},
{
  value: 'Azerbaijan',
  label: 'Azerbaijan',
},
{
  value: 'Bahamas',
  label: 'Bahamas',
},
{
  value: 'Bahrain',
  label: 'Bahrain',
},
{
  value: 'Bangladesh',
  label: 'Bangladesh',
},
{
  value: 'Barbados',
  label: 'Barbados',
},
{
  value: 'Belarus',
  label: 'Belarus',
},
{
  value: 'Belgium',
  label: 'Belgium',
},
{
  value: 'Belize',
  label: 'Belize',
},
{
  value: 'Benin',
  label: 'Benin',
},
{
  value: 'Bermuda',
  label: 'Bermuda',
},
{
  value: 'Bhutan',
  label: 'Bhutan',
},
{
  value: 'Bolivia',
  label: 'Bolivia',
},
{
  value: 'Bosnia and Herzegovina',
  label: 'Bosnia and Herzegovina',
},
{
  value: 'Botswana',
  label: 'Botswana',
},
{
  value: 'Bouvet Island',
  label: 'Bouvet Island',
},
{
  value: 'Brazil',
  label: 'Brazil',
},
{
  value: 'British Indian Ocean Territory',
  label: 'British Indian Ocean Territory',
},
{
  value: 'Brunei Darussalam',
  label: 'Brunei Darussalam',
},
{
  value: 'Bulgaria',
  label: 'Bulgaria',
},
{
  value: 'Burkina Faso',
  label: 'Burkina Faso',
},
{
  value: 'Burundi',
  label: 'Burundi',
},
{
  value: 'Cambodia',
  label: 'Cambodia',
},
{
  value: 'Cameroon',
  label: 'Cameroon',
},
{
  value: 'Canada',
  label: 'Canada',
},
{
  value: 'Cape Verde',
  label: 'Cape Verde',
},
{
  value: 'Caribbean Nations',
  label: 'Caribbean Nations',
},
{
  value: 'Cayman Islands',
  label: 'Cayman Islands',
},
{
  value: 'Central African Republic',
  label: 'Central African Republic',
},
{
  value: 'Chad',
  label: 'Chad',
},
{
  value: 'Chile',
  label: 'Chile',
},
{
  value: 'China',
  label: 'China',
},
{
  value: 'Christmas Island',
  label: 'Christmas Island',
},
{
  value: 'Cocos (Keeling) Islands',
  label: 'Cocos (Keeling) Islands',
},
{
  value: 'Colombia',
  label: 'Colombia',
},
{
  value: 'Comoros',
  label: 'Comoros',
},
{
  value: 'Congo',
  label: 'Congo',
},
{
  value: 'Cook Islands',
  label: 'Cook Islands',
},
{
  value: 'Costa Rica',
  label: 'Costa Rica',
},
{
  value: "Cote D'Ivoire (Ivory Coast)",
  label: "Cote D'Ivoire (Ivory Coast)",
},
{
  value: 'Croatia',
  label: 'Croatia',
},
{
  value: 'Cuba',
  label: 'Cuba',
},
{
  value: 'Cyprus',
  label: 'Cyprus',
},
{
  value: 'Czech Republic',
  label: 'Czech Republic',
},
{
  value: 'Democratic Republic of the Congo',
  label: 'Democratic Republic of the Congo',
},
{
  value: 'Denmark',
  label: 'Denmark',
},
{
  value: 'Djibouti',
  label: 'Djibouti',
},
{
  value: 'Dominica',
  label: 'Dominica',
},
{
  value: 'Dominican Republic',
  label: 'Dominican Republic',
},
{
  value: 'East Timor',
  label: 'East Timor',
},
{
  value: 'Ecuador',
  label: 'Ecuador',
},
{
  value: 'Egypt',
  label: 'Egypt',
},
{
  value: 'El Salvador',
  label: 'El Salvador',
},
{
  value: 'Equatorial Guinea',
  label: 'Equatorial Guinea',
},
{
  value: 'Eritrea',
  label: 'Eritrea',
},
{
  value: 'Estonia',
  label: 'Estonia',
},
{
  value: 'Ethiopia',
  label: 'Ethiopia',
},
{
  value: 'Falkland Islands',
  label: 'Falkland Islands',
},
{
  value: 'Faroe Islands',
  label: 'Faroe Islands',
},
{
  value: 'Federated States of Micronesia',
  label: 'Federated States of Micronesia',
},
{
  value: 'Fiji',
  label: 'Fiji',
},
{
  value: 'Finland',
  label: 'Finland',
},
{
  value: 'France',
  label: 'France',
},
{
  value: 'France, Metropolitan',
  label: 'France, Metropolitan',
},
{
  value: 'French Guiana',
  label: 'French Guiana',
},
{
  value: 'French Polynesia',
  label: 'French Polynesia',
},
{
  value: 'French Southern Territories',
  label: 'French Southern Territories',
},
{
  value: 'Gabon',
  label: 'Gabon',
},
{
  value: 'Gambia',
  label: 'Gambia',
},
{
  value: 'Georgia',
  label: 'Georgia',
},
{
  value: 'Germany',
  label: 'Germany',
},
{
  value: 'Ghana',
  label: 'Ghana',
},
{
  value: 'Gibraltar',
  label: 'Gibraltar',
},
{
  value: 'Greece',
  label: 'Greece',
},
{
  value: 'Greenland',
  label: 'Greenland',
},
{
  value: 'Grenada',
  label: 'Grenada',
},
{
  value: 'Guadeloupe',
  label: 'Guadeloupe',
},
{
  value: 'Guam',
  label: 'Guam',
},
{
  value: 'Guatemala',
  label: 'Guatemala',
},
{
  value: 'Guinea',
  label: 'Guinea',
},
{
  value: 'Guinea-Bissau',
  label: 'Guinea-Bissau',
},
{
  value: 'Guyana',
  label: 'Guyana',
},
{
  value: 'Haiti',
  label: 'Haiti',
},
{
  value: 'Heard Island and McDonald Islands',
  label: 'Heard Island and McDonald Islands',
},
{
  value: 'Honduras',
  label: 'Honduras',
},
{
  value: 'Hong Kong',
  label: 'Hong Kong',
},
{
  value: 'Hungary',
  label: 'Hungary',
},
{
  value: 'Iceland',
  label: 'Iceland',
},
{
  value: 'India',
  label: 'India',
},
{
  value: 'Indonesia',
  label: 'Indonesia',
},
{
  value: 'Iran',
  label: 'Iran',
},
{
  value: 'Iraq',
  label: 'Iraq',
},
{
  value: 'Ireland',
  label: 'Ireland',
},
{
  value: 'Israel',
  label: 'Israel',
},
{
  value: 'Italy',
  label: 'Italy',
},
{
  value: 'Jamaica',
  label: 'Jamaica',
},
{
  value: 'Japan',
  label: 'Japan',
},
{
  value: 'Jordan',
  label: 'Jordan',
},
{
  value: 'Kazakhstan',
  label: 'Kazakhstan',
},
{
  value: 'Kenya',
  label: 'Kenya',
},
{
  value: 'Kiribati',
  label: 'Kiribati',
},
{
  value: 'Korea',
  label: 'Korea',
},
{
  value: 'Korea (North)',
  label: 'Korea (North)',
},
{
  value: 'Kuwait',
  label: 'Kuwait',
},
{
  value: 'Kyrgyzstan',
  label: 'Kyrgyzstan',
},
{
  value: 'Laos',
  label: 'Laos',
},
{
  value: 'Latvia',
  label: 'Latvia',
},
{
  value: 'Lebanon',
  label: 'Lebanon',
},
{
  value: 'Lesotho',
  label: 'Lesotho',
},
{
  value: 'Liberia',
  label: 'Liberia',
},
{
  value: 'Libya',
  label: 'Libya',
},
{
  value: 'Liechtenstein',
  label: 'Liechtenstein',
},
{
  value: 'Lithuania',
  label: 'Lithuania',
},
{
  value: 'Luxembourg',
  label: 'Luxembourg',
},
{
  value: 'Macao',
  label: 'Macao',
},
{
  value: 'Macedonia',
  label: 'Macedonia',
},
{
  value: 'Madagascar',
  label: 'Madagascar',
},
{
  value: 'Malawi',
  label: 'Malawi',
},
{
  value: 'Malaysia',
  label: 'Malaysia',
},
{
  value: 'Maldives',
  label: 'Maldives',
},
{
  value: 'Mali',
  label: 'Mali',
},
{
  value: 'Malta',
  label: 'Malta',
},
{
  value: 'Marshall Islands',
  label: 'Marshall Islands',
},
{
  value: 'Martinique',
  label: 'Martinique',
},
{
  value: 'Mauritania',
  label: 'Mauritania',
},
{
  value: 'Mauritius',
  label: 'Mauritius',
},
{
  value: 'Mayotte',
  label: 'Mayotte',
},
{
  value: 'Mexico',
  label: 'Mexico',
},
{
  value: 'Moldova',
  label: 'Moldova',
},
{
  value: 'Monaco',
  label: 'Monaco',
},
{
  value: 'Mongolia',
  label: 'Mongolia',
},
{
  value: 'Montserrat',
  label: 'Montserrat',
},
{
  value: 'Morocco',
  label: 'Morocco',
},
{
  value: 'Mozambique',
  label: 'Mozambique',
},
{
  value: 'Myanmar',
  label: 'Myanmar',
},
{
  value: 'Namibia',
  label: 'Namibia',
},
{
  value: 'Nauru',
  label: 'Nauru',
},
{
  value: 'Nepal',
  label: 'Nepal',
},
{
  value: 'Netherlands',
  label: 'Netherlands',
},
{
  value: 'Netherlands Antilles',
  label: 'Netherlands Antilles',
},
{
  value: 'New Caledonia',
  label: 'New Caledonia',
},
{
  value: 'New Zealand',
  label: 'New Zealand',
},
{
  value: 'Nicaragua',
  label: 'Nicaragua',
},
{
  value: 'Niger',
  label: 'Niger',
},
{
  value: 'Nigeria',
  label: 'Nigeria',
},
{
  value: 'Niue',
  label: 'Niue',
},
{
  value: 'Norfolk Island',
  label: 'Norfolk Island',
},
{
  value: 'Northern Mariana Islands',
  label: 'Northern Mariana Islands',
},
{
  value: 'Norway',
  label: 'Norway',
},
{
  value: 'Pakistan',
  label: 'Pakistan',
},
{
  value: 'Palau',
  label: 'Palau',
},
{
  value: 'Palestinian Territory',
  label: 'Palestinian Territory',
},
{
  value: 'Panama',
  label: 'Panama',
},
{
  value: 'Papua New Guinea',
  label: 'Papua New Guinea',
},
{
  value: 'Paraguay',
  label: 'Paraguay',
},
{
  value: 'Peru',
  label: 'Peru',
},
{
  value: 'Philippines',
  label: 'Philippines',
},
{
  value: 'Pitcairn',
  label: 'Pitcairn',
},
{
  value: 'Poland',
  label: 'Poland',
},
{
  value: 'Portugal',
  label: 'Portugal',
},
{
  value: 'Puerto',
  label: 'Puerto Rico',
},
{
  value: 'Qatar',
  label: 'Qatar',
},
{
  value: 'Reunion',
  label: 'Reunion',
},
{
  value: 'Romania',
  label: 'Romania',
},
{
  value: 'Russian Federation',
  label: 'Russian Federation',
},
{
  value: 'Rwanda',
  label: 'Rwanda',
},
{
  value: 'S. Georgia and S. Sandwich Islands',
  label: 'S. Georgia and S. Sandwich Islands',
},
{
  value: 'Saint Helena',
  label: 'Saint Helena',
},
{
  value: 'Saint Kitts and Nevis',
  label: 'Saint Kitts and Nevis',
},
{
  value: 'Saint Lucia',
  label: 'Saint Lucia',
},
{
  value: 'Saint Pierre and Miquelon',
  label: 'Saint Pierre and Miquelon',
},
{
  value: 'Saint Vincent and the Grenadines',
  label: 'Saint Vincent and the Grenadines',
},
{
  value: 'Samoa',
  label: 'Samoa',
},
{
  value: 'San Marino',
  label: 'San Marino',
},
{
  value: 'Sao Tome and Principe',
  label: 'Sao Tome and Principe',
},
{
  value: 'Saudi Arabia',
  label: 'Saudi Arabia',
},
{
  value: 'Senegal',
  label: 'Senegal',
},
{
  value: 'Serbia and Montenegro',
  label: 'Serbia and Montenegro',
},
{
  value: 'Seychelles',
  label: 'Seychelles',
},
{
  value: 'Sierra Leone',
  label: 'Sierra Leone',
},
{
  value: 'Singapore',
  label: 'Singapore',
},
{
  value: 'Slovak Republic',
  label: 'Slovak Republic',
},
{
  value: 'Slovenia',
  label: 'Slovenia',
},
{
  value: 'Solomon Islands',
  label: 'Solomon Islands',
},
{
  value: 'Somalia',
  label: 'Somalia',
},
{
  value: 'South Africa',
  label: 'South Africa',
},
{
  value: 'South Sudan',
  label: 'South Sudan',
},
{
  value: 'Spain',
  label: 'Spain',
},
{
  value: 'Sri Lanka',
  label: 'Sri Lanka',
},
{
  value: 'Sudan',
  label: 'Sudan',
},
{
  value: 'Sultanate of Oman',
  label: 'Sultanate of Oman',
},
{
  value: 'Suriname',
  label: 'Suriname',
},
{
  value: 'Svalbard and Jan Mayen',
  label: 'Svalbard and Jan Mayen',
},
{
  value: 'Swaziland',
  label: 'Swaziland',
},
{
  value: 'Sweden',
  label: 'Sweden',
},
{
  value: 'Switzerland',
  label: 'Switzerland',
},
{
  value: 'Syria',
  label: 'Syria',
},
{
  value: 'Taiwan',
  label: 'Taiwan',
},
{
  value: 'Tajikistan',
  label: 'Tajikistan',
},
{
  value: 'Tanzania',
  label: 'Tanzania',
},
{
  value: 'Thailand',
  label: 'Thailand',
},
{
  value: 'Timor-Leste',
  label: 'Timor-Leste',
},
{
  value: 'Togo',
  label: 'Togo',
},
{
  value: 'Tokelau',
  label: 'Tokelau',
},
{
  value: 'Tonga',
  label: 'Tonga',
},
{
  value: 'Trinidad and Tobago',
  label: 'Trinidad and Tobago',
},
{
  value: 'Tunisia',
  label: 'Tunisia',
},
{
  value: 'Turkey',
  label: 'Turkey',
},
{
  value: 'Turkmenistan',
  label: 'Turkmenistan',
},
{
  value: 'Turks and Caicos Islands',
  label: 'Turks and Caicos Islands',
},
{
  value: 'Tuvalu',
  label: 'Tuvalu',
},
{
  value: 'Uganda',
  label: 'Uganda',
},
{
  value: 'Ukraine',
  label: 'Ukraine',
},
{
  value: 'United Arab Emirates',
  label: 'United Arab Emirates',
},
{
  value: 'United Kingdom',
  label: 'United Kingdom',
},
{
  value: 'United States',
  label: 'United States',
},
{
  value: 'Uruguay',
  label: 'Uruguay',
},
{
  value: 'Uzbekistan',
  label: 'Uzbekistan',
},
{
  value: 'Vanuatu',
  label: 'Vanuatu',
},
{
  value: 'Vatican City State (Holy See)',
  label: 'Vatican City State (Holy See)',
},
{
  value: 'Venezuela',
  label: 'Venezuela',
},
{
  value: 'Viet Nam',
  label: 'Viet Nam',
},
{
  value: 'Virgin Islands (British)',
  label: 'Virgin Islands (British)',
},
{
  value: 'Virgin Islands (U.S.)',
  label: 'Virgin Islands (U.S.)',
},
{
  value: 'Wallis and Futuna',
  label: 'Wallis and Futuna',
},
{
  value: 'Western Sahara',
  label: 'Western Sahara',
},
{
  value: 'Yemen',
  label: 'Yemen',
},
{
  value: 'Yugoslavia',
  label: 'Yugoslavia',
},
{
  value: 'Zambia',
  label: 'Zambia',
},
{
  value: 'Zimbabwe',
  label: 'Zimbabwe',
}];
export const jobSpecializationsData = {
  doctor: {
    'acute medicine': {
      displayName: 'Acute Medicine',
    },
    'adult psychiatry': {
      displayName: 'Adult Psychiatry',
    },
    'allergy and immunology': {
      displayName: 'Allergy and Immunology',
    },
    anaesthesiology: {
      displayName: 'Anaesthesiology',
    },
    audiology: {
      displayName: 'Audiology',
    },
    'bariatric surgery': {
      displayName: 'Bariatric Surgery',
    },
    cardiology: {
      displayName: 'Cardiology',
    },
    'cardiothoracic surgery': {
      displayName: 'Cardiothoracic Surgery',
    },
    'cellular pathology': {
      displayName: 'Cellular Pathology',
    },
    'child & adolescent psychiatry': {
      displayName: 'Child & Adolescent Psychiatry',
    },
    'clinical oncology': {
      displayName: 'Clinical Oncology',
    },
    'colorectal surgery': {
      displayName: 'Colorectal Surgery',
    },
    'critical care': {
      displayName: 'Critical Care',
    },
    'critical care medicine': {
      displayName: 'Critical Care Medicine',
    },
    dentistry: {
      displayName: 'Dentistry',
    },
    dermatology: {
      displayName: 'Dermatology',
    },
    'emergency medicine': {
      displayName: 'Emergency Medicine',
    },
    endocrinology: {
      displayName: 'Endocrinology',
    },
    endocronology: {
      displayName: 'Endocronology',
    },
    endodontistry: {
      displayName: 'Endodontistry',
    },
    ent: {
      displayName: 'ENT',
    },
    epidemiology: {
      displayName: 'Epidemiology',
    },
    gastroenterology: {
      displayName: 'Gastroenterology',
    },
    'general medicine': {
      displayName: 'General Medicine',
    },
    'general practice': {
      displayName: 'General Practice',
    },
    'general practitioner': {
      displayName: 'General Practitioner',
    },
    'general surgery': {
      displayName: 'General Surgery',
    },
    genetics: {
      displayName: 'Genetics',
    },
    'geriatric medicine': {
      displayName: 'Geriatric Medicine',
    },
    gynaecology: {
      displayName: 'Gynaecology',
    },
    haematology: {
      displayName: 'Haematology',
    },
    hematopathology: {
      displayName: 'Hematopathology',
    },
    hepatology: {
      displayName: 'Hepatology',
    },
    histopathology: {
      displayName: 'Histopathology',
    },
    immunology: {
      displayName: 'Immunology',
    },
    'infectious disease': {
      displayName: 'Infectious Disease',
    },
    'internal medicine': {
      displayName: 'Internal Medicine',
    },
    'interventional radiology': {
      displayName: 'Interventional Radiology',
    },
    'maternal-fetal medicine': {
      displayName: 'Maternal-Fetal Medicine',
    },
    microbiology: {
      displayName: 'Microbiology',
    },
    neonatology: {
      displayName: 'Neonatology',
    },
    nephrology: {
      displayName: 'Nephrology',
    },
    neurology: {
      displayName: 'Neurology',
    },
    neurophysiology: {
      displayName: 'Neurophysiology',
    },
    neurosurgery: {
      displayName: 'Neurosurgery',
    },
    'nuclear medicine': {
      displayName: 'Nuclear Medicine',
    },
    'nuclear radiology': {
      displayName: 'Nuclear Radiology',
    },
    obstetrics: {
      displayName: 'Obstetrics',
    },
    'obstetrics and gynecology': {
      displayName: 'Obstetrics and Gynecology',
    },
    'occupational medicine': {
      displayName: 'Occupational Medicine',
    },
    'older person psychiatry': {
      displayName: 'Older Person Psychiatry',
    },
    oncology: {
      displayName: 'Oncology',
    },
    'oncoplastic surgery': {
      displayName: 'Oncoplastic Surgery',
    },
    ophthalmology: {
      displayName: 'Ophthalmology',
    },
    optometry: {
      displayName: 'Optometry',
    },
    'oral and maxillofacial surgery': {
      displayName: 'Oral and Maxillofacial Surgery',
    },
    'oral surgery': {
      displayName: 'Oral Surgery',
    },
    orthodontics: {
      displayName: 'Orthodontics',
    },
    orthopaedics: {
      displayName: 'Orthopaedics',
    },
    'orthopedic surgery': {
      displayName: 'Orthopedic Surgery',
    },
    otolaryngology: {
      displayName: 'Otolaryngology',
    },
    paediatrics: {
      displayName: 'Paediatrics',
    },
    'pain management': {
      displayName: 'Pain Management',
    },
    'palliative care': {
      displayName: 'Palliative Care',
    },
    'palliative medicine': {
      displayName: 'Palliative Medicine',
    },
    pathology: {
      displayName: 'Pathology',
    },
    pedodontistry: {
      displayName: 'Pedodontistry',
    },
    'perinatal psychiatry': {
      displayName: 'Perinatal Psychiatry',
    },
    periodontology: {
      displayName: 'Periodontology',
    },
    pharmacology: {
      displayName: 'Pharmacology',
    },
    'physical medicine and rehabilitation': {
      displayName: 'Physical Medicine and Rehabilitation',
    },
    'physical therapy': {
      displayName: 'Physical Therapy',
    },
    physiology: {
      displayName: 'Physiology',
    },
    'plastic and reconstructive surgery': {
      displayName: 'Plastic and Reconstructive Surgery',
    },
    'plastic surgery': {
      displayName: 'Plastic Surgery',
    },
    'podiatric surgery': {
      displayName: 'Podiatric Surgery',
    },
    podiatry: {
      displayName: 'Podiatry',
    },
    prosthodontistry: {
      displayName: 'Prosthodontistry',
    },
    psychiatry: {
      displayName: 'Psychiatry',
    },
    psychology: {
      displayName: 'Psychology',
    },
    'public health': {
      displayName: 'Public Health',
    },
    pulmonology: {
      displayName: 'Pulmonology',
    },
    radiology: {
      displayName: 'Radiology',
    },
    'rehabilitation medicine': {
      displayName: 'Rehabilitation Medicine',
    },
    'reproductive medicine': {
      displayName: 'Reproductive Medicine',
    },
    'respiratory medicine': {
      displayName: 'Respiratory Medicine',
    },
    rheumatology: {
      displayName: 'Rheumatology',
    },
    'spinal cord injuries specialistion': {
      displayName: 'Spinal Cord Injuries Specialistion',
    },
    'stroke medicine': {
      displayName: 'Stroke Medicine',
    },
    'stroke specialisation': {
      displayName: 'Stroke Specialisation',
    },
    therapy: {
      displayName: 'Therapy',
    },
    'thoracic surgery': {
      displayName: 'Thoracic Surgery',
    },
    'urologic surgery': {
      displayName: 'Urologic Surgery',
    },
    urology: {
      displayName: 'Urology',
    },
    'vascular surgery': {
      displayName: 'Vascular Surgery',
    },
  },
  nurse: {
    'adult nurse': {
      displayName: 'Adult Nurse',
    },
    'advanced nurse practitioner': {
      displayName: 'Advanced Nurse Practitioner',
    },
    'allergy and immunology': {
      displayName: 'Allergy and Immunology',
    },
    anesthesiology: {
      displayName: 'Anesthesiology',
    },
    'associate nurse': {
      displayName: 'Associate Nurse',
    },
    audiology: {
      displayName: 'Audiology',
    },
    'biomedical science': {
      displayName: 'Biomedical Science',
    },
    'cardiac nurse': {
      displayName: 'Cardiac Nurse',
    },
    'clinical nurse': {
      displayName: 'Clinical Nurse',
    },
    'clinical physiology': {
      displayName: 'Clinical Physiology',
    },
    'community nurse': {
      displayName: 'Community Nurse',
    },
    'critical care nurse': {
      displayName: 'Critical Care Nurse',
    },
    'dental nurse': {
      displayName: 'Dental Nurse',
    },
    dermatology: {
      displayName: 'Dermatology',
    },
    dietician: {
      displayName: 'Dietician',
    },
    'echo cardiographer': {
      displayName: 'Echo Cardiographer',
    },
    'emergency medicine': {
      displayName: 'Emergency Medicine',
    },
    endocrinology: {
      displayName: 'Endocrinology',
    },
    'family nurse': {
      displayName: 'Family Nurse',
    },
    gastroenterology: {
      displayName: 'Gastroenterology',
    },
    'general practice nurse': {
      displayName: 'General Practice Nurse',
    },
    'geriatric nurse': {
      displayName: 'Geriatric Nurse',
    },
    haematology: {
      displayName: 'Haematology',
    },
    'infectious disease': {
      displayName: 'Infectious Disease',
    },
    'mental health': {
      displayName: 'Mental Health',
    },
    'mental health nurse': {
      displayName: 'Mental Health Nurse',
    },
    'midwifery nurse': {
      displayName: 'Midwifery Nurse',
    },
    'neonatal nurse': {
      displayName: 'Neonatal Nurse',
    },
    neurology: {
      displayName: 'Neurology',
    },
    neurosurgery: {
      displayName: 'Neurosurgery',
    },
    'obstetrics and gynecology': {
      displayName: 'Obstetrics and Gynecology',
    },
    'occupational therapy': {
      displayName: 'Occupational Therapy',
    },
    oncology: {
      displayName: 'Oncology',
    },
    'oncology nurse': {
      displayName: 'Oncology Nurse',
    },
    ophthalmology: {
      displayName: 'Ophthalmology',
    },
    'orthopaedic practitioner': {
      displayName: 'Orthopaedic Practitioner',
    },
    orthopaedics: {
      displayName: 'Orthopaedics',
    },
    otolaryngology: {
      displayName: 'Otolaryngology',
    },
    'paediatric nurse': {
      displayName: 'Paediatric Nurse',
    },
    paediatrics: {
      displayName: 'Paediatrics',
    },
    'pain management': {
      displayName: 'Pain Management',
    },
    'palliative care': {
      displayName: 'Palliative Care',
    },
    pharmacy: {
      displayName: 'Pharmacy',
    },
    phlebotomy: {
      displayName: 'Phlebotomy',
    },
    'physical medicine and rehabilitation': {
      displayName: 'Physical Medicine and Rehabilitation',
    },
    'physical therapy': {
      displayName: 'Physical Therapy',
    },
    'physician associate': {
      displayName: 'Physician Associate',
    },
    physiology: {
      displayName: 'Physiology',
    },
    physiotherapy: {
      displayName: 'Physiotherapy',
    },
    'plaster room': {
      displayName: 'Plaster Room',
    },
    podiatrist: {
      displayName: 'Podiatrist',
    },
    psychiatry: {
      displayName: 'Psychiatry',
    },
    'public health': {
      displayName: 'Public Health',
    },
    pulmonology: {
      displayName: 'Pulmonology',
    },
    radiography: {
      displayName: 'Radiography',
    },
    radiology: {
      displayName: 'Radiology',
    },
    radiotherapy: {
      displayName: 'Radiotherapy',
    },
    'registered nurse': {
      displayName: 'Registered Nurse',
    },
    rheumatology: {
      displayName: 'Rheumatology',
    },
    'senior nurse': {
      displayName: 'Senior Nurse',
    },
    'senior staff nurse': {
      displayName: 'Senior Staff Nurse',
    },
    'specialist nurse': {
      displayName: 'Specialist Nurse',
    },
    'speech and language therapy': {
      displayName: 'Speech and Language Therapy',
    },
    'staff nurse': {
      displayName: 'Staff Nurse',
    },
    'surgical nurse': {
      displayName: 'Surgical Nurse',
    },
    'theatre nurse': {
      displayName: 'Theatre Nurse',
    },
    'thoracic surgery': {
      displayName: 'Thoracic Surgery',
    },
    urology: {
      displayName: 'Urology',
    },
    'vascular science': {
      displayName: 'Vascular Science',
    },
    'vascular surgery': {
      displayName: 'Vascular Surgery',
    },
  },
};

export const workExperienceOptions = [
  {
    value: '-None-',
    id: 'None',
    label: 'None',
  },
  {
    value: 'Fresher',
    id: 'Fresher',
    label: 'Fresher',
  },
  {
    value: '0-1 year',
    id: '0-1 year',
    label: '0-1 year',
  },
  {
    value: '1-3 years',
    id: '1-3 years',
    label: '1-3 years',
  },
  {
    value: '3-5 years',
    id: '4-5 years',
    label: '4-5 years',
  },
  {
    value: '5+ years',
    id: '5+ years',
    label: '5+ years',
  },

];

export const jobEnglishLevelRequirement = [
  {
    label: '-None-',
    value: 'None',
    id: 'None',
  },
  {
    label: 'Beginner',
    value: 'Beginner',
    id: 'Beginner',
  },
  {
    label: 'Intermediate',
    value: 'Intermediate',
    id: 'Intermediate',
  },
  {
    label: 'Advanced',
    value: 'Advanced',
    id: 'Advanced',
  },
  {
    label: 'Proficient',
    value: 'Proficient',
    id: 'Proficient',
  },
];
export const jobSponsorshipProvided = [
  {
    label: '-None-',
    value: 'None',
    id: 'None',
  },
  {
    label: 'Yes',
    value: 'Yes',
    id: 'yes',
  },
  {
    label: 'No',
    value: 'No',
    id: 'no',
  },
];

export const jobRoles = [
  {
    value: 'Care Worker (HCA)',
    label: 'Care Worker (HCA)',
    id: 'Care Worker (HCA)',
  },
  {
    value: 'Nurse',
    label: 'Nurse',
    id: 'Nurse',
  },
  {
    value: 'Doctor',
    label: 'Doctor',
    id: 'Doctor',
  },
  {
    value: 'Physician Assistant',
    label: 'Physician Assistant',
    id: 'Physician Assistant',
  },
  {
    value: 'Allied Health Professional (AHP)',
    label: 'Allied Health Professional (AHP)',
    id: 'Allied Health Professional (AHP)',
  },
  {
    value: 'Health Science Service (HSS)',
    label: 'Health Science Service (HSS)',
    id: 'Health Science Service (HSS)',
  },
];

export const jobTargetDate = [
  {
    label: '-None-',
    value: 'None',
    id: 'None',
  },
];

export const employmentTypes = [
  {
    label: '-None-',
    value: 'None',
    id: 'None',
  },
  {
    label: 'Full time',
    value: 'Full time',
    id: 'Full time',
  },
  {
    label: 'Any',
    value: 'Any',
    id: 'Any',
  },
  {
    label: 'Part time',
    value: 'Part time',
    id: 'Part time',
  },
  {
    label: 'Permanent',
    value: 'Permanent',
    id: 'Permanent',
  },
  {
    label: 'Contract',
    value: 'Contract',
    id: 'Contract',

  },
  {
    label: 'Temporary',
    value: 'Temporary',
    id: 'Temporary',
  },
  {
    label: 'Training',
    value: 'Training',
    id: 'Training',
  },
  {
    label: 'Volunteer',
    value: 'Volunteer',
    id: 'Volunteer',
  },
  {
    label: 'Seasonal',
    value: 'Seasonal',
    id: 'Seasonal',
  },
  {
    label: 'Freelance',
    value: 'Freelance',
    id: 'Freelance',
  },
];
