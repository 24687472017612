import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { CardTitle } from 'reactstrap';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import styles from './styles.module.scss';
import { getPortalContent } from '../../redux/reducers/languageReducer';
import Helper from '../../services/helper';

function UserDetails() {
  const userData = Helper.getUserInfo();
  const { given_name: name = '' } = userData;
  const portalContent = useSelector(getPortalContent);

  return (
    <CardTitle className={classNames(styles.textWrapper, 'heading-2')}>
      {portalContent.HI}
      {' '}
      {name}
      ! 👋
    </CardTitle>
  );
}

export default UserDetails;
