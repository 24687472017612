/* eslint-disable */
import {
    LIST_CONTACTS_REQUEST,
    LIST_CONTACTS_SUCCESS,
    LIST_CONTACTS_ERROR,
    CREATE_CONTACT_REQUEST,
    CREATE_CONTACT_SUCCESS,
    CREATE_CONTACT_ERROR
} from '../actions/actionTypes';

const initialState = {
    isContactsSuccess: false,
    isContactsError: false,
    contacts: [],
    message: '',
    isCreateContactSuccess: false,
    isCreateContactError: false,
    createContact: {}
}

export function contactsReducer(state = initialState, { type, response }) {
    switch (type) {
        case LIST_CONTACTS_REQUEST:
            return {
                ...state,
                isContactsSuccess: false,
                isContactsError: false,
                contacts: []
            };
        case LIST_CONTACTS_SUCCESS:
            return {
                ...state,
                isContactsSuccess: true,
                isContactsError: false,
                contacts: response.contacts
            };
        case LIST_CONTACTS_ERROR:
            return {
                ...state,
                isContactsSuccess: false,
                isContactsError: true,
                contacts: response
            };
        case CREATE_CONTACT_REQUEST:
            return {
                ...state,
                isCreateContactSuccess: false,
                isCreateContactError: false,
                createContact: {}
            };
        case CREATE_CONTACT_SUCCESS:
            return {
                ...state,
                isCreateContactSuccess: true,
                isCreateContactError: false,
                createContact: response
            };
        case CREATE_CONTACT_ERROR:
            return {
                ...state,
                isCreateContactSuccess: false,
                isCreateContactError: true,
                createContact: response
            };
        default:
            return state;
    }
}