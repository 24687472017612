/* eslint-disable */
import "react-toastify/dist/ReactToastify.css";
import "react-step-progress-bar/styles.css";

import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Col, Input, Row } from "reactstrap";
import { Form, Modal } from "react-bootstrap";
import classNames from "classnames";
import { Select, Space } from "antd";
import { toast } from "react-toastify";

import {
  cancelInterviewRequest,
  createContactRequest,
  deleteInterviewRequest,
  fetchInterviewDetailsRequest,
  getCandidateProfileRequest,
  getContactsRequest,
  listInterviewRequest,
  scheduleCandidateInterviewRequest,
} from "../../redux/actions/action";
import styles from "./styles.module.scss";
import loading from "../../images/loading.gif";
import successSvg from "../../images/success.svg";
import Trash from "../../images/delete.png";

import { mappedApplicationStages } from "../../data/jobStages";
import Helper from "../../services/helper";
import DoctorProfile from "./subViews/doctorProfile";
import NurseProfile from "./subViews/nurseProfile";
import Overlay from "../../components/overlay";

const customSelectStyle = {
  width: 629,
  border: "#006d5b solid 1px",
  borderRadius: "30px",
  backgroundColor: "transparent",
  color: "#006d5b",
  height: "44px",
  justifyContent: "space-between",
  padding: "10px, 20px, 10px, 20px",
};

const CURR_DATE = new Date().toISOString().split("T")[0];

function Profile(props) {
  const {
    candidateIds,
    candidateProfileId,
    candidateSubStageId,
    jobOpeningId,
    jobOpeningCode,
    content,
    languageCode,
    isGetCandidateProfileSuccess,
    candidateProfileData,
    isScheduleCandidateInterviewSuccess,
    isScheduleCandidateInterviewError,
    scheduleCandidateInterviewData,
    isListInterviewsSuccess,
    isListInterviewsError,
    listInterviewsData,
    isInterviewDetailsSuccess,
    isInterviewDetailsError,
    interviewDetailsData,
    isDeleteInterviewSuccess,
    isDeleteInterviewError,
    deleteInterviewData,
    isCancelInterviewSuccess,
    isCancelInterviewError,
    cancelInterviewData,
    isContactsSuccess,
    isContactsError,
    contacts,
    isCreateContactSuccess,
    isCreateContactError,
    createContact,
    isChangeApplicationStageSuccess,
    isChangeApplicationStageError,
    profileType,
    jobOpeningName,
    agencySubClientId,
    getCandidateProfileRequest,
    scheduleCandidateInterviewRequest,
    listInterviewRequest,
    fetchInterviewDetailsRequest,
    deleteInterviewRequest,
    cancelInterviewRequest,
    getContactsRequest,
    createContactRequest,
    changeApplicationStageRequest,
    isJobFunnelLoading,
  } = props;

  const [currentProfile, setCurrentProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showNextBtn, setShowNextBtn] = useState(true);
  const [showScheduleInterviewModal, setShowScheduleInterviewModal] =
    useState(false);
  const [
    showScheduleInterviewSuccessModal,
    setShowScheduleInterviewSuccessModal,
  ] = useState(false);
  const [candidateCurrentSubStageId, setCandidateCurrentSubStageId] =
    useState(null);
  const [selectedSubStageId, setSelectedSubStageId] = useState(null);
  const [truncatedText, setTruncatedText] = useState("");
  const [aboutMeTextLength, setAboutMeTextLength] = useState(0);
  const [isShortlisted, setIsShortlisted] = useState(false);
  const [shortlistButtonText, setShortlistButtonText] = useState(
    content.ADD_TO_SHORTLIST,
  );

  const [currClientId, setCurrClientId] = useState(null);

  const [interviewSlots, setInterviewSlots] = useState({
    count: 1,
    slots: [{ interviewDate: "", startTime: "", endTime: "" }],
    comments: "",
  });
  const [optionVisible, setOptionVisible] = useState([
    { startTime: false, endTime: false },
  ]);
  const [timeIntervals, setTimeIntervals] = useState([]);
  const [currEmployerName, setCurrEmployerName] = useState(null);
  const [scheduleInterviewState, setScheduleInterviewState] = useState(false);

  const [attendees, setAttendees] = useState({});
  const [attendeesAddMode, setAttendeesAddMode] = useState(false);
  const [attendeeFirstName, setAttendeeFirstName] = useState("");
  const [attendeeLastName, setAttendeeLastName] = useState("");
  const [attendeeEmail, setAttendeeEmail] = useState("");
  const [newAttendee, setNewAttendee] = useState({});
  const [disableAddAttendeeAction, setDisableAddAttendeeAction] =
    useState(false);

  const [atendeesList, setAtendeesList] = useState([]);
  const [emailValid, setEmailValid] = useState("");
  const [firstNameNotValid, setFirstNameNotValid] = useState(false);
  const prevProps = useRef(props);

  const getLabel = (contact) => (
    <Row>
      <Col className={styles.nameSelectOptionLayout}>{contact.fullName}</Col>
      <Col className={styles.emailSelectOptionLayout}>{contact.email}</Col>
    </Row>
  );

  useEffect(() => {
    if(contacts?.length) {
      setAtendeesList(
        contacts.map((contact) => {
          return {
            value: contact.id,
            label: getLabel(contact),
            id: contact.id,
          };
        }),
        );
      }
  }, [contacts]);

  const getCandidateProfile = async (data) => {
    try {
      setIsLoading(true);
      await getCandidateProfileRequest(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong!");
    }
  };

  const setTimeInterval = (date) => {
    const now = new Date();
    const hours = now.getHours();
    const ampm = hours >= 12 ? "pm" : "am";
    const currentHours = (now.getHours() % 12 || 12)
      .toString()
      .padStart(2, "0");
    const currentMinutes = now.getMinutes().toString().padStart(2, "0");
    const intervals = Helper.intervals(
      `${currentHours}:${currentMinutes} ${ampm}`,
      "11:45 pm",
    );
    setTimeIntervals((previous) => [
      ...previous,
      { startTime: intervals, endTime: intervals },
    ]);
  };

  useEffect(() => {
    if (localStorage.agencyClients) {
      const clientList = JSON.parse(localStorage.agencyClients).clientsList;

      const foundClient = clientList.find(
        (data) => data.id === agencySubClientId,
      );

      if (foundClient) {
        setCurrEmployerName(foundClient.name);
      }
    }
  }, [agencySubClientId]);

  useEffect(() => {
    setTimeInterval();
  }, []);

  const handleToggleShortlist = (isMovedToShortlist) => {
    setShortlistButtonText(
      isMovedToShortlist
        ? content.REMOVE_FROM_SHORTLIST
        : content.ADD_TO_SHORTLIST,
    );
  };

  useEffect(() => {
    fetchListInterviews();
    fetchContacts();
  }, []);

  const fetchInterviewDetails = (interviewId) => {
    const interviewDetailsRequest = {
      interviewId,
      clientId: "99217000000272516",
    };

    fetchInterviewDetailsRequest(interviewDetailsRequest);
  };

  const fetchContacts = () => {
    const contactsRequest = {
      clientId: Helper.getItem("clientId"),
    };

    getContactsRequest(contactsRequest);
  };

  useEffect(() => {
    const { isListInterviewsSuccess: prevIsListInterviewsSuccess } =
      prevProps.current;

    if (!prevIsListInterviewsSuccess && isListInterviewsSuccess) {
      const { interviews } = listInterviewsData;

      if (interviews)
        for (const interview of interviews) {
          fetchInterviewDetails(interview.id);
        }
    }

    prevProps.current = props;
  }, [isListInterviewsSuccess]);

  useEffect(() => {
    const { isCreateContactSuccess: prevIsCreateContactSuccess } =
      prevProps.current;

    if (!prevIsCreateContactSuccess && isCreateContactSuccess) {
      const copyOfAttendees = { ...attendees };
      copyOfAttendees[createContact.id] = newAttendee;

      setAttendees(copyOfAttendees);

      setDisableAddAttendeeAction(false);

      setAttendeesAddMode(false);
    }

    prevProps.current = props;
  }, [isCreateContactSuccess, createContact]);

  useEffect(() => {
    const { isListInterviewsSuccess: prevIsListInterviewsSuccess } =
      prevProps.current;

    if (!prevIsListInterviewsSuccess && isListInterviewsSuccess) {
      const { interviews } = listInterviewsData;

      if (interviews)
        for (const interview of interviews) {
          fetchInterviewDetails(interview.id);
        }
    }

    prevProps.current = props;
  }, [isListInterviewsSuccess]);

  const fetchListInterviews = () => {
    let clientId = Helper.getItem("clientId");

    const candidateProfileObj = {
      candidateId: candidateProfileId,
      clientId,
    };

    listInterviewRequest(candidateProfileObj);
  };

  const convertTruncatedText = () => {
    if (candidateProfileData?.about == undefined) return;

    const words = candidateProfileData?.about.split(" ");
    if (words.length <= 50) {
      setTruncatedText(candidateProfileData.about);
    } else {
      const truncatedWords = words.slice(0, 50);
      const shortText = truncatedWords.join(" ");
      setTruncatedText(shortText);
    }
    setAboutMeTextLength(words.length);
  };

  useEffect(() => {
    let clientId = Helper.getItem("clientId");
    setCurrClientId(clientId);
    setCandidateCurrentSubStageId(candidateSubStageId);
    const candidateProfileObj = {};
    candidateProfileObj.candidateId = candidateProfileId;
    candidateProfileObj.clientId = clientId;

    getCandidateProfile(candidateProfileObj);
  }, []);

  useEffect(() => {
    if ( candidateProfileData?.about) {
      convertTruncatedText();
    }
  }, [candidateProfileData]);

  useEffect(() => {
    const {
      isScheduleCandidateInterviewSuccess:
        prevIsScheduleCandidateInterviewSuccess,
    } = prevProps.current;

    if (
      !prevIsScheduleCandidateInterviewSuccess &&
      isScheduleCandidateInterviewSuccess
    ) {
      setShowScheduleInterviewModal(false);
      setShowScheduleInterviewSuccessModal(true);
      setScheduleInterviewState(false);
      setInterviewSlots({
        count: 1,
        slots: [{ interviewDate: "", startTime: "", endTime: "" }],
        comments: "",
      });
      notification(content.JOB_STATUS_UPDATED_SUCCESSFULLY);
    }

    prevProps.current = props;
  }, [isScheduleCandidateInterviewSuccess]);

  const clearScheduleInterviewState = () => {
    setAttendeeFirstName("");
    setAttendeeLastName("");
    setAttendeeEmail("");
    setNewAttendee("");
    setAtendeesList([]);
  };

  useEffect(() => {
    const {
      isChangeApplicationStageSuccess: prevIsChangeApplicationStageSuccess,
      isChangeApplicationStageError: prevIsChangeApplicationStageError,
    } = prevProps.current;

    if (
      prevIsChangeApplicationStageSuccess === false &&
      isChangeApplicationStageSuccess
    ) {
      setCandidateCurrentSubStageId(selectedSubStageId);
      const isMovedToShortlist = !isShortlisted;
      setIsShortlisted(isMovedToShortlist);
      handleToggleShortlist(isMovedToShortlist);
    }

    if (
      prevIsChangeApplicationStageError === false &&
      isChangeApplicationStageError
    ) {
      let messages = [];
      messages.push("Error");
    }

    prevProps.current = props;
  }, [
    isChangeApplicationStageSuccess,
    isChangeApplicationStageError,
  ]);

  const handleNextProfile = async () => {
    let currentIndex = currentProfile;
    let newIndex = currentIndex + 1;
    let clientId = Helper.getItem("clientId");

    if (newIndex <= candidateIds.length - 1) {
      const nextCandidateProfileObj = new Object();
      nextCandidateProfileObj.candidateId = candidateIds[newIndex].id;
      nextCandidateProfileObj.clientId = clientId;

      getCandidateProfile(nextCandidateProfileObj);

      setCurrentProfile(newIndex);
    }

    if (newIndex === candidateIds.length - 1) {
      setShowNextBtn(false);
    }
  };

  const notification = (messages) => {
    toast.success(messages, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
    });
  };

  const closeScheduleInterviewModal = () => {
    setShowScheduleInterviewModal(false);
    document.getElementById("quickProfileModal").style.filter =
      "brightness(100%)";
    document.getElementById("quickProfileModal").style.backgroundColor = "#fff";
  };

  const closeScheduleInterviewSuccessModal = () => {
    fetchListInterviews();
    document.getElementById("quickProfileModal").style.filter =
      "brightness(100%)";
    setShowScheduleInterviewSuccessModal(false);
    document.getElementById("quickProfileModal").style.backgroundColor = "#fff";
  };

  const scheduleInterview = async (event) => {

    setScheduleInterviewState(true);

    event.preventDefault();

    const convertedInterviewSlots = (interviewSlots?.slots ?? []).map((slot) => {
      return {
        status: "Pending",
        startTime:
          new Date(`${slot.interviewDate} ${slot.startTime}`)
            .toISOString()
            .split(".")[0] + "Z",
        endTime:
          new Date(`${slot.interviewDate} ${slot.endTime}`)
            .toISOString()
            .split(".")[0] + "Z",
      };
    });

    const scheduleInterviewObj = {
      interviewName: `Interview with ${candidateProfileData?.name ?? "Candidate"}`,
      clientId: currClientId,
      candidateId: candidateProfileId,
      candidateName: candidateProfileData?.name,
      jobOpeningTitle: jobOpeningName,
      jobOpeningCode: jobOpeningCode,
      jobId: jobOpeningId,
      interviewers: Object.keys(attendees).join(","),
      startDateTime: convertedInterviewSlots[0].startTime,
      endDateTime: convertedInterviewSlots[0].endTime,
      interviewSlots: convertedInterviewSlots,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      scheduleComments: interviewSlots.comments,
      meetingProvider: "Live Interview",
      questionnaireId: null,
      interviewOwnerId: null,
      employerName: currEmployerName,
    };

    let errorFlag = false;
    await setInterviewSlots((prevInterviewSlots) => ({
      ...prevInterviewSlots,
      slots: (prevInterviewSlots?.slots ?? []).map((data) => {
        const validateTime = validateInterviewTime(
          data.startTime,
          data.endTime,
        );

        if (!validateTime) {
          errorFlag = true;
          return {
            ...data,
            error: "End Time should be greater than Start Time",
          };
        }

        delete data.error;
        return data;
      }),
    }));

    if (errorFlag) return;

    try {
      await scheduleCandidateInterviewRequest(scheduleInterviewObj);
    } catch (error) {
      setScheduleInterviewState(false);

      console.log("something went wrong");
    }
  };

  const getSubStageId = (subStageName) => {
    const foundEntry = Object.entries(mappedApplicationStages).find(
      ([key, value]) => value === subStageName,
    );
    return foundEntry[0];
  };

  const validateInterviewTime = (startTime, endTime) =>
    Helper.convertTo24hr(endTime) > Helper.convertTo24hr(startTime);

  const updateFormData = (e, idx) => {
    const { id } = e.target;

    let value = "";
    if (id === "interviewDate") {
      value = e.target.value;
      if (value > CURR_DATE) {
        const intervals = Helper.intervals("12:00 am", "11:45 pm");
        setTimeIntervals((previous) =>
          (previous ?? []).map((interval, currentIndex) =>
            currentIndex === idx
              ? { ...interval, startTime: intervals, endTime: intervals }
              : interval,
          ),
        );
      }
    } else {
      value = e.target.textContent;
      setOptionVisible((previousState) =>
        (previousState ?? []).map(() => ({ startTime: false, endTime: false })),
      );
    }

    if (id === "startTime") {
      const intervals = Helper.intervals(value, "11:45 pm");

      const filteredIntervals = intervals.filter(
        (ele) => Helper.convertTo24hr(ele) > Helper.convertTo24hr(value),
      );

      setTimeIntervals((previous) =>
        (previous ?? []).map((interval, currentIndex) =>
          currentIndex === idx
            ? { ...interval, endTime: filteredIntervals }
            : interval,
        ),
      );
    }

    const obj = { ...interviewSlots["slots"][idx], [id]: value };
    const newSlots = [...interviewSlots.slots];
    newSlots[idx] = obj;

    setInterviewSlots({ ...interviewSlots, slots: newSlots });
  };

  const now = new Date();
  const hours = now.getHours();
  const ampm = hours >= 12 ? "pm" : "am";
  const currentHours = now.getHours().toString().padStart(2, "0");
  const currentMinutes = now.getMinutes().toString().padStart(2, "0");
  const PROPS = {
    htmlFor: "start",
    date: now,
    intervals: Helper.intervals(
      `${currentHours}:${currentMinutes} ${ampm}`,
      "24:00 PM",
    ),
  };
  const { htmlFor, date, intervals } = PROPS;

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (optionVisible && !event.target.closest(".triggerOutside")) {
        setOptionVisible((previousState) =>
          (previousState ?? []).map(() => ({ startTime: false, endTime: false })),
        );
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [optionVisible]);
  const handleOptionVisible = (type, idx) => {
    setOptionVisible((previousState) => {
      const newStates = (previousState ?? []).map((state, index) => {
        if (index === idx) {
          return {
            ...state,
            [type]: true,
            [type === "startTime" ? "endTime" : "startTime"]: false,
          };
        } else {
          return { ...state, startTime: false, endTime: false };
        }
      });
      return newStates;
    });
  };

  const renderInterviewSlots = () => {
    const slots = [];
    for (let i = 0; i <= interviewSlots.count - 1; i++) {
      slots.push(
        <div style={{ width: "644px" }}>
          <Form.Row key={i} style={{ marginTop: "20px" }}>
            <Form.Group as={Col}>
              <Form.Label className="body-2">
                {content.SELECT_DATE}{" "}
                <span className={styles.requiredField}>*</span>
              </Form.Label>
              <Form.Control
                style={{ width: "210px" }}
                className={classNames(styles.inputFields, "body-2")}
                type="date"
                value={interviewSlots.slots[i].interviewDate}
                onChange={(e) => updateFormData(e, i)}
                placeholder="Enter number of positions"
                id="interviewDate"
                onClick={(e) => e.target.showPicker()}
                min={CURR_DATE}
                required
              />
            </Form.Group>
            <Form.Group as={Col} style={{ marginLeft: "8px" }}>
              <Form.Label className="body-2">
                {content.START_TIME}{" "}
                <span className={styles.requiredField}>*</span>
              </Form.Label>
              <div className={classNames("triggerOutside", styles.container)}>
                <div
                  className={classNames(styles.inputContainer, "body-2")}
                  onClick={() => handleOptionVisible("startTime", i)}
                >
                  <input
                    className={styles.timeInput}
                    type="text"
                    value={interviewSlots.slots[i].startTime}
                    placeholder="--:--"
                    readOnly
                    required
                  />
                  <span className={styles.icon}></span>
                </div>
                {optionVisible[i].startTime && (
                  <div className={styles.optionsContainer}>
                    <div className={styles.list}>
                      {(timeIntervals?.[i]?.["startTime"] ?? []).map((data, idx) => (
                        <div
                          className={styles.options}
                          onClick={(e) => updateFormData(e, i)}
                          key={idx}
                          value={data}
                          id="startTime"
                        >
                          {data}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label className="body-2">
                {content.END_TIME}{" "}
                <span className={styles.requiredField}>*</span>
              </Form.Label>
              <div className={classNames("triggerOutside", styles.container)}>
                <div
                  className={classNames(styles.inputContainer, "body-2")}
                  onClick={() => handleOptionVisible("endTime", i)}
                >
                  <input
                    className={styles.timeInput}
                    type="text"
                    value={interviewSlots.slots[i].endTime}
                    placeholder="--:--"
                    readOnly
                    required
                  />
                  <span className={styles.icon}></span>
                </div>
                {optionVisible[i].endTime && (
                  <div className={styles.optionsContainer}>
                    <div className={styles.list}>
                      {(timeIntervals?.[i]?.["endTime"] ?? []).map((data, idx) => (
                        <div
                          className={styles.options}
                          onClick={(e) => updateFormData(e, i)}
                          key={idx}
                          value={data}
                          id="endTime"
                        >
                          {data}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </Form.Group>
            <button
              onClick={() => handleDeleteSlot(i)}
              type="button"
              className={styles.trash}
              disabled={interviewSlots.count === 1}
            >
              <img
                style={{ marginTop: "32px" }}
                src={Trash}
                alt="Bin"
                width="24px"
                height="24px"
              />
            </button>
          </Form.Row>
          {interviewSlots.slots[i].error && (
            <span className={styles.errorMessage}>
              {interviewSlots.slots[i].error}
            </span>
          )}
        </div>,
      );
    }
    return slots;
  };
  const addNewSlot = () => {
    if (interviewSlots.count < 3) {
      const newInterviewObj = { interviewDate: "", startTime: "", endTime: "" };
      const newSlots = [...interviewSlots.slots];
      newSlots.push(newInterviewObj);
      setInterviewSlots({
        ...interviewSlots,
        count: interviewSlots.count + 1,
        slots: newSlots,
      });
      setOptionVisible((previousState) => [
        ...previousState,
        { startTime: false, endTime: false },
      ]);
      setTimeInterval();
    }
  };

  const handleDeleteSlot = (idx) => {
    if (interviewSlots.count > 1) {
      const newSlots = [...interviewSlots.slots];
      newSlots.splice(idx, idx);
      setInterviewSlots({
        ...interviewSlots,
        count: interviewSlots.count - 1,
        slots: newSlots,
      });

      const newOptionVisible = [...optionVisible];
      newOptionVisible.splice(idx, idx);
      setOptionVisible(newOptionVisible);

      const newTimeIntervals = [...timeIntervals];
      newTimeIntervals.splice(idx, idx);
      setTimeIntervals(newTimeIntervals);
    }
  };

  const renderProfile = () => {
    if (candidateProfileData.message)
      return (
        <div className="error-alert-msg">
          Something went wrong, Please try again later
        </div>
      );

    if (Object.keys(candidateProfileData).length < 1)
      return (
        <div className={styles.profileLoader}>
          <img height="74px" width="74px" src={loading} alt="loading" />
        </div>
      );

    if (candidateProfileData.profession === "Doctor") {
      return (
        <DoctorProfile
          candidateProfileId={candidateProfileId}
          jobOpeningCode={jobOpeningCode}
          jobOpeningId={jobOpeningId}
          candidateProfileData={candidateProfileData}
          changeApplicationStageRequest={changeApplicationStageRequest}
          setSelectedSubStageId={setSelectedSubStageId}
          content={content}
          isShortlisted={isShortlisted}
          setShowScheduleInterviewModal={setShowScheduleInterviewModal}
          candidateCurrentSubStageId={candidateCurrentSubStageId}
          isLoading={isLoading}
          isJobFunnelLoading={isJobFunnelLoading}
          profileType={profileType}
        />
      );
    }

    return (
      <NurseProfile
        candidateProfileId={candidateProfileId}
        jobOpeningCode={jobOpeningCode}
        jobOpeningId={jobOpeningId}
        candidateProfileData={candidateProfileData}
        changeApplicationStageRequest={changeApplicationStageRequest}
        setSelectedSubStageId={setSelectedSubStageId}
        content={content}
        isShortlisted={isShortlisted}
        setShowScheduleInterviewModal={setShowScheduleInterviewModal}
        candidateCurrentSubStageId={candidateCurrentSubStageId}
        isLoading={isLoading}
        isJobFunnelLoading={isJobFunnelLoading}
        profileType={profileType}
      />
    );
  };

  const deleteAttendee = (id) => {
    delete attendees[id];
    setAttendees(attendees);
  };

  const renderAttendees = () => {
    const selectedAttendees = [];

    for (const [id, contact] of Object.entries(attendees)) {
      selectedAttendees.push(
        <>
          <div className={styles.attendeeSectionLayout}>
            <div className={styles.attendeeDetailsLayout}>
              <span className={styles.attendeeImage}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M20 21C20 19.6044 20 18.9067 19.8278 18.3389C19.44 17.0605 18.4395 16.06 17.1611 15.6722C16.5933 15.5 15.8956 15.5 14.5 15.5H9.5C8.10444 15.5 7.40665 15.5 6.83886 15.6722C5.56045 16.06 4.56004 17.0605 4.17224 18.3389C4 18.9067 4 19.6044 4 21M16.5 7.5C16.5 9.98528 14.4853 12 12 12C9.51472 12 7.5 9.98528 7.5 7.5C7.5 5.01472 9.51472 3 12 3C14.4853 3 16.5 5.01472 16.5 7.5Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>

              <span className={styles.attendeeName}>
                <span className={styles.attendeeNameStyle}>
                  {contact.fullName}
                </span>
                <span className={styles.attendeeEmailStyle}>
                  {contact.email}
                </span>
              </span>
            </div>
            <div className={styles.attendeeActionLayout}>
              <span
                className={styles.deleteAttendee}
                onClick={() => deleteAttendee(id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_5260_24902)">
                    <path
                      d="M15.3106 8.69531C15.0001 8.69531 14.7485 8.94692 14.7485 9.25737V19.8803C14.7485 20.1905 15.0001 20.4423 15.3106 20.4423C15.621 20.4423 15.8727 20.1905 15.8727 19.8803V9.25737C15.8727 8.94692 15.621 8.69531 15.3106 8.69531Z"
                      fill="#D50F0F"
                    />
                    <path
                      d="M8.67827 8.69531C8.36782 8.69531 8.11621 8.94692 8.11621 9.25737V19.8803C8.11621 20.1905 8.36782 20.4423 8.67827 20.4423C8.98872 20.4423 9.24033 20.1905 9.24033 19.8803V9.25737C9.24033 8.94692 8.98872 8.69531 8.67827 8.69531Z"
                      fill="#D50F0F"
                    />
                    <path
                      d="M3.84439 7.14503V20.993C3.84439 21.8115 4.14452 22.5801 4.66881 23.1317C5.19069 23.6847 5.91698 23.9987 6.67708 24H17.3114C18.0717 23.9987 18.798 23.6847 19.3197 23.1317C19.844 22.5801 20.1441 21.8115 20.1441 20.993V7.14503C21.1863 6.86839 21.8617 5.86152 21.7223 4.79207C21.5826 3.72284 20.6717 2.923 19.5932 2.92278H16.7155V2.22021C16.7188 1.62939 16.4852 1.06206 16.067 0.644685C15.6487 0.227532 15.0805 -0.00475635 14.4897 7.385e-05H9.49879C8.90797 -0.00475635 8.33976 0.227532 7.92151 0.644685C7.50326 1.06206 7.26965 1.62939 7.27295 2.22021V2.92278H4.39525C3.3168 2.923 2.40587 3.72284 2.26623 4.79207C2.12681 5.86152 2.80216 6.86839 3.84439 7.14503ZM17.3114 22.8759H6.67708C5.71609 22.8759 4.9685 22.0504 4.9685 20.993V7.19443H19.02V20.993C19.02 22.0504 18.2724 22.8759 17.3114 22.8759ZM8.39707 2.22021C8.39333 1.92754 8.50838 1.64585 8.71608 1.43925C8.92356 1.23265 9.2059 1.11914 9.49879 1.12419H14.4897C14.7826 1.11914 15.0649 1.23265 15.2724 1.43925C15.4801 1.64563 15.5952 1.92754 15.5914 2.22021V2.92278H8.39707V2.22021ZM4.39525 4.0469H19.5932C20.152 4.0469 20.6049 4.49984 20.6049 5.05861C20.6049 5.61737 20.152 6.07031 19.5932 6.07031H4.39525C3.83648 6.07031 3.38354 5.61737 3.38354 5.05861C3.38354 4.49984 3.83648 4.0469 4.39525 4.0469Z"
                      fill="#D50F0F"
                    />
                    <path
                      d="M11.9942 8.69531C11.6837 8.69531 11.4321 8.94692 11.4321 9.25737V19.8803C11.4321 20.1905 11.6837 20.4423 11.9942 20.4423C12.3046 20.4423 12.5562 20.1905 12.5562 19.8803V9.25737C12.5562 8.94692 12.3046 8.69531 11.9942 8.69531Z"
                      fill="#D50F0F"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5260_24902">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            </div>
          </div>
          <br />{" "}
        </>,
      );
    }
    return selectedAttendees;
  };
  const attendeesOnSelect = (selectedValue) => {
    attendees[selectedValue] = contacts.find(
      (contact) => contact.id === selectedValue,
    );

    setAttendees(attendees);
  };

  const setNewAttendeeAddMode = () => {
    setAttendeeFirstName("");
    setAttendeeLastName("");
    setAttendeeEmail("");
    setAttendeesAddMode(true);
  };

  const filterOption = (input, option) => {
    const foundContact = contacts.find((data) => data.id === option.id);
    if (!foundContact) {
      return false;
    }

    return (
      foundContact.fullName.toLowerCase().includes(input.toLowerCase()) ||
      foundContact.email?.toLowerCase().includes(input.toLowerCase())
    );
  };

  const renderSelectAttendee = () =>
    !attendeesAddMode ? (
      <>
        <div className="body-2" style={{ marginBottom: "10px" }}>
          {"Attendees"}{" "}
        </div>
        <Select
          showSearch
          bordered={false}
          colortext="#006d5b"
          style={customSelectStyle}
          placeholder={"Search Users"}
          value={null}
          onSelect={attendeesOnSelect}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Space style={{ padding: "0 8px 4px" }}>
                <Form.Row style={{ justifyContent: "start" }}>
                  <button
                    onClick={setNewAttendeeAddMode}
                    style={{ fontSize: "1rem" }}
                    className={styles.addAttendeePopupButtonStyle}
                  >
                    <span className={styles.addAlternateTimeUnderline}>
                      {"Add New Attendee"}
                    </span>
                  </button>
                </Form.Row>
              </Space>
            </>
          )}
          options={atendeesList || []}
          filterOption={filterOption}
        />
      </>
    ) : null;

  const isAttendeeDetailsValid = () => {
    if (!attendeeFirstName) {
      setFirstNameNotValid(true);
      return false;
    } else {
      setFirstNameNotValid(false);
    }

    const isNotValidEmail = Helper.validateEmail(attendeeEmail);

    if (isNotValidEmail) {
      setEmailValid(isNotValidEmail);
      return false;
    } else {
      setEmailValid("");
    }

    return true;
  };
  const addAttendee = () => {
    if (!isAttendeeDetailsValid()) {
      return;
    }

    setDisableAddAttendeeAction(true);

    const addAttendeeRequestPayload = {
      email: attendeeEmail,
      firstName: attendeeFirstName,
      lastName: attendeeLastName,
      mobile: "",
      salutation: "",
      clientId: Helper.getItem("clientId"),
    };

    setNewAttendee({
      email: attendeeEmail,
      fullName: attendeeFirstName + " " + attendeeLastName,
    });

      createContactRequest(addAttendeeRequestPayload);
  };

  const renderAddAttendeeSection = () =>
    attendeesAddMode ? (
      <div className={styles.addAttendeeLayout}>
        <div className={styles.addNewAttendeeTitleLayout}>
          <span className={styles.addNewAttendeeTitleStyle}>
            Add New Attendee Details
          </span>
        </div>
        <div className={styles.attendeeInput}>
          <div className={styles.attendeeNameInputLayout}>
            <div>
              <div className={styles.firstNameTitleLayout}>
                <span>First Name</span>
              </div>
              <div className={styles.firstNameInputStyle}>
                <Input
                  className={styles.inputBoxStyle}
                  type="text"
                  value={attendeeFirstName}
                  onChange={(event) => setAttendeeFirstName(event.target.value )}
                  name="attendeeFirstName"
                  placeholder={"Jonathan"}
                />
              </div>
              {firstNameNotValid ? (
                <div className={styles.errorText}>{"First name required."}</div>
              ) : null}
            </div>
          </div>

          <div className={styles.attendeeNameInputLayout}>
            <div>
              <div className={styles.nameTitleLayout}>
                <span>Last Name</span>
              </div>
              <div className={styles.firstNameInputStyle}>
                <Input
                  className={styles.inputBoxStyle}
                  type="text"
                  value={attendeeLastName}
                  onChange={(event) => setAttendeeLastName(event.target.value)}
                  name="attendeeLastName"
                  placeholder={"Mccarthy"}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.attendeeEmailInputLayout}>
          <div className={styles.attendeeNameInputLayout}>
            <div>
              <div className={styles.nameTitleLayout}>
                <span>Email ID</span>
              </div>
              <div className={styles.emailInputStyle}>
                <Input
                  className={styles.inputBoxStyle}
                  type="text"
                  value={attendeeEmail}
                  onChange={(event) => setAttendeeEmail(event.target.value)}
                  name="attendeeLastName"
                  placeholder={"jonathan.mccarthy@gmail.com"}
                />
              </div>
              {emailValid ? (
                <div className={styles.errorText}>{emailValid}</div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={styles.addAttendeeButtonLayout}>
          <button
            className={styles.addAttendeeButtonStyle}
            onClick={addAttendee}
            disabled={disableAddAttendeeAction}
          >
            <span className={styles.addAttendeeBtnTextStyle}>
              {!disableAddAttendeeAction
                ? "Confirm New Attendee Details"
                : "Adding Attendee..."}
            </span>
          </button>
        </div>
        <div
          className={styles.addAttendeeButtonLayout}
          style={{ marginTop: "-10px" }}
        >
          <button
            className={styles.addAttendeeCancelButtonStyle}
            onClick={() => {
              setAttendeesAddMode(false);
            }}
          >
            <span className={styles.cancelAddAtendeeBtnTextStyle}>Cancel</span>
          </button>
        </div>
      </div>
    ) : null;

  return (
    <>
      {renderProfile()}
      <Overlay
        isOpen={showScheduleInterviewModal}
        showCloseButton={true}
        onClose={closeScheduleInterviewModal}
        classes={{ modal: styles.customModalInterview }}
      >
        <div
          className={styles.modelBody}
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            maxHeight: "62vh",
            minHeight: "62vh",
          }}
        >
          <Form style={{ width: "100%", borderRadius: "15px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3 className={styles.formDataHeading}>
                {content.REQUEST_INTERVIEW_FOR}
                {jobOpeningName ? jobOpeningName : "..."}
                {content.WITH}
                {candidateProfileData?.name}
              </h3>
            </div>
            <div className={classNames("body-2", styles.message)}>
              {content.INTERVIEW_SCHEDULE_NOTE}
            </div>
            {(renderInterviewSlots() ?? []).map((data) => data)}
            <Form.Row style={{ justifyContent: "start" }}>
              <button
                onClick={addNewSlot}
                style={{ fontSize: "1rem" }}
                className={styles.addAlternateTime}
                disabled={interviewSlots.count >= 3}
              >
                <span className={styles.addAlternateTimeUnderline}>
                  {content.ADD_ALTERNATE_TIME}
                </span>
              </button>
            </Form.Row>
            <br />
            {renderAttendees()}
            {renderAddAttendeeSection()}
            {renderSelectAttendee()}
            <br />
            <br />
            <Form.Group controlId="comments">
              <Form.Label className="body-2">
                {content.COMMENTS}
                <span style={{ fontSize: "14px" }}> ({content.OPTIONAL}) </span>
              </Form.Label>
              <Form.Control
                as="textarea"
                maxlength="210"
                style={{
                  paddingRight: "20px  !important",
                  paddingLeft: "20px !important",
                }}
                className={styles.commentBox}
                value={interviewSlots.comments}
                onChange={(e) =>
                  setInterviewSlots({
                    ...interviewSlots,
                    comments: e.target.value,
                  })
                }
                name="comments"
                rows={3}
              />
            </Form.Group>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                marginTop: "30px",
              }}
            >
              <button
                className="button-secondary button-text"
                type="button"
                onClick={closeScheduleInterviewModal}
              >
                {content.CLOSE}
              </button>
              <button
                className="button-primary button-text"
                disabled={scheduleInterviewState}
                onClick={scheduleInterview}
              >
                {scheduleInterviewState
                  ? content.SCHEDULING_INTERVIEW
                  : content.SCHEDULE_INTERVIEW}
              </button>
            </div>
          </Form>
        </div>
      </Overlay>

      <Modal
        show={showScheduleInterviewSuccessModal}
        onHide={closeScheduleInterviewSuccessModal}
        dialogClassName={styles.customModal1}
        keyboard={false}
      >
        <Modal.Body
          style={{ padding: "0px", margin: "0px", border: "none" }}
          className={styles.modelBody}
        >
          <div className={styles.successModal}>
            <div>
              <img
                src={successSvg}
                className={styles.successImg}
                alt="successTickSvg"
              />
              <h4 className={styles.successModalHeading}>
                {content.INTERVIEW_REQUESTED_SUCCESSFULLY}
              </h4>
              <p className={styles.successModalMsg}>
                {content.INTERVIEW_SCHEDULED_SUCCESS_MESSAGE}
              </p>
              <Button
                style={{
                  width: "35%",
                  color: "#006D5B",
                  backgroundColor: "#fff",
                  borderRadius: "50px",
                  padding: "10px 30px",
                  border: "1px solid #006D5B",
                  position: "relative",
                  left: "33%",
                }}
                onClick={closeScheduleInterviewSuccessModal}
              >
                {content.CLOSE}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    content: state.languageReducer.portalContent,
    languageCode: state.languageReducer.languageCode,
    isGetCandidateProfileSuccess:
      state.candidateReducer.isGetCandidateProfileSuccess,
    candidateProfileData: state.candidateReducer.candidateProfileData,
    isScheduleCandidateInterviewSuccess:
      state.candidateReducer.isScheduleCandidateInterviewSuccess,
    isScheduleCandidateInterviewError:
      state.candidateReducer.isScheduleCandidateInterviewError,
    scheduleCandidateInterviewData:
      state.candidateReducer.scheduleCandidateInterviewData,

    isListInterviewsSuccess: state.candidateReducer.isListInterviewsSuccess,
    isListInterviewsError: state.candidateReducer.isListInterviewsError,
    listInterviewsData: state.candidateReducer.listInterviewsData,

    isInterviewDetailsSuccess: state.candidateReducer.isInterviewDetailsSuccess,
    isInterviewDetailsError: state.candidateReducer.isInterviewDetailsError,
    interviewDetailsData: state.candidateReducer.interviewDetailsData,

    isDeleteInterviewSuccess: state.candidateReducer.isDeleteInterviewSuccess,
    isDeleteInterviewError: state.candidateReducer.isDeleteInterviewError,
    deleteInterviewData: state.candidateReducer.deleteInterviewData,

    isCancelInterviewSuccess: state.candidateReducer.isCancelInterviewSuccess,
    isCancelInterviewError: state.candidateReducer.isCancelInterviewError,
    cancelInterviewData: state.candidateReducer.cancelInterviewData,

    isContactsSuccess: state.contactsReducer.isContactsSuccess,
    isContactsError: state.contactsReducer.isContactsError,
    contacts: state.contactsReducer.contacts,

    isCreateContactSuccess: state.contactsReducer.isCreateContactSuccess,
    isCreateContactError: state.contactsReducer.isCreateContactError,
    createContact: state.contactsReducer.createContact,

    isJobFunnelLoading: state.jobsReducer.isJobFunnelLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCandidateProfileRequest: (data) =>
      dispatch(getCandidateProfileRequest(data)),
    scheduleCandidateInterviewRequest: (data) =>
      dispatch(scheduleCandidateInterviewRequest(data)),
    listInterviewRequest: (data) => dispatch(listInterviewRequest(data)),
    fetchInterviewDetailsRequest: (data) =>
      dispatch(fetchInterviewDetailsRequest(data)),

    deleteInterviewRequest: (data) => dispatch(deleteInterviewRequest(data)),
    cancelInterviewRequest: (data) => dispatch(cancelInterviewRequest(data)),

    getContactsRequest: (data) => dispatch(getContactsRequest(data)),

    createContactRequest: (data) => dispatch(createContactRequest(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Profile));
