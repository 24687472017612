import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Modal, Form } from 'react-bootstrap';
import { Col } from 'reactstrap';
import classNames from 'classnames';

import styles from './styles.module.scss';
import Helper from '../../services/helper';
import close from '../../images/closeIconBlack.svg';
import trash from '../../images/trash.png';
import loader from '../../images/loadingWithoutBG.gif';
import uploadIcon from '../../images/uploadFileIcon.svg';
import JobsAPI from '../../apis/jobsApi';
import { AGENCY_CLIENTS } from '../../services/constants';
import Picklist from '../../components/picklist';
import { getPortalContent } from '../../redux/reducers/languageReducer';

const acceptableJobDescriptionFormat = ['pdf', 'doc', 'docx'];

function PostJobModal(props) {
  const {
    addJob,
    showAddJobModal,
    closeAddJobModal,
    clientId,
    epochTime,
  } = props;
  const content = useSelector(getPortalContent);
  const [uploadProgress, setUploadProgress] = useState({});
  const [listOfJDs, setListOfJDs] = useState([]);
  const [isLoading, setIsLoading] = useState({ status: false, timeStamp: '' });
  const GROUP_ID = ['PostJob', 'client', clientId, epochTime].join(':');
  const CURR_DATE = new Date().toISOString().split('T')[0];
  const [postJobFormData, setpostJobFormData] = useState({});
  const [jobPostingForEmployer, setJobPostingForEmployer] = useState(null);
  const [error, setError] = useState({
    status: true,
    errMessage: '',
    code: '',
  });

  const clientsListFormatted = JSON.parse(Helper.getItem(AGENCY_CLIENTS))?.clientsList?.map((client) => ({ value: client.id, label: client.name }));
  const handlePostJobFormDataChange = (e) => {
    const { name, value } = e.target;
    if (e.target.files?.[0]) {
      setpostJobFormData((prevState) => ({
        ...prevState,
        [name]: e.target.files?.[0],
      }));
    } else setpostJobFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFileChangeAndUpload = async (file, idx) => {
    if (!file) return;

    const fileNameParts = file.name.split('.');
    const fileType = fileNameParts[fileNameParts.length - 1].toLowerCase();

    if (!acceptableJobDescriptionFormat.includes(fileType)) {
      setError({
        status: true,
        errMessage:
          'Unsupported file format. Please upload a file in PDF, DOC, or DOCX format.',
        code: 'jobDescription',
      });
      return;
    } if (file.size / (1024 * 1024) > 4) {
      setError({
        status: true,
        errMessage:
          'File size exceeds the allowed limit. Please upload a file smaller than 4mb',
        code: 'jobDescription',
      });
      return;
    }

    setError({ status: false });
    const jdObj = file && {
      fileName: file.name,
      progress: 0,
      timeStamp: file.lastModified,
    };
    setListOfJDs((prevList) => [...prevList, jdObj]);

    const fileBase64format = await Helper.convertFileToBase64(file);

    const body = {
      clientId,
      fileName: fileBase64format.fileName,
      fileContent: fileBase64format.fileData,
      isBase64: true,
      uploadGroupId: GROUP_ID,
    };

    const uploadConfig = {
      onUploadProgress(progressEvent) {
        setUploadProgress({
          ...uploadProgress,
          [jdObj.timeStamp]: Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          ),
        });
      },
    };

    try {
      setIsLoading({ ...isLoading, status: true, [file.lastModified]: true });
      const resp = await JobsAPI.UploadJD(body, uploadConfig);
      jdObj.uploadJobId = resp.data.uploadJobId;
      setListOfJDs((prevList) => {
        const updatedList = [...prevList];
        updatedList[idx] = jdObj;
        return updatedList;
      });
    } catch (e) {
      Helper.notification('Something went wrong', true);
      setIsLoading({ ...isLoading, status: false, [file.lastModified]: false });
    }
    setIsLoading({ ...isLoading, status: false, [file.lastModified]: false });
  };

  const handleJdRemoval = async (
    list,
    uploadJobId = null,
  ) => {
    if (list.length < 1) {
      return;
    }

    const body = {
      clientId,
      uploadGroupId: GROUP_ID,
      attachmentType: 'jobs/jd',
    };

    if (uploadJobId) {
      body.uploadJobIds = [uploadJobId];
    } else {
      body.uploadJobIds = list.map((obj) => obj.uploadJobId);
    }

    try {
      await JobsAPI.DeleteUploadedJD(body);

      const newList = list.filter(
        (item) => item.uploadJobId !== uploadJobId,
      );
      setListOfJDs(newList);
    } catch (e) {
      Helper.notification('Something went wrong', true);
    }
  };

  const setFieldError = (field, errorMessage, code) => {
    setError({ ...error, errMessage: errorMessage, code });
    return false;
  };

  const isFieldEmpty = (field, errorMessage, code) => (postJobFormData[field]
    ? true
    : setFieldError(field, errorMessage, code));

  const handleValidation = () => {
    if (clientsListFormatted?.length > 0 && !jobPostingForEmployer) {
      setFieldError('selectClient', 'Please select employer', 'selectClient');
      return false;
    }
    if (
      !isFieldEmpty(
        'jobPosition',
        content.MANDATORY_FIELD_MSG,
        'jobPosition',
      )
      || !isFieldEmpty(
        'numberOfPositions',
        content.MANDATORY_FIELD_MSG,
        'numberOfPositions',
      )
    ) return false;

    if (listOfJDs.length < 1) {
      setFieldError(
        'jobDescription',
        'Please upload job description file',
        'jobDescription',
      );
      return false;
    }

    setError({ status: false, errMessage: '' });
    return true;
  };

  const handlePostJobSubmit = async (e) => {
    e.preventDefault();

    if (!handleValidation()) return;

    const uploadedJDs = listOfJDs.map((obj) => obj.uploadJobId);
    let formattedDate = '';
    if (postJobFormData.targetDate) formattedDate = postJobFormData.targetDate.split('-').reverse().join('-');

    const body = {
      uploadJobIds: uploadedJDs,
      jobOpeningName: postJobFormData.jobPosition,
      numberOfPositions: postJobFormData.numberOfPositions,
      targetDate: formattedDate,
      comment: postJobFormData.additionalInfo,
      uploadGroupId: GROUP_ID,
      clientId,
      employerName: clientsListFormatted?.length > 0 ? jobPostingForEmployer : null,
    };
    try {
      setIsLoading({ ...isLoading, status: true });
      await JobsAPI.PostNewJob(body);

      setListOfJDs([]);
      setUploadProgress({});
      setpostJobFormData({});
      addJob(); // success popup
      setIsLoading({ ...isLoading, status: false });
    } catch (err) {
      Helper.notification('Something went wrong', true);
      setIsLoading({ ...isLoading, status: true });
    }
  };

  const handleClose = async (listofJDs, groupId) => {
    await handleJdRemoval(listofJDs, groupId);
    setListOfJDs([]);
    setUploadProgress({});
    setpostJobFormData({});
    setJobPostingForEmployer(null);
    setError({ status: true, errMessage: '', code: '' });
    closeAddJobModal();
  };

  const handleClientsListChange = (selectedOption) => {
    setJobPostingForEmployer(selectedOption.label);
    if (error.code === 'selectClient')error.errMessage = '';
  };
  return (
    <Modal
      show={showAddJobModal}
      onHide={closeAddJobModal}
      dialogClassName={styles.customModal}
      keyboard={false}
    >
      <Modal.Body className={styles.modelBody}>
        <div className={styles.headerContent}>
          <h3 className="heading-3">{content.ADD_A_JOB}</h3>
          <img
            className={styles.closeIcon}
            src={close}
            onClick={() => handleClose(listOfJDs)}
            alt="close"
          />
        </div>
        {clientsListFormatted?.length > 0 && (
        <div className={styles.formGroup}>
          <p className={classNames(styles.dropdownMargin, 'body-2')}>
            Select Employer
            <span className={styles.requiredField}>*</span>
          </p>
          <Picklist options={clientsListFormatted} onSelect={handleClientsListChange} dropdownStyle={styles.dropdownHeaderStyle} />
          {error.code === 'selectClient' && (
          <div className="errorText body-3">{error.errMessage}</div>
          )}
        </div>
        )}
        <Form onSubmit={handlePostJobSubmit}>
          <Form.Group controlId="jobPosition" className={styles.formGroup}>
            <Form.Label className="body-2">
              {content.JOB_POSITION}
              <span className={styles.requiredField}>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="jobPosition"
              className={classNames(styles.formControlBox, 'body-2', {
                [styles.errorBox]: error.code === 'jobPosition',
              })}
              value={
                postJobFormData.jobPosition ? postJobFormData.jobPosition : ''
              }
              onChange={handlePostJobFormDataChange}
              placeholder="e.g Nurse Manager - Emergency Department"
            />
            {error.code === 'jobPosition' && (
              <div className="errorText body-3">{error.errMessage}</div>
            )}
          </Form.Group>

          <Form.Row className={styles.formRowGap}>
            <Form.Group
              as={Col}
              controlId="numberOfPositions"
              className={styles.formGroup}
            >
              <Form.Label className="body-2">
                {content.NUMBER_OF_POSITIONS}
                <span className={styles.requiredField}>*</span>
              </Form.Label>
              <Form.Control
                type="number"
                name="numberOfPositions"
                className={classNames(styles.formControlBox, 'body-2', {
                  [styles.errorBox]: error.code === 'numberOfPositions',
                })}
                value={
                  postJobFormData.numberOfPositions
                    ? postJobFormData.numberOfPositions
                    : ''
                }
                onChange={handlePostJobFormDataChange}
              />
              {error.code === 'numberOfPositions' && (
                <div className="errorText body-3">{error.errMessage}</div>
              )}
            </Form.Group>

            <Form.Group
              as={Col}
              controlId="targetDate"
              className={styles.formGroup}
            >
              <Form.Label className="body-2">{content.TARGET_DATE}</Form.Label>
              <Form.Control
                type="date"
                name="targetDate"
                className={classNames(styles.formControlBox, 'body-2')}
                value={
                  postJobFormData.targetDate ? postJobFormData.targetDate : ''
                }
                onChange={handlePostJobFormDataChange}
                min={CURR_DATE}
                onClick={(e) => e.target.showPicker()}
              />
            </Form.Group>
          </Form.Row>

          <Form.Group controlId="jobDescription" className={styles.formGroup}>
            <Form.Label className="body-2">
              {content.JOB_DESCRIPTION}
              <span className={styles.requiredField}>*</span>
            </Form.Label>

            <div>
              {listOfJDs.map((data) => (
                <div
                  key={data.fileName}
                  className={classNames(styles.progressbar, 'body-1')}
                >
                  <div
                    className={styles.progressBar}
                    style={{
                      width: `${uploadProgress[`${data.timeStamp}`]}%`,
                    }}
                  />
                  <div className={styles.progressPercent}>
                    <span>{data.fileName}</span>
                    <span>
                      {uploadProgress[`${data.timeStamp}`] < 100
                        ? `${uploadProgress[`${data.timeStamp}`]}%`
                        : ''}
                    </span>
                    <img
                      onClick={() => handleJdRemoval(
                        listOfJDs,
                        data.uploadJobId,
                        data.timeStamp,
                      )}
                      src={
                        isLoading.status && isLoading[data.timeStamp]
                          ? loader
                          : trash
                      }
                      className={styles.loaderIcon}
                      alt=""
                    />
                  </div>
                </div>
              ))}
            </div>

            {listOfJDs.length < 1 && (
              <>
                <div
                  className={classNames(styles.browseFile, {
                    [styles.errorBox]: error.code === 'jobDescription',
                  })}
                >
                  <input
                    type="file"
                    id="file-input"
                    onChange={(e) => handleFileChangeAndUpload(e.target.files?.[0] || null)}
                    style={{ display: 'none' }}
                    accept=".pdf,.doc,.docx"
                  />
                  <button
                    type="button"
                    onClick={() => document.querySelector('input[type="file"]').click()}
                    className={classNames(styles.browseBtn, 'body-1')}
                  >
                    {' '}
                    <img
                      src={uploadIcon}
                      alt="upload file Icon"
                      className={styles.uploadIcon}
                    />
                    {content.UPLOAD_FILE}
                  </button>
                </div>
                {error.code === 'jobDescription' && (
                  <div className="errorText body-3">{error.errMessage}</div>
                )}

                <div className={classNames(styles.uploadNote, 'body-1')}>
                  {content.POST_JOB_UPLOAD_NOTE}
                </div>
              </>
            )}
          </Form.Group>

          <Form.Group controlId="additionalInfo" className={styles.formGroup}>
            <Form.Label className="body-2">
              {content.ADDITIONAL_INFO}
            </Form.Label>
            <Form.Control
              as="textarea"
              name="additionalInfo"
              className={classNames(styles.additionalInfoBox, 'body-2')}
              value={
                postJobFormData.additionalInfo
                  ? postJobFormData.additionalInfo
                  : ''
              }
              onChange={handlePostJobFormDataChange}
              rows={3}
            />
          </Form.Group>
          <div className={styles.footerDiv}>
            <button
              className="button-secondary button-text"
              type="button"
              onClick={() => handleClose(listOfJDs)}
            >
              {content.CLOSE}
            </button>
            <button
              className="button-primary button-text"
              type="submit"
              disabled={isLoading.status}
            >
              {isLoading.status ? content.SUBMIT_BTN_STATUS : content.ADD_A_JOB}
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default PostJobModal;
