/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  FormGroup, Input, Row, Col,
} from 'reactstrap';
import { forgotPasswordRequest } from '../../redux/actions/action';
import Helper from '../../services/helper';
import 'react-toastify/dist/ReactToastify.css';
import styles from './styles.module.scss';
import Logo from '../../images/ternLogo.svg';
import AlertMessage from '../../subViews/alertMessage';

function ForgotPassword(props) {
  const { content } = props;
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState();
  const [isSuccessAlertMsg, setIsSuccessAlertMsg] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const prevProps = useRef(props);
  const history = useHistory();

  const handleResetPassword = async () => {
    const { forgotPasswordRequest } = props;
    setIsLoading(true);
    if (!validateCredentials(email)) return;

    const forgotPasswordObj = { email };
    await forgotPasswordRequest(forgotPasswordObj);
  };

  const validateCredentials = (email) => {
    const isValidEmailMsg = Helper.validateEmail(email);
    if (isValidEmailMsg) {
      setEmailError(isValidEmailMsg);
      setIsLoading(false);
      return false;
    } setEmailError(null);

    return true;
  };

  useEffect(() => {
    Helper.checkAndRedirectToHomePage(history);
    const {
      isForgotPasswordSuccess: prevIsForgotPasswordSuccess,
      isForgotPasswordError: prevIsForgotPasswordError,
    } = prevProps.current;
    const {
      isForgotPasswordSuccess,
      isForgotPasswordError,
      forgotPasswordData,
    } = props;

    if (prevIsForgotPasswordSuccess === false && isForgotPasswordSuccess) {
      setIsSuccessAlertMsg(true);
      setMessage(forgotPasswordData?.responseMessage);
      setIsLoading(false);
    }

    if (prevIsForgotPasswordError === false && isForgotPasswordError) {
      setIsLoading(false);
      setIsSuccessAlertMsg(false);
      setMessage(forgotPasswordData?.responseMessage);
    }

    prevProps.current = props;
  }, [props.isForgotPasswordSuccess, props.isForgotPasswordError]);

  const onPressEnter = async (e) => {
    if (e.key === 'Enter') handleResetPassword();
  };

  return (
    <div className={styles.content}>
      <div className={styles.ternImg}>
        <Col md="12" className={styles.centeredContainer}>
          <img alt="logo" src={Logo} className={styles.logo} />
        </Col>

        <Col md="12" className={styles.centeredContainer}>
          <div className={classNames(styles.iconFooterText, 'heading-3')}>
            {content.RESET_PASSWORD}
          </div>
        </Col>
      </div>
      <br />
      {message && (
      <Col md="4">
        <AlertMessage isSuccess={isSuccessAlertMsg} message={message} />
        {isSuccessAlertMsg && (
        <Row className={styles.centeredContainer}>
          <Col style={{ textAlign: 'center' }}>
            <a
              href="#"
              className={classNames('button-text')}
              style={{ color: '#3B8D75' }}
              onClick={() => window.location.reload(true)}
            >
              Try again
            </a>
          </Col>
        </Row>
        )}
      </Col>
      )}

      {!isSuccessAlertMsg && (
      <Col md="4">
        <Row className={styles.centeredContainer}>
          <Col>
            <FormGroup>
              <label htmlFor="email" className={styles.labelText}>
                {content.ENTER_GIVEN_EMAIL}
              </label>
              <Input
                id="email"
                className={styles.inputBox}
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={onPressEnter}
              />
              {emailError && (
              <div className={styles.errorText}>{emailError}</div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row className={styles.centeredContainer}>
          <Col>
            <button
              className={classNames(
                styles.submitBtn,
                'button-primary',
                'button-text',
              )}
              color="warning"
              type="button"
              onClick={handleResetPassword}
            >
              {isLoading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                content.RESET_PASSWORD
              )}
            </button>
          </Col>
        </Row>
      </Col>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  content: state.languageReducer.portalContent,
  languageCode: state.languageReducer.languageCode,
  isForgotPasswordSuccess: state.authReducer.isForgotPasswordSuccess,
  isForgotPasswordError: state.authReducer.isForgotPasswordError,
  forgotPasswordData: state.authReducer.forgotPasswordData,
});

const mapDispatchToProps = (dispatch) => ({
  forgotPasswordRequest: (data) => dispatch(forgotPasswordRequest(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ForgotPassword));
