import Helper from '../services/helper';

import { AWS_ENDPOINT } from '../services/constants';
import axiosInstance from '../services/axiosConfig';

class CandidateAPI {
  static GetCandidateProfile = async (request) => axiosInstance.get(`${AWS_ENDPOINT}/getCandidateProfile`, {
    params: request.params,
    headers: Helper.authHeader(),
  });

  static ScheduleCandidateInterview = async (request) => axiosInstance.post(`${AWS_ENDPOINT}/createInterview`, request.params, {
    headers: Helper.authHeader(),
  });

  static UploadCV = async (formData, uploadConfig) => axiosInstance.post(`${AWS_ENDPOINT}/attachments/resume`, formData, {
    ...uploadConfig,
    headers: { ...Helper.authHeader() },
  });

  static submitUploadedCV = async (payload) => axiosInstance.put(`${AWS_ENDPOINT}/attachments/resume`, payload, {
    headers: { ...Helper.authHeader() },
  });

  static deleteUploadedCV = async (payload) => axiosInstance.delete(`${AWS_ENDPOINT}/attachments/resume`, {
    data: payload,
    headers: Helper.authHeader(),
  });

  static ListInterviews = async (request) => axiosInstance.get(`${AWS_ENDPOINT}/listInterviews`, {
    params: request.params,
    headers: Helper.authHeader(),
  });

  static DeleteInterview = async (request) => axiosInstance.delete(`${AWS_ENDPOINT}/deleteInterview`, {
    params: request.params,
    headers: Helper.authHeader(),
  });

  static CancelInterview = async (request) => axiosInstance.put(`${AWS_ENDPOINT}/cancelInterview`, request.params, {
    headers: Helper.authHeader(),
  });

  static FetchInterviewDetails = async (request) => axiosInstance.get(`${AWS_ENDPOINT}/getInterviewDetails`, {
    params: request.params,
    headers: Helper.authHeader(),
  });
}

export default CandidateAPI;
