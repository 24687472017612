/* eslint-disable max-len */
import { takeLatest, put, call } from 'redux-saga/effects';

import {
  USER_LOGIN_REQUEST, USER_FORGOT_PASSWORD_REQUEST, USER_SET_PASSWORD_REQUEST, USER_UPDATE_PASSWORD_REQUEST, USER_LOGOUT_REQUEST, USER_LOGIN_WITH_PHONE_REQUEST,
} from '../actions/actionTypes';
import {
  loginSuccess, loginError, forgotPasswordSuccess, forgotPasswordError, setPasswordSuccess, setPasswordError, updatePasswordSuccess, updatePasswordError, logoutSuccess, logoutError,
} from '../actions/action';
import AuthAPI from '../../apis/authApi';
import Helper from '../../services/helper';

function* login(params) {
  try {
    const response = yield call(AuthAPI.Login, params);
    const resData = response.data;
    if (resData.status === 200) {
      yield put(loginSuccess(resData));
    } else yield put(loginError(resData));
  } catch (error) {
    yield put(loginError(error.response.data));
  }
}

function* loginWithPhone(params) {
  try {
    const response = yield call(AuthAPI.Login, params);
    const resData = response.data;
    if (resData.status === 200) {
      yield put(loginSuccess(resData));
    } else yield put(loginError(resData));
  } catch (error) {
    yield put(loginError(error.response.data));
  }
}

function* forgotPassword(params) {
  try {
    const response = yield call(AuthAPI.ForgotPassword, params);

    const data = yield response.data;

    if (data) {
      yield put(forgotPasswordSuccess(data));
    } else yield put(forgotPasswordError(data));
  } catch (error) {
    yield put(forgotPasswordError(error.response.data));
  }
}

function* setPassword(params) {
  try {
    const response = yield call(AuthAPI.SetPassword, params);

    const data = yield response.data;

    if (data) {
      yield put(setPasswordSuccess(data));
    } else yield put(setPasswordError(data));
  } catch (error) {
    yield put(setPasswordError(error.response.data));
  }
}

function* updatePassword(params) {
  try {
    const response = yield call(AuthAPI.UpdatePassword, params);

    const data = yield response.data;

    if (data) {
      yield put(updatePasswordSuccess(data));
    } else yield put(updatePasswordError(data));
  } catch (error) {
    yield put(updatePasswordError(error.response.data));
  }
}

function* logout(params) {
  try {
    const response = yield call(AuthAPI.Logout, params);

    const data = yield response.data;
    if (data.message === 'success') {
      yield put(logoutSuccess(data));
      Helper.clearStorage();
      Helper.notification('Logged Out Successfully !', false);
    } else {
      yield put(logoutError(data));
      Helper.notification('Error Logging Out User !', true);
    }
  } catch (error) {
    yield put(logoutError(error.response.data));
    Helper.notification('Error Logging Out User !', true);
  }
}

function* authSaga() {
  yield takeLatest(USER_LOGIN_REQUEST, login);
  yield takeLatest(USER_LOGIN_WITH_PHONE_REQUEST, loginWithPhone);
  yield takeLatest(USER_FORGOT_PASSWORD_REQUEST, forgotPassword);
  yield takeLatest(USER_SET_PASSWORD_REQUEST, setPassword);
  yield takeLatest(USER_UPDATE_PASSWORD_REQUEST, updatePassword);
  yield takeLatest(USER_LOGOUT_REQUEST, logout);
}
export default authSaga;
