/* eslint-disable no-nested-ternary */
import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { CardTitle, Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { getPortalContent } from '../../redux/reducers/languageReducer';
import styles from './styles.module.scss';
import loadingSpinner from '../../images/loading.gif';
import visa from '../../images/visa.svg';
import candidate from '../../images/candidate.png';
import relocated from '../../images/relocated.svg';

const ellipsisStyle = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

const RecentActivities = ({
  isRecentActivitiesError,
  recentActivitiesData,
}) => {
  const content = useSelector(getPortalContent);

  if (isRecentActivitiesError) {
    return (
      <Row className={classNames('centerAlignContent', styles.centerAlign)}>{content.SOMETHING_WENT_WRONG}</Row>
    );
  }

  if (recentActivitiesData?.length === 0) {
    return (
      <Row className={classNames('centerAlignContent', styles.centerAlign)}>
        {content.RECENT_ACTIVITY_EMPTY_MSG1}
        <br />
        {content.RECENT_ACTIVITY_EMPTY_MSG2}
      </Row>
    );
  }

  const result = Array.isArray(recentActivitiesData) && recentActivitiesData.length > 0 ? (
    recentActivitiesData.map((data) => (
      <Row key={data.title} className={styles.recentActivityRow}>
        <Col md="2" xs="2">
          <div className={styles.icon}>
            {data.title === 'New candidate added' ? (
              <img className={styles.img} alt={data.title} src={candidate} />
            ) : data.title === 'Visa applied' ? (
              <img className={styles.img} alt={data.title} src={visa} />
            ) : (
              <img className={styles.img} alt={data.title} src={relocated} />
            )}
          </div>
        </Col>
        <Col md="10" xs="10" className={styles.recentActivityCol}>
          <CardTitle
            style={ellipsisStyle}
            className={classNames(
              'subtitle-3',
              'cardTitleMarginBottomMedium',
            )}
          >
            {data.title}
          </CardTitle>
          <CardTitle
            style={ellipsisStyle}
            className={classNames('cardTitleMarginBottomMedium', 'body-3')}
          >
            {data.subTitle}
          </CardTitle>
          <CardTitle
            style={ellipsisStyle}
            className={classNames(styles.dateText, 'body-3')}
          >
            {data.activityDate}
          </CardTitle>
        </Col>
      </Row>
    ))
  ) : (
    <Row className="centerAlignContent">
      <img
        style={{ height: '50%', width: '35%', marginLeft: '20px' }}
        alt="loadingRecentActivityDetails"
        src={loadingSpinner}
      />
    </Row>
  );

  return result;
};

export default RecentActivities;
