import React from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import classNames from 'classnames';
import styles from './styles.module.scss';

function AlertMessage({ isSuccess, message }) {
  return (
    <Row className={styles.centeredContainer}>
      <Col md="6">
        <FormGroup>
          <div
            className={classNames(
              isSuccess ? 'success-alert-msg' : 'error-alert-msg',
              styles.alertText,
            )}
          >
            {message}
          </div>
        </FormGroup>
      </Col>
    </Row>
  );
}

export default AlertMessage;
