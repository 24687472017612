import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import {
  CategoryScale,
  LinearScale,
  Chart,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import {
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  CardFooter,
} from 'reactstrap';
import classNames from 'classnames';
import styles from './styles.module.scss';
import submission from '../../images/shortlisted.svg';
import hired1 from '../../images/ready.svg';
import interview from '../../images/interview.svg';
import offer from '../../images/offer.svg';
import relocatedDot from '../../images/relocated1.svg';
import hired from '../../images/visa1.svg';
import loadingSpinner from '../../images/loading.gif';

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
);
let content;

function CandidateStats({ isCandidateStatsError, candidateStatsData }) {
  Chart.register(Filler);

  const history = useHistory();

  const viewAllJobs = async () => {
    history.push('/all-jobs');
  };

  if (isCandidateStatsError) {
    return (
      <Row style={{ height: '363px' }}>
        <Col md="12" className="centerAlignContent">
          {content.SOMETHING_WENT_WRONG}
        </Col>
      </Row>
    );
  }

  if (candidateStatsData?.totalCandidates === 0) {
    return (
      <>
        <CardHeader className="cardTitlePaddingTopMedium">
          <CardTitle
            className={classNames('subtitle-1', 'cardTitleMarginTopSmall')}
          >
            {content.TOTAL_CANDIDATES}
          </CardTitle>
        </CardHeader>
        <CardBody className="cardBody">
          <Row className="centerAlignContent" style={{ textAlign: 'center' }}>
            <div>
              {content.EMPTY_CAND_STATS_MSG1}
              {' '}
              <br />
              {content.EMPTY_CAND_STATS_MSG2}
              {' '}
              <br />
              <button
                className={classNames(
                  styles.addCandBtn,
                  'button-secondary',
                  'button-text',
                )}
                onClick={viewAllJobs}
                color="warning"
                type="button"
              >
                {content.SELECT_JOB_TO_ADD_CANDIDATE}
              </button>
            </div>
          </Row>
        </CardBody>
        <CardFooter>
          <div className={styles.activityFooter} />
        </CardFooter>
      </>
    );
  }

  const result = candidateStatsData?.applicationStages ? (
    <Row>
      <Col md="5">
        <CardHeader className="cardTitlePaddingTopMedium">
          <CardTitle
            className={classNames(
              'subtitle-1',
              'cardTitleMarginTopSmall',
              'cardTitleMarginBottomRemove',
            )}
          >
            {content.TOTAL_CANDIDATES}
          </CardTitle>
          <h1
            className={classNames('heading-1', 'cardTitleMarginBottomRemove')}
          >
            {candidateStatsData?.totalCandidates}
          </h1>
        </CardHeader>
        <CardBody className={styles.candidatesBody}>
          <Row>
            <Col md="6" xs="6">
              <img
                className={styles.value}
                alt="Value color"
                src={submission}
              />
              <div className={classNames(styles.stagesTextColor, 'caption-1')}>
                {content.SUBMISSIONS}
              </div>
            </Col>
            <Col md="6" xs="6">
              <img className={styles.value} alt="Value color" src={hired} />
              <div className={classNames(styles.stagesTextColor, 'caption-1')}>
                {content.HIRED}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6" xs="6">
              <img className={styles.value} alt="Value color" src={interview} />
              <div className={classNames(styles.stagesTextColor, 'caption-1')}>
                {content.INTERVIEW}
              </div>
            </Col>
            <Col md="6" xs="6">
              <img className={styles.value} alt="Value color" src={hired1} />
              <div className={classNames(styles.stagesTextColor, 'caption-1')}>
                {content.VISA_APPLIED}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6" xs="6">
              <img className={styles.value} alt="Value color" src={offer} />
              <div className={classNames(styles.stagesTextColor, 'caption-1')}>
                {content.OFFERED}
              </div>
            </Col>
            <Col md="6" xs="6">
              <img
                className={styles.value}
                alt="Value color"
                src={relocatedDot}
              />
              <div className={classNames(styles.stagesTextColor, 'caption-1')}>
                {content.RELOCATED}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Col>
      <Col md="7">
        <CardBody className={styles.candidatesChart}>
          <Bar
            data={{
              labels: [
                content.SUBMISSIONS,
                content.INTERVIEW,
                content.OFFERED,
                content.HIRED,
                content.VISA_APPLIED,
                content.RELOCATED,
              ],

              datasets: [
                {
                  data: candidateStatsData?.applicationStages
                    ?.slice(0, 6)
                    .map((stage) => stage.count),
                  borderColor: '#66615B',
                  fill: true,
                  backgroundColor: [
                    '#FFC44299',
                    '#5D45DB99',
                    '#FF696D99',
                    '#00C07F99',
                    '#3F76C999',
                    '#AD00FF99',
                  ],
                  barPercentage: 0.5,
                  showLabelBackdrop: true,
                },
              ],
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,

              plugins: {
                labels: false,
                legend: {
                  display: false,
                },
                tooltips: {
                  tooltipFillColor: 'rgba(0,0,0,0.5)',
                  tooltipFontFamily:
                    "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                  tooltipFontSize: 14,
                  tooltipFontStyle: 'normal',
                  tooltipFontColor: '#fff',
                  tooltipTitleFontFamily:
                    "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                  tooltipTitleFontSize: 14,
                  tooltipTitleFontStyle: 'bold',
                  tooltipTitleFontColor: '#fff',
                  tooltipYPadding: 6,
                  tooltipXPadding: 6,
                  tooltipCaretSize: 8,
                  tooltipCornerRadius: 6,
                  tooltipXOffset: 10,
                },
              },
              scales: {
                x: {
                  grid: {
                    display: false,
                  },
                },
                y: {
                  ticks: {
                    color: '#9f9f9f',
                    beginAtZero: true,
                    maxTicksLimit: 6,
                    padding: 10,
                    precision: 0,
                  },
                  grid: {
                    display: false,
                  },
                },
              },
            }}
          />
        </CardBody>
      </Col>
    </Row>
  ) : (
    <Row style={{ height: '363px' }}>
      <Col
        md="12"
        style={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          style={{ height: '32%', width: '15%', marginTop: '35px' }}
          alt="loadingRecentActivityDetails"
          src={loadingSpinner}
        />
      </Col>
    </Row>
  );

  return result;
}

const mapStateToProps = (state) => {
  content = state.languageReducer.portalContent;
  return {
    content: state.languageReducer.portalContent,
    languageCode: state.languageReducer.languageCode,
  };
};

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CandidateStats));
