/* eslint-disable default-param-last */
import { GET_ALL_JOBS_REQUEST, GET_ALL_JOBS_REQUEST_SUCCESS, GET_ALL_JOBS_REQUEST_ERROR } from '../actions/actionTypes';

const initialState = {
  isAllJobsSuccess: false,
  isAllJobsError: false,
  isAllJobsData: {},
  message: '',
};

export function allJobsReducer(state = initialState, { type, response }) {
  switch (type) {
    case GET_ALL_JOBS_REQUEST:
      return {
        ...state,
        isAllJobsSuccess: false,
        isAllJobsError: false,
        isAllJobsData: {},
      };
    case GET_ALL_JOBS_REQUEST_SUCCESS:
      return {
        ...state,
        isAllJobsSuccess: true,
        isAllJobsError: false,
        isAllJobsData: response,
      };
    case GET_ALL_JOBS_REQUEST_ERROR:
      return {
        ...state,
        isAllJobsSuccess: false,
        isAllJobsError: true,
        isAllJobsData: response,
      };
    default:
      return state;
  }
}
