import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { CardTitle, Row, Col } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import styles from './styles.module.scss';
import loadingSpinner from '../../images/loading.gif';

const ellipsisStyle = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};
let content;
function UpcomingInterviews({
  isUpcomingInterviewsError,
  upcomingInterviewsData,
}) {
  if (isUpcomingInterviewsError) {
    return (
      <Row className="centerAlignContent">{content.SOMETHING_WENT_WRONG}</Row>
    );
  }

  if (upcomingInterviewsData?.length === 0) {
    return (
      <Row className={classNames('centerAlignContent', styles.centerAlign)}>{content.INTERVIEWS_EMPTY_MSG}</Row>
    );
  }

  const result = Array.isArray(upcomingInterviewsData)
    && upcomingInterviewsData?.length > 0 ? (
      upcomingInterviewsData.map((data) => (
        <Row key={data.interviewId} className={styles.upcomingInterviews}>
          <Row className={styles.rectangle}>
            <Col md="10" xs="10" className={styles.activityCol}>
              <CardTitle
                style={ellipsisStyle}
                className={classNames(
                  'body-1',
                  'cardTitleMarginBottomMedium',
                )}
              >
                {content.INTERVIEW}
                :
                {data.candidateName}
              </CardTitle>
              <CardTitle
                style={ellipsisStyle}
                className={classNames(
                  'subtitle-3',
                  'cardTitleMarginBottomMedium',
                )}
              >
                For
                {' '}
                {data.jobTitle}
                {' '}
              </CardTitle>
              <CardTitle style={ellipsisStyle} className="body-3">
                {data.interviewStartTime}
                {' '}
                -
                {data.interviewEndTime}
              </CardTitle>
            </Col>
          </Row>
        </Row>
      ))
    ) : (
      <Row className="centerAlignContent">
        <img
          style={{ height: '50%', width: '35%', marginLeft: '20px' }}
          alt="loadingRecentActivityDetails"
          src={loadingSpinner}
        />
      </Row>
    );

  return result;
}

const mapStateToProps = (state) => {
  content = state.languageReducer.portalContent;
  return {
    content: state.languageReducer.portalContent,
    languageCode: state.languageReducer.languageCode,
  };
};

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(UpcomingInterviews));
