/* eslint-disable */
import React from 'react';
import cx from 'classnames';
import { Modal } from 'react-responsive-modal';
import styles from './styles.module.scss';
import 'react-responsive-modal/styles.css';

const closeIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 26 26" fill="none">
    <path d="M2 2.42328L23.5778 24M2.42217 23.5767L24 2" stroke="#666E82" strokeWidth="3" strokeLinecap="round" />
  </svg>
);

function Overlay({
  isOpen, showCloseIcon, onClose, classes = {}, children, ...restProps
}) {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      showCloseIcon={showCloseIcon}
      closeIcon={closeIcon}
      center
      classNames={{
        overlay: cx(styles.customOverlay, classes.overlay),
        modal: cx(styles.customModal, classes.modal),
        closeButton: cx(styles.closeButton, classes.closeButton),
      }}
      {...restProps}
    >
      {children}
    </Modal>
  );
}
export default Overlay;
