import React from 'react';
import { Card, Row, Col } from 'reactstrap';
import CardHeaderJobStats from './cardHeaderJobStats';
import EmptyCardBodyJobStats from './emptyCardBodyJobStats';
import CardBodyJobStats from './cardBodyJobStats';
import styles from './styles.module.scss';
import { jobCandidateCount } from './helpers';

const JobStatsCard = ({
  data,
  content,
  client,
  jobStatsData,
  jobIndex,
  isJobDetailsPage,
  viewFilterJob,
  funnelParams,
}) => {
  const renderCardBody = () => {
    if (jobCandidateCount(data)) {
      return (
        <CardBodyJobStats
          data={data}
          content={content}
          jobIndex={jobIndex}
          viewFilterJob={viewFilterJob}
          jobStatsData={jobStatsData}
          funnelParams={funnelParams}
        />
      );
    }

    return (
      <EmptyCardBodyJobStats
        jobStatsData={jobStatsData}
        jobIndex={jobIndex}
      />
    );
  };

  return (
    <Row key={data.clientId} className={styles.jobRow}>
      <Col>
        <Card>
          <CardHeaderJobStats
            client={client}
            content={content}
            jobStatsData={jobStatsData}
            jobIndex={jobIndex}
            data={data}
            isJobDetailsPage={isJobDetailsPage}
          />
          {renderCardBody()}
        </Card>
      </Col>
    </Row>
  );
};

export default JobStatsCard;
