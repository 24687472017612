import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import styles from './styles.module.scss';
import ReportCard from './reportCard';

import filterLines from '../../images/filter-lines.png';
import notificationBox from '../../images/notification-box.png';
import clockFastForward from '../../images/clock-fast-forward.png';
import horizontalBarChart from '../../images/horizontal-bar-chart-01.png';
import SuccessModal from '../../modals/successModal';

function Reports({ content }) {
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const renderCardDetails = [
    {
      title: 'Candidate Pipeline Report',
      desc: 'Aggregated view of all open jobs and number of candidates.',
      imgSrc: filterLines,
      buttonTitle: content.REQUEST_REPORT,
    },
    {
      title: 'Candidates By Status',
      desc: 'Number of Candidates from Tern in active job pipelines',
      imgSrc: notificationBox,
      buttonTitle: content.REQUEST_REPORT,
    },
    {
      title: 'Time to Hire',
      desc: 'Report to show details on job posting to interview and rest stages',
      imgSrc: clockFastForward,
      buttonTitle: content.REQUEST_REPORT,
    },
    {
      title: 'Application by Status',
      desc: 'Number of Candidates from Tern in active job pipelines',
      imgSrc: horizontalBarChart,
      buttonTitle: content.REQUEST_REPORT,
    },
  ];

  return (
    <>
      <div className={styles.reportsContainer}>
        <div className="heading-3">{content.STATUS_REPORTS}</div>
        <div className={styles.cardParentContainer}>
          {renderCardDetails.map((obj) => (
            <ReportCard
              key={`${obj.title}:${obj.desc}`}
              title={obj.title}
              desc={obj.desc}
              imgSrc={obj.imgSrc}
              buttonTitle={obj.buttonTitle}
              setShowSuccessModal={setShowSuccessModal}
            />
          ))}
        </div>
      </div>

      <SuccessModal
        heading={content.REQUEST_SUBMITTED_SUCCESSFULL}
        successMsg={content.REPORT_SUCCESS_NOTE}
        showSuccessModal={showSuccessModal}
        closeSuccessModal={() => setShowSuccessModal(false)}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  content: state.languageReducer.portalContent,
  languageCode: state.languageReducer.languageCode,
});

export default connect(mapStateToProps)(withRouter(Reports));
