/* eslint-disable */
global.translatedContent = null;
function getLocalTranslations(portalLanguages) {
  const objLang = new Object();

  for (const pLang of portalLanguages) {
    const key = pLang.substring(0, 2);
    objLang[key] = require(`../languages/${pLang.substring(0, 2)}.json`);
  }

  global.translatedContent = objLang;

  localStorage.setItem('translatedContent', JSON.stringify(global.translatedContent));
}
export async function getTranslations() {
  let portalLanguages = [];

  await fetch(`${window.location.origin}/config.json`)
    .then((r) => r.json())
    .then((data) => {
      portalLanguages = data.portalLanguages;
    });

  getLocalTranslations(portalLanguages);
}
