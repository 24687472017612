import React from 'react';
import LoaderImg from '../../images/loading.gif';
import styles from './styles.module.scss';

function Loader(props) {
  const { loaderHeight = '40px', loaderWidth = '40px' } = props;
  return (
    <div className={styles.loader}>
      <img height={loaderHeight} width={loaderWidth} src={LoaderImg} alt="loader" />
    </div>
  );
}

export default Loader;
