import Helper from '../services/helper';

import { FILTER_JOBS } from '../services/urls';
import axiosInstance from '../services/axiosConfig';

class JobsAPI {
  static getAllJobs = async (request) => axiosInstance.get(
    `${FILTER_JOBS}`,
    {
      params: request.params,
      headers: Helper.authHeader(),
    },
  );
}

export default JobsAPI;
