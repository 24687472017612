/* eslint-disable max-len */
import { takeLatest, put, call } from 'redux-saga/effects';
import {
  GET_UPCOMING_INTERVIEWS_REQUEST, GET_RECENT_ACTIVITIES_REQUEST, GET_JOB_STATS_REQUEST, GET_USER_DETAILS_REQUEST, GET_AGENCY_CLIENTS_REQUEST, GET_CANDIDATE_STATS_REQUEST,
} from '../actions/actionTypes';
import {
  getUserDetailsSuccess, getUserDetailsError, getCandidateStatsSuccess, getCandidateStatsError,
  getUpcomingInterviewsSuccess, getUpcomingInterviewsError, getRecentActivitiesSuccess, getRecentActivitiesError, getJobStatsSuccess, getJobStatsError, getAgencyClientsError, getAgencyClientsSuccess,
} from '../actions/action';
import dashboardAPI from '../../apis/dashboardApi';
import { AGENCY_CLIENTS } from '../../services/constants';

function* getUserDetails(params) {
  try {
    const response = yield call(dashboardAPI.GetUserDetails, params);

    const data = yield response.data;

    if (data.message !== 'fail') yield put(getUserDetailsSuccess(data.payload));
    else yield put(getUserDetailsError(data));
  } catch (error) {
    yield put(getUserDetailsError(error.message));
  }
}

function* getUpcomingInterviews(params) {
  try {
    const response = yield call(dashboardAPI.GetUpcomingInterviews, params);

    const data = yield response.data;

    // console.log("data: " + JSON.stringify(data));

    if (data.message !== 'fail') yield put(getUpcomingInterviewsSuccess(data.payload));
    else yield put(getUpcomingInterviewsError(data));
  } catch (error) {
    yield put(getUpcomingInterviewsError(error.message));
  }
}

function* getRecentActivities(params) {
  try {
    const response = yield call(dashboardAPI.GetRecentActivities, params);

    const data = yield response.data;

    // console.log("data: " + JSON.stringify(data));

    if (data.message !== 'fail') yield put(getRecentActivitiesSuccess(data.payload));
    else yield put(getRecentActivitiesError(data));
  } catch (error) {
    yield put(getRecentActivitiesError(error.message));
  }
}

function* getJobStats(params) {
  try {
    const response = yield call(dashboardAPI.GetJobStats, params);

    const data = yield response.data;

    if (data.message !== 'fail') yield put(getJobStatsSuccess(data.payload));
    else yield put(getJobStatsError(data));
  } catch (error) {
    yield put(getJobStatsError(error.message));
  }
}

function* getAgencyClients(params) {
  try {
    const response = yield call(dashboardAPI.GetAgencyClients, params);

    const data = yield response.data;
    localStorage.setItem(AGENCY_CLIENTS, JSON.stringify(data.payload));

    if (data.message !== 'fail') yield put(getAgencyClientsSuccess(data.payload));
    else yield put(getAgencyClientsError(data));
  } catch (error) {
    yield put(getAgencyClientsError(error.message));
  }
}

function* getCandidateStats(params) {
  try {
    const response = yield call(dashboardAPI.GetCandidateStats, params);

    const data = yield response.data;

    if (data.message !== 'fail') yield put(getCandidateStatsSuccess(data.payload));
    else yield put(getCandidateStatsError(data));
  } catch (error) {
    yield put(getCandidateStatsError(error.message));
  }
}

function* dashboardSaga() {
  yield takeLatest(GET_USER_DETAILS_REQUEST, getUserDetails);
  yield takeLatest(GET_UPCOMING_INTERVIEWS_REQUEST, getUpcomingInterviews);
  yield takeLatest(GET_RECENT_ACTIVITIES_REQUEST, getRecentActivities);
  yield takeLatest(GET_JOB_STATS_REQUEST, getJobStats);
  yield takeLatest(GET_CANDIDATE_STATS_REQUEST, getCandidateStats);
  yield takeLatest(GET_AGENCY_CLIENTS_REQUEST, getAgencyClients);
}

export default dashboardSaga;
