const STATUS_100 = 100;
const STATUS_200 = 200;
const STATUS_300 = 300;
const STATUS_400 = 400;
const STATUS_500 = 500;

export const STATUS = {
  STATUS_100,
  STATUS_200,
  STATUS_300,
  STATUS_400,
  STATUS_500,
};
