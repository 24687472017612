/* eslint-disable */
import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { Card, CardBody, Row, Col } from "reactstrap";
import { Select } from "antd";
import { Tabs, Tab } from "react-bootstrap";
import { ProgressBar } from "react-step-progress-bar";
import classNames from "classnames";

import loading from "../../../../images/loading.gif";
import verified from "../../../../images/verified.png";
import maskGroup from "../../../../images/profilepic.jpg";
import download from "../../../../images/download.svg";

import {
  stageChangeOptions,
  mappedApplicationStages,
} from '../../../../data/jobStages';
import Helper from '../../../../services/helper';

import Interviews from "../interviewDetails";
import { Collapse, Space } from "antd";
import '../accordionStyles.css';
import greenDownArrow from '../../../../images/greenDownArrow.png';

import WorkExperience from "../../subSections/workExperience";

const customSelectStyle = {
  width: 210,
  height: 46,
  border: '#006d5b solid 1px',
  borderRadius: '30px',
  backgroundColor: 'transparent',
  color: '#006d5b',
  fontFamily: 'Poppins',
  fontSize: '1rem !important',
  textAlign: 'center',
  margin: '0px 5px'
};

function NurseProfile(props) {
  const {
    candidateProfileId,
    jobOpeningCode,
    jobOpeningId,
    candidateProfileData,
    changeApplicationStageRequest,
    setSelectedSubStageId,
    content,
    isShortlisted,
    setShowScheduleInterviewModal,
    candidateCurrentSubStageId,
    isLoading,
    isJobFunnelLoading,
    profileType,
  } = props;
  const [showFullText, setShowFullText] = useState(true);
  const [referencesDetailsAccordion, setReferencesDetailsAccordion] = useState(1);
  const [languageDetailsAccordion, setLanguageDetailsAccordion] = useState(1);
  const [englishProficiencyAccordion, setEnglishProficiencyAccordion] = useState(1);
  const [drivingLicenseStatusAccordion, setDrivingLicenseStatusAccordion] = useState(1);
  const [truncatedText, setTruncatedText] = useState('');
  const [aboutMeTextLength, setAboutMeTextLength] = useState(0);
  const [currClientId, setCurrClientId] = useState(null);
  const [navId, setNavId] = useState(1);

  useEffect(() => {
    const clientId = Helper.getItem('clientId');
    setCurrClientId(clientId);
    toggleText();
  }, []);

  useEffect(() => {
    toggleText();
  }, [truncatedText, candidateProfileData]);

  const getSubStageId = (subStageName) => {
    const foundEntry = Object.entries(mappedApplicationStages).find(
      ([key, value]) => value === subStageName,
    );
    return foundEntry[0];
  };

  const showDocument = (url) => {
    window.open(url, '_blank');
  };

  const toggleText = () => {
    if (!showFullText) {
      convertTruncatedText();
    }
    setShowFullText(!showFullText);
  };
  const convertTruncatedText = () => {
    if (candidateProfileData.about == undefined) return;

    const words = candidateProfileData.about.split(' ');
    if (words.length <= 35) {
      setTruncatedText(candidateProfileData.about);
    } else {
      const truncatedWords = words.slice(0, 35);
      const shortText = truncatedWords.join(' ');
      setTruncatedText(shortText);
    }
    setAboutMeTextLength(words.length);
  };

  const openScheduleInterviewModal = () => {
    setShowScheduleInterviewModal(true);
  };

  const handleStageChange = async (selectedOption) => {
    setSelectedSubStageId(getSubStageId(selectedOption));
    const changeApplicationStageObject = {
      candidateId: candidateProfileId,
      jobOpeningId,
      stageId: '',
      jobOpeningCode,
      applicationStageId: getSubStageId(selectedOption),
      applicationStageName: selectedOption,
      clientId: currClientId,
      isAssociated: true,
    };
    // Call API function
    changeApplicationStageRequest(changeApplicationStageObject);
  };

  const expandIcon = ({ isActive }) =>
    isActive ? (
      <img src={greenDownArrow} height="12px" alt="" />
    ) : (
      <img
        src={greenDownArrow}
        height="12px"
        style={{ rotate: "180deg", transition: "transform 0.3s ease" }}
        alt=""
      />
    );

  const candidateDetails = () => (
    <div className={styles.profileIntoContainer}>
      {candidateProfileData?.experience && (
      <div>
        <div className={styles.textWrapper69}>
          {candidateProfileData?.experience}
        </div>
        <div className={styles.textWrapper68}>{content.EXPERIENCE}</div>
      </div>
      )}
      {candidateProfileData.availableIn && (
      <div className={styles.relocationTab}>
        <div className={styles.textWrapper69}>
          {candidateProfileData?.availableIn}
        </div>
        <div className={styles.textWrapper68}>{content.AVAILABLE_IN}</div>
      </div>
      )}
      {candidateProfileData?.ieltsScore && (
      <div>
        <div className={styles.textWrapper69}>
          {candidateProfileData?.ieltsScore}
        </div>
        <div className={styles.textWrapper68}>{content.IELTS_SCORE}</div>
      </div>
      )}
    </div>
  );

  const languageDetails = () => (
    candidateProfileData.languages && (
    <Space direction="vertical" className={styles.AccordionHeader}>
      <Collapse
        ghost
        collapsible="header"
        activeKey={languageDetailsAccordion}
        onChange={(key) => setLanguageDetailsAccordion(key)}
        className="profile"
        expandIcon={expandIcon}
        items={[
          {
            key: 1,
            label: content.LANGUAGES,
            className: styles.textWrapper48,
            children: (
              <div className="body-1">{candidateProfileData.languages}</div>
            ),
          },
        ]}
      />
    </Space>
    )
  );

  const englishProficiency = () => {
    const isIELTSEmpty = Helper.checkEmptyValues(
      candidateProfileData?.ieltsDetails ?? {},
      ['grade'],
    );
    const isOETEmpty = Helper.checkEmptyValues(
      candidateProfileData.oetDetails ?? {},
      ['grade'],
    );

    return (
      <>
        {isIELTSEmpty && isOETEmpty ? null : (
          <Space direction="vertical" className={styles.AccordionHeader}>
            <Collapse
              ghost
              collapsible="header"
              activeKey={englishProficiencyAccordion}
              onChange={(key) => setEnglishProficiencyAccordion(key)}
              className="profile"
              expandIcon={expandIcon}
              items={[
                {
                  key: 1,
                  label: content.ENGLISH_PROFICIENCY,
                  children: (
                    <div>
                      {!isIELTSEmpty && (
                        <>
                          <div className="subtitle-1">IELTS</div>
                          <Row className={styles.noMargin}>
                            <Col md="6" className={styles.noPadding}>
                              <div className={styles.textWrapper49}>
                                {content.LISTENING}
                              </div>
                            </Col>
                            <Col md="5" className={styles.progressBar}>
                              <ProgressBar
                                percent={
                                  candidateProfileData.ieltsDetails?.listening
                                  * 9
                                }
                                filledBackground="#006d5b"
                                width="100%"
                              />
                            </Col>
                            <Col md="1" className={styles.listeningCol}>
                              {candidateProfileData.ieltsDetails?.listening}
                            </Col>
                          </Row>
                          <Row className={styles.noMargin}>
                            <Col md="6" className={styles.noPadding}>
                              <div className={styles.textWrapper49}>
                                {content.READING}
                              </div>
                            </Col>
                            <Col md="5" className={styles.progressBar}>
                              <ProgressBar
                                percent={
                                  candidateProfileData.ieltsDetails?.reading * 9
                                }
                                filledBackground="#006d5b"
                              />
                            </Col>
                            <Col md="1" className={styles.listeningCol}>
                              {candidateProfileData.ieltsDetails?.reading}
                            </Col>
                          </Row>
                          <Row className={styles.noMargin}>
                            <Col md="6" className={styles.noPadding}>
                              <div className={styles.textWrapper49}>
                                {content.WRITING}
                              </div>
                            </Col>
                            <Col md="5" className={styles.progressBar}>
                              <ProgressBar
                                percent={
                                  candidateProfileData.ieltsDetails?.writing * 9
                                }
                                filledBackground="#006d5b"
                              />
                            </Col>
                            <Col md="1" className={styles.listeningCol}>
                              {candidateProfileData.ieltsDetails?.writing}
                            </Col>
                          </Row>
                          <Row className={styles.noMargin}>
                            <Col md="6" className={styles.noPadding}>
                              <div className={styles.textWrapper49}>
                                {content.SPEAKING}
                              </div>
                            </Col>
                            <Col md="5" className={styles.progressBar}>
                              <ProgressBar
                                percent={
                                  candidateProfileData.ieltsDetails?.speaking
                                  * 9
                                }
                                filledBackground="#006d5b"
                              />
                            </Col>
                            <Col md="1" className={styles.listeningCol}>
                              {candidateProfileData.ieltsDetails?.speaking}
                            </Col>
                          </Row>
                          <Row className={styles.noMargin}>
                            <Col md="6" className={styles.noPadding}>
                              <div className={styles.textWrapper49}>
                                {content.OVERALL_BAND_SCORE}
                              </div>
                            </Col>
                            <Col md="5" className={styles.progressBar}>
                              <ProgressBar
                                percent={
                                  candidateProfileData.ieltsDetails?.overall * 9
                                }
                                filledBackground="#006d5b"
                                className={styles.progressBar}
                              />
                            </Col>
                            <Col md="1" className={styles.listeningCol}>
                              {candidateProfileData.ieltsDetails?.overall}
                            </Col>
                          </Row>
                          <br />
                        </>
                      )}
                      {!isOETEmpty && (
                        <>
                          <div className="subtitle-1">OET</div>
                          <Row className={styles.noMargin}>
                            <Col md="6" className={styles.noPadding}>
                              <div className={styles.textWrapper49}>
                                {content.LISTENING}
                              </div>
                            </Col>
                            <Col md="5" className={styles.progressBar}>
                              <ProgressBar
                                percent={
                                  candidateProfileData.oetDetails?.listening * 9
                                }
                                filledBackground="#006d5b"
                                width="100%"
                              />
                            </Col>
                            <Col md="1" className={styles.listeningCol}>
                              {candidateProfileData.oetDetails?.listening}
                            </Col>
                          </Row>
                          <Row className={styles.noMargin}>
                            <Col md="6" className={styles.noPadding}>
                              <div className={styles.textWrapper49}>
                                {content.READING}
                              </div>
                            </Col>
                            <Col md="5" className={styles.progressBar}>
                              <ProgressBar
                                percent={
                                  candidateProfileData.oetDetails?.reading * 9
                                }
                                filledBackground="#006d5b"
                              />
                            </Col>
                            <Col md="1" className={styles.listeningCol}>
                              {candidateProfileData.oetDetails?.reading}
                            </Col>
                          </Row>
                          <Row className={styles.noMargin}>
                            <Col md="6" className={styles.noPadding}>
                              <div className={styles.textWrapper49}>
                                {content.WRITING}
                              </div>
                            </Col>
                            <Col md="5" className={styles.progressBar}>
                              <ProgressBar
                                percent={
                                  candidateProfileData.oetDetails?.writing * 9
                                }
                                filledBackground="#006d5b"
                              />
                            </Col>
                            <Col md="1" className={styles.listeningCol}>
                              {candidateProfileData.oetDetails?.writing}
                            </Col>
                          </Row>
                          <Row className={styles.noMargin}>
                            <Col md="6" className={styles.noPadding}>
                              <div className={styles.textWrapper49}>
                                {content.SPEAKING}
                              </div>
                            </Col>
                            <Col md="5" className={styles.progressBar}>
                              <ProgressBar
                                percent={
                                  candidateProfileData.oetDetails?.speaking * 9
                                }
                                filledBackground="#006d5b"
                              />
                            </Col>
                            <Col md="1" className={styles.listeningCol}>
                              {candidateProfileData.oetDetails?.speaking}
                            </Col>
                          </Row>
                          <Row className={styles.noMargin}>
                            <Col md="6" className={styles.noPadding}>
                              <div className={styles.textWrapper49}>
                                {content.OVERALL_BAND_SCORE}
                              </div>
                            </Col>
                            <Col md="5" className={styles.progressBar}>
                              <ProgressBar
                                percent={
                                  candidateProfileData.oetDetails?.overall * 9
                                }
                                filledBackground="#006d5b"
                                className={styles.progressBar}
                              />
                            </Col>
                            <Col md="1" className={styles.listeningCol}>
                              {candidateProfileData.oetDetails?.overall}
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                  ),
                },
              ]}
            />
          </Space>
        )}
      </>
    );
  };

  const referencesDetails = () => (candidateProfileData.References?.length ? (
    <Space direction="vertical" className={styles.AccordionHeader}>
      <Collapse
        ghost
        collapsible="header"
        activeKey={referencesDetailsAccordion}
        onChange={(key) => setReferencesDetailsAccordion(key)}
        className="profile"
        expandIcon={expandIcon}
        items={[
          {
            key: 1,
            label: content.REFERENCES,
            className: styles.textWrapper48,
            children: (
              <Row className={styles.noMargin}>
                <Col md="12" className={styles.noPadding}>
                  <p>
                    {candidateProfileData.References?.map((item, idx) => (
                      <React.Fragment key={idx}>
                        <span className={styles.textWrapper45}>
                          {item.name}
                        </span>
                        <br />
                        <span className={styles.textWrapper46}>
                          {' '}
                          {item.hospital}
                        </span>
                        <br />
                      </React.Fragment>
                    ))}
                  </p>
                </Col>
              </Row>
            ),
          },
        ]}
      />
    </Space>
  ) : null);

  const documentsDetails = () => {
    const showDocumentsSection = candidateProfileData.documents.some(
      (document) => (document?.name === content.FORMATTED_RESUME),
    );

    return showDocumentsSection ? (
      <>
        <Row>
          <Col md="12">
            <div className={styles.textWrapper42}>{content.DOCUMENTS}</div>
          </Col>
        </Row>
        <br />
        {candidateProfileData.documents?.length > 0
          && candidateProfileData.documents.map((document, index) => document.name === content.FORMATTED_RESUME && (
          <React.Fragment key={index}>
            <Row className={styles.documentRow}>
              <Col md="1">
                <img
                  className={styles.download}
                  alt="Download"
                  src={download}
                  onClick={() => showDocument(candidateProfileData.documents?.url)}
                />
              </Col>
              <Col md="11" className={styles.downloadCVCol}>
                <div
                  className={styles.textWrapper43}
                  onClick={() => window.open(
                    candidateProfileData.documents[index]?.url,
                    '_blank',
                  )}
                  style={{ cursor: 'pointer' }}
                >
                  {document.name}
                </div>
              </Col>
            </Row>
          </React.Fragment>
          ))}
        <br />
      </>
    ) : null;
  };

  const scoreCardDetails = () => (
    candidateProfileData.scoreCard && (
    <Row>
      <Col md="1">
        <img
          className={styles.download}
          alt="Download"
          src={download}
          onClick={() => showDocument(candidateProfileData.scoreCard)}
        />
      </Col>
      <Col md="11" className={styles.downloadCVCol}>
        <div
          className={styles.textWrapper43}
          onClick={() => window.open(candidateProfileData.scoreCard, '_blank')}
          style={{ cursor: 'pointer' }}
        >
          {content.DOWNLOAD_SCORE_CARD}
        </div>
      </Col>
    </Row>
    )
  );

  const drivingLicenseStatus = () => (
    candidateProfileData.drivingLicense != 'No License'
      || ('' && (
        <Space direction="vertical" className={styles.AccordionHeader}>
          <Collapse
            ghost
            collapsible="header"
            activeKey={drivingLicenseStatusAccordion}
            onChange={(key) => setDrivingLicenseStatusAccordion(key)}
            className="profile"
            expandIcon={expandIcon}
            items={[
              {
                key: 1,
                label: content.DRIVING_LICENSE_STATUS,
                className: styles.textWrapper48,
                children: (
                  <div className="body-1">
                    {candidateProfileData.drivingLicense}
                  </div>
                ),
              },
            ]}
          />
        </Space>
      ))
  );

  const renderInfoSection = () => (
    <>
      {candidateDetails()}
      <br />
      <hr />
      {languageDetails()}
      {englishProficiency()}
      {drivingLicenseStatus()}
      {referencesDetails()}
      {documentsDetails()}
      {scoreCardDetails()}
    </>
  );

  const onSelect = (id) => {
    if (id == navId) {
      setNavId();
      return;
    }

    setNavId(id);
  };

  const renderVerificationBadge = (value) => {
    const styleClass = value === 'Verified'
      ? styles.bgVerified
      : value === 'Failed'
        ? styles.bgFailed
        : styles.bgInProgress;
    const text = value === 'Verified'
      ? content.VERIFIED
      : value === 'Failed'
        ? content.FAILED
        : content.IN_PROGRESS;
    return <span className={classNames(styleClass, 'caption-1')}>{text}</span>;
  };

  return (
    <>
      {candidateProfileData && (
        <div className={styles.content} id="quickProfileModal">
          {!isLoading ? (
            <Row>
              <Col md="12" className={styles.firstCol}>
                <Row className={styles.titleRow}>
                  <Col md="1">
                    <img
                      src={
                        candidateProfileData.profilePic
                          ? candidateProfileData.profilePic
                          : maskGroup
                      }
                      className={styles.profileImg}
                      alt="Mask Group"
                    />
                  </Col>
                  <Col md="5">
                    <div className={styles.group13}>
                      <div className={styles.textWrapper40}>
                        {candidateProfileData.location}
                      </div>
                      <div className={styles.textWrapper41}>
                        {candidateProfileData.name}
                        {candidateProfileData.isVerified && (
                          <img
                            className={styles.verified}
                            alt="Verified"
                            src={verified}
                          />
                        )}
                      </div>
                    </div>
                  </Col>
                  {profileType === "addCandidate" ? (
                    <Col md="6" className={styles.changeStageColumn}>
                      <button
                        className="button-text button-primary"
                        color="warning"
                        type="button"
                        onClick={() =>
                          handleStageChange(
                            isShortlisted
                              ? "Submitted to client"
                              : "Shortlisted",
                          )
                        }
                      >
                        {isJobFunnelLoading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          <>
                            {isShortlisted ? "- " : "+ "}
                            {!isShortlisted
                              ? content.ADD_TO_SHORTLIST
                              : content.REMOVE_FROM_SHORTLIST}
                          </>
                        )}
                      </button>
                    </Col>
                  ) : profileType != "APPLSTAGE:TERN:200" ? (
                    <Col md="6" className={styles.changeStageColumn}>
                      <div className="ant-select-selector">
                        <Select
                          bordered={false}
                          colortext="#006d5b"
                          popupClassName={styles.dpdown}
                          style={customSelectStyle}
                          value={
                            mappedApplicationStages[candidateCurrentSubStageId]
                          }
                          onChange={(selectedOption) =>
                            handleStageChange(selectedOption)
                          }
                          options={stageChangeOptions || []}
                        />
                      </div>
                    </Col>
                  ) : (
                    <>
                      <div className={styles.profileFlex} />
                      <Col md="5.5">
                        <div className="ant-select-selector">
                          <Select
                            bordered={false}
                            popupClassName={styles.dpdown}
                            colortext="#006d5b"
                            style={customSelectStyle}
                            value={
                              mappedApplicationStages[
                                candidateCurrentSubStageId
                              ]
                            }
                            onChange={(selectedOption) =>
                              handleStageChange(selectedOption)
                            }
                            options={stageChangeOptions || []}
                          />
                        </div>
                      </Col>
                      <Col md="5.5" className={styles.changeStageColumn}>
                        <button
                          className="button-text button-primary"
                          color="warning"
                          type="button"
                          onClick={openScheduleInterviewModal}
                        >
                          + {content.SCHEDULE_INTERVIEW}
                        </button>
                      </Col>
                    </>
                  )}
                </Row>
                <Card style={{ marginTop: "20px" }}>
                  <Tabs className={styles.profileNavs} onSelect={onSelect}>
                    <Tab key={1} eventKey={1} title="Profile" />
                    <Tab key={2} eventKey={2} title="Interviews" />
                  </Tabs>
                </Card>
                {navId == 1 ? (
                  <div className={styles.profileMainContent}>
                    <Row style={{ marginRight: "0px" }}>
                      <Col className={styles.profileLeftContent} md="4">
                        {renderInfoSection()}
                      </Col>
                      <Col className={styles.profileLeftContent} md="8">
                        <Row>
                          <Col md="12">
                            <div className={styles.textWrapper81}>
                              {content.ABOUT_ME}
                            </div>
                            <br />
                            <br />
                            <p className={styles.multiSkilledAnd}>
                              <span className={styles.textWrapper01}>
                                {showFullText
                                  ? candidateProfileData.about
                                  : truncatedText}
                              </span>
                              {aboutMeTextLength >= 35 && (
                                <span
                                  className={styles.textWrapper80}
                                  style={{ cursor: "pointer", color: "blue" }}
                                  onClick={toggleText}
                                >
                                  {showFullText ? (
                                    <u> Show less..</u>
                                  ) : (
                                    <u> Read more..</u>
                                  )}
                                </span>
                              )}
                            </p>
                          </Col>
                        </Row>

                        <hr />
                        <Row>
                          <Col md="12">
                            <div className={styles.textWrapper81}>
                              {content.WORK_EXPERIENCE}
                            </div>
                          </Col>
                        </Row>
                        <br />
                        <br />
                        <Row>
                          <Col md="12">
                            <Card id="candidateWorkExp">
                              <CardBody className={styles.cardBodyTag}>
                                <Row>
                                  <Col md="12">
                                    <div>
                                      <WorkExperience
                                        candidateProfileData={
                                          candidateProfileData
                                        }
                                      />
                                      <div className={styles.rectangle16} />
                                    </div>
                                    {/*  Languages */}
                                    <br />
                                    <br />
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <div>
                    <Interviews
                      candidateProfileId={candidateProfileId}
                      props={props}
                      profileType={profileType}
                    />
                  </div>
                )}
              </Col>
            </Row>
          ) : (
            <Row className={styles.loaderRow}>
              <img src={loading} className={styles.loader} alt="loader" />
            </Row>
          )}
        </div>
      )}
    </>
  );
}

export default NurseProfile;
