import { AWS_V2_ENDPOINT } from './constants';

// dashboard
export const GET_UPCOMING_INTERVIEW = `${AWS_V2_ENDPOINT}/interviews`;
export const GET_ACTIVITY_STREAM = `${AWS_V2_ENDPOINT}/activities`;
export const GET_JOB_STATS = `${AWS_V2_ENDPOINT}/jobs/stats`;
export const GET_CLIENTS = `${AWS_V2_ENDPOINT}/clients`;

// get all candidates
export const GET_ALL_CANDIDATES_API_ENDPOINT = `${AWS_V2_ENDPOINT}/candidates`;

// allJobs
export const FILTER_JOBS = `${AWS_V2_ENDPOINT}/jobs/filter`;

// emailer
export const SUBMIT_PLATFORM_FEEDBACK = `${AWS_V2_ENDPOINT}/submitPlatformFeedback`;

// jobs
export const GET_JOB_FUNNEL = `${AWS_V2_ENDPOINT}/jobs/funnel`;

// redirects
const DASHBOARD = '/dashboard';
const ALL_JOBS = '/all-jobs';
const ALL_CANDIDATES = '/all-candidate';
const REPORTS = '/reports';
const SERVICES_OFFERED = '/services-offered';
const JOB_DETAILS = '/job-details';
const JOB = '/jobs';

export const PATH = {
  DASHBOARD,
  ALL_JOBS,
  JOB,
  JOB_DETAILS,
  ALL_CANDIDATES,
  REPORTS,
  SERVICES_OFFERED,
};
