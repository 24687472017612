import Helper from '../services/helper';

import { AWS_ENDPOINT, AWS_V2_ENDPOINT } from '../services/constants';
import axiosInstance from '../services/axiosConfig';

const GetJobDetailsUrl = `${AWS_ENDPOINT}/getJobDetails`;

class JobDetailsApi {
  static GetJobDetails = async (request) => axiosInstance.get(GetJobDetailsUrl, {
    headers: Helper.authHeader(),
    params: request.params,
  });

  static changeJobStatus = async (request) => axiosInstance.put(
    `${AWS_V2_ENDPOINT}/changeJobStatus`,
    request.params,
    {
      headers: Helper.authHeader(),
    },
  );
}

export default JobDetailsApi;
