import content from '../languages/en.json';

export const submissionSubStages = [
  { value: 'New', label: 'New', id: 'APPL_SUBSTAGE:TERN:110' },
  { value: 'On Hold', label: 'On Hold', id: 'APPL_SUBSTAGE:TERN:120' },
  { value: 'Rejected at submission', label: 'Rejected at submission', id: 'APPL_SUBSTAGE:TERN:130' },
  { value: 'Shortlisted', label: 'Shortlisted', id: 'APPL_SUBSTAGE:TERN:140' },
  { value: 'Application withdrawn', label: 'Application withdrawn', id: 'APPL_SUBSTAGE:TERN:150' },
];
export const interviewSubStages = [
  { value: 'Interview to be scheduled', label: 'Interview to be Scheduled', id: 'APPL_SUBSTAGE:TERN:210' },
  { value: 'Interview-Scheduled', label: 'Interview-Scheduled', id: 'APPL_SUBSTAGE:TERN:220' },
  { value: 'Candidate No Show', label: 'Candidate No Show', id: 'APPL_SUBSTAGE:TERN:230' },
  { value: 'Rejected after Interview', label: 'Rejected after Interview', id: 'APPL_SUBSTAGE:TERN:240' },
];
export const offeredSubStages = [
  { value: 'Offer planned', label: 'Offer Planned', id: 'APPL_SUBSTAGE:TERN:310' },
  { value: 'Offer made', label: 'Offer Made', id: 'APPL_SUBSTAGE:TERN:320' },
  { value: 'Offer accepted', label: 'Offer Accepted', id: 'APPL_SUBSTAGE:TERN:330' },
  { value: 'Offer declined', label: 'Offer Declined', id: 'APPL_SUBSTAGE:TERN:340' },
  { value: 'Offer withdrawn', label: 'Offer Withdrawn', id: 'APPL_SUBSTAGE:TERN:350' },
];
export const hiredSubStages = [
  { value: 'Offer Letter Signed', label: 'Offer Letter Signed', id: 'APPL_SUBSTAGE:TERN:410' },
  { value: 'Sponsorship Certificate Sent', label: 'Sponsorship Certificate Sent', id: 'APPL_SUBSTAGE:TERN:420' },
  { value: 'Documents Awaited', label: 'Documents Awaited', id: 'APPL_SUBSTAGE:TERN:430' },
  { value: 'Documents Received', label: 'Documents Received', id: 'APPL_SUBSTAGE:TERN:440' },
  { value: 'Candidate Declined Post Acceptance', label: 'Candidate Declined Post Acceptance', id: 'APPL_SUBSTAGE:TERN:450' },
];
export const visaAppliedSubStages = [
  { value: 'Visa to be Applied', label: 'Visa to be Applied', id: 'APPL_SUBSTAGE:TERN:510' },
  { value: 'Visa Appointment Confirmed', label: 'Visa Appointment', id: 'APPL_SUBSTAGE:TERN:520' },
  { value: 'Awaiting Visa', label: 'Awaiting Visa', id: 'APPL_SUBSTAGE:TERN:530' },
  { value: 'Visa Confirmed', label: 'Visa Confirmed', id: 'APPL_SUBSTAGE:TERN:540' },
  { value: 'Visa Rejected', label: 'Visa Rejected', id: 'APPL_SUBSTAGE:TERN:550' },
];
export const relocatedSubStages = [
  { value: 'Travel Date Finalised', label: 'Travel Date Finalised', id: 'APPL_SUBSTAGE:TERN:610' },
  { value: 'Candidate Arrived', label: 'Candidate Arrived', id: 'APPL_SUBSTAGE:TERN:620' },
  { value: 'Candidate Joined', label: 'Candidate Joined', id: 'APPL_SUBSTAGE:TERN:630' },
];
export const stageChangeOptions = [

  {
    label: content.SUBMISSIONS,
    options: submissionSubStages,
  },
  {
    label: content.INTERVIEW,
    options: interviewSubStages,
  },
  {
    label: content.OFFERED,
    options: offeredSubStages,
  },
  {
    label: content.HIRED,
    options: hiredSubStages,
  },
  {
    label: content.VISA_APPLIED,
    options: visaAppliedSubStages,
  },
  {
    label: content.RELOCATED,
    options: relocatedSubStages,
  },
];

export const mappedApplicationStages = {
  'APPLSTAGE:TERN:100': 'Submissions',
  'APPL_SUBSTAGE:TERN:110': 'New',
  'APPL_SUBSTAGE:TERN:120': 'On Hold',
  'APPL_SUBSTAGE:TERN:130': 'Rejected at submission',
  'APPL_SUBSTAGE:TERN:140': 'Shortlisted',
  'APPL_SUBSTAGE:TERN:150': 'Application withdrawn',
  'APPLSTAGE:TERN:200': 'Interview',
  'APPL_SUBSTAGE:TERN:210': 'Interview to be scheduled',
  'APPL_SUBSTAGE:TERN:220': 'Interview-Scheduled',
  'APPL_SUBSTAGE:TERN:230': 'Candidate No Show',
  'APPL_SUBSTAGE:TERN:240': 'Rejected after Interview',
  'APPLSTAGE:TERN:300': 'Offered',
  'APPL_SUBSTAGE:TERN:310': 'Offer planned',
  'APPL_SUBSTAGE:TERN:320': 'Offer made',
  'APPL_SUBSTAGE:TERN:330': 'Offer accepted',
  'APPL_SUBSTAGE:TERN:340': 'Offer declined',
  'APPL_SUBSTAGE:TERN:350': 'Offer withdrawn',
  'APPLSTAGE:TERN:400': 'Hired',
  'APPL_SUBSTAGE:TERN:410': 'Offer Letter Signed',
  'APPL_SUBSTAGE:TERN:420': 'Sponsorship Certificate Sent',
  'APPL_SUBSTAGE:TERN:430': 'Documents Awaited',
  'APPL_SUBSTAGE:TERN:440': 'Documents Received',
  'APPL_SUBSTAGE:TERN:450': 'Candidate Declined Post Acceptance',
  'APPLSTAGE:TERN:500': 'Visa Applied',
  'APPL_SUBSTAGE:TERN:510': 'Visa to be Applied',
  'APPL_SUBSTAGE:TERN:520': 'Visa Appointment Confirmed',
  'APPL_SUBSTAGE:TERN:530': 'Awaiting Visa',
  'APPL_SUBSTAGE:TERN:540': 'Visa Confirmed',
  'APPL_SUBSTAGE:TERN:550': 'Visa Rejected',
  'APPLSTAGE:TERN:600': 'Relocated',
  'APPL_SUBSTAGE:TERN:610': 'Travel Date Finalised',
  'APPL_SUBSTAGE:TERN:620': 'Candidate Arrived',
  'APPL_SUBSTAGE:TERN:630': 'Candidate Joined',
  'APPL_SUBSTAGE:TERN:999': 'Other',
  'APPL_SUBSTAGE:TERN:910': 'Submitted to client',
};

export const subStageTabOptions = {
  'APPLSTAGE:TERN:100': submissionSubStages,
  'APPLSTAGE:TERN:200': interviewSubStages,
  'APPLSTAGE:TERN:300': offeredSubStages,
  'APPLSTAGE:TERN:400': hiredSubStages,
  'APPLSTAGE:TERN:500': visaAppliedSubStages,
  'APPLSTAGE:TERN:600': relocatedSubStages,
};
