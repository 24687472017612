export const platformFeedbackOptions = [
  {
    value: 'productImprovement',
    label: 'Product Improvements',
    id: 'Product_Improvements',
  },
  {
    value: 'ReportABug',
    label: 'Report a Bug',
    id: 'Report_a_Bug',
  },
];
