import React from 'react';

import Select from 'react-select';
import styles from './styles.module.scss';
import inputStyles from '../styles.module.scss';

const selectStyle = (componentStyles) => ({ minWidth: '100%', ...componentStyles });

const selectInternalStyle = {
  control: (baseStyles) => ({
    ...baseStyles,
    minWidth: '100%',
    borderRadius: '15px !important',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '0em',
    textAlign: 'left',
    minHeight: '42px !important',
  }),
};

function MultiSelectWithTitle({
  title,
  layoutStyle,
  selectValue,
  onChangeInput,
  selectPlaceholder,
  selectOptions,
  onInputChange,
  componentStyles,
  isLoading,
  error,
  noOptionMessage,
}) {
  return (
    <div className={styles.selectLayout} style={layoutStyle}>
      <div className="body-2-no-color">{title}</div>
      <Select
        className={styles.selectBoxLayout}
        closeMenuOnSelect={false}
        cacheOptions
        isMulti
        noOptionsMessage={noOptionMessage}
        value={selectValue}
        placeholder={selectPlaceholder}
        onChange={onChangeInput}
        style={selectStyle(componentStyles)}
        options={selectOptions}
        onInputChange={onInputChange}
        isLoading={isLoading}
        styles={selectInternalStyle}
      />
      {error && <span className={inputStyles.danger}>{error}</span>}
    </div>
  );
}

export default MultiSelectWithTitle;
