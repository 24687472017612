import React from 'react';
import classNames from 'classnames';
import successSvg from '../../images/success.svg';
import styles from './styles.module.scss';
import Overlay from '../../components/overlay';

function SuccessModal(props) {
  const {
    heading, successMsg, showSuccessModal, closeSuccessModal,
  } = props;

  return (
    <Overlay
      isOpen={showSuccessModal}
      showCloseIcon={false}
      onClose={closeSuccessModal}
      classes={{ modal: styles.customSuccessModal }}
    >
      <div className={styles.modelBody}>
        <div>
          <div className={styles.centerContent}>
            <img
              src={successSvg}
              alt="successTickSvg"
              className={styles.successTick}
            />
            <h4 className={classNames(styles.headingText, 'heading-3')}>
              {heading}
            </h4>
            <p className={classNames(styles.successMsgStyle, 'body-1')}>{successMsg}</p>
            <div>
              <button
                type="button"
                className={classNames(
                  styles.closeBtn,
                  'button-secondary',
                  'button-text',
                )}
                onClick={closeSuccessModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </Overlay>
  );
}

export default SuccessModal;
