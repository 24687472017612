/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Row, Col } from 'reactstrap';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import styles from './styles.module.scss';
import {
  getJobFunnelRequest,
  listPotentialApplicantsRequest,
  getCandidateProfileRequest,
  changeApplicationStageRequest,
  disassociateApplicantRequest,
  listInterviewRequest,
} from '../../redux/actions/action';
import loading from '../../images/loading.gif';
import ReactTable from '../../components/reactTable';
import PaginatedItems from '../../components/pagination';
import userImg from '../../images/user.png';
import Profile from '../profile';
import filterIcon from '../../images/filter.png';
import downArrow from '../../images/down_arrow.png';
import 'react-toastify/dist/ReactToastify.css';
import closeIcon from '../../images/closeIconBlack.svg';
import Helper from '../../services/helper';
import AddCandidateModal from '../../modals/addCandidate';
import CandidateCard from './candidateCard';
import FilterModal from '../../modals/filterModal';
import { potentialCandidatesFilter } from '../../data/filterModalFields';
import SuccessModal from '../../modals/successModal';
import { getTableRangeLabel } from '../allJobs/utils';
import { ALL_CANDIDATE_PAGE_DEFAULT_ITEM_COUNT, PROFILE_TYPE, SUBMIT_TO_CLIENT } from '../../utils/constants';

function AddCandidate(props) {
  const {
    isJobFunnelData,
    isListPotentialApplicantsData,
    content,
    isJobFunnelError,
    isChangeApplicationStageSuccess,
    isChangeApplicationStageError,
    changeApplicationStageRequest,
  } = props;
  const history = useHistory();
  const prevProps = useRef(props);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [candidateIds, setCandidateIds] = useState(null);
  const [candidateProfileId, setCandidateProfileId] = useState(null);
  const [candidateSubStageId, setCandidateSubStageId] = useState(null);
  const [showCvUploadModal, setShowCvUploadModal] = useState(false);
  const [showAddCandidateSuccessModal, setShowAddCandidateSuccessModal] = useState(false);
  const [currJobOpeningId, setCurrJobOpeningId] = useState(null);
  const [currJobOpeningTitle, setCurrJobOpeningTitle] = useState(null);
  const [jobOpeningCode, setJobOpeningCode] = useState(null);
  const [stageChangedCandidateId, setStageChangedCandidateId] = useState(null);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterCriteria, setFilterCriteria] = useState({});
  const [currJobRoleCategory, setCurrJobRoleCategory] = useState(null);
  const [currJobSpecialisation, setCurrJobSpecialisation] = useState(null);
  const [potentialCandidatesData, setPotentialCandidatesData] = useState([]);
  const [shortlistReqPendingFor, setShortlistReqPendingFor] = useState('');
  const [currClientId, setCurrClientId] = useState(null);
  const [epochTime, setEpochTime] = useState(null);
  const pageInfo = isListPotentialApplicantsData?.pageInfo;
  const itemsPerPage = 10;
  const clientId = Helper.getItem('clientId');
  const url = new URL(window.location.href);
  // Get the query parameters
  const queryParams = new URLSearchParams(url.search);

  const jobCode = queryParams.get('jobCode');
  const currJobId = queryParams.get('currJobId');
  const jobRoleCategory = queryParams.get('jobRoleCategory');
  const jobTitle = queryParams.get('jobTitle');
  const specialisation = queryParams.get('specialisation');
  const getListPotentialApplicants = (pageNum) => {
    const { listPotentialApplicantsRequest } = props;
    const listPotentialApplicantsObj = {
      candFilters: specialisation && JSON.stringify({
        aoe: JSON.parse(specialisation)?.filter((val) => val !== ''),
      }),
      jobOpeningId: currJobId,
      jobOpeningCode: jobCode,
      pageNum,
      pageLimit: 10,
      clientId,
      sortKey: 'ternScore',
      jobRoleCategory,
    };
    listPotentialApplicantsRequest(listPotentialApplicantsObj);
  };
  useEffect(() => {
    if (!clientId) {
      Helper.clearStorage();
      Helper.lastVisitedRoute();
      history.push('/');
      return;
    }
    setCurrClientId(clientId);
    setJobOpeningCode(jobCode);
    setCurrJobOpeningId(currJobId);
    setCurrJobRoleCategory(jobRoleCategory);
    setCurrJobOpeningTitle(jobTitle);
    setCurrJobSpecialisation(specialisation);

    async function fetchData() {
      const {
        getJobFunnelRequest,
      } = props;

      const getJobFunnelObject = {
        jobOpeningId: currJobId,
        pageNo: 1,
        jobOpeningCode: jobCode,
        pageLimit: 10,
        subStageId: 'APPL_SUBSTAGE:TERN:910',
        clientId,
      };

      await getJobFunnelRequest(getJobFunnelObject);
      getListPotentialApplicants(1);
    }
    fetchData();
  }, []);

  useEffect(() => {
    const {
      isJobFunnelSuccess: prevIsJobFunnelSuccess,
      isJobFunnelError: prevIsJobFunnelError,
    } = prevProps.current;
    const { isJobFunnelSuccess, isJobFunnelError, isJobFunnelData } = props;

    if (prevIsJobFunnelSuccess === false && isJobFunnelSuccess) {
      const updatedFilteredData = isJobFunnelData?.candidates.map(
        (candidate) => ({
          ...candidate,
          isShortlisted: false,
          isShortlistReqPending: false,
          isAssociated: true,
        }),
      );

      setFilteredData(updatedFilteredData);
      setIsLoading(false);
    }

    if (prevIsJobFunnelError === false && isJobFunnelError) {
      const messages = [];
      messages.push('Error');
    }

    prevProps.current = props;
  }, [props.isJobFunnelSuccess, props.isJobFunnelError]);
  useEffect(() => {
    const {
      isListPotentialApplicantsSuccess: prevIsListPotentialApplicantsSuccess,
      isListPotentialApplicantsError: prevIsListPotentialApplicantsError,
    } = prevProps.current;
    const {
      isListPotentialApplicantsSuccess,
      isListPotentialApplicantsError,
      isListPotentialApplicantsData,
    } = props;

    if (
      prevIsListPotentialApplicantsSuccess === false
      && isListPotentialApplicantsSuccess
    ) {
      const updatedPotentialCandidatesData = isListPotentialApplicantsData?.candidates.map((candidate) => ({
        ...candidate,
        isShortlisted: false,
        isShortlistReqPending: false,
        isAssociated: false,
      }));

      setPotentialCandidatesData(updatedPotentialCandidatesData);
      setIsLoading(false);
    }

    if (
      prevIsListPotentialApplicantsError === false
      && isListPotentialApplicantsError
    ) {
      const messages = [];
      messages.push('Error');
    }

    prevProps.current = props;
  }, [
    props.isListPotentialApplicantsSuccess,
    props.isListPotentialApplicantsError,
  ]);
  const potentialCandidateRange = getTableRangeLabel(
    content,
    pageInfo?.totalCandidates ?? null,
    isListPotentialApplicantsData?.candidates?.length ?? null,
    currentPage,
    ALL_CANDIDATE_PAGE_DEFAULT_ITEM_COUNT
  );

  useEffect(() => {
    const {
      isChangeApplicationStageSuccess: prevIsChangeApplicationStageSuccess,
      isChangeApplicationStageError: prevIsChangeApplicationStageError,
    } = prevProps.current;
    const {
      isChangeApplicationStageSuccess,
      isChangeApplicationStageError,
    } = props;
    if (
      prevIsChangeApplicationStageSuccess === false
      && isChangeApplicationStageSuccess
    ) {
      let isCandidateShortlisted;
      let isCandAssociated;
      if (shortlistReqPendingFor === 'Recommended') {
        const updatedData = filteredData.map((candidate) => {
          if (candidate.candidateId === stageChangedCandidateId) {
            isCandidateShortlisted = !candidate.isShortlisted;
            isCandAssociated = candidate.isAssociated;
            return {
              ...candidate,
              isShortlisted: !candidate.isShortlisted,
              isShortlistReqPending: false,
            };
          }
          return candidate;
        });
        setFilteredData(updatedData);
      } else if (shortlistReqPendingFor === 'Potential') {
        const updatedData = potentialCandidatesData.map((candidate) => {
          if (candidate.candidateId === stageChangedCandidateId) {
            isCandidateShortlisted = !candidate.isShortlisted;
            isCandAssociated = candidate.isAssociated;
            return {
              ...candidate,
              isShortlisted: !candidate.isShortlisted,
              isShortlistReqPending: false,
            };
          }
          return candidate;
        });
        setPotentialCandidatesData(updatedData);
        slicedData = updatedData?.slice(startIndex, endIndex);
      }

      if (isCandidateShortlisted) {
        Helper.notification(content.ADD_TO_SHORTLIST_SUCCESS_MESSAGE, false);
        getListPotentialApplicants(currentPage + 1);
      } else {
        Helper.notification(
          content.REMOVE_FROM_SHORTLIST_SUCCESS_MESSAGE,
          false,
        );
      }
    }

    if (
      prevIsChangeApplicationStageError === false
      && isChangeApplicationStageError
    ) {
      let isCandidateShortlisted;
      let isCandAssociated;
      if (shortlistReqPendingFor === 'Recommended') {
        const updatedData = filteredData.map((candidate) => {
          if (candidate.candidateId === stageChangedCandidateId) {
            isCandidateShortlisted = !candidate.isShortlisted;
            isCandAssociated = candidate.isAssociated;
            return {
              ...candidate,
              isShortlistReqPending: false,
            };
          }
          return candidate;
        });
        setFilteredData(updatedData);
      } else if (shortlistReqPendingFor === 'Potential') {
        const updatedData = potentialCandidatesData.map((candidate) => {
          if (candidate.candidateId === stageChangedCandidateId) {
            isCandidateShortlisted = !candidate.isShortlisted;
            isCandAssociated = candidate.isAssociated;
            return {
              ...candidate,
              isShortlistReqPending: false,
            };
          }
          return candidate;
        });
        setPotentialCandidatesData(updatedData);
        slicedData = updatedData?.slice(startIndex, endIndex);
      }

      Helper.notification(content.ADD_TO_SHORTLIST_ERROR_MESSAGE, true);
    }

    prevProps.current = props;
  }, [
    props.isChangeApplicationStageSuccess,
    props.isChangeApplicationStageError,
  ]);

  useEffect(() => {
    const {
      isDisassociateApplicantSuccess: prevIsDisassociateApplicantSuccess,
      isDisassociateApplicantError: prevIsDisassociateApplicantError,
    } = prevProps.current;
    const {
      isDisassociateApplicantSuccess,
      isDisassociateApplicantError,
      isDisassociateApplicantData,
    } = props;
    if (
      prevIsDisassociateApplicantSuccess === false
      && isDisassociateApplicantSuccess
    ) {
      const updatedData = potentialCandidatesData.map((candidate) => {
        if (candidate.candidateId === stageChangedCandidateId) {
          return {
            ...candidate,
            isShortlisted: !candidate.isShortlisted,
            isShortlistReqPending: false,
          };
        }
        return candidate;
      });
      setPotentialCandidatesData(updatedData);
      slicedData = updatedData?.slice(startIndex, endIndex);
      Helper.notification(content.DISASSOCIATE_SUCCESS_MSG, false);
    }

    if (
      prevIsDisassociateApplicantError === false
      && isDisassociateApplicantError
    ) {
      const updatedData = potentialCandidatesData.map((candidate) => {
        if (candidate.candidateId === stageChangedCandidateId) {
          return {
            ...candidate,
            isShortlisted: !candidate.isShortlisted,
            isShortlistReqPending: false,
          };
        }
        return candidate;
      });
      setPotentialCandidatesData(updatedData);
      slicedData = updatedData?.slice(startIndex, endIndex);
      Helper.notification(content.DISASSOCIATE_APPLICANT_ERROR_MSG, true);
    }

    prevProps.current = props;
  }, [
    props.isDisassociateApplicantSuccess,
    props.isDisassociateApplicantError,
  ]);

  const handleStageChange = async (
    data,
    selectedOption,
    isAssociated,
    index,
  ) => {
    if (isAssociated) {
      setShortlistReqPendingFor('Recommended');
      filteredData[index].isShortlistReqPending = true;
    } else {
      setShortlistReqPendingFor('Potential');
      potentialCandidatesData[index].isShortlistReqPending = true;
    }
    setStageChangedCandidateId(data.candidateId);
    const { changeApplicationStageRequest } = props;
    if (selectedOption === 'disAssociate') {
      disAssociateCandidate(data.candidateId, isJobFunnelData?.jobOpeningId);
      return;
    }
    const changeApplicationStageObject = {
      candidateId: data.candidateId,
      jobOpeningId: isJobFunnelData?.jobOpeningId,
      stageId: '',
      jobOpeningCode,
      applicationStageName: selectedOption,
      applicationStageId: selectedOption === 'Shortlisted'
        ? 'APPL_SUBSTAGE:TERN:140'
        : SUBMIT_TO_CLIENT,
      clientId: currClientId,
      isAssociated,
    };

    await changeApplicationStageRequest(changeApplicationStageObject);
  };

  const openProfileModal = (candidateId, currSubStageId) => {
    const candidateIdList = [];
    const index = slicedData.findIndex(
      (item) => item.candidateId === candidateId,
    );
    if (index !== -1) {
      const reducedArray = slicedData.slice(index);
      reducedArray.map((data) => {
        candidateIdList.push({ id: data.candidateId });
      });
    } else {
      candidateIdList.push({ id: candidateId });
    }
    setCandidateIds(candidateIdList);
    setCandidateProfileId(candidateId);
    setCandidateSubStageId(currSubStageId);
    setShowProfileModal(true);
  };

  const closeProfileModal = () => {
    setCandidateIds(null);
    setCandidateProfileId(null);
    setCandidateSubStageId(null);
    setShowProfileModal(false);
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);  
    if (Object.keys(filterCriteria).length) {
      filterPotentialCandidatesData(filterCriteria, selectedPage + 1);
    } else {
      getListPotentialApplicants(selectedPage + 1);
    }
  };
  // Calculate the data to display on the current page
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  let slicedData = potentialCandidatesData?.slice(startIndex, endIndex);

  const openCvUploadModal = () => {
    setEpochTime(Date.now());
    setShowCvUploadModal(true);
  };

  const addCandidate = () => {
    setShowCvUploadModal(false);
    setShowAddCandidateSuccessModal(true);
  };

  const closeAddCandidateSuccessModal = () => {
    setShowAddCandidateSuccessModal(false);
  };
  const handleFilterPotentialCandidates = (filterCriteria) => {
    const pageNum = 1; // after applying filter, fetching 1st page data
    filterPotentialCandidatesData(filterCriteria, pageNum);
    setCurrentPage(pageNum - 1);
  };
  const filterPotentialCandidatesData = (filterCriteria, pageNum) => {
    const updatedCriteria = { ...filterCriteria };
    Object.keys(updatedCriteria).forEach((key) => {
      updatedCriteria[key] = updatedCriteria[key][0];
    });
    if (currJobSpecialisation) {
      updatedCriteria.aoe = JSON.parse(currJobSpecialisation)?.filter(
        (val) => val !== '',
      );
    }
    setFilterCriteria(updatedCriteria);
    setShowFilterModal(false);
    setIsLoading(true);
    const listPotentialApplicantsObj = {
      candFilters: JSON.stringify(updatedCriteria),
      jobOpeningId: currJobOpeningId,
      jobOpeningCode,
      pageNum,
      pageLimit: 10,
      clientId: currClientId,
      sortKey: 'ternScore',
      jobRoleCategory: currJobRoleCategory,
    };

    props.listPotentialApplicantsRequest(listPotentialApplicantsObj);
  };

  const closeFilterModal = () => {
    setShowFilterModal(false);
  };

  const disAssociateCandidate = (candidateId, jobId) => {
    const { disassociateApplicantRequest } = props;
    const disassociateApplicantObj = {
      candidateId,
      jobOpeningId: jobId,
    };
    disassociateApplicantRequest(disassociateApplicantObj);
  };

  const renderFilterData = () => {
    if (isJobFunnelError) {
      return <div>{content.SOMETHING_WENT_WRONG}</div>;
    }

    if (filteredData.length === 0) {
      return <div>{content.MAGIC_MATCH_CANDIDATES_NOTHING_TO_SHOW}</div>;
    }

    const result = filteredData && filteredData.length > 0 ? (
      <div className={styles.cardContainer}>
        {filteredData.map((candidate, index) => (
          <CandidateCard
            candidate={candidate}
            content={content}
            index={index}
            handleStageChange={handleStageChange}
            openProfileModal={openProfileModal}
          />
        ))}
      </div>
    ) : (
      <Row className={styles.loaderRow}>
        <img src={loading} className={styles.loader} alt="loader" />
      </Row>
    );
    return result;
  };

  const getRoleSpecificHeader = (col) => {
    if (col === 4) {
      return currJobRoleCategory === 'Doctor'
        ? content.RCE_STATUS
        : content.IELTS_SCORE;
    }
  };

  const getCellData = (row, col) => {
    if (col === 4) {
      const {
        royalCollegeExamGiven,
        royalCollegeMembershipStatus,
        ieltsScore,
      } = row?.original || {};
      const doctorRceData = currJobRoleCategory === 'Doctor'
        ? `${royalCollegeExamGiven || ''} - ${royalCollegeMembershipStatus || ''}`
        : '';
      return currJobRoleCategory === 'Doctor' ? doctorRceData : ieltsScore;
    }
  };

  const renderMoreCandidates = () => {
    if (props.isListPotentialApplicantsError) {
      return (
        <Row className={styles.loaderRow}>{content.SOMETHING_WENT_WRONG}</Row>
      );
    }

    const result = !isLoading && isListPotentialApplicantsData?.candidates ? (
      <ReactTable
        data={isListPotentialApplicantsData?.candidates}
        columns={[
          {
            disableSortBy: true,
            isSortingEnable: false,
            Header: () => (
              <div className={styles.candidateName}>Candidate Name</div>
            ),
            accessor: 'name',
            Cell: ({ row }) => (
              <div
                onClick={() => openProfileModal(
                  row.original.candidateId,
                  row.original.subStageId,
                )}
                className={styles.openModalDiv}
              >
                <div className={styles.icon}>
                  <img
                    className={styles.img}
                    alt="Element tif"
                    src={
                        row.original.profilePic
                          ? row.original.profilePic
                          : userImg
                      }
                  />
                </div>
                <div className={styles.tableImgName}>{row.original.name}</div>
              </div>
            ),
          },
          {
            Header: content.TERN_SCORE,
            accessor: 'ternScore',
          },
          {
            Header: content.AVAILABLE_IN_MONTHS,
            accessor: 'availableIn',
          },
          {
            Header: getRoleSpecificHeader(4),
            accessor: 'ieltsScore',
            Cell: ({ row }) => getCellData(row, 4),
          },
          {
            Header: content.EXP_YEARS,
            accessor: 'experience',
          },
          {
            Header: content.SELECT_STAGE,
            accessor: 'actions',
            sortable: false,
            filterable: false,
            class: styles.selectStageColumn,
            Cell: ({ row }) => (
              <div className={styles.selectStageBtnContainer}>
                <button
                  type="button"
                  className={classNames(
                    'button-secondary',
                    styles.addShortlist,
                  )}
                  onClick={() => handleStageChange(
                    row.original,
                    row.original.isShortlisted
                      ? 'disAssociate'
                      : 'Shortlisted',
                    false,
                    row.index,
                  )}
                >
                  {row.original.isShortlistReqPending ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    <>
                      {row.original.isShortlisted ? '-' : '+'}
                      {row.original.isShortlisted
                        ? ` ${content.REMOVE_FROM_SHORTLIST}`
                        : ` ${content.ADD_TO_SHORTLIST}`}
                    </>
                  )}
                </button>
              </div>
            ),
          },
        ]}
        emptyDataMsg={content.EMPTY_DATA_MESSAGE_FOR_ADD_CANDIDATES}
        isBorderRadiusReq
      />
    ) : (
      <Row>
        <img src={loading} className={styles.loader} alt="loader" />
      </Row>
    );

    return result;
  };

  return (
    <>
      <div className={styles.content}>
        <Row>
          <Col md="12">
            <Row>
              <Col md="10" className={styles.titleCol}>
                <p className="heading-3">
                  {content.SEARCH_CANDIDATES_FOR}
                  {' '}
                  <Link
                    className="linkAppearanceNone"
                    to={`/job-details?jobOpeningCode=${jobOpeningCode}&jobOpeningId=${currJobOpeningId}&jobTitle=${currJobOpeningTitle}`}
                  >
                    <span className={classNames('heading-3', styles.span)}>
                      {isJobFunnelData?.title
                        ? isJobFunnelData.title
                        : currJobOpeningTitle}
                    </span>
                  </Link>
                </p>
              </Col>
              <Col md="2" className={styles.colMd2}>
                <Col md="2" className={styles.colMd2} />
                <button
                  className="button-text button-primary"
                  type="button"
                  onClick={openCvUploadModal}
                >
                  {content.ADD_MORE_CV}
                </button>
              </Col>
            </Row>
            <div
              className={classNames('subtitle-1', styles.magicMatchCandidate)}
            >
              {content.MAGIC_MATCH_CANDIDATES}
            </div>
            {renderFilterData()}

            <div className={styles.filterSection}>
              <div className="heading-3">
                {content.DISCOVER_MORE_CANDIDATES}
              </div>
              <button
                type="button"
                onClick={() => setShowFilterModal(true)}
                className={styles.filterButton}
              >
                <img
                  src={filterIcon}
                  width="16px"
                  height="16px"
                  alt="filter icon"
                />
                <span>

                  {content.FILTER}
                  {Object.keys(filterCriteria).length > 0 && (
                    <span className={styles.redDot} />
                  )}
                </span>
                <img
                  src={downArrow}
                  width="16px"
                  height="16px"
                  alt="filter icon"
                />
              </button>
            </div>
            <div>
              {renderMoreCandidates()}
            </div>
            {!navigator.userAgent.match(/Android/i) ? potentialCandidatesData?.length > 0 && (
              <PaginatedItems
                currentPage={currentPage}
                totalItems={pageInfo?.totalCandidates}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
              />
            ) : null}
            <div className={styles.message}>
              {isListPotentialApplicantsData?.candidates?.length > 0 ? (
                <div className={styles.message}>{potentialCandidateRange}</div>
              ) : null}
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        show={showProfileModal}
        onHide={closeProfileModal}
        dialogClassName={`${styles.customModal} custom-modal-slide`}
      >
        <Modal.Body className={styles.modelBody}>
          <Profile
            candidateIds={candidateIds}
            candidateProfileId={candidateProfileId}
            candidateSubStageId={candidateSubStageId}
            jobOpeningId={currJobOpeningId}
            jobOpeningCode={jobOpeningCode}
            isChangeApplicationStageSuccess={isChangeApplicationStageSuccess}
            isChangeApplicationStageError={isChangeApplicationStageError}
            changeApplicationStageRequest={changeApplicationStageRequest}
            profileType={PROFILE_TYPE.ADD_CANDIDATE}
          />
          <div>
            <img
              className={styles.profileCloseIcon}
              onClick={closeProfileModal}
              src={closeIcon}
              height={24}
              width={24}
              alt="close"
            />
          </div>
        </Modal.Body>
      </Modal>

      <AddCandidateModal
        showCvUploadModal={showCvUploadModal}
        closeCvUploadModal={() => setShowCvUploadModal(false)}
        addCandidate={addCandidate}
        jobOpeningId={currJobOpeningId}
        clientId={currClientId}
        jobOpeningName={isJobFunnelData.title}
        epochTime={epochTime}
      />

      <SuccessModal
        heading={content.CANDIDATE_ADDED_SUCCESSFULLY}
        successMsg={content.ADD_CANDIDATE_SUCCESS_MESSAGE}
        showSuccessModal={showAddCandidateSuccessModal}
        closeSuccessModal={closeAddCandidateSuccessModal}
      />

      <FilterModal
        filterData={handleFilterPotentialCandidates}
        showFilterModal={showFilterModal}
        closeFilterModal={closeFilterModal}
        filterOptionsData={potentialCandidatesFilter}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  content: state.languageReducer.portalContent,
  languageCode: state.languageReducer.languageCode,
  isJobFunnelSuccess: state.jobsReducer.isJobFunnelSuccess,
  isJobFunnelError: state.jobsReducer.isJobFunnelError,
  isJobFunnelData: state.jobsReducer.isJobFunnelData,
  isListPotentialApplicantsSuccess:
    state.addCandidatesReducer.isListPotentialApplicantsSuccess,
  isListPotentialApplicantsError:
    state.addCandidatesReducer.isListPotentialApplicantsError,
  isListPotentialApplicantsData:
    state.addCandidatesReducer.isListPotentialApplicantsData,
  isGetCandidateProfileSuccess:
    state.candidateReducer.isGetCandidateProfileSuccess,
  candidateProfileData: state.candidateReducer.candidateProfileData,
  isChangeApplicationStageSuccess:
    state.jobsReducer.isChangeApplicationStageSuccess,
  isChangeApplicationStageError:
    state.jobsReducer.isChangeApplicationStageError,
  isDisassociateApplicantSuccess:
    state.addCandidatesReducer.isDisassociateApplicantSuccess,
  isDisassociateApplicantError:
    state.addCandidatesReducer.isDisassociateApplicantError,
  isDisassociateApplicantData:
    state.addCandidatesReducer.isDisassociateApplicantData,
});

const mapDispatchToProps = (dispatch) => ({
  getJobFunnelRequest: (data) => dispatch(getJobFunnelRequest(data)),
  getCandidateProfileRequest: (data) => dispatch(getCandidateProfileRequest(data)),
  changeApplicationStageRequest: (data) => dispatch(changeApplicationStageRequest(data)),
  listPotentialApplicantsRequest: (data) => dispatch(listPotentialApplicantsRequest(data)),
  disassociateApplicantRequest: (data) => dispatch(disassociateApplicantRequest(data)),
  listInterviewRequest: (data) => dispatch(listInterviewRequest(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AddCandidate));
