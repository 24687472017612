import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Modal, Form } from 'react-bootstrap';
import classNames from 'classnames';
import styles from './styles.module.scss';
import Helper from '../../services/helper';
import close from '../../images/closeIconBlack.svg';
import trash from '../../images/trash.png';
import loader from '../../images/loadingWithoutBG.gif';
import uploadIcon from '../../images/uploadFileIcon.svg';

import { getPortalContent } from '../../redux/reducers/languageReducer';
import { platformFeedbackOptions } from '../../data/platforrmFeedback';
import EmailerApi from '../../apis/emailerApi';
import { acceptableFileFormat } from '../../utils/constants';

function FeedbackModal(props) {
  const {
    feedbackModal, closeFeedbackModal, feedbackSuccess,
  } = props;
  const { given_name: userName = '', email: userEmail, 'cognito:groups': [client] = [] } = Helper.getUserInfo();
  const clientName = client?.split(':')[1] ?? '';

  const clientId = Helper.getItem('clientId');
  const GROUP_ID = ['PlatformFeedback', 'client', clientId, Date.now()].join(':');
  const content = useSelector(getPortalContent);
  const [uploadProgress, setUploadProgress] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [isLoading, setIsLoading] = useState({ status: false, timeStamp: '' });
  const [filePath, setFilePath] = useState('');
  const [feedbackFormData, setFeedbackFormData] = useState({});

  const [error, setError] = useState({
    status: true,
    errMessage: '',
    code: '',
  });

  const handleFeedbackModalDataChange = (e) => {
    const { name, value } = e.target;
    if (e.target.files?.[0]) {
      setFeedbackFormData((prevState) => ({
        ...prevState,
        [name]: e.target.files?.[0],
      }));
    } else setFeedbackFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFileChangeAndUpload = async (file) => {
    if (!file) return;

    const fileNameParts = file.name.split('.');
    const fileType = fileNameParts[fileNameParts.length - 1].toLowerCase();

    if (!acceptableFileFormat.includes(fileType)) {
      setError({
        status: true,
        errMessage:
          'Unsupported file format. Please upload a file in PDF, DOC, or DOCX format.',
        code: 'attachment',
      });
      return;
    }
    if (file.size / (1024 * 1024) > 4) {
      setError({
        status: true,
        errMessage:
          'File size exceeds the allowed limit. Please upload a file smaller than 4mb',
        code: 'attachment',
      });
      return;
    }

    setError({ status: false });
    const attachment = file && {
      fileName: file.name,
      progress: 0,
      timeStamp: file.lastModified,
    };
    setAttachments([attachment]);

    const fileBase64format = await Helper.convertFileToBase64(file);

    const body = {
      clientId,
      uploadGroupId: GROUP_ID,
      fileName: fileBase64format.fileName,
      fileContent: fileBase64format.fileData,
      isBase64: true,
    };

    const uploadConfig = {
      onUploadProgress(progressEvent) {
        setUploadProgress({
          ...uploadProgress,
          [attachment.timeStamp]: Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          ),
        });
      },
    };

    try {
      setIsLoading({ ...isLoading, status: true, [file.lastModified]: true });
      const resp = await EmailerApi.UploadFeedbackAttachment(body, uploadConfig);

      attachment.uploadJobId = resp.data.uploadJobId;
      setAttachments([attachment]);
      setFilePath(resp.data.path);
    } catch (e) {
      Helper.notification('Something went wrong', true);
      setIsLoading({ ...isLoading, status: false, [file.lastModified]: false });
    }
    setIsLoading({ ...isLoading, status: false, [file.lastModified]: false });
  };

  const handleAttachmentRemoval = async (list, uploadJobId = null) => {
    if (list.length < 1) {
      return;
    }

    const body = {
      clientId,
      attachmentType: 'feedbacks/platform',
      uploadGroupId: GROUP_ID,
    };

    if (uploadJobId) {
      body.uploadJobIds = [uploadJobId];
    } else {
      body.uploadJobIds = list.filter((attachment) => attachment.uploadJobId).map((obj) => obj?.uploadJobId);
    }
    try {
      await EmailerApi.DeleteFeedbackAttachment(body);

      const newList = list.filter((item) => item.uploadJobId !== uploadJobId);
      setAttachments(newList);
    } catch (e) {
      Helper.notification('Something went wrong', true);
    }
  };

  const setFieldError = (field, errorMessage, code) => {
    setError({ ...error, errMessage: errorMessage, code });
    return false;
  };

  const isFieldEmpty = (field, errorMessage, code) => (feedbackFormData[field]
    ? true
    : setFieldError(field, errorMessage, code));

  const handleValidation = () => {
    if (!feedbackFormData?.typeOfFeedback) {
      setFieldError('typeOfFeedback', 'Please select feedback type', 'typeOfFeedback');
      return false;
    }
    if (
      !isFieldEmpty(
        'additionalInfo',
        content.MANDATORY_FIELD_MSG,
        'additionalInfo',
      )

    ) return false;

    setError({ status: false, errMessage: '' });
    return true;
  };

  const handlePostFeedbackSubmit = async (e) => {
    e.preventDefault();

    if (!handleValidation()) return;

    const { additionalInfo, typeOfFeedback } = feedbackFormData;
    const body = {
      typeOfFeedback,
      feedback: {
        message: additionalInfo, userName, userEmail, clientName,
      },
      feedbackFileKey: filePath,
      pageUrl: window.location.href,
    };
    try {
      setIsLoading({ ...isLoading, status: true });
      await EmailerApi.SubmitFeedback(body);

      setAttachments([]);
      setFilePath('');
      setUploadProgress({});
      setFeedbackFormData({});
      Helper.notification(content.FEEDBACK_SUCCESS);
      feedbackSuccess();
    } catch (err) {
      Helper.notification('Something went wrong', true);
    }
    setIsLoading({ ...isLoading, status: false });
  };

  const handleClose = async (listofJDs, groupId) => {
    await handleAttachmentRemoval(listofJDs, groupId);
    setAttachments([]);
    setFilePath('');
    setUploadProgress({});
    setFeedbackFormData({});
    setError({ status: true, errMessage: '', code: '' });
    closeFeedbackModal();
  };

  const renderOptions = () => (
    <div className={styles.filterSelectBtnsDiv}>
      {platformFeedbackOptions?.map((option) => (
        <button
          key={option.id}
          className={classNames(
            feedbackFormData?.typeOfFeedback === option.value
              ? styles.filterSelectedButton
              : styles.filterSelectBtns,
            'body-3',
          )}
          type="button"
          onClick={() => handleFeedbackModalDataChange({
            target: { name: 'typeOfFeedback', value: option.value },
          })}
        >
          <span aria-label={option.label}>{option.label}</span>
        </button>
      ))}
    </div>
  );
  return (
    <Modal
      show={feedbackModal}
      onHide={closeFeedbackModal}
      dialogClassName={styles.customModal}
      keyboard={false}
    >
      <Modal.Header className={styles.headerContent}>
        <Modal.Title className={classNames(styles.modalTitle, 'heading-3')}>
          {content.HOW_CAN_WE_IMPROVE}
        </Modal.Title>
        <img
          className={styles.closeIcon}
          src={close}
          onClick={() => handleClose(attachments)}
          alt="close"
        />
      </Modal.Header>
      <Modal.Body className={styles.modelBody}>

        <Form onSubmit={handlePostFeedbackSubmit}>
          <Form.Group className={styles.formGroup}>
            {renderOptions()}
            {error.code === 'typeOfFeedback' && (
            <div className="errorText body-3">{error.errMessage}</div>
            )}
          </Form.Group>
          <Form.Group controlId="additionalInfo" className={styles.formGroup}>
            <Form.Control
              as="textarea"
              name="additionalInfo"
              placeholder={content.FEEDBACK_PLACEHOLDER}
              className={classNames(styles.additionalInfoBox, 'body-2')}
              value={feedbackFormData.additionalInfo ?? ''}
              onChange={handleFeedbackModalDataChange}
              rows={6}
            />
            {error.code === 'additionalInfo' && (
            <div className="errorText body-3">{error.errMessage}</div>
            )}
          </Form.Group>
          <Form.Group className={styles.formGroup}>
            <div>
              {attachments.map((data) => (
                <div
                  key={data.fileName}
                  className={classNames(styles.progressbar, 'body-1')}
                >
                  <div
                    className={styles.progressBar}
                    style={{
                      width: `${uploadProgress[`${data.timeStamp}`]}%`,
                    }}
                  />
                  <div className={styles.progressPercent}>
                    <span>{data.fileName}</span>
                    <span>
                      {uploadProgress[`${data.timeStamp}`] < 100
                        ? `${uploadProgress[`${data.timeStamp}`]}%`
                        : ''}
                    </span>
                    <img
                      onClick={() => handleAttachmentRemoval(
                        attachments,
                        data.uploadJobId,
                      )}
                      src={
                          isLoading.status && isLoading[data.timeStamp]
                            ? loader
                            : trash
                        }
                      className={styles.loaderIcon}
                      alt=""
                    />
                  </div>
                </div>
              ))}
            </div>

            {attachments.length < 1 && (
            <>
              <div
                className={classNames(styles.browseFile, {
                  [styles.errorBox]: error.code === 'attachment',
                })}
              >
                <input
                  type="file"
                  id="file-input"
                  onChange={(e) => handleFileChangeAndUpload(e.target.files?.[0] || null)}
                  style={{ display: 'none' }}
                  accept=".pdf,.doc,.docx"
                />
                <button
                  type="button"
                  onClick={() => document.querySelector('input[type="file"]').click()}
                  className={classNames(styles.browseBtn, 'body-1')}
                >
                  {' '}
                  <img
                    src={uploadIcon}
                    alt="upload file Icon"
                    className={styles.uploadIcon}
                  />
                  {content.UPLOAD_FILE}
                </button>
              </div>
              {error.code === 'attachment' && (
              <div className="errorText body-3">{error.errMessage}</div>
              )}

              <div className={classNames(styles.uploadNote, 'body-1')}>
                {content.POST_JOB_UPLOAD_NOTE}
              </div>
            </>
            )}
          </Form.Group>

          <div className={styles.footerDiv}>
            <button
              className="button-secondary button-text"
              type="button"
              onClick={() => handleClose(attachments)}
            >
              {content.CANCEL}
            </button>
            <button
              className="button-primary button-text"
              type="submit"
              disabled={isLoading.status}
            >
              {isLoading.status
                ? content.SUBMIT_BTN_STATUS
                : content.SUBMIT_FEEDBACK}
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default FeedbackModal;
