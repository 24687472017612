// eslint-disable-next-line import/no-extraneous-dependencies
import parsePhoneNumberFromString from 'libphonenumber-js';
import Helper from '../services/helper';
import { AGENCY_CLIENTS, CLIENTID } from '../services/constants';
import { mappedApplicationStages, subStageTabOptions } from '../data/jobStages';
import { CURRENT_JOB_ID } from './constants';

export const findByKey = (options, key, value) => options?.find(
  (option) => option?.[key] === value,
);

export const convertArrayToCommaSeparatedString = (data, delimiter = ',') => {
  if (Array.isArray(data) && data.length > 0) {
    // Filter out null or undefined elements
    const filteredData = data.filter(Boolean);
    // Join the filtered array with the specified delimiter
    return filteredData.join(delimiter);
  }
  return '';
};

export const getAgencyClientList = () => {
  const clientsList = JSON.parse(
    Helper.getItem(AGENCY_CLIENTS),
  )?.clientsList?.map((client) => ({ value: client.id, label: client.name }));

  const clientsListFormatted = clientsList?.length > 0
    ? [{ value: Helper.getItem(CLIENTID), label: 'All Employers' }, ...clientsList]
    : [];
  return clientsListFormatted;
};

export const getQueryParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const queryParams = {};

  searchParams.forEach((value, key) => {
    try {
      if (key === CURRENT_JOB_ID) {
        queryParams[key] = value; // Keep currJobId as a string
      } else {
        queryParams[key] = JSON.parse(value);
      }
    } catch (error) {
      // Handle parsing error
      queryParams[key] = value; // Use the raw value if parsing fails
    }
  });

  return queryParams;
};

export const addQueryParams = (paramsObject) => {
  const searchParams = new URLSearchParams(window.location.search);

  Object.keys(paramsObject).forEach((key) => {
    const value = paramsObject[key];
    try {
      const serializedValue = typeof value !== 'string' ? JSON.stringify(value) : String(value);
      searchParams.set(key, serializedValue);
    } catch (error) {
      // Handle serialization error, e.g., log the error or skip the parameter
      // console.error(`Error serializing value for key '${key}': ${error.message}`);
    }
  });

  const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
  window.history.replaceState({}, '', newUrl);
};

export const getJobStageMappedValue = (targetValue) => {
  const entries = Object.entries(mappedApplicationStages);

  let resp = {};
  entries.forEach(([key, value]) => {
    if (value === targetValue) {
      resp = { id: key, value };
    }
    if (subStageTabOptions[key]) {
      resp.subStage = subStageTabOptions[key];
    }
  });

  return Object.keys(resp).length ? resp : null;
};

// only support for indian phone numbers with country code
export const validatePhoneNumber = (phone) => {
  const pattern = /^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[789]\d{9}$/gm;
  return pattern.test(phone);
};

// only support 4 and 6 digit OTP
export const validateOTP = (otp) => {
  const pattern = /^\d{4}(?:\d{2})?$/;
  return pattern.test(otp);
};

export const formatPhoneNumber = (phoneNumber) => {
  const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
  if (parsedPhoneNumber) {
    return parsedPhoneNumber.formatInternational(); // +91 99999 99999
  }
  return phoneNumber; // +919999999999
};
