import { SPECIALISATION } from '../utils/specialisation';

export const InactiveJobFilterFields = [
  {
    heading: 'Employer',
    value: 'employer',
    options: {},
    selection: 'multi',
    emptyStateMsg: 'No data',
  },
  {
    heading: 'Profession',
    value: 'profession',
    options: [
      { value: 'Nurse', label: 'Nurse' },
      { value: 'Doctor', label: 'Doctor' },
    ],
    selection: 'multi',
    emptyStateMsg: 'No data',
  },

  {
    heading: 'Specialisation',
    value: 'specialisation',
    dynamicOptions: (selectedProfession) => {
      const specialisations = {
        Nurse: SPECIALISATION.nurse,
        Doctor: SPECIALISATION.doctor,
      };

      return specialisations[selectedProfession] || [];
    },
    selection: 'multi',
    dependent: 'profession',
    emptyStateMsg: 'Please select profession first.',
  },
];

export const potentialCandidatesFilter = [
  {
    heading: 'Years Of Experience',
    value: 'yoe',
    options: [
      { label: '0 - 1 year', value: [0, 1] },
      { label: '1 - 3 years', value: [1, 3] },
      { label: '4 - 6 years', value: [4, 6] },
      { label: '7 - 9 years', value: [7, 9] },
      { label: '10 + years', value: [10, 100] },
    ],
    selection: 'single',
    emptyStateMsg: 'No data',
  },
  {
    heading: 'Available In',
    value: 'ai',
    options: [
      { label: '1 month', value: 1 },
      { label: '2 month', value: 2 },
      { label: '3 months', value: 3 },
      { label: '4 months', value: 4 },
      { label: '6 months', value: 6 },
    ],
    selection: 'single',
    emptyStateMsg: 'No data',
  },
  // {
  //     heading : "Tern Score",
  //     value : "ts",
  //     options : [
  //         { label: "60 +", value: 60 },
  //         { label: "70 +", value: 70 },
  //         { label: "80 +", value: 80 },
  //         { label: "90 +", value: 90 },
  //         { label: "95 +", value: 95 }
  //     ],
  //     selection : "single",
  //     emptyStateMsg : "No data"
  // }
];

export const allCandidatesFilter = [
  {
    heading: 'Employer',
    value: 'employer',
    options: [],
    selection: 'multi',
    emptyStateMsg: 'No data',
  },
  {
    heading: 'Profession',
    value: 'profession',
    options: [
      { value: 'Nurse', label: 'Nurse' },
      { value: 'Doctor', label: 'Doctor' },
    ],
    selection: 'multi',
    emptyStateMsg: 'No data',
  },

  {
    heading: 'Specialisation',
    value: 'specialisation',
    dynamicOptions: (selectedProfession) => {
      const specialisations = {
        Nurse: [
          { value: 'Adult Nurse', label: 'Adult Nurse' },
          { value: 'Community Nurse', label: 'Community Nurse' },
          { value: 'Mental Health Nurse', label: 'Mental Health Nurse' },
          { value: 'Midwifery Nurse', label: 'Midwifery Nurse' },
          { value: 'Paediatric Nurse', label: 'Paediatric Nurse' },
          { value: 'Advanced Nurse Practitioner', label: 'Advanced Nurse Practitioner' },
          { value: 'Allergy and Immunology', label: 'Allergy and Immunology' },
          { value: 'Anesthesiology', label: 'Anesthesiology' },
          { value: 'Associate Nurse', label: 'Associate Nurse' },
          { value: 'Cardiac Nurse', label: 'Cardiac Nurse' },
          { value: 'Clinical Nurse', label: 'Clinical Nurse' },
          { value: 'Critical Care Nurse', label: 'Critical Care Nurse' },
          { value: 'Dental Nurse', label: 'Dental Nurse' },
          { value: 'Dermatology', label: 'Dermatology' },
          { value: 'Emergency Medicine', label: 'Emergency Medicine' },
          { value: 'Endocrinology', label: 'Endocrinology' },
          { value: 'Family Nurse', label: 'Family Nurse' },
          { value: 'Gastroenterology', label: 'Gastroenterology' },
          { value: 'General Practice Nurse', label: 'General Practice Nurse' },
          { value: 'Geriatric Nurse', label: 'Geriatric Nurse' },
          { value: 'Haematology', label: 'Haematology' },
          { value: 'Infectious Disease', label: 'Infectious Disease' },
          { value: 'Mental Health', label: 'Mental Health' },
          { value: 'Neonatal Nurse', label: 'Neonatal Nurse' },
          { value: 'Neurology', label: 'Neurology' },
          { value: 'Neurosurgery', label: 'Neurosurgery' },
          { value: 'Obstetrics and Gynecology', label: 'Obstetrics and Gynecology' },
          { value: 'Oncology', label: 'Oncology' },
          { value: 'Oncology Nurse', label: 'Oncology Nurse' },
          { value: 'Ophthalmology', label: 'Ophthalmology' },
          { value: 'Orthopaedics', label: 'Orthopaedics' },
          { value: 'Otolaryngology', label: 'Otolaryngology' },
          { value: 'Paediatrics', label: 'Paediatrics' },
          { value: 'Pain Management', label: 'Pain Management' },
          { value: 'Palliative Care', label: 'Palliative Care' },
          { value: 'Physical Medicine and Rehabilitation', label: 'Physical Medicine and Rehabilitation' },
          { value: 'Physical Therapy', label: 'Physical Therapy' },
          { value: 'Physiology', label: 'Physiology' },
          { value: 'Psychiatry', label: 'Psychiatry' },
          { value: 'Public Health', label: 'Public Health' },
          { value: 'Pulmonology', label: 'Pulmonology' },
          { value: 'Radiology', label: 'Radiology' },
          { value: 'Registered Nurse', label: 'Registered Nurse' },
          { value: 'Rheumatology', label: 'Rheumatology' },
          { value: 'Senior Nurse', label: 'Senior Nurse' },
          { value: 'Senior Staff Nurse', label: 'Senior Staff Nurse' },
          { value: 'Specialist Nurse', label: 'Specialist Nurse' },
          { value: 'Staff Nurse', label: 'Staff Nurse' },
          { value: 'Surgical Nurse', label: 'Surgical Nurse' },
          { value: 'Theatre Nurse', label: 'Theatre Nurse' },
          { value: 'Thoracic Surgery', label: 'Thoracic Surgery' },
          { value: 'Urology', label: 'Urology' },
          { value: 'Vascular Surgery', label: 'Vascular Surgery' },
          { value: 'Physician Associate', label: 'Physician Associate' },
          { value: 'Occupational Therapy', label: 'Occupational Therapy' },
          { value: 'Speech and Language Therapy', label: 'Speech and Language Therapy' },
          { value: 'Physiotherapy', label: 'Physiotherapy' },
          { value: 'Radiography', label: 'Radiography' },
          { value: 'Radiotherapy', label: 'Radiotherapy' },
          { value: 'Podiatrist', label: 'Podiatrist' },
          { value: 'Dietician', label: 'Dietician' },
          { value: 'Audiology', label: 'Audiology' },
          { value: 'Biomedical Science', label: 'Biomedical Science' },
          { value: 'Clinical Physiology', label: 'Clinical Physiology' },
          { value: 'Pharmacy', label: 'Pharmacy' },
          { value: 'Phlebotomy', label: 'Phlebotomy' },
          { value: 'Vascular Science', label: 'Vascular Science' },
          { value: 'Echo Cardiographer', label: 'Echo Cardiographer' },
          { value: 'Plaster Room', label: 'Plaster Room' },
          { value: 'Orthopaedic Practitioner', label: 'Orthopaedic Practitioner' },
        ],
        Doctor: [
          { value: 'General Practitioner', label: 'General Practitioner' },
          { value: 'General Medicine', label: 'General Medicine' },
          { value: 'Acute Medicine', label: 'Acute Medicine' },
          { value: 'Emergency Medicine', label: 'Emergency Medicine' },
          { value: 'Geriatric Medicine', label: 'Geriatric Medicine' },
          { value: 'Internal Medicine', label: 'Internal Medicine' },
          { value: 'Respiratory Medicine', label: 'Respiratory Medicine' },
          { value: 'Stroke Medicine', label: 'Stroke Medicine' },
          { value: 'Palliative Medicine', label: 'Palliative Medicine' },
          { value: 'Rehabilitation Medicine', label: 'Rehabilitation Medicine' },
          { value: 'Paediatrics', label: 'Paediatrics' },
          { value: 'Obstetrics', label: 'Obstetrics' },
          { value: 'Neonatology', label: 'Neonatology' },
          { value: 'Dermatology', label: 'Dermatology' },
          { value: 'Neurology', label: 'Neurology' },
          { value: 'Cardiology', label: 'Cardiology' },
          { value: 'Ophthalmology', label: 'Ophthalmology' },
          { value: 'Gynaecology', label: 'Gynaecology' },
          { value: 'Gastroenterology', label: 'Gastroenterology' },
          { value: 'Hepatology', label: 'Hepatology' },
          { value: 'Urology', label: 'Urology' },
          { value: 'Rheumatology', label: 'Rheumatology' },
          { value: 'Pulmonology', label: 'Pulmonology' },
          { value: 'ENT', label: 'ENT' },
          { value: 'Oncology', label: 'Oncology' },
          { value: 'Clinical Oncology', label: 'Clinical Oncology' },
          { value: 'Haematology', label: 'Haematology' },
          { value: 'Immunology', label: 'Immunology' },
          { value: 'Nephrology', label: 'Nephrology' },
          { value: 'Orthopaedics', label: 'Orthopaedics' },
          { value: 'Neurophysiology', label: 'Neurophysiology' },
          { value: 'Critical Care', label: 'Critical Care' },
          { value: 'Palliative Care', label: 'Palliative Care' },
          { value: 'Stroke Specialisation', label: 'Stroke Specialisation' },
          { value: 'Spinal Cord Injuries Specialistion', label: 'Spinal Cord Injuries Specialistion' },
          { value: 'Psychiatry', label: 'Psychiatry' },
          { value: 'Adult Psychiatry', label: 'Adult Psychiatry' },
          { value: 'Child & Adolescent Psychiatry', label: 'Child & Adolescent Psychiatry' },
          { value: 'Older Person Psychiatry', label: 'Older Person Psychiatry' },
          { value: 'Perinatal Psychiatry', label: 'Perinatal Psychiatry' },
          { value: 'Psychology', label: 'Psychology' },
          { value: 'General Surgery', label: 'General Surgery' },
          { value: 'Neurosurgery', label: 'Neurosurgery' },
          { value: 'Oral Surgery', label: 'Oral Surgery' },
          { value: 'Orthopedic Surgery', label: 'Orthopedic Surgery' },
          { value: 'Thoracic Surgery', label: 'Thoracic Surgery' },
          { value: 'Cardiothoracic Surgery', label: 'Cardiothoracic Surgery' },
          { value: 'Colorectal Surgery', label: 'Colorectal Surgery' },
          { value: 'Oral and Maxillofacial Surgery', label: 'Oral and Maxillofacial Surgery' },
          { value: 'Bariatric Surgery', label: 'Bariatric Surgery' },
          { value: 'Urologic Surgery', label: 'Urologic Surgery' },
          { value: 'Vascular Surgery', label: 'Vascular Surgery' },
          { value: 'Plastic Surgery', label: 'Plastic Surgery' },
          { value: 'Oncoplastic Surgery', label: 'Oncoplastic Surgery' },
          { value: 'Pathology', label: 'Pathology' },
          { value: 'Histopathology', label: 'Histopathology' },
          { value: 'Hematopathology', label: 'Hematopathology' },
          { value: 'Cellular Pathology', label: 'Cellular Pathology' },
          { value: 'Radiology', label: 'Radiology' },
          { value: 'Interventional Radiology', label: 'Interventional Radiology' },
          { value: 'Nuclear Radiology', label: 'Nuclear Radiology' },
          { value: 'Microbiology', label: 'Microbiology' },
          { value: 'Anaesthesiology', label: 'Anaesthesiology' },
          { value: 'Dentistry', label: 'Dentistry' },
          { value: 'Pedodontistry', label: 'Pedodontistry' },
          { value: 'Orthodontics', label: 'Orthodontics' },
          { value: 'Periodontology', label: 'Periodontology' },
          { value: 'Endodontistry', label: 'Endodontistry' },
          { value: 'Prosthodontistry', label: 'Prosthodontistry' },
          { value: 'Allergy and Immunology', label: 'Allergy and Immunology' },
          { value: 'Audiology', label: 'Audiology' },
          { value: 'Critical Care Medicine', label: 'Critical Care Medicine' },
          { value: 'Endocrinology', label: 'Endocrinology' },
          { value: 'Epidemiology', label: 'Epidemiology' },
          { value: 'General Practice', label: 'General Practice' },
          { value: 'Genetics', label: 'Genetics' },
          { value: 'Infectious Disease', label: 'Infectious Disease' },
          { value: 'Maternal-Fetal Medicine', label: 'Maternal-Fetal Medicine' },
          { value: 'Nuclear Medicine', label: 'Nuclear Medicine' },
          { value: 'Obstetrics and Gynecology', label: 'Obstetrics and Gynecology' },
          { value: 'Occupational Medicine', label: 'Occupational Medicine' },
          { value: 'Optometry', label: 'Optometry' },
          { value: 'Otolaryngology', label: 'Otolaryngology' },
          { value: 'Pain Management', label: 'Pain Management' },
          { value: 'Pharmacology', label: 'Pharmacology' },
          { value: 'Physical Medicine and Rehabilitation', label: 'Physical Medicine and Rehabilitation' },
          { value: 'Physical Therapy', label: 'Physical Therapy' },
          { value: 'Physiology', label: 'Physiology' },
          { value: 'Plastic and Reconstructive Surgery', label: 'Plastic and Reconstructive Surgery' },
          { value: 'Podiatric Surgery', label: 'Podiatric Surgery' },
          { value: 'Podiatry', label: 'Podiatry' },
          { value: 'Public Health', label: 'Public Health' },
          { value: 'Reproductive Medicine', label: 'Reproductive Medicine' },
          { value: 'Therapy', label: 'Therapy' },

        ],
      };

      return specialisations[selectedProfession] || [];
    },
    selection: 'multi',
    dependent: 'profession',
    emptyStateMsg: 'Please select profession first.',
  },
];
