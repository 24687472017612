/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../header';
import Breadcrumbs from '../breadcrumbs';

function LandingLayout({ children, ...rest }) {
  return (
    <div className="wrapper">
      <div className="center-content">
        <Header {...rest} />
        <Breadcrumbs />
        {children}
      </div>
    </div>

  );
}

export default withRouter(LandingLayout);
