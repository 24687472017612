/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable max-len */
/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import OTPInput from 'react-otp-input';

import AuthAPI from '../../apis/authApi';
import Helper from '../../services/helper';

import { getPortalContent } from '../../redux/reducers/languageReducer';
import { loginWithPhoneRequest } from '../../redux/actions/action';
import {
  validateOTP, getQueryParams, formatPhoneNumber,
} from '../../utils/helpers';
import {
  EMAIL, PHONE, RETRY, OTP_SENT, DEFAULT_COUNTRY_CODE,
} from '../../utils/constants';
import {
  LAST_VISITED_URL,
  COOKIE_PATH,
  CANDIDATE_ROLE,
  CANDIDATE_PLATFORM,
  CANDIDATE_TOKEN,
  CANDIDATE_REFRESH_TOKEN,
  COOKIE_DOMAIN,
  RETURN_URL,
  LOCAL_PLATFORM_CANDIDATE,
  ENVIRONMENTS,
} from '../../services/constants';

import ResendOTPTimer from '../../components/resendOtpTimer';
import { getCountryCodeList, searchArrayByString } from '../../utils/countryCodeList';
import useOutsideClick from '../../hooks/useOutsideClick';

import Logo from '../../images/ternLogo.svg';
import DownArrow from '../../images/downIcon.svg';
import SearchIcon from '../../images/searchIcon.svg';
import styles from './styles.module.scss';
import EditSVG from '../../components/svg/editSvg';

const RESEND_OTP_TIMER = 30; // resend opt timer 30s
const ENV = process.env.REACT_APP_ENV; // for development only

function PhoneNumberLogin(props) {
  const {
    changeLoginType, otpRetry, setOtpRetry, allowLoginWithEmail = true,
  } = props;
  const history = useHistory();
  const portalContent = useSelector(getPortalContent);
  const dispatch = useDispatch();
  const [inputPhoneNumber, setinputPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [isOtpGenerated, setIsOtpGenerated] = useState(false);
  const [generateOtpLoader, setGenerateOtpLoader] = useState(false);
  const [otpResendTimer, setOtpResendTimer] = useState(false);
  const [reqParams, setReqParams] = useState({});
  const [countryCodeSelector, setCountryCodeSelector] = useState(DEFAULT_COUNTRY_CODE);
  const [countryCodes, setCountryCodes] = useState(getCountryCodeList());
  const [isCountryCodeModal, setIsCountryCodeModal] = useState(false);
  const [countrySearchIpt, setCountrySearchIpt] = useState('');
  const [error, setError] = useState({
    retry: false,
    retryMsg: portalContent.RETRY_ERROR,
    validPhoneNumber: false,
    validatePhoneNumberMsg: portalContent.PHONE_NUMBER_VALIDATION_ERROR,
    otp: false,
    otpMsg: portalContent.OTP_ERROR,
  });

  const countryCodesRef = useOutsideClick(() => setIsCountryCodeModal(false));

  // TODO: need fixing after release
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies([
    CANDIDATE_TOKEN,
    CANDIDATE_REFRESH_TOKEN,
  ]);

  const {
    loginData, isLoginError, isLoading, isLoginSuccess,
  } = useSelector((state) => state.authReducer);

  const handleCountrySearchInpt = (e) => {
    const { value } = e.target;
    setCountrySearchIpt(value);
    const filterValues = searchArrayByString(value);
    setCountryCodes(filterValues);
  };

  const handleCountryCodeChange = (value) => {
    setCountryCodeSelector(value);
  };

  const handleCountryCodeModalToggle = (toggle = null) => {
    if (toggle) {
      setIsCountryCodeModal(toggle);
    }
    setIsCountryCodeModal(!isCountryCodeModal);
  };

  const renderAlert = (type, msg = '') => {
    const style = {
      position: 'top-center',
    };
    if (type === 'error') {
      const message = msg || portalContent.SOMETHING_WENT_WRONG;
      return toast.error(message, style);
    }
    if (type === 'success') {
      const message = msg || OTP_SENT;
      return toast.success(message, style);
    }
    return null;
  };

  useEffect(() => {
    if (isLoginError) {
      renderAlert('error', loginData.responseMessage);
    }
  }, [isLoginError]);

  const handleOtpTimer = () => {
    setOtpResendTimer(false);
  };

  const handleError = (type) => {
    if (type === RETRY) {
      setError({ ...error, retry: true });
    }
    if (type === PHONE) {
      setError({
        ...error,
        validPhoneNumber: true,
      });
    }
  };

  const handleInputPhoneChange = (e) => {
    const { value } = e.target;
    setinputPhoneNumber(value);
    setError({
      ...error, retry: false, validPhoneNumber: false, otp: false,
    });

    if (value.length < 1) return;

    if (!/^\d+$/.test(value)) {
      handleError(PHONE);
    }
  };

  const handleOtpChange = (value) => {
    setOtp(value);
    setReqParams({ ...reqParams, otp: value });
    setError({
      ...error, otp: false,
    });
  };

  useEffect(() => {
    setReqParams({ ...reqParams, phoneNumber: countryCodeSelector + inputPhoneNumber });
  }, [inputPhoneNumber, countryCodeSelector]);

  useEffect(() => {
    const phoneNumber = inputPhoneNumber.replace(/\s+/g, '');

    setReqParams({ ...reqParams, session: loginData?.session, phoneNumber: `${countryCodeSelector}${phoneNumber}` });
  }, [loginData, isLoginError, inputPhoneNumber]);

  const generateOtp = async () => {
    // const isIndian = isIndianPhoneNumber(); this validates if phone number entered from india
    if (inputPhoneNumber.length > 16) {
      handleError(PHONE);
      return;
    }
    let login;
    try {
      setGenerateOtpLoader(true);
      setOtp('');
      login = await AuthAPI.GenerateOTP(reqParams);
      setGenerateOtpLoader(false);
      setIsOtpGenerated(true);
      setOtpResendTimer(true);
      const retryLeft = otpRetry - 1;
      setOtpRetry(retryLeft);
      setReqParams({
        ...reqParams,
        session: login?.data?.session,
      });
      renderAlert('success');
    } catch (error) {
      setGenerateOtpLoader(false);
      renderAlert('error', portalContent.PHONE_NUMBER_NOT_REGISTERED_ERROR);
    }
  };

  const handleEnter = (e, callback) => {
    if (e.key === 'Enter') {
      callback();
    }
  };

  const handleLogin = async () => {
    if (otp.length > 3 && validateOTP(otp)) {
      dispatch(loginWithPhoneRequest(reqParams));
    } else {
      renderAlert('error', loginData?.responseMessage);
      setError({
        ...error, otp: true,
      });
    }
  };

  const resendOtp = async () => {
    if (otpRetry > 0) {
      await generateOtp();
    } else {
      const retryLeft = otpRetry - 1;
      setOtpRetry(retryLeft);
      setError({
        ...error, retry: true,
      });
    }
  };

  const editOtpPhoneNumber = () => {
    setIsOtpGenerated(false);
  };

  const renderInputSearch = () => (
    <div className={styles.searchContainer}>
      <img src={SearchIcon} alt="SearchIcon" width="20px" height="20px" />
      <input autoFocus value={countrySearchIpt} onChange={handleCountrySearchInpt} className={classnames(styles.searchInput, 'body-3')} type="text" placeholder="Search.." />
    </div>
  );

  const handleSelectOptionClick = (countryCode) => {
    handleCountryCodeChange(countryCode);
    handleCountryCodeModalToggle(false);
  };

  const renderCountryCodeSelect = () => (
    <div ref={countryCodesRef} className={classnames(styles.selectContainer, 'body-3')}>
      <div onClick={handleCountryCodeModalToggle} className={styles.selectLabel}>
        <div className={styles.labelCountryFlag}>{countryCodeSelector}</div>
        <img src={DownArrow} alt="downArrow" width="20px" height="20px" />
      </div>
      {isCountryCodeModal
        && (
          <div className={styles.selectContainerOptionContainerParent}>
              {renderInputSearch()}
            <div className={styles.selectContainerOptionContainer}>
              {countryCodes.map((obj) => (
                <div key={obj.uinqueKey} onClick={() => handleSelectOptionClick(obj.countryCallingCode)} className={styles.selectOption}>
                  <div className={styles.countryFlag} dangerouslySetInnerHTML={{ __html: obj.countryFlag }} />
                  <div className={styles.selectOptionLabel}>
                    <div>
                      {obj.countryNameEn}
                    </div>
                    <div>
                      {obj.countryCallingCode}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
    </div>
  );

  const renderInput = () => {
    if (!isOtpGenerated) {
      return (
        <div className={styles.inpContainer}>
          <div className={styles.labelText}>
            {portalContent.ENTER_PHONE_NUMBER}
          </div>
          <div className={styles.phoneIptContainer}>
            {renderCountryCodeSelect()}
            <input autoFocus value={inputPhoneNumber} onKeyUp={(e) => handleEnter(e, generateOtp)} onChange={handleInputPhoneChange} className={styles.phoneIpt} type="text" inputMode="numeric" placeholder={portalContent.PHONE_NUMBER_INPUT_PLACEHOLDER} disabled={generateOtpLoader} />
          </div>
          <div className={`errorText body-3 ${!error.validPhoneNumber && 'visibilityHidden'}`}>{error.validatePhoneNumberMsg}</div>
        </div>
      );
    }
    return (
      <div className={styles.inpContainer}>
        <div className={classnames(styles.labelText, styles.otpSent, styles.otpSentContainer)}>
          {`${portalContent.OTP_SENT_TO} ${formatPhoneNumber(inputPhoneNumber)}`}
          <EditSVG onclick={editOtpPhoneNumber} cssClass={styles.editPhoneNumber} defaultColorHexCode="#545151" />
        </div>
        <div className={classnames('heading-3', styles.otpLabel)}>{portalContent.ENTER_OTP}</div>
        <OTPInput
          value={otp}
          onChange={handleOtpChange}
          containerStyle={styles.optInptContainer}
          inputStyle={styles.optInpt}
          inputType="tel"
          numInputs={4}
          shouldAutoFocus
          // eslint-disable-next-line react/jsx-props-no-spreading
          renderInput={(props) => <input onKeyUp={(e) => handleEnter(e, handleLogin)} {...props} />}
        />
        <div className={`errorText body-3 ${styles.textAlignCenter} ${!error.otp && 'visibilityHidden'}`}>{error.otpMsg}</div>
      </div>
    );
  };

  const renderSubmitBtn = () => {
    if (otpRetry < 0) {
      return (
        <div className="errorText body-3">
          {error.retryMsg}
        </div>
      );
    }
    if (isOtpGenerated) {
      return (
        <div className={styles.btnContainer}>
          <button onClick={handleLogin} type="button" className={classnames(styles.btnStyle, 'button-text button-primary')} disabled={otp.length < 4}>
            {isLoading
              ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              )
              : portalContent.LOGIN_TO_TERN}
          </button>
          {otpResendTimer
            ? <ResendOTPTimer durationInSeconds={RESEND_OTP_TIMER} onTimerEnd={handleOtpTimer} />
            : (
              <button onClick={resendOtp} type="button" className={classnames('button-text button-secondary', styles.submitCTAs)}>
                { generateOtpLoader
                  ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )
                  : portalContent.RESEND_OTP}
              </button>
            )}
        </div>
      );
    }
    return (
      <div className={styles.btnContainer}>
        <button onClick={generateOtp} type="submit" className={classnames(styles.btnStyle, 'button-text button-primary')} disabled={inputPhoneNumber.length < 5}>
          { generateOtpLoader
            ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            )
            : portalContent.SEND_OTP}
        </button>
        {allowLoginWithEmail && (
        <button onClick={() => changeLoginType(EMAIL)} type="submit" className={classnames('button-text button-secondary', styles.submitCTAs)}>
          {portalContent.LOGIN_WITH_EMAIL}
        </button>
        )}
      </div>
    );
  };

  const setCookies = (loginData) => {
    const cookieOptions = {
      path: COOKIE_PATH,
      secure: true,
      sameSite: 'strict',
      domain: COOKIE_DOMAIN,
    };

    const userRoles = Helper.getUserRoles(loginData?.payload?.token);
    if (Helper.indexOfArray(CANDIDATE_ROLE, userRoles) !== -1) {
      if (ENV === ENVIRONMENTS.LOCAL) {
        setCookie(CANDIDATE_TOKEN, loginData?.payload?.token);
        setCookie(
          CANDIDATE_REFRESH_TOKEN,
          loginData?.payload?.refreshToken,
        );
      } else {
        setCookie(CANDIDATE_TOKEN, loginData?.payload?.token, cookieOptions);
        setCookie(
          CANDIDATE_REFRESH_TOKEN,
          loginData?.payload?.refreshToken,
          cookieOptions,
        );
      }

      let redirectUrl = ENV === ENVIRONMENTS.LOCAL ? LOCAL_PLATFORM_CANDIDATE : CANDIDATE_PLATFORM;
      const queryParams = getQueryParams();
      if (Object.prototype.hasOwnProperty.call(queryParams, RETURN_URL)) {
        redirectUrl += queryParams.returnUrl;
      }

      window.location.assign(redirectUrl);
    } else {
      const storedData = Helper.sessionGetItem(LAST_VISITED_URL);
      if (storedData) {
        history.push(storedData);
      }
    }
  };

  useEffect(() => {
    Helper.checkAndRedirectToHomePage(history);

    if (
      isLoginSuccess
      && loginData.authType === 'login'
    ) {
      setCookies(loginData);
    }

    if (
      isLoginSuccess
      && (loginData.authType === 'reset' || loginData.authType === 'expire')
    ) {
      history.push('/reset-password');
    }
  }, [isLoginSuccess, isLoginError]);

  return (
    <div className={styles.phoneLoginContainer}>
      <img src={Logo} className={styles.logo} alt="Logo" />
      {renderInput()}
      {renderSubmitBtn()}
    </div>
  );
}

export default PhoneNumberLogin;
