import React from 'react';

import { Input } from 'reactstrap';
import styles from './styles.module.scss';
import inputStyles from '../styles.module.scss';

function InputWithTitle({
  title,
  layoutStyle,
  inputValue,
  onChangeInput,
  inputPlaceholder,
  inputType = 'text',
  inputStyle,
  error,
}) {
  return (
    <div className={styles.inputLayout} style={layoutStyle}>
      <div className="body-2-no-color">{title}</div>
      <Input
        className={styles.inputBoxLayout}
        min={0}
        type={inputType}
        style={inputStyle}
        value={inputValue}
        onChange={(event) => onChangeInput(event.target.value)}
        name="inputBox"
        placeholder={inputPlaceholder}
      />
      {error && <span className={inputStyles.danger}>{error}</span>}
    </div>
  );
}

export default InputWithTitle;
