export const feedbackOptions = [
  {
    value: 'Interviewer Unavailable',
    label: 'Interviewer Unavailable',
    id: 'Interviewer_Unavailable',
  },
  {
    value: 'Candidate No-Show',
    label: 'Candidate No-Show',
    id: 'Candidate_No-Show',
  },
  {
    value: 'Job Opening Closed',
    label: 'Job Opening Closed',
    id: 'Job_Opening_Closed',
  },
  {
    value: 'Job Opening On-Hold',
    label: 'Job Opening On-Hold',
    id: 'Job_Opening_On-Hold',
  },
];
